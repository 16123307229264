import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { patch } from '@ngxs/store/operators';
import { removeTeacherList, TeacherDetailedList } from './teacherlist.action';

export interface teacherListDetailsModal {
  teacherData: any[];
}

@State<any>({
  name: 'teacherList',
  defaults: {
    teacherData: [],
  },
})
@Injectable()
export class teacherListState {
  @Selector() static getTeacherDetailedList(state: teacherListDetailsModal) {
    return state.teacherData;
  }
  constructor() {}

  @Action(TeacherDetailedList)
  setTeacherList(ctx: StateContext<any>, { teacherData }: TeacherDetailedList) {
    ctx.setState(
      patch({
        teacherData,
      })
    );
  }

  @Action(removeTeacherList)
  removeTeacherList(ctx: StateContext<any>) {
    ctx.setState(patch({ teacherData: [] }));
  }
}
