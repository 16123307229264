import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  RemoveClasses,
  SchoolClasses,
  updatingClassStudentsList,
} from './class-list.action';

@State<any>({
  name: 'ClassList',
  defaults: {
    classList: [],
  },
})
@Injectable()
export class ClassListState {
  @Select() static getClassList(state: any) {
    return state.ClassList.classList;
  }
  constructor() {}

  @Action(SchoolClasses)
  addClasses(ctx: StateContext<any>, { classList }: any) {
    console.log('new dispatch', classList);
    ctx.setState(
      patch({
        classList: classList,
      })
    );
  }

  @Action(RemoveClasses)
  removeClasses(ctx: StateContext<any>) {
    ctx.setState(patch({ classList: [] }));
  }
}
