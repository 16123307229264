import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnChanges, SimpleChanges } from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { curriculum } from 'src/app/auth/shared/models/school';
import { Category, LibraryFine, SchoolLibrary } from '../models/library.model';
import { LibraryBook } from '../models/library_book.model';
import { LibraryBookRequest } from '../models/library_book_request.model';
import { SelectedSchoolState } from '../store/school/selected-school.state';
import { CommonService } from './common.service';
import { SnackbarService } from './snackbar.service';
import { isEmpty } from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {
  currentschoolDocId: string = '';
  selectedPhotoFile!: File;
  selectedBookPhotoFile!: File;
  libraryPhotoUrl: string = '';
  libraryProfileUploadTask!: AngularFireUploadTask;
  libraryProfileUploadPercentage!: Observable<number | undefined>;
  libraryProfileUploadSnapShot!: Observable<any>;
  libraryId: string = '';
  progress: boolean = false;
  newBookPhotoUrl: string = '';
  newBookProfileUploadTask!: AngularFireUploadTask;
  newBookProfileUploadPercentage!: Observable<number | undefined>;
  newBookProfileUploadSnapShot!: Observable<any>;
  newBookId: string = '';
  newBookProgress: boolean = false;
  selectedBorrowerSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  selectedStudent: any = {};
  selectedTeacher: any = {};

  categories: string[] = [
    'agriculture',
    'chemistry',
    'commerce and accountancy',
    'economics',
    'geography',
    'geology',
    'history',
    'political science',
    'mathematics',
    'medical science',
    'philosophy',
    'physics',
    'psychology',
    'sociology',
    'statistics',
    'science',
    'adventure stories',
    'classics',
    'crime',
    'fairy tales',
    'fantasy',
    'historical fiction',
    'horror',
    'humour and satire',
    'mystery',
    'poetry',
    'plays',
    'romance',
    'science fiction',
    'short stories',
    'thrillers',
    'young adult',
  ];
  selectedSchoolLibraryCategories: string[] = [];
  curriculum: curriculum;

  constructor(
    private fireStore: AngularFirestore,
    private store: Store,
    private commonSrvc_: CommonService,
    private snackBarSrvc_: SnackbarService,
    private fireStorage: AngularFireStorage,
    private httpClient: HttpClient
  ) {
    let selectedAdmin$ = this.store.select(SelectedSchoolState);
    let currentSchoolDetails$: Observable<any> = selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.commonSrvc_.getCurrentSchoolDetails(
            data.selectedAdmin.school_id
          );
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      this.currentschoolDocId = el.id;
      this.curriculum = el.curriculum;
    });
  }

  //create Library

  createLibrary(library: SchoolLibrary) {
    return this.fireStore.collection('school_library').add(library);
  }

  updateFineSystem(libraryId: string, fine: LibraryFine | null) {
    return this.fireStore.collection('school_library').doc(libraryId).update({
      fine_system: fine,
    });
  }

  getFirebaseId(string: string) {
    let autogenUid = this.fireStore.createId();
    if (string == 'library') {
      this.libraryId = autogenUid;
    } else if (string == 'book') {
      this.newBookId = autogenUid;
    }
    // return autogenUid;
  }

  fetchLibraryBooks(libraryId: string) {
    return this.fireStore
      .collection('library_books', (ref) =>
        ref
          .where('school_id', '==', this.currentschoolDocId)
          .where('library_id', '==', libraryId)
          .where('deleted', '==', false)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.created_at = data.created_at.toDate();
            // data.inauguration_date = data.inauguration_date.toDate();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  setLibraryDoc(schoolLibrary: SchoolLibrary) {
    return this.fireStore
      .collection('school_library')
      .doc(this.libraryId)
      .set(schoolLibrary);
  }

  updateLibraryDoc(
    libraryId: string,
    categories: Category[],
    totalBooks: number
  ) {
    return this.fireStore.collection('school_library').doc(libraryId).update({
      categories: categories,
      total_books: totalBooks,
    });
  }

  updateCategoriesTotalLostTotalBooksInLibraryDoc(
    libraryId: string,
    categories: Category[]
  ) {
    return this.fireStore
      .collection('school_library')
      .doc(libraryId)
      .update({
        categories: categories,
        total_books: firebase.firestore.FieldValue.increment(-1),
        total_lost: firebase.firestore.FieldValue.increment(1),
      });
  }

  uploadLibraryProfileImage() {
    this.getFirebaseId('library');
    this.progress = true;
    const filePath = `libraries/${this.libraryId}_${this.currentschoolDocId}.jpeg`;
    const ref = this.fireStorage.ref(filePath);
    this.libraryProfileUploadTask = this.fireStorage.upload(
      filePath,
      this.selectedPhotoFile
    );
    this.libraryProfileUploadPercentage =
      this.libraryProfileUploadTask.percentageChanges();
    this.libraryProfileUploadSnapShot = this.libraryProfileUploadTask
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          await ref
            .getDownloadURL()
            .toPromise()
            .then((url: any) => {
              this.libraryPhotoUrl = url;
              console.log(this.libraryPhotoUrl);
              this.progress = false;
              this.snackBarSrvc_.openSnackBarTop(
                'Profile photo uploaded successfully',
                'Success'
              );
            })
            .catch((error: any) => {
              this.snackBarSrvc_.openSnackBarTop(error, 'Failure');
              this.progress = false;
            });
        })
      );
    // this.profileUploadSnapShot.subscribe();
  }

  uploadBookCoverImage() {
    this.getFirebaseId('book');
    this.newBookProgress = true;
    const filePath = `library_books/${this.newBookId}.jpeg`;
    const ref = this.fireStorage.ref(filePath);
    this.newBookProfileUploadTask = this.fireStorage.upload(
      filePath,
      this.selectedBookPhotoFile
    );
    this.newBookProfileUploadPercentage =
      this.newBookProfileUploadTask.percentageChanges();
    this.newBookProfileUploadSnapShot = this.newBookProfileUploadTask
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          await ref
            .getDownloadURL()
            .toPromise()
            .then((url: any) => {
              this.newBookPhotoUrl = url;
              console.log(this.newBookPhotoUrl);
              this.newBookProgress = false;
              this.snackBarSrvc_.openSnackBarTop(
                'Book cover photo uploaded successfully',
                'Success'
              );
            })
            .catch((error: any) => {
              this.snackBarSrvc_.openSnackBarTop(error, 'Failure');
              this.newBookProgress = false;
            });
        })
      );
  }

  setBookDoc(book: LibraryBook) {
    return this.fireStore
      .collection('library_books')
      .doc(this.newBookId)
      .set(book);
  }

  addBook(book: LibraryBook) {
    return this.fireStore.collection('library_books').add(book);
  }

  //get School Library
  getSchoolLibrary() {
    return this.fireStore
      .collection('school_library', (ref) =>
        ref.where('school_id', '==', this.currentschoolDocId).limit(1)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.created_at = data.created_at.toDate();
            data.inauguration_date = data.inauguration_date.toDate();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  getBookDetailsByISBN(ISBNCode: string) {
    let headers = new HttpHeaders();
    headers = headers.set(
      'Authorization',
      '48242_41a621d33a9a5bf3c4bdb1c907872eb7'
    );
    headers.set('Content-Type', 'application/json');
    return this.httpClient.get(`https://api2.isbndb.com/book/${ISBNCode}`, {
      headers,
    });
  }

  getLibraryDoc(libId: string) {
    return this.fireStore
      .collection('school_library')
      .doc(libId)
      .get()
      .pipe(
        map((documentSnapshot) => {
          var id = documentSnapshot.id;
          var data: any = documentSnapshot.data();
          data.created_at = data.created_at.toDate();
          data.inauguration_date = data.inauguration_date.toDate();
          return { ...data, id };
        })
      );
  }

  getTotalBookRequests():Observable<any> {
    return this.fireStore
      .collection('library_book_requests', (ref) =>
        ref.where('school_id', '==', this.currentschoolDocId)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            data.created_at = data.created_at.toDate();
            data.returned_at =
              data.returned_at == null ? null : data.returned_at?.toDate();
            data.lost_at = data.lost_at == null ? null : data.lost_at?.toDate();
            data.issued_at =
              data.issued_at == null ? null : data.issued_at?.toDate();
            data.rejected_at =
              data.rejected_at == null ? null : data.rejected_at?.toDate();
            data.return_date =
              data.return_date == null ? null : data.return_date?.toDate();
            data.requested_at =
              data.requested_at == null ? null : data.requested_at?.toDate();
            if (data.user_review != null) {
              data.user_review.date = data.user_review?.date?.toDate();
            } else {
              data.user_review = null;
            }
            // data.class_ref = data.class_ref.path;
            return { ...data, id  };
          })
        )
      );
  }

  // fetchTotalBookRequests() {
  //   return this.fireStore
  //     .collection('library_book_requests', (ref) =>
  //       ref.where('school_id', '==', this.currentschoolDocId)
  //     )
  //     .snapshotChanges()
  //     .pipe(
  //       map((actions) => {
  //         return actions.map((a) => {
  //           const data: any = a.payload.doc.data();
  //           const id = a.payload.doc.id;
  //           data.created_at = data.created_at.toDate();
  //           data.returned_at =
  //             data.returned_at == null ? null : data.returned_at?.toDate();
  //           data.lost_at = data.lost_at == null ? null : data.lost_at?.toDate();
  //           data.issued_at =
  //             data.issued_at == null ? null : data.issued_at?.toDate();
  //           data.rejected_at =
  //             data.rejected_at == null ? null : data.rejected_at?.toDate();
  //           data.return_date =
  //             data.return_date == null ? null : data.return_date?.toDate();
  //           data.requested_at =
  //             data.requested_at == null ? null : data.requested_at?.toDate();
  //           if (data.user_review != null) {
  //             data.user_review.date = data.user_review?.date?.toDate();
  //           } else {
  //             data.user_review = null;
  //           }

  //           // data.inauguration_date = data.inauguration_date.toDate();
  //           return {
  //             id,
  //             ...data,
  //           };
  //         });
  //       })
  //     );
  // }

  updateLibraryRequestDoc(docId: string, fieldObjects: any) {
    return this.fireStore
      .collection('library_book_requests')
      .doc(docId)
      .update(fieldObjects);
  }

  updateSchoolLibraryDoc(docId: string, fieldObjects: any) {
    return this.fireStore
      .collection('school_library')
      .doc(docId)
      .update(fieldObjects);
  }

  incrementTotalIssuedInLibraryDoc(docId: string, count: number) {
    return this.fireStore
      .collection('school_library')
      .doc(docId)
      .update({
        total_issued: firebase.firestore.FieldValue.increment(count),
      });
  }

  incrementTotalReturnedInLibraryDoc(docId: string, count: number) {
    return this.fireStore
      .collection('school_library')
      .doc(docId)
      .update({
        total_returned: firebase.firestore.FieldValue.increment(count),
      });
  }

  incrementIssuedAndTotalIssuedInBookDoc(docId: string) {
    return this.fireStore
      .collection('library_books')
      .doc(docId)
      .update({
        total_issued: firebase.firestore.FieldValue.increment(1),
        issued: firebase.firestore.FieldValue.increment(1),
      });
  }

  decrementIssuedInBookDoc(docId: string) {
    return this.fireStore
      .collection('library_books')
      .doc(docId)
      .update({
        issued: firebase.firestore.FieldValue.increment(-1),
      });
  }

  updateIssuedQuantityTotalLostInBookDoc(docId: string) {
    return this.fireStore
      .collection('library_books')
      .doc(docId)
      .update({
        issued: firebase.firestore.FieldValue.increment(-1),
        quantity: firebase.firestore.FieldValue.increment(-1),
        total_lost: firebase.firestore.FieldValue.increment(1),
      });
  }

  updateSchoolLibraryBookDoc(docId: string, fieldObjects: any) {
    return this.fireStore
      .collection('library_books')
      .doc(docId)
      .update(fieldObjects);
  }

  addLibraryRequestDoc(libraryRequestDoc: LibraryBookRequest) {
    return this.fireStore
      .collection('library_book_requests')
      .add(libraryRequestDoc);
  }
}
