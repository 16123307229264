import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { patch } from '@ngxs/store/operators';
import { selectedQuestionpaperNaviagtion } from './questionPaperNavigation.action';

@State<any>({
  name: 'QuestioPapernNavigation',
  defaults: {
    SelectedQuestiopaper: {},
  },
})
@Injectable()
export class SelectedQuestionPaperForNavigationState {
  @Selector() static getSelectedQuestionpaper(state: any) {
    return state.SelectedQuestiopaper;
  }

  constructor() {}

  @Action(selectedQuestionpaperNaviagtion)
  setGradeFMS(
    ctx: StateContext<any>,
    { data }: selectedQuestionpaperNaviagtion
  ) {
    ctx.setState(
      patch({
        SelectedQuestiopaper: data,
      })
    );
  }
}
