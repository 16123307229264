import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { RacesActions } from './shared/store/school-admin/school-admin.action';
import { StudentsActions } from './shared/store/students/student.action';
import { TeachersActions } from './shared/store/teachers/teachers.action';
import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior,
} from '@angular/cdk/platform';
import { SnackbarService } from './shared/services/snackbar.service';
import { CommonService } from './shared/services/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'hurrey-platform';

  constructor(private router: Router, private store: Store,public platform: Platform,private snackbar:SnackbarService,
  public commonSrvc_: CommonService) {}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // refresh listner to refresh connections
    // this.router.events
    //   .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    //   .subscribe((event) => {
    //     if (event.id === 1 && event.url === event.urlAfterRedirects) {
    //       let school_id =
    //         this.store.snapshot().SelectedAdmin?.selectedAdmin?.school_id;
    //       let uid = this.store.snapshot().SelectedAdmin?.selectedAdmin?.user_id;
    //       this.store.dispatch(new TeachersActions.GetAllTeachers(school_id));
    //       this.store.dispatch(new StudentsActions.getAllStudents(school_id));
    //       this.store.dispatch(new RacesActions.GetAllSchoolAdmin(uid));
    //     }
    //   });
    if(this.platform.SAFARI){
      this.snackbar.openSnackBarTop('please use latest version Safari (>14.1)  or use chrome , firefox browser','Close');

        // alert('please use latest version Safari (>14.1)  or use chrome , firefox browser');
    }

  }
}
