export class GetSchoolHouses {
  static readonly type = '[schoolHouses] getAllSchoolHouses';
  constructor(public schoolHouses: any) {}
}

export class selectedHouse {
  static readonly type = '[schoolHouse] getSelectedHouse';
  constructor(public schoolHouse: any) {}
}

export class SchoolStudentsSnapShot {
  static readonly type = '[schoolHouses] getSchoolStudentsSnapShot';
  constructor(public students: any) {}
}

export class RemoveSchoolStudentsSnapShot {
  static readonly type = '[schoolHouses] removeSchoolStudentsSnapShot';
}

export class SchoolTeachersSnapShot {
  static readonly type = '[schoolHouses] getSchoolTeachersSnapShot';
  constructor(public teachers: any) {}
}

export class RemoveSchoolTeachersSnapShot {
  static readonly type = '[schoolHouses] removeSchoolTeachersSnapShot';
}

export class removeselectedHouse {
  static readonly type = '[schoolHouse] removeSelectedHouse';
}
