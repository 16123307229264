export namespace TeachersActions {
  export class GetAllTeachers {
    public static readonly type = '[SchoolTeacher] GetAll';
    constructor(public payload: string) {}
  }

  export class Get {
    public static readonly type = '[teachers] Get';
    constructor(public payload: string) {}
  }

  export class removeTeacherlist {
    public static readonly type = '[Removeteachers] remove';
  }
}
