import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { firebaseUser } from '../models/firebaseUser';
import { schoolDbData } from '../models/school_db_data';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  firebaseuser: firebaseUser;

  boardslist = ['cbse', 'icse', 'isc'];

  mediumlist = ['english', 'hindi'];

  schoolAffliation = ['Government', 'Primary'];

  schoolCategory: any[] = [
    'Primary',
    'Upper primary',
    'Middle',
    'Secondary',
    'Senior secondary',
  ];

  noCode = false;
  type = {
    email: false,
    mobile: true,
    social: false,
  };
  constructor(private commonSrvc_: CommonService) {
    this.getData();
  }

  public signupForm: FormGroup = new FormGroup({
    schoolCode: new FormControl('', [Validators.required]),
    email: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    ]),
    password: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.pattern(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
      ),
    ]),
    phone: new FormControl('', [Validators.required]),
  });

  public schoolForm: FormGroup = new FormGroup({
    // schoolCode: new FormControl('', [Validators.required]),
    schoolName: new FormControl('', [Validators.required]),
    schoolEmail: new FormControl('', [Validators.required, Validators.email]),
    schoolWebsite: new FormControl(''),
    schoolAddress: new FormControl('', [Validators.required]),
    schoolPincode: new FormControl('', [Validators.required]),
    schoolState: new FormControl('', [Validators.required]),
    schoolCity: new FormControl('', [Validators.required]),
    schoolDistrict: new FormControl('', [Validators.required]),
    schoolCountry: new FormControl('', [Validators.required]),
    schoolLat: new FormControl(''),
    schoolLong: new FormControl(''),
    schoolBoard: new FormArray([]),
    schoolMedium: new FormArray([]),
    schoolStartDate: new FormControl('', [Validators.required]),
    schoolEndDate: new FormControl('', [Validators.required]),
    schoolMobile: new FormControl('', [Validators.required]),
    schoolAffliation: new FormControl('', Validators.required),
    schoolCategory: new FormControl('', Validators.required),

    schoolUsername: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(30),
      this.commonSrvc_.userNameValidatorFn(),
    ]),
  });

  get schoolCode() {
    return this.signupForm.controls['schoolCode'];
  }

  get schoolCodeFormControls() {
    return this.signupForm.controls;
  }

  get SchoolFormControls() {
    return this.schoolForm.controls;
  }

  get SchoolBoardControls() {
    return this.schoolForm.get('schoolBoard') as FormArray;
  }

  get schoolMediumControls() {
    return this.schoolForm.get('schoolMedium') as FormArray;
  }

  school_code_details = new Subject<schoolDbData>();
  school_UDISE_details = new Subject<schoolDbData>();
  school_code_details_store: schoolDbData;
  getData() {
    console.log(this.signupForm.value);

    this.school_code_details.subscribe((el) => {
      console.log(el);
      this.school_code_details_store = el;
      this.schoolForm.patchValue({
        // schoolCode: el.school_id,
        schoolName: el.name.join(' '),
        schoolEmail: el.email.join(' '),
        schoolWebsite: el.website.join(' '),
        schoolMobile: el.mobile.join(' '),
        schoolAddress: el.address.address_line,
        schoolPincode: el.address.pincode,
        schoolState: el.address.state,
        schoolCity: el.address.city,
        schoolDistrict: el.address.district,
        schoolCountry: el.address.country,
        schoolLat: el.address.latitude,
        schoolLong: el.address.longitude,
        // schoolBoard: el.board,
      });

      let boards = this.schoolForm.get('schoolBoard') as FormArray;

      // boards.push();
      const items = boards;
      for (let i = 0; i < items.length; i++) {
        items.removeAt(i);
      }
      for (let index = 0; index < el.board.length; index++) {
        const element = el.board[index];
        items.push(new FormControl(element as any, [Validators.required]));
      }
      // boards.push(new FormControl(''));
      // boards.setValue(el.board);
    });
  }
}
