import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoDataFoundComponent implements OnInit {
  @Input() message: string = "No Data Found";
  @Input() color: string = "#303437";
  @Input() fontsize: string = 'large1';
  @Input() width: string = '100%';
  @Input() height: string = '100%';
  options: AnimationOptions = {
    path: "../../../assets/lottie/not-found.json"
  };

  constructor() {

  }
ngOnInit(): void {
  
}



}
