import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { classesArrayinTeacherDoc } from '../shared/modals/classesArrayinTeacherDoc';
import { TeachersArrayinClassesDoc } from '../shared/modals/TeachersArrayinClassesDoc';
import { of, Subscription } from 'rxjs';
import { TeacherService } from '../shared/service/teacher.service';
import { ClassListState } from 'src/app/shared/store/class-list/class-list.state';
import { ClassAndStudentsService } from 'src/app/shared/services/classandstudents.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-teacher-dialog',
  templateUrl: './teacher-dialog.component.html',
  styleUrls: ['./teacher-dialog.component.scss'],
})
export class TeacherDialogComponent implements OnInit, OnDestroy {
  subName: any;
  selectedSubjects: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogServ_: DialogService,
    public common_: CommonService,
    private store: Store,
    private teacherService: TeacherService,
    private ClassAndStudentServ_: ClassAndStudentsService,
    private snack_: SnackbarService
  ) {}

  selectedClasses: any[] = [];
  classesArray: classesArrayinTeacherDoc[] = [];

  ngOnInit(): void {
    this.fetchClasses();
    this.classesArray = this.data?.allocations?.classes;
    if (this.classesArray.length)
      this.selectedClasses = this.classesArray.map((cls) => {
        let seelcted_class_details = {
          class_id: cls.class_doc_id,
          name: cls.grade,
          section: cls.section,
        };
        return seelcted_class_details;
      });

    this.selectedClasses = this.dedupe(this.selectedClasses);

    console.log(this.selectedClasses);
  }

  Selection(event: any, cls: any) {
    let seelcted_class_details = {
      class_id: cls.id,
      name: cls.name,
      section: cls.section,
    };

    console.log(seelcted_class_details, this.selectedClasses);

    if (event.target.checked) {
      // classobj['selectedSubjects'].push(subject);

      this.selectedClasses.push(seelcted_class_details);
    } else {
      this.selectedClasses = this.selectedClasses.filter(
        (a: any) => a.class_id !== seelcted_class_details.class_id
      );
    }
    console.log('here', this.selectedClasses);

    // console.log(cls);

    // if (this.selectedClasses.includes(seelcted_class_details)) {
    //   this.selectedClasses = this.selectedClasses.filter(
    //     (e: any) => e !== seelcted_class_details
    //   );
    // } else {
    //   this.selectedClasses.push(seelcted_class_details);
    // }
    // console.log(this.selectedClasses);
  }

  schoolClasses: any[] = [];
  sub1!: Subscription;

  schoolClasses$ = this.store.select(ClassListState);

  async fetchClasses() {
    this.ClassAndStudentServ_.fetchClasses();
    this.sub1 = this.schoolClasses$.subscribe((el) => {
      this.schoolClasses = cloneDeep(el.classList);
      this.schoolClasses = this.common_.sortClasses1(this.schoolClasses);
      this.schoolClasses = this.schoolClasses.map((el) => {
        el['state'] = 0;
        el['selectedSubjects'] = [];
        el['classteacher'] = false;

        return el;
      });

      // sort

      // this.schoolClasses = this.schoolClasses.sort((a, b) =>
      //   a.section.localeCompare(b.section)
      // );

      // this.schoolClasses = this.schoolClasses.sort((a, b) =>
      //   a.name.localeCompare(b.name)
      // );
      console.log(this.schoolClasses);
    });

    // let store = this.store.snapshot();
    // let schoolid = store.SelectedAdmin?.selectedAdmin?.school_id;
    // this.common_.getCurrentSchoolDetails(schoolid).subscribe(async (el) => {
    //   console.log(el);
    //   this.schoolClasses = await this.common_
    //     .getSchoolClasses(schoolid, el.curriculum)
    //     .toPromise();
    //   console.log(this.schoolClasses);
    //   this.schoolClasses = this.schoolClasses.map((el) => {
    //     el['state'] = 0;
    //     el['selectedSubjects'] = [];
    //     el['classteacher'] = false;

    //     return el;
    //   });

    //   // sort

    //   this.schoolClasses = this.schoolClasses.sort((a, b) =>
    //     a.section.localeCompare(b.section)
    //   );

    //   this.schoolClasses = this.schoolClasses.sort((a, b) =>
    //     a.name.localeCompare(b.name)
    //   );
    //   console.log(this.schoolClasses);
    // });
  }
  toggle = 0;

  save1() {
    if (this.selectedClasses.length) {
      let newArray = [];

      for (const iterator of this.selectedClasses) {
        for (const it of this.schoolClasses) {
          if (it.name == iterator.name && it.section == iterator.section) {
            newArray.push(it);
          }
        }
      }

      this.selectedClasses = newArray;

      for (const iterator of this.selectedClasses) {
        // console.log(iterator);

        let teacherArray = iterator.teachers;

        if (teacherArray.length) {
          for (const subject of iterator.subjects) {
            let find = teacherArray.filter(
              (a: any) =>
                a.subject === subject &&
                a.teacher_id === this.data?.allocations?.user_id
            );
            console.log(find);
            if (find.length) {
              iterator.selectedSubjects.push(subject);
            }
          }
        }
      }

      console.log(this.selectedClasses, this.data?.allocations?.user_id);

      this.selectedClasses = this.selectedClasses.sort((a, b) =>
        a.section.localeCompare(b.section)
      );

      this.selectedClasses = this.selectedClasses.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      this.toggle = 1;
    } else {
      //popup
      this.snack_.openSnackBarTop(
        'Please select atleast one class to assign',
        'Error'
      );
    }
  }

  onClickingSub(event: any, classobj: any, subject: string) {
    // let Classes: classesArrayinTeacherDoc = {
    //   class_doc_id: classobj.id,
    //   class_teacher: classobj.classteacher,
    //   grade: classobj.name,
    //   section: classobj.section,
    //   subject: subject,
    // };

    // let teacher: TeachersArrayinClassesDoc = {
    //   class_teacher: classobj.classteacher,
    //   subject: subject,
    //   teacher_id: this.data?.allocations?.user_id,

    //   // class_id: iterator.id,
    // };

    if (event.target.checked) {
      console.log('checked');
      classobj['selectedSubjects'].push(subject);

      // classobj.teachers.push(teacher);
    } else {
      console.log('unchecked');
      classobj['selectedSubjects'] = classobj['selectedSubjects'].filter(
        (a: string) => a !== subject
      );
    }
  }

  // find classes seletcion
  findSelection(classobj: any) {
    let find = this.selectedClasses.filter(
      (a: any) => a.name === classobj.name && a.section === classobj.section
    );

    // console.log(classesArray, classobj);

    if (find.length) return true;
    else return false;

    // let seelcted_class_details = {
    //   class_id: classobj.id,
    //   name: classobj.name,
    //   section: classobj.section,
    // };

    // if (this.selectedClasses.includes(seelcted_class_details)) {
    //   return true;
    // } else {
    //   return false;
    // }
  }

  findChecked(classobj: any, subject: string) {
    let teacherArray = classobj.teachers;
    // console.log(classobj, subject, teacherArray);

    if (teacherArray.length) {
      let find = teacherArray.filter(
        (a: any) =>
          a.subject === subject &&
          a.teacher_id === this.data?.allocations?.user_id
      );
      // console.log(find);
      if (find.length) return true;
      else return false;
    } else {
      return false;
    }
  }

  checktick(item: any) {
    if (item.classteacher) {
      return true;
    } else {
      return false;
    }
  }

  classTeacher(event: any) {
    let source = event.target.value;

    console.log(source);

    if (event.target.checked) {
      this.selectedClasses = this.selectedClasses.map((element: any) => {
        if (element.id == source) {
          element['classteacher'] = true;
        } else {
          element['classteacher'] = false;
        }
        return element;
      });
    } else {
      this.selectedClasses = this.selectedClasses.map((element: any) => {
        if (element.id == source) {
          element['classteacher'] = false;
        }
        return element;
      });

      return;
    }
  }

  isChecked(item: any) {
    return false;
  }

  save2() {
    let teachersArraytoClassdoc: Array<{
      id: string;
      data: TeachersArrayinClassesDoc;
    }> = [];
    let ClaasesArraytoTeacherdoc: classesArrayinTeacherDoc[] = [];

    // add docs to class list and  teacher doc
    let count = 0;
    let StudAttMap: any = {};

    for (const iterator of this.selectedClasses) {
      // console.log(iterator);

      // let teacherArray = iterator.teachers;

      // if (teacherArray.length) {
      //   for (const subject of iterator.subjects) {
      //     let find = teacherArray.filter(
      //       (a: any) =>
      //         a.subject === subject &&
      //         a.teacher_id === this.data?.allocations?.user_id
      //     );
      //     console.log(find);
      //     if (find.length) {
      //       iterator.selectedSubjects.push(subject);
      //     }
      //   }
      // }

      if (iterator.selectedSubjects.length != 0) {
        for (const it of iterator.selectedSubjects) {
          // this goes to teacher doc
          let Classes: classesArrayinTeacherDoc = {
            class_doc_id: iterator.id,
            class_teacher: iterator.classteacher,
            grade: iterator.name,
            section: iterator.section,
            subject: it,
          };

          ClaasesArraytoTeacherdoc.push(Classes);

          // this goeas to classes doc
          let teacher: TeachersArrayinClassesDoc = {
            class_teacher: false,
            subject: it,
            teacher_id: this.data?.allocations?.user_id,

            // class_id: iterator.id,
          };

          teachersArraytoClassdoc.push({ id: iterator.id, data: teacher });
        }

        // the etra object for classteacher
        if (iterator.classteacher) {
          let teacher: TeachersArrayinClassesDoc = {
            class_teacher: true,
            subject: '',
            teacher_id: this.data?.allocations?.user_id,
          };

          teachersArraytoClassdoc.push({ id: iterator.id, data: teacher });

          let Classes: classesArrayinTeacherDoc = {
            class_doc_id: iterator.id,
            class_teacher: iterator.classteacher,
            grade: iterator.name,
            section: iterator.section,
            subject: '',
          };

          ClaasesArraytoTeacherdoc.push(Classes);
        }

        // console.log(ClaasesArraytoTeacherdoc);

        // for (const stuff of teachersArraytoClassdoc) {
        //   let id: string = stuff.class_id!;
        //   if (id in StudAttMap != true) {
        //     StudAttMap[id] = [];
        //     StudAttMap[id].push(stuff);
        //   } else {
        //     StudAttMap[id].push(stuff);
        //   }
        // }
      } else {
        count++;
      }
    }
    if (count != 0) {
      this.snack_.openSnackBarTop(
        'Please select atleast one subject for each class',
        'Error'
      );
    } else {
      console.log(ClaasesArraytoTeacherdoc, teachersArraytoClassdoc);

      this.checkDuplicacy(ClaasesArraytoTeacherdoc, teachersArraytoClassdoc);
    }
  }

  checkDuplicacy(Classesarray: any[], teachersArraytoClassdoc: any[]) {
    //check if class array is same

    this.common_.showSpinner = true;

    let presrntClassArray: any[] = this.data.allocations.classes;

    console.log(presrntClassArray);
    if (presrntClassArray.length) {
      presrntClassArray = presrntClassArray.sort((a, b) =>
        a.section.localeCompare(b.section)
      );

      presrntClassArray = presrntClassArray.sort((a, b) =>
        a.grade.localeCompare(b.grade)
      );

      Classesarray = Classesarray.sort((a, b) =>
        a.section.localeCompare(b.section)
      );

      Classesarray = Classesarray.sort((a, b) =>
        a.grade.localeCompare(b.grade)
      );

      if (JSON.stringify(Classesarray) === JSON.stringify(presrntClassArray)) {
        console.log('same');
        return;
      }
    }

    // delete from exiting class arrays
    let toremove = [];
    for (const i of presrntClassArray) {
      let teacherdoc: TeachersArrayinClassesDoc;

      if (i.class_teacher) {
        teacherdoc = {
          class_teacher: true,
          subject: i.subject,
          teacher_id: this.data?.allocations?.user_id,
        };

        toremove.push({ id: i.class_doc_id, data: teacherdoc });
      } else {
        teacherdoc = {
          class_teacher: false,
          subject: i.subject,
          teacher_id: this.data?.allocations?.user_id,
        };
      }
      toremove.push({ id: i.class_doc_id, data: teacherdoc });
    }

    console.log(toremove);

    // remove using array remove

    let store = this.store.snapshot();
    let schoolid = store.SelectedAdmin?.selectedAdmin?.school_id;

    this.teacherService
      .removeFromClassesDoc(toremove, schoolid)
      .then((el) => {
        // add new object to teachers array

        this.teacherService
          .updateClassesDoc(teachersArraytoClassdoc, schoolid)
          .then((el) => {
            this.teacherService
              .updateClassesArrayinTeacherDoc(
                this.data?.allocations?.id,
                Classesarray
              )
              .then((el) => {
                //done!!!
                this.common_.showSpinner = false;
                this.dialogServ_.dismissAll();
              });
          })
          .catch((el) => {
            alert('something went wrong');
          });
        // update classes array in teachers doc
      })
      .catch((el) => {
        alert('something went wrong');
        this.common_.showSpinner = false;
      });
  }

  dedupe(arr: any[]) {
    return arr.reduce(
      function (p, c) {
        // create an identifying id from the object values
        var id = [c.name, c.section].join('|');

        // if the id is not found in the temp array
        // add the object to the output array
        // and add the key to the temp array
        if (p.temp.indexOf(id) === -1) {
          p.out.push(c);
          p.temp.push(id);
        }
        return p;

        // return the deduped array
      },
      {
        temp: [],
        out: [],
      }
    ).out;
  }

  ngOnDestroy(): void {
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
  }
}
