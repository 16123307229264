import { Directive } from '@angular/core';

@Directive({
  selector: '[appNoDateFound]'
})
export class NoDateFoundDirective {

  constructor() { }

}
