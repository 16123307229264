<div class="dialog_contianer flex flex-col gap-4 items-center ">
  <h3 *ngIf="!data">Create Exam</h3>
  <h3 *ngIf="!!data">Edit Exam</h3>
  <h3> Are you sure ?
  </h3>
  <div class="actions">
    <button class="btn_blue" (click)="onYes()">Continue</button>
    <button class="btn_white" mat-dialog-close>Cancel</button>
  </div>
</div>
