<div class="attandace_container flex flex-col  justify-center gap-4">
    <div class="flex justify-between">
      <h1 class="text-ink_light font-bold text_large">Attendence</h1>
      <button mat-dialog-close style="outline: none !important;">
        <mat-icon color="danger">close</mat-icon>
      </button>
    </div>

    <div class="flex gap-4  justify-center items-center">
      <!-- selected classes  presented absented -->
      <div class="attandeance present bg-green_lightest p-2 flex flex-col items-center justify-center gap-2"
        (click)="markAttendance('present')" [ngClass]="{'border_green': studentInfo.present==true}">
        <h1 class="font-bold text_large text-green_darkest">P</h1>
        <h2 class="text-green_light font-semibold text_medium">Present</h2>
      </div>
      <div class=" attandeance absent bg-red_lightest p-2 flex flex-col items-center justify-center gap-2"
        (click)="markAttendance('false')" [ngClass]="{'border_red': studentInfo.present==false}">
        <h1 class="font-bold text_large text-red_light">A</h1>
        <h2 class="text-red_light font-semibold text_medium">Absent</h2>
      </div>
    </div>

    <ng-container *ngIf="subjectExamPapersDetails.question_papers.length > 1 &&studentInfo.present == true">
      <div class="flex justify-between">
        <h1 class="text-ink_light font-bold text_large">Select Set</h1>
      </div>
      <div class="flex gap-2 flex-wrap justify-center items-center">
        <button class="btn_white hover:btn_blue button_cus" [ngClass]="{'btn_blue': paper.set==selectedQuestionPaper.set}"
          *ngFor="let paper of subjectExamPapersDetails.question_papers" (click)="choosePaper(paper)">Set
          {{paper.set|uppercase}}</button>
      </div>
    </ng-container>




    <div class="flex justify-center items-center">
      <button class="btn_blue" (click)="goToEnterMarksPage()" *ngIf="studentInfo.present==true && !isObjEmpty()">Enter
        Marks</button>
      <!-- <button class="btn_blue" routerLink="/layout/examcenter/marksportal/studetmarks">Submit</button> -->
    </div>


  </div>
