import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-exam-dialog',
  templateUrl: './exam-dialog.component.html',
  styleUrls: ['./exam-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExamDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogSrvc_: DialogService,
    private commonSrvc_: CommonService
  ) {}

  ngOnInit(): void {}

  onYes() {
    this.dialogSrvc_.createExamSubject.next(true);
    this.dialogSrvc_.dismissAll();
    this.commonSrvc_.showSpinner = true;
  }
}
