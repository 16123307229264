import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  GetSchoolTeams,
  removeSelectedTeam,
  selectedTeam,
} from './schoolTeam.action';

@State<any>({
  name: 'SchoolTeamState',
  defaults: {
    schoolTeams: [],
    selectedTeam: {},
  },
})
@Injectable()
export class SchoolTeamState {
  @Selector() static getSchoolTeams(state: any) {
    console.log(state);
    return state.schoolTeams;
  }
  @Action(GetSchoolTeams)
  setSchoolTeams(ctx: StateContext<any>, { schoolTeams }: GetSchoolTeams) {
    ctx.setState(
      patch({
        schoolTeams: schoolTeams,
      })
    );
  }

  @Action(selectedTeam)
  setSelectedTeam(ctx: StateContext<any>, { schoolTeam }: selectedTeam) {
    ctx.setState(
      patch({
        selectedTeam: schoolTeam,
      })
    );
  }

  @Action(removeSelectedTeam)
  removeSelectedTeam(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        selectedTeam: {},
      })
    );
  }
}
