import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Select, Store } from '@ngxs/store';
import { forkJoin, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { schoolAdmin } from 'src/app/auth/shared/models/school_admin';
import { AuthService } from 'src/app/auth/shared/services/auth.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { schoolAdminState } from 'src/app/shared/store/school-admin/school-admin.state';
import { schoolListState } from 'src/app/shared/store/school/school-list.state';
import { UserDataState } from 'src/app/shared/store/user/user.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    // '(document:click)': 'onClick($event)',
  },
})
export class HeaderComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private store: Store,
    private _eref: ElementRef,
    private snackbar: SnackbarService,
    private sidebar: SidebarService
  ) {}
  @Input() sidenav!: MatSidenav;
  @Input() right: MatSidenav;

  public getScreenWidth: any;
  public getScreenHeight: any;
  @Select(UserDataState) userData$: Observable<any>;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  ngOnInit(): void {
    console.log(this.store.snapshot());
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    console.log(this.getScreenHeight, this.getScreenWidth);
  }

  profile_dropdown_items: any = [
    { icon: '../../../../assets/icons/header/Profile.svg', name: 'Profile' },
    // {
    //   icon: '../../../../assets/icons/header/Edit.svg',
    //   name: 'Switch Accounts',
    // },
    // { icon: '../../../../assets/icons/header/settings.svg', name: 'Settings' },
    { icon: '../../../../assets/icons/header/Edit.svg', name: 'Help' },
    { icon: '../../../../assets/icons/header/Edit.svg', name: 'Sign Out' },
  ];

  getbg(thumb: any) {
    if (thumb) {
      // return `url(${thumb})`;
      return `${thumb}`;
    } else return '../../../../assets/images/userimg.png';
  }

  logout() {
    this.authService.SignOut();
  }

  dropClick() {
    const element: any = document.querySelector('#dropDown');
    // console.log(element);
    // element?.classList.remove("mat-menu-panel");
    console.log(element.style.display);
    if (element.style.display == 'block') {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }

    // // element?.classList.add("mat-menu-panel");
  }
  onClick(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      const element: any = document.querySelector('#dropDown');
      element.style.display = 'none';
    }
  }
  OndropDownClick(name: string) {
    if (name == 'Sign Out') {
      this.authService.SignOut();
    } else {
      this.snackbar.openSnackBar('Coming Soon');
    }
  }

  opensidebar() {
    this.sidebar.sidebarVisibleSubject.next(true);
  }
}
