import { DocumentReference } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { removeSelectedAdmin, SelectedSchool } from './selected-school.action';

@State<any>({
  name: 'SelectedAdmin',
  defaults: [],
})
@Injectable()
export class SelectedSchoolState {
  @Select() static getselectedAdminSchoolId(state: any) {
    return state.SelectedAdmin.selectedAdmin.school_id;
  }
  constructor() {}

  @Action(SelectedSchool)
  selectSchool(ctx: StateContext<any>, { school }: SelectedSchool) {
    ctx.setState(
      patch({
        selectedAdmin: school,
      })
    );
  }

  @Action(removeSelectedAdmin)
  removeSchools(ctx: StateContext<any>, { schools }: removeSelectedAdmin) {
    ctx.setState(
      patch({
        selectedAdmin: {},
      })
    );
  }
}
