import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { SchoolOnboardService } from 'src/app/shared/services/school-onboard.service';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper implements OnInit {
  ngOnInit(): void {
    console.log(this.head);
    if (this.head == 'timetable') {
      this.heading = '';
    }
  }
  // @Input() navigateFn: Function;

  @Input() head!: any;

  heading: any = 'Set Up School';
  backOptionTitle = 'Back To Dashboard';
  selectStepByIndex(index: number): void {
    console.log(this.selectedIndex);
    this.selectedIndex = index;
  }

  check_n_save() {
    console.log('step 1');
  }

  checkSub() {
    console.log('step 2');
  }

  back(type: string) {
    console.log(type);
  }
}
