<div class="title" mat-dialog-title [align]="'center'">{{ data?.title }}</div>
<div mat-dialog-content [align]="'center'">
  <div class="message">
    {{ data?.message }}
  </div>
</div>
<div class="actions gap-2" mat-dialog-actions [align]="'center'">
  <button class="secondary-btn" mat-dialog-close>
    {{ data?.secondaryButton }}
  </button>
  <button class="primary-btn" (click)="data.primaryCallBackFn()">
    {{ data?.primaryButton }}
  </button>
</div>
<span (click)="modal.closeAll()" class="close cursor-pointer" mat-dialog-close>
  <img
    src="../../../../assets/icons/school-onboard/close-icon-dialog.svg"
    alt=""
  />
</span>
