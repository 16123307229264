import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  ClearStudentsTeachers,
  GetSchoolStudents,
  GetSchoolTeachers,
} from './students_teachers.action';

@State<any>({
  name: 'studentsTeachersState',
  defaults: {
    schoolStudents: [],
    schoolTeachers: [],
  },
})
@Injectable()
export class StudentsAndTeachersState {
  @Selector() static getSchoolTeachers(state: any) {
    return state.schoolTeachers;
  }

  @Selector() static getSchoolStudents(state: any) {
    return state.schoolStudents;
  }
  @Action(GetSchoolStudents)
  setSchoolStudents(ctx: StateContext<any>, { students }: GetSchoolStudents) {
    ctx.setState(
      patch({
        schoolStudents: students,
      })
    );
  }

  @Action(GetSchoolTeachers)
  setSchoolTeachers(ctx: StateContext<any>, { teachers }: GetSchoolTeachers) {
    ctx.setState(
      patch({
        schoolTeachers: teachers,
      })
    );
  }

  @Action(ClearStudentsTeachers)
  clearState(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        schoolTeachers: [],
        schoolStudents: [],
      })
    );
  }
}
