<div class="component_container flex flex-col gap-4">
  <!-- close button -->
  <div class="flex justify-end">
    <button class="outline-none" mat-dialog-close>
      <img src="../../../../assets/images/icons/close.svg" alt="close">
    </button>
  </div>
  <!-- header -->
  <div class="flex items-center justify-between">
    <h2 class="text_medium font-bold text-ink-dark ">{{data.title}}</h2>
    <button class="btn_blue_small" (click)="onSave()">Save</button>
  </div>

  <div class="flex flex-col gap-4 ">
    <!-- yes or no -->
    <div class="flex ">
      <mat-radio-group aria-label="Select an option" [formControl]="radioFormControl" class="flex gap-4 items-center">
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">Clear Fine System</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field appearance="outline" *ngIf="radioFormControl.value==true">
      <mat-label>Enter the amount of Fine</mat-label>
      <input matInput placeholder="Enter the amount of Fine" [formControl]="amount" digitOnly>
    </mat-form-field>
  </div>
</div>
