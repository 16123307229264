import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Controller } from 'swiper';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CreateSchoolFormService {
  boardslist = ['cbse', 'icse', 'isc'];

  mediumlist = ['english', 'hindi'];

  schoolType = ['k-5', 'k-8', 'k-10', 'k-12'];

  schoolAffliation = ['Government', 'Private'];

  schoolCategory: any[] = [
    'Primary',
    'Upper primary',
    'Middle',
    'Secondary',
    'Senior secondary',
  ];

  boardslist2 = [
    'BHSIEUP',
    'BIEAP',
    'BSEAP',
    'BSEB',
    'BSEH',

    'CGBSE',
    'GSEB',
    'HPBOSE',
    'IB',

    'JAC',
    'JKBOSE',
    'KSEEB',
    'MPBSE',
    'MSBSHSE',
    'NIOS',
    'PSEB',
    'RBSE',
    'SEBA',
    'WBBSE',
    'WBCHSE',
  ];

  mediumlist2 = [
    'Assamese',
    'Bengali',
    'Gujarati',
    'Kannada',
    'Konkani',
    'Malayalam',
    'Marathi',
    'Odia',
    'Sanskrit',
    'Tamil',
    'Telugu',
    'Urd',
  ];

  createSchoolForm: FormGroup = new FormGroup({
    schoolName: new FormControl('', [Validators.required]),
    schoolEmail: new FormControl('', [Validators.required, Validators.email]),
    schoolWebsite: new FormControl(''),
    schoolAddress: new FormControl('', [Validators.required]),
    schoolPincode: new FormControl('', [Validators.required]),
    schoolState: new FormControl('', [Validators.required]),
    schoolCity: new FormControl('', [Validators.required]),
    schoolDistrict: new FormControl('', [Validators.required]),
    schoolCountry: new FormControl('', [Validators.required]),
    schoolLat: new FormControl(''),
    schoolLong: new FormControl(''),
    schoolBoard: new FormArray([]),
    schoolMedium: new FormArray([]),
    schoolType: new FormArray([]),
    schoolStartDate: new FormControl('', [Validators.required]),
    schoolEndDate: new FormControl('', [Validators.required]),
    schoolMobile: new FormControl('', [Validators.required]),
    schoolCode: new FormControl('', [Validators.required]),
    schoolAffliation: new FormControl('', Validators.required),
    schoolCategory: new FormControl('', Validators.required),
    schoolUsername: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(30),
      this.commonSrvc_.userNameValidatorFn(),
    ]),
  });

  titleCase(str: string) {
    return str
      .toLowerCase()
      .split(' ')
      .map( (word:any)=> {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  }

  constructor(private commonSrvc_: CommonService) {}
}
