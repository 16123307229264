<div class="header_container flex justify-between items-center px-4 gap-2">
  <!-- <span (click)="opensidebar()" class="md:hidden">123</span> -->
  <div class="icon">
    <img src="../../../../assets/hurrey.svg" alt="" />
  </div>

  <div class="flex items-center md:gap-8 gap-2">
    <div class="search_box flex gap-2 relative hide_in_mobile">
      <img
        src="../../../../assets/icons/search.svg"
        class="absolute top-3 left-3"
        alt="search"
      />
      <input type="text" placeholder="Search " class="search_input" />
    </div>
    <div class="flex items-center gap-7">
      <button tippy="notifications coming soon">
        <img src="../../../../assets/icons/side-bar/notification.svg" alt="" />
      </button>
      <button tippy="laungage selection - coming soon">
        <img src="../../../../assets/icons/side-bar/laungage.svg" alt="" />
      </button>
      <div class="relative dropdown_container">
        <button>
          <img
            DefaultImage
            [src]="
              !!(userData$ | async).userData.photo_url
                ? (userData$ | async).userData.photo_url
                : (userData$ | async).userData.gender == 'male'
                ? '../../../../assets/images/admin-default-male.svg'
                : '../../../../assets/images/admin-default-female.svg'
            "
            class="profile_img"
            alt=""
            default="../../../../assets/icons/no_profile_pic.svg"
          />
        </button>
        <div class="drop_downContent">
          <div class="drop_items">
            <div
              class="drop_item text-ink_base regular_normal1 p-3"
              *ngFor="let item of profile_dropdown_items"
            >
              <img src="../../../../assets/icons/side-bar/profile.svg" alt="" />
              <a (click)="OndropDownClick(item.name)">{{ item.name }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
