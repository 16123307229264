import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsComponent implements OnInit {
  @Input() label: string = 'Hurrey';
  @Input() color: string = 'blue';
  @Input() size: string = 'medium';
  @Input() type: string = 'button';
  @Input() isDisabled: boolean = false;
  @Output() btnClick: any = new EventEmitter();
  @Input() route: string = '';
  buttonStyle: string = 'btn_ble_medium';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.buttonStyle = `btn_${this.color}_${this.size}`;
    if (this.isDisabled) {
      this.buttonStyle = this.buttonStyle + ` opacity-50 cursor-default`;
    }
    console.log(this.buttonStyle);
  }
  Onclick() {
    if (this.route.length) {
      this.router.navigate([this.route]);
    } else {
      this.btnClick.emit();
    }
  }

  ngOnChanges() {
    this.color = this.color.toLowerCase();
    this.buttonStyle = `btn_${this.color}_${this.size}`;
  }
}
