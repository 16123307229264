import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {

  @Input() progress: any = 0;
  @Input() color: any = 'green';
  @Input() width:any='100px';

  constructor() { }

  ngOnInit(): void {
  }

}
