import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/services/common.service';
import { CreateEventNewDialogComponent } from '../../calender/create-event-new-dialog/create-event-new-dialog.component';
import { SubstitutionDoc } from '../shared/modals/substitutionDoc';

@Component({
  selector: 'app-substitution-modal',
  templateUrl: './substitution-modal.component.html',
  styleUrls: ['./substitution-modal.component.scss'],
})
export class SubstitutionModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private common_: CommonService,
    private MatDialog_: MatDialog
  ) {}
  selectedPeriod: any;

  reccoTeachers: any[] = [];
  otherTeacher: any[] = [];
  ngOnInit(): void {
    console.log(this.data);

    this.type = this.data.data.type;
    this.selectedPeriod = JSON.parse(
      JSON.stringify(this.data.data.selectedPeriodDetails)
    );
    this.teacherDetails = JSON.parse(
      JSON.stringify(this.data.data.nonAbscentTeachersList)
    );
    this.findUnAvailableTeachers();

    let reccomended = [];
    let others = [];
    for (const td of this.teacherDetails) {
      let TeachersClasses = td?.allocations?.classes;

      td['availablity'] = this.findAvailablity(td.id);

      // console.log(td?.allocations?.classes);
      let find = TeachersClasses.filter(
        (x: any) =>
          x.grade == this.selectedPeriod?.myclass?.classDetails.name ||
          x.subject == this.selectedPeriod?.myclass?.subject
      );

      // console.log(find);

      if (find.length > 0) {
        reccomended.push(td);
      } else {
        others.push(td);
      }
    }

    this.reccoTeachers = reccomended;
    this.otherTeacher = others;

    console.log(this.reccoTeachers, this.otherTeacher);
  }

  findAvailablity(id: string) {
    if (this.nonAvailableTeachers.length) {
      let find = this.nonAvailableTeachers.filter(
        (x: any) => x.teacher_id == id
      );

      if (find.length) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  nonAvailableTeachers: any[] = [];

  findUnAvailableTeachers() {
    let todaysPeriods = this.data.data.todaysPeriods;

    let Allperiods: any[] = [];

    for (const p of todaysPeriods) {
      Allperiods = Allperiods.concat(p.periods);
    }

    console.log(Allperiods);

    // Allperiods =
    //   Allperiods.filter((el:any)=>

    let unmatchingPeriods = this.validate(
      this.data.data.selectedPeriodDetails.start,
      this.data.data.selectedPeriodDetails.end,
      Allperiods
    );

    console.log(unmatchingPeriods);

    for (const iterator of unmatchingPeriods) {
      if (iterator.teachers.length) {
        this.nonAvailableTeachers = this.nonAvailableTeachers.concat(
          iterator.teachers
        );
      }
    }

    // this.nonAvailableTeachers = this.nonAvailableTeachers.filter(
    //   (el) => el.classDetails
    // );

    console.log(this.nonAvailableTeachers);
    // return this.nonAvailableTeachers
  }

  validate(sTime: string, eTime: string, timeList: any[]) {
    let matching: any[] = [];
    for (const tim of timeList) {
      let start = this.getDate(tim.start);
      let end = this.getDate(tim.end);

      let sTime2 = this.getDate(sTime);
      let eTime2 = this.getDate(eTime);

      if (start <= sTime2 && end >= eTime2) {
        matching.push(tim);
      }
    }

    return matching;
  }

  getDate(time: string) {
    var today = new Date();
    var _t = time.split(':');
    today.setHours(parseInt(_t[0]), parseInt(_t[1]), 0, 0);
    return today;
  }

  teacherDetails: any[] = [];
  type: string;

  assign(item: any) {
    console.log(item);

    if (this.type == 'make') {
      let obj: SubstitutionDoc = {
        absent_teacher_id: this.data.data.AbscentTeacherid,
        class_id: this.data.data.selectedPeriodDetails.myclass.class_id,
        created_at: new Date(),
        created_by: '',
        grade: this.data.data.selectedPeriodDetails.myclass.classDetails.name,
        period_end: this.data.data.selectedPeriodDetails.end,
        period_start: this.data.data.selectedPeriodDetails.start,
        section:
          this.data.data.selectedPeriodDetails.myclass?.classDetails.section,
        subject: this.data.data.selectedPeriodDetails.myclass?.subject,
        substitute_teacher_id: item.id,
        date: {
          day: new Date().getDate(),
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear(),
        },

        curriculum:
          this.data.data.selectedPeriodDetails.myclass?.classDetails.curriculum,
      };

      console.log(obj);

      this.common_
        .createTeacherSubstitute(this.data.data.schoolid, obj)
        .then((res: any) => {
          this.MatDialog_.closeAll();
        });
    } else {
      let substituteddocid = this.data.data.selectedPeriodDetails.substitute.id;
      this.common_
        .updateTeacherSubstitute(
          this.data.data.schoolid,
          substituteddocid,
          item.id
        )
        .then((res: any) => {
          this.MatDialog_.closeAll();
        });
    }
  }
}
