import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/auth/shared/services/form-service.service';
import { CreateSchoolComponent } from 'src/app/pages/school-onboard/create-school/create-school.component';
import { CreateSchoolFormService } from 'src/app/shared/services/create-school-form.service';

@Component({
  selector: 'app-boards-popup',
  templateUrl: './boards-popup.component.html',
  styleUrls: ['./boards-popup.component.scss'],
})
export class BoardsPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formService: FormService,
    private createSchoolSrvc_: CreateSchoolFormService
  ) {}

  ngOnInit(): void {}

  isChecked(item: any) {
    if (this.data.name == 'board' && this.data.from == 'same') {
      const formArray: FormArray = this.formService.schoolForm.get(
        'schoolBoard'
      ) as FormArray;
      if (formArray.value.includes(item)) {
        return true;
      } else {
        return false;
      }
    } else if (this.data.name == 'medium' && this.data.from == 'same') {
      const formArray: FormArray = this.formService.schoolForm.get(
        'schoolMedium'
      ) as FormArray;
      if (formArray.value.includes(item)) {
        return true;
      } else {
        return false;
      }
    } else if (this.data.name == 'board' && this.data.from == 'diff') {
      const formArray: FormArray = this.createSchoolSrvc_.createSchoolForm.get(
        'schoolBoard'
      ) as FormArray;
      if (formArray.value.includes(item)) {
        return true;
      } else {
        return false;
      }
    } else if (this.data.name == 'medium' && this.data.from == 'diff') {
      const formArray: FormArray = this.createSchoolSrvc_.createSchoolForm.get(
        'schoolMedium'
      ) as FormArray;
      if (formArray.value.includes(item)) {
        return true;
      } else {
        return false;
      }
    } else {
      return;
    }
  }

  Name = '';

  onClicking(event: any) {
    if (this.data.name == 'board' && this.data.from == 'same') {
      const formArray: FormArray = this.formService.schoolForm.get(
        'schoolBoard'
      ) as FormArray;

      /* Selected */
      if (event.target.checked) {
        // Add a new control in the arrayForm
        formArray.push(new FormControl(event.target.value));
        this.formService.boardslist.push(event.target.value);
      } else {
        /* unselected */
        // find the unselected element
        let i: number = 0;

        formArray.controls.forEach((ctrl) => {
          if (ctrl.value == event.target.value) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }

          i++;
        });

        if (this.formService.boardslist.includes(event.target.value)) {
          let index = this.formService.boardslist.indexOf(event.target.value);
          if (index != -1) {
            this.formService.boardslist.splice(index, 1);
          } else {
          }
        }
      }
    } else if (this.data.name == 'medium' && this.data.from == 'same') {
      const formArray: FormArray = this.formService.schoolForm.get(
        'schoolMedium'
      ) as FormArray;

      /* Selected */
      if (event.target.checked) {
        // Add a new control in the arrayForm
        formArray.push(new FormControl(event.target.value));
        this.formService.mediumlist.push(event.target.value);
      } else {
        /* unselected */
        // find the unselected element
        let i: number = 0;

        formArray.controls.forEach((ctrl) => {
          if (ctrl.value == event.target.value) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }

          i++;
        });

        if (this.formService.mediumlist.includes(event.target.value)) {
          let index = this.formService.mediumlist.indexOf(event.target.value);
          if (index != -1) {
            this.formService.mediumlist.splice(index, 1);
          } else {
          }
        }
      }
    } else if (this.data.name == 'board' && this.data.from == 'diff') {
      const formArray: FormArray = this.createSchoolSrvc_.createSchoolForm.get(
        'schoolBoard'
      ) as FormArray;

      /* Selected */
      if (event.target.checked) {
        // Add a new control in the arrayForm
        formArray.push(new FormControl(event.target.value));
        this.createSchoolSrvc_.boardslist.push(event.target.value);
      } else {
        /* unselected */
        // find the unselected element
        let i: number = 0;

        formArray.controls.forEach((ctrl) => {
          if (ctrl.value == event.target.value) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }

          i++;
        });

        if (this.createSchoolSrvc_.boardslist.includes(event.target.value)) {
          let index = this.createSchoolSrvc_.boardslist.indexOf(event.target.value);
          if (index != -1) {
            this.createSchoolSrvc_.boardslist.splice(index, 1);
          } else {
          }
        }
      }
    } else if (this.data.name == 'medium' && this.data.from == 'diff') {
      const formArray: FormArray = this.createSchoolSrvc_.createSchoolForm.get(
        'schoolMedium'
      ) as FormArray;

      /* Selected */
      if (event.target.checked) {
        // Add a new control in the arrayForm
        formArray.push(new FormControl(event.target.value));
        this.createSchoolSrvc_.mediumlist.push(event.target.value);
      } else {
        /* unselected */
        // find the unselected element
        let i: number = 0;

        formArray.controls.forEach((ctrl) => {
          if (ctrl.value == event.target.value) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }

          i++;
        });

        if (this.createSchoolSrvc_.mediumlist.includes(event.target.value)) {
          let index = this.createSchoolSrvc_.mediumlist.indexOf(event.target.value);
          if (index != -1) {
            this.createSchoolSrvc_.mediumlist.splice(index, 1);
          } else {
          }
        }
      }
    }
  }
}
