import { Component, Inject, OnInit } from '@angular/core';
import { DialogRole, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-library-successfull-dialog',
  templateUrl: './library-successfull-dialog.component.html',
  styleUrls: ['./library-successfull-dialog.component.scss'],
})
export class LibrarySuccessfullDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialogSrvc_: DialogService
  ) {}

  ngOnInit(): void {}

  navigateToLibraryDashboard() {
    this.router.navigate([`/layout/library`]);
    this.dialogSrvc_.close();
  }
}
