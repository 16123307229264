<div class="container">
  <h1 class="title">Select Teacher to Substitute</h1>

  <!-- list of teachers -->
  <div class="profiles mt-5" *ngIf="teacherDetails?.length">
    Reccomended Teachers
    <a
      (click)="item.availablity ? assign(item) : ''"
      [ngClass]="{ grey: !item.availablity }"
      class="profile"
      *ngFor="let item of reccoTeachers"
    >
      <div class="info">
        <img
          [src]="
            !!item?.photo_url
              ? item?.photo_url
              : item?.gender == 'male'
              ? '../../../../assets/images/teachers/teacher-default-male.svg'
              : '../../../../assets/images/teachers/teacher-default-female.svg'
          "
          alt="teacher"
          class="profile_icon"
        />
        <div class="details">
          <h4>
            {{ item?.name.join(" ") | titlecase }}

            <small *ngIf="!item.availablity">
              ( Not available for this period )
            </small>
          </h4>
          <div class="flex">
            <small *ngFor="let item of item?.allocations?.classes">
              <span *ngIf="item.subject">
                {{ item.grade }} {{ item.section | uppercase }}
                {{ item.subject | titlecase }}</span
              >
              ,
            </small>
          </div>

          <!-- <p>subject</p> -->
        </div>
      </div>
    </a>

    <div class="horizontal_line mb-3 mt-3"></div>

    Other Available Teachers
    <a
      [ngClass]="{ grey: !item.availablity }"
      class="profile"
      *ngFor="let item of otherTeacher"
    >
      <div class="info" (click)="item.availablity ? assign(item) : ''">
        <img
          [src]="
            !!item?.photo_url
              ? item?.photo_url
              : item?.gender == 'male'
              ? '../../../../assets/images/teachers/teacher-default-male.svg'
              : '../../../../assets/images/teachers/teacher-default-female.svg'
          "
          alt="teacher"
          class="profile_icon"
        />
        <div class="details">
          <h4>{{ item?.name.join(" ") | titlecase }}</h4>
          <div class="flex">
            <small *ngFor="let item of item?.allocations?.classes">
              <span *ngIf="item.subject">
                {{ item.grade }} {{ item.section | uppercase }}
                {{ item.subject | titlecase }}</span
              >
              ,
            </small>
          </div>

          <!-- <p>subject</p> -->
        </div>
      </div>
    </a>

    <div class="horizontal_line mb-3 mt-3"></div>
  </div>
</div>
