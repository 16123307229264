<div class="list_contianer flex flex-col gap-4">
    <div class="flex justify-end items-end">
        <button class="flex justify-end items-end mb-4" mat-dialog-close>
            <img src="../../../../assets/icons/close_blue.svg" alt="">
        </button>
    </div>
    <ng-container *ngIf="!data.role && !data.action">
        <div class="header flex items-center justify-between">
            <h1 class="text-ink_dark font-bold text_large">Add Students</h1>
            <button class="btn_blue" style="padding: 10px 20px 10px 20px ;"
                [ngClass]="{'opacity-50': !selectedStudentsArray.length,'cursor-default': !selectedStudentsArray.length}"
                [disabled]="!selectedStudentsArray.length" (click)="onSaving()">
                Save</button>
        </div>
        <div
            class="classlist_filter_contianer flex  md:flex-row flex-col-reverse gap-3   md:justify-between justify-start">


            <div class="sorting_header flex gap-4 ">
                <div class="flex  flex-wrap   " [class]=" selected_sorting_option == i ? 'option_selected' :'option' "
                    *ngFor="let grade of gradesArray;let i=index" (click)="filterStudentWRTGrade(grade,i)">
                    <p class="">Grade {{grade|uppercase}}</p>
                </div>
            </div>
            <!-- search Options -->
            <!-- [(ngModel)]="searchInput"
                  (ngModelChange)="searchFilter($event)" -->

            <div class="search_container md:py-0 py-4">
                <div class="search_box flex gap-2 relative">
                    <img src="../../../../assets/icons/search.svg" class="absolute top-3 left-3" alt="search">
                    <input type="text" placeholder="Search class" class="search_input " />
                </div>
            </div>
        </div>
        <div class="list_items flex flex-col gap-4">
            <div class="list_item flex justify-between gap-4 items-center bg-sky_lightest p-4 rounded-lg hover:bg-purple_lightest"
                *ngFor="let student of filteredStudents" (click)="popPushStudent(student.id)"
                [ngClass]="{'bg-purple_lightest': !!selectedStudentsArray.includes(student.id)}">
                <div class="flex items-center gap-9">
                    <div class="flex gap-4 items-center">
                        <app-rounded-image borderColor="#FFB600" [imageSrc]="student?.userDetails?.photo_url"
                            default="../../../../assets/icons/no_profile_pic.svg"></app-rounded-image>
                        <div class="flex flex-col gap-1">
                            <h1 class="text-ink_dark font-bold text_medium">{{student?.userDetails?.name?.join('
                                ')|titlecase}}</h1>
                        </div>
                    </div>
                    <div class="hide_in_mobile">
                        <h1 class="font-semibold text-ink_darker text_Small ">{{student.grade|uppercase}}
                            {{student.section|uppercase}}</h1>
                    </div>
                </div>

                <div>
                    <!-- if selected -->
                    <img *ngIf="!selectedStudentsArray.includes(student.id)"
                        src="../../../../assets/icons/radio_selection.svg" alt="">
                    <!-- if not selected -->
                    <img *ngIf="!!selectedStudentsArray.includes(student.id)"
                        src="../../../../assets/icons/tick_purple.svg" alt="">
                </div>

            </div>
            <div>

            </div>



        </div>

    </ng-container>


    <ng-container *ngIf="!!data.role && !!data.action">
        <div class="header flex items-center justify-between">
            <h1 class="text-ink_dark font-bold text_large">{{data?.action|titlecase}} {{data?.role|titlecase}}</h1>
            <button class="btn_blue" style="padding: 10px 20px 10px 20px ;"
                [ngClass]="{'opacity-50': !selectedStudentsArray.length,'cursor-default': !selectedStudentsArray.length}"
                [disabled]="!selectedStudentsArray.length" (click)="onSavingCaptainViceCaptain()">
                Save</button>
        </div>
        <div
            class="classlist_filter_contianer flex  md:flex-row flex-col-reverse gap-3   md:justify-between justify-start">


            <div class="sorting_header flex gap-4 ">
                <div class="flex  flex-wrap   " [class]=" selected_sorting_option == i ? 'option_selected' :'option' "
                    *ngFor="let grade of gradesArray;let i=index" (click)="filterStudentWRTGrade(grade,i)">
                    <p class="">Grade {{grade|uppercase}}</p>
                </div>
            </div>
            <!-- search Options -->
            <!-- [(ngModel)]="searchInput"
                  (ngModelChange)="searchFilter($event)" -->

            <div class="search_container md:py-0 py-4">
                <div class="search_box flex gap-2 relative">
                    <img src="../../../../assets/icons/search.svg" class="absolute top-3 left-3" alt="search">
                    <input type="text" placeholder="Search class" class="search_input " />
                </div>
            </div>
        </div>
        <div class="list_items flex flex-col gap-4">
            <div class="list_item flex justify-between gap-4 items-center bg-sky_lightest p-4 rounded-lg hover:bg-purple_lightest"
                *ngFor="let student of filteredStudents" (click)="popPushCaptain(student.id)"
                [ngClass]="{'bg-purple_lightest': !!selectedStudentsArray.includes(student.id)}">
                <div class="flex items-center gap-9">
                    <div class="flex gap-4 items-center">
                        <app-rounded-image borderColor="#FFB600" [imageSrc]="student?.userDetails?.photo_url"
                            default="../../../../assets/icons/no_profile_pic.svg"></app-rounded-image>
                        <div class="flex flex-col gap-1">
                            <h1 class="text-ink_dark font-bold text_medium">{{student?.userDetails?.name?.join('
                                ')|titlecase}}</h1>
                        </div>
                    </div>
                    <div class="hide_in_mobile">
                        <h1 class="font-semibold text-ink_darker text_Small ">{{student.grade|uppercase}}
                            {{student.section|uppercase}}</h1>
                    </div>
                </div>

                <div>
                    <!-- if selected -->
                    <img *ngIf="!selectedStudentsArray.includes(student.id)"
                        src="../../../../assets/icons/radio_selection.svg" alt="">
                    <!-- if not selected -->
                    <img *ngIf="!!selectedStudentsArray.includes(student.id)"
                        src="../../../../assets/icons/tick_purple.svg" alt="">
                </div>

            </div>
            <div>

            </div>



        </div>

    </ng-container>


</div>
