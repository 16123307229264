import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoDataComponent implements OnInit {
  @Input() imageSrc: string = '../../../assets/images/default_prifile.svg';
  @Input() width: string = '';
  @Input() height: string = '400';
  @Input() radius: string = '';
  @Input() borderwidth: string = '';
  @Input() borderType: String = '';
  @Input() borderColor: string = '';
  @Input() default: string = '';
  @Input() btnClick: any = new EventEmitter();
  @Input() btnText: string = 'no data';
  @Input() btnType: string = 'large';
  @Input() btnColor: string = 'blue';
  @Input() route: string = '';
  buttonStyle: string = '';
  imgeStyles: String = `width:${this.width}px;height:${this.height}px;border-radius:${this.radius};border:${this.borderwidth} ${this.borderType} ${this.borderColor};`;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.imgeStyles = `width:${this.width}px;height:${this.height}px;border-radius:${this.radius};border:${this.borderwidth} ${this.borderType} ${this.borderColor};`;

    let tempBtnStyle: string = '';
    if (this.btnType == 'small' && this.btnColor == 'blue') {
      tempBtnStyle = 'btn_blue_small';
    }
    else if (this.btnType == 'small' && this.btnColor == 'white') {
      tempBtnStyle = 'btn_white_small';
    }
    else if (this.btnType == 'medium' && this.btnColor == 'blue') {
      tempBtnStyle = 'btn_blue_medium';
    }
    else if (this.btnType == 'medium' && this.btnColor == 'white') {
      tempBtnStyle = 'btn_white_medium';
    }
    else if (this.btnType == 'large' && this.btnColor == 'blue') {
      tempBtnStyle = 'btn_blue_large';
    }
    else if (this.btnType == 'large' && this.btnColor == 'white') {
      tempBtnStyle = 'btn_white_large';
    }
    this.buttonStyle = tempBtnStyle;

  }

  // if on click of button, route is provided, then navigate to that route or else emit the event
  OnClick() {
    console.log(this.route);
    if (this.route.length) {
      this.router.navigate([this.route]);
    } else {
      this.btnClick.emit();
    }
  }

}
