import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  SchoolEvent,
  Type_of_event,
} from 'src/app/shared/models/school_event.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { EventService } from 'src/app/shared/services/event.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ClassListState } from 'src/app/shared/store/class-list/class-list.state';

@Component({
  selector: 'app-create-event-new-dialog',
  templateUrl: './create-event-new-dialog.component.html',
  styleUrls: ['./create-event-new-dialog.component.scss'],
})
export class CreateEventNewDialogComponent implements OnInit {
  showDatapicker1: boolean = false;
  showDatapicker2: boolean = false;
  typeOfEvent: string = 'exams';
  selectAllClasses: boolean = false;
  schoolClasses: any[] = [];
  sub1: Subscription;
  viewClasses: boolean = false;
  selectedClasses: any[] = [];
  minEndDate: Date;
  submitted: boolean = false;
  save: BehaviorSubject<string> = new BehaviorSubject<string>('Save');
  patch: boolean = false;
  docId: string;

  createEventForm = new FormGroup({
    title: new FormControl('', Validators.required),
    from: new FormControl({ value: '', disabled: true }, [Validators.required]),
    to: new FormControl({ value: '', disabled: true }, [Validators.required]),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private eventSrvc_: EventService,
    private dialogSrvc_: DialogService,
    private snackBarSrvc_: SnackbarService
  ) {}
  ngOnInit(): void {
    this.sub1 = this.store.select(ClassListState).subscribe((el) => {
      this.schoolClasses = JSON.parse(JSON.stringify(el.classList));
      const collator = new Intl.Collator('en', {
        numeric: true,
        sensitivity: 'base',
      });
      this.schoolClasses = this.schoolClasses.sort((a, b) =>
        collator.compare(`${a.name}${a.section}`, `${b.name}${b.section}`)
      );
    });
    if (!!this.data.type) {
      this.typeOfEvent = this.data.type;
    }
    if (!!this.data.edit) {
      this.patch = true;
      this.patchValues(this.data.event);
      this.selectedClasses = this.data.event.class_ids.map((class_id: any) => {
        let classFound = this.schoolClasses.find(
          (clas: any) => clas.id == class_id
        );
        if (!!classFound) {
          return classFound;
        } else {
          return;
        }
      });
      this.startDate?.enable();
      this.endDate?.enable();
      this.minEndDate = new Date(
        this.startDate?.value.getFullYear(),
        this.startDate?.value.getMonth(),
        this.startDate?.value.getDate(),
        this.startDate?.value.getHours(),
        this.startDate?.value.getMinutes()
      );
    } else {
      this.startDate?.enable();
    }
    this.startDate?.valueChanges.subscribe((el: any) => {
      if (el) {
        this.minEndDate = new Date(
          this.startDate?.value.getFullYear(),
          this.startDate?.value.getMonth(),
          this.startDate?.value.getDate(),
          this.startDate?.value.getHours(),
          this.startDate?.value.getMinutes()
        );
        this.endDate?.enable();
      }
    });
  }

  patchValues(event: any) {
    this.typeOfEvent = event.type;
    this.docId = event.id;
    this.createEventForm.patchValue({
      title: event.title,
      from: event.from,
      to: event.to,
    });
  }

  toggleEvent(type: string) {
    this.typeOfEvent = type;
  }

  classViewButton() {
    this.viewClasses = true;
  }

  onClickingClass(event: any, clas: any) {
    console.log(clas);
    if (event.target.checked) {
      this.selectedClasses.push(clas);
    } else {
      let arr = this.selectedClasses.filter(
        (clas1: any) => clas1.id == clas.id
      );
      if (arr.length) {
        let index = this.selectedClasses.findIndex(
          (clas1: any) => clas1.id == clas.id
        );
        if (index != -1) {
          this.selectedClasses.splice(index, 1);
        } else {
        }
      }
      console.log(this.selectedClasses);
    }
  }

  onSelectingAllClasses(event: any) {
    if (event.target.checked) {
      this.selectedClasses = JSON.parse(JSON.stringify(this.schoolClasses));
      this.selectAllClasses = true;
    } else {
      this.selectedClasses = [];
      this.selectAllClasses = false;
    }
  }

  selectAllButton() {
    this.selectAllClasses = !this.selectAllClasses;
    if (this.selectAllClasses) {
      this.selectedClasses = JSON.parse(JSON.stringify(this.schoolClasses));
      console.log(this.selectedClasses);
    } else {
      this.selectedClasses = [];
    }
  }

  isChecked(item: any) {
    let arr = this.selectedClasses.filter((clas: any) => clas.id == item.id);
    // console.log(arr.length);
    return !!arr.length;
  }

  getTitleErrorMessage() {
    return this.title?.hasError('required') ? 'Please enter a title' : '';
  }

  getStartErrorMessage() {
    return this.startDate?.hasError('required') ? 'Start Date required' : '';
  }

  getEndErrorMessage() {
    if (this.endDate?.hasError('required')) {
      return 'End Date required';
    }
    return this.endDate?.hasError('min')
      ? 'End Date should be greater than start date'
      : '';
  }

  calculateLeave(startDate: any, endDate: any) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    console.log(date1, date2);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Math.floor(Difference_In_Days + 1);
  }

  onSave() {
    this.createEventForm.markAllAsTouched();
    this.submitted = true;
    console.log(this.title?.hasError('required') && this.submitted);
    if (!this.createEventForm.valid) {
      this.snackBarSrvc_.openSnackBarTop(
        'Please fill all the required fields',
        'Alert'
      );
      this.submitted = false;
      return;
    }
    if (this.selectedClasses.length == 0) {
      this.snackBarSrvc_.openSnackBarTop(
        'Please select atleast one class',
        'Alert'
      );
      this.submitted = false;
      return;
    }
    if (this.createEventForm.valid && this.selectedClasses.length) {
      this.save.next('Saving');
      let event: SchoolEvent = {
        title: this.createEventForm.value.title,
        from:
          this.typeOfEvent == 'holidays'
            ? new Date(
                this.startDate?.value.getFullYear(),
                this.startDate?.value.getMonth(),
                this.startDate?.value.getDate(),
                0,
                0,
                0
              )
            : new Date(
                this.startDate?.value.getFullYear(),
                this.startDate?.value.getMonth(),
                this.startDate?.value.getDate(),
                this.startDate?.value.getHours(),
                this.startDate?.value.getMinutes(),
                0
              ),
        to:
          this.typeOfEvent == 'holidays'
            ? new Date(
                this.endDate?.value.getFullYear(),
                this.endDate?.value.getMonth(),
                this.endDate?.value.getDate(),
                23,
                59,
                59
              )
            : new Date(
                this.endDate?.value.getFullYear(),
                this.endDate?.value.getMonth(),
                this.endDate?.value.getDate(),
                this.endDate?.value.getHours(),
                this.endDate?.value.getMinutes(),
                0
              ),
        created_at: new Date(),
        type:
          this.typeOfEvent == 'activities'
            ? Type_of_event.events
            : this.typeOfEvent == 'exams'
            ? Type_of_event.exams
            : this.typeOfEvent == 'holidays'
            ? Type_of_event.holidays
            : Type_of_event.others,
        curriculum: this.eventSrvc_.currentSchoolDetails.curriculum,
        school_id: this.eventSrvc_.currentSchoolId,
        class_ids: this.selectedClasses.map((a) => a.id),
        day: this.calculateLeave(this.startDate?.value, this.endDate?.value),
      };
      if (!this.patch) {
        this.eventSrvc_
          .addingAnEvent(event)
          .then(() => {
            this.submitted = false;
            this.save.next('Saved');
            this.snackBarSrvc_.openSnackBarTop(
              'Event added successfully!!',
              'Success'
            );
            this.dialogSrvc_.dismissAll();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.eventSrvc_
          .updatingAnEvent(event, this.docId)
          .then(() => {
            this.submitted = false;
            this.save.next('Saved');
            this.snackBarSrvc_.openSnackBarTop(
              'Event updated successfully!!',
              'Success'
            );
            this.dialogSrvc_.dismissAll();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  get title() {
    return this.createEventForm.get('title') as FormControl;
  }

  get startDate() {
    return this.createEventForm.get('from') as FormControl;
  }

  get endDate() {
    return this.createEventForm.get('to') as FormControl;
  }
}
