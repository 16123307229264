<div class="bar_chart_outer relative" [style]="outerStyle" *ngIf="type ==1">
  <div [style]="innerStyle" class="absolute bottom-0 innerStyle">
  </div>
</div>

<!-- <div class="relative flex items-end" *ngIf="type ==2" style="height: 100%;">
    <div [style]="type2Styles">

    </div>
</div> -->
<div class="relative flex " *ngIf="type ==2" style="height: 100%;">
  <!--  -->
  <div class="grid grid-cols-12 w-full ">
    <!-- y labels -->
    <!-- <div class="flex col-span-1 flex-col justify-between">
            <div *ngFor="let ylabel of barchartType2DataPoints.y_labels.slice()?.reverse()">
                <p class="text-xs " [style.color]="barchartType2DataPoints.y_label_color">{{ylabel}}</p>
            </div>
        </div> -->
    <!-- x labels -->
    <div class="flex col-span-11 flex-end gap-3 items-end justify-between overflow-auto">
      <div *ngFor="let x of barchartType2DataPoints.x_labels;let i=index" class="h-full flex  ">
        <div class="flex flex-col gap-1 items-center">
          <div class="h-full flex items-end justify-center gap-2 w-full">
            <ng-container *ngFor="let item of barchartType2DataPoints.data[i]?.values">
              <div [style.background]="item.color" [style.height]="calculateEachDataPercentage(item.value)+1+'%'"
                class="w-1/2 rounded-md" style="width: 30px;" [tippy]="tootlTipValue(i)"></div>
            </ng-container>
          </div>
          <p class="text-xs " [style.color]="barchartType2DataPoints.x_label_color">{{x}}</p>
        </div>

      </div>
    </div>

  </div>

</div>
