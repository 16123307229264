import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CommonService } from 'src/app/shared/services/common.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SchoolCouncilService } from "../../../shared/services/school-council.service";
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StudentsAndTeachersState } from 'src/app/shared/store/students_teachers/students_teachers.state';
import { cloneDeep, union } from 'lodash';
import { council_student } from 'src/app/shared/models/school_council.model';

@Component({
  selector: 'app-council-students-list-dialog',
  templateUrl: './council-students-list-dialog.component.html',
  styleUrls: ['./council-students-list-dialog.component.scss'],
})
export class CouncilStudentsListDialogComponent implements OnInit {
  selected_sorting_option = 0;
  schoolStudentList: any[] = [];
  gradesArray: any[] = [];
  filteredStudents: any[] = [];
  selectedStudentsArray: any[] = [];
  searchInput: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private commonSrvc_: CommonService,
    // private schoolHouseSrvc_: SchoolHouseService,
    // private schoolTeamSrvc_:TeamService,
    private schoolCouncilSrvc_:SchoolCouncilService,
    private snackBarSrvc_: SnackbarService,
    private dialogSrvc_: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store
    .select(StudentsAndTeachersState.getSchoolStudents)
    .subscribe((el: any) => {
      console.log(el);
      let stdList = cloneDeep(el);
      this.gradesArray = union(stdList.map((a: any) => a.grade));
      this.gradesArray = this.commonSrvc_.sortClassForExams(this.gradesArray);
      console.log(this.gradesArray);
      this.schoolStudentList = cloneDeep(el);
      this.schoolStudentList = this.schoolStudentList.filter(
        (student: any) =>
          !student.hasOwnProperty('council_id') || student.council_id == ''
      );
      this.filterStudentWRTGrade(this.gradesArray[0], 0);
    });
  }

  filterStudentWRTGrade(grade: string, index: number) {
    this.selected_sorting_option = index;
    this.filteredStudents = this.schoolStudentList.filter(
      (student: any) => student.grade.toLowerCase() == grade.toLowerCase()
    );
    console.log(this.filteredStudents);
  }

  popPushStudent(id: string) {
    if (this.selectedStudentsArray.includes(id)) {
      let index = this.selectedStudentsArray.findIndex(
        (sid: string) => sid == id
      );
      this.selectedStudentsArray.splice(index, 1);
    } else {
      this.selectedStudentsArray.push(id);
      this.selectedStudentsArray = union(this.selectedStudentsArray);
    }
    console.log(this.selectedStudentsArray);
  }

  onSaving() {
    //add selectedHousedoc id to student doc
    let students: council_student[] = [];
    for (let i = 0; i < this.selectedStudentsArray.length; i++) {
      const studentDocId = this.selectedStudentsArray[i];
      let student = this.schoolStudentList.find(
        (student: any) => student.id == studentDocId
      );
      if (!!student) {
        let councilStudent: council_student = {
          class_id:
            student.class_ref.split('/')[
              student.class_ref.split('/').length - 1
            ],
          grade: student.grade.toLowerCase(),
          roll: student.roll,
          section: student.section.toLowerCase(),
          uid: student.user_id,
          doc_id: studentDocId,
        };
        students.push(councilStudent);
      }
    }
    console.log(students);
    this.commonSrvc_.showSpinner = true;

    this.schoolCouncilSrvc_
      .updateStudentsArrayInCouncilDoc(this.data.id, students)
      .then(() => {
        this.snackBarSrvc_.openSnackBarTop(
          'Councils Doc Updated Successfully',
          'Success!!'
        );
        this.schoolCouncilSrvc_
          .updateMultipleStudentDocs(this.selectedStudentsArray, this.data.id)
          .then(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Student Documents updated successfully',
              'Success!!'
            );
            this.commonSrvc_.showSpinner = false;
            this.dialogSrvc_.close();
            this.router.navigate(['layout/community/council/list']);
          })
          .catch(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Student Documents updation failed',
              'Failure'
            );
          });
      })
      .catch((err) => {
        this.snackBarSrvc_.openSnackBarTop(
          `Councils Doc Updation failed due to ${err}`,
          'Failure'
        );
      });
    return;
  }
}
