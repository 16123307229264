import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-h-button',
  templateUrl: './h-button.component.html',
  styleUrls: ['./h-button.component.scss']
})
export class HButtonComponent implements OnInit {
  @Input() btnType="blue"
  @Input() label :string = "Button"
  @Input() size :string = "medium" // medium large  small extra large 
  @Input() backGroundColor ="#6B4EFF"
  @Input() color ="white"
  @Input() borderRadius = '10px'
  @Input() borederWidth:'1px';
  @Input() borderType ='solid';
  @Input() borderColor = '#6B4EFF';
  @Input() weight = '500';
  @Input() fontSize='18px'

 btnBlueStyle = ''

  constructor() { }

  ngOnInit(): void {

  }

}
