import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  openSnackBarTop(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'top';
    config.panelClass = ['blue-snackbar'];
    this._snackBar.open(message, action, config);
  }
  openSnackBar(message: string, action: string = 'Dismiss', duration: number= 2000, horizontalPosition: any = 'center', verticalPosition: any='bottom') {
    this._snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }
}
