<div class="close">
  <div class="menu" *ngIf="sidebarWidth == 0">
    <button (click)="sidebarWidth = 290">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="menu" *ngIf="sidebarWidth == 290">
    <button (click)="sidebarWidth = 0" style="background: none">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div
  class="sidebar_container"
  (mouseover)="sidebarWidth = 290"
  (mouseleave)="onMouseLeave()"
  [style.width.px]="sidebarWidth"
  [style.padding]="sidebarWidth >= 70 ? '25px 6px 0px 16px' : '0px'"
>
  <!-- school selection drop down -->
  <div class="cursor-pointer" mat-button [matMenuTriggerFor]="menu">
    <div class="selcted_school flex gap-4 items-center">
      <div class="school_image">
        <img
          *ngIf="
            (selectedSchoolDetails$ | async)?.profile_url as img;
            else default
          "
          [src]="img"
          (error)="updateUrl($event)"
          alt=""
        />

        <ng-template #default>
          <img src="../../../../assets/icons/default_school_icon.svg" alt="" />
        </ng-template>
      </div>
      <div class="schoolName flex flex-col">
        <h1 class="regular_normal1 text-white school_title">
          {{ (selectedSchoolDetails$ | async)?.name?.join(" ") | titlecase }}
        </h1>
        <h6 class="role text-purple_lightest small_normal2">Admin</h6>
      </div>
      <div class="cursor-pointer">
        <img
          class="school_icon"
          src="../../../../assets/icons/side-bar/dropDown.svg"
          alt=""
        />
      </div>
      <mat-menu #menu="matMenu" backdropClass="dropdownMenu">
        <!-- school count andd add scholl -->
        <div class="flex gap-2 justify-between items-center drop_header">
          <h1 class="large_normal2 text-purple">
            My Schools ({{ totalAdmin$ | async }})
          </h1>
          <button routerLink="/dashboard-onboard">
            <img
              tippy="Add New School"
              src="../../../../assets/icons/side-bar/add.svg"
              alt=""
            />
          </button>
        </div>
        <button
          mat-menu-item
          (click)="schoolSelected(item)"
          class="p-4"
          [ngClass]="(checkedOrNot(item) | async) ? 'drop_down_selected' : ''"
          *ngFor="let item of adminList$ | async"
        >
          <div class="flex items-center gap-5 p-2">
            <img
              class="drop_down_school_image"
              src="../../../../assets/icons/school_default.svg"
            />
            <div class="down_school_text flex flex-col gap-1">
              <div class="drop_down_dropdown">
                <h3
                  class="drop_down_school_name text-ink_base regular_normal1"
                  style="margin: 0px"
                >
                  {{
                    (getSchoolDetails(item.school_id) | async)?.name?.join(" ")
                      | titlecase
                  }}
                </h3>
              </div>
              <p class="user_type text-ink_lighter small_normal2">
                {{ item.role | titlecase }}
              </p>
            </div>
          </div>
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- sitebase menu items -->
  <div class="menu_container">
    <div class="flex flex-col gap-6">
      <div
        class="relative flex items-center gap-3 menu_items"
        *ngFor="let item of menuList; let i = index"
        (click)="navigateToRoute(item.route)"
        id="item{{ i }}"
      >
        <div
          class="flex items-center gap-5 item_contents"
          [class.selected]="selectedMenu == i"
        >
          <img [src]="item.active" class="item_icon icon_active" alt="" />
          <img [src]="item.inactive" class="item_icon icon_inactive" alt="" />

          <div class="flex items-center gap-4 item_content">
            <h1 class="regular_normal2 text-purple_lighter title">
              {{ item.title }}
            </h1>
            <div class="indication">
              <img
                src="../../../../assets/icons/side-bar/sidebar-active-indication.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="active_symbol" *ngIf="i == selectedMenu">
          <div class="active"></div>
        </div>
      </div>

      <!-- school setup -->

      <div class="flex flex-col gap-2 mt-6">
        <div class="nav_saparator"></div>
        <div
          class="relative flex items-center gap-3 menu_items"
          (click)="navigateToRoute('/layout/onboard-school/edit')"
          id="item{{ menuList.length }}"
        >
          <div
            class="flex items-center gap-5 item_contents"
            [class.selected]="selectedMenu == menuList.length"
          >
            <img
              src="../../../../assets/icons/side-bar/settings-active.svg"
              class="item_icon icon_active"
              alt=""
            />
            <img
              src="../../../../assets/icons/side-bar/settings-inactive.svg"
              class="item_icon icon_inactive"
              alt=""
            />

            <div class="flex items-center gap-4 item_content">
              <h1 class="regular_normal2 text-purple_lighter title">
                School Setting
              </h1>
              <div class="indication">
                <img
                  src="../../../../assets/icons/side-bar/sidebar-active-indication.svg"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div class="active_symbol" *ngIf="menuList.length == selectedMenu">
            <div class="active"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
