import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyComponent implements OnInit {

  @Input() message: string = "No Data Found";
  @Input() color: string = "#303437";
  @Input() fontsize: string = 'large1';
  @Input() width: string = '100%';
  @Input() height: string = '100%';

 


  options: AnimationOptions = {
    path: "../../../assets/lottie/emptystate.json"
  };

  constructor() {

  }
ngOnInit(): void {

}

}
