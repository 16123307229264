import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss'],
})
export class BarchartComponent implements OnInit, OnChanges {
  @Input() height: string = '150px';
  @Input() width: string = '40px';
  @Input() backGroundColor: string = '#F7F9FA';
  @Input() fillColor: string = '#6B4EFF';
  @Input() fillPersentage: string = '0%';
  @Input() borderRadius: string = '10px';
  @Input() type: any = 1;
  @Input() data!: any;
  outerStyle: string;
  innerStyle: string;

  maxValue: number = 100;

  // second type bar chart
  type2Styles: any;

  barchartType2DataPoints = {
    // y_labels: [0, 20, 40, 60, 80, 100],
    y_label_color: '#6B4EFF',
    x_labels: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    x_label_color: '#6B4EFF',
    data: [
      { label: 'Sunday', values: [{ value: 20, color: '#6B4EFF' }] },
      { label: 'Monday', values: [{ value: 0, color: '#6B4EFF' }] },
      { label: 'Tuesday', values: [{ value: 20, color: '#6B4EFF' }] },
      { label: 'Wednesday', values: [{ value: 60, color: '#6B4EFF' }] },
      { label: 'Thursday', values: [{ value: 80, color: '#6B4EFF' }] },
      { label: 'Friday', values: [{ value: 50, color: '#6B4EFF' }] },
      { label: 'Saturday', values: [{ value: 20, color: '#6B4EFF' }] },
    ],
  };

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!isEmpty(this.data)) {
      this.barchartType2DataPoints = this.data;
      this.maxValue = this.calculateTheMax();
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.outerStyle = `height: ${this.height}; width: ${this.width}; background-color: ${this.backGroundColor}; border-radius:${this.borderRadius}; `;
    this.innerStyle = `height: ${this.fillPersentage}; width: ${this.width}; background-color: ${this.fillColor}; border-radius:${this.borderRadius}; `;
    this.type2Styles = `height: ${this.height}; width: ${this.width}; background-color: ${this.backGroundColor}; border-radius:${this.borderRadius};`;
    this.maxValue = this.calculateTheMax();
  }

  calculateTheMax() {
    let values = [];
    for (let i = 0; i < this.barchartType2DataPoints.data.length; i++) {
      const data = this.barchartType2DataPoints.data[i];
      values.push(data.values[0].value);
    }
    return values.reduce(function (p, v) {
      return p > v ? p : v;
    });
  }

  calculateEachDataPercentage(value: number) {
    return (value * 100) / this.maxValue;
  }

  tootlTipValue(index: number) {
    return `${this.barchartType2DataPoints.data[index].values[0].value}`;
  }
}
