import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  bookFromRequest,
  bookFromSearch,
  BooksListForNavigation,
  bookToReturn,
  clearBooksForNavigation,
  ClearlibraryState,
  GetLibraryBookRequests,
  GetLibraryBooks,
  SelectedSchoolLibrary,
} from './library.action';

@State<any>({
  name: 'SchoolLibraryState',
  defaults: {
    schoolLibrary: {},
    books: [],
    forNavigation: [],
    bookFromSearch: {},
    bookFromRequest: {},
    bookToReturn: {},
    bookRequests: [],
  },
})
@Injectable()
export class SchoolLibraryState {
  @Selector() static getCurrentNavigationBooks(state: any) {
    return state.forNavigation;
  }

  @Action(SelectedSchoolLibrary)
  setSchoolLibrary(ctx: StateContext<any>, { library }: SelectedSchoolLibrary) {
    ctx.setState(
      patch({
        schoolLibrary: library,
      })
    );
  }

  @Action(GetLibraryBooks)
  setLibraryBooks(ctx: StateContext<any>, { books }: GetLibraryBooks) {
    ctx.setState(
      patch({
        books: books,
      })
    );
  }

  @Action(GetLibraryBookRequests)
  setLibraryBookRequests(
    ctx: StateContext<any>,
    { bookRequests }: GetLibraryBookRequests
  ) {
    ctx.setState(
      patch({
        bookRequests: bookRequests,
      })
    );
  }

  @Action(BooksListForNavigation)
  setBooksListForNavigation(
    ctx: StateContext<any>,
    { books }: BooksListForNavigation
  ) {
    ctx.setState(
      patch({
        forNavigation: books,
      })
    );
  }

  @Action(bookFromSearch)
  setBookFromSearch(ctx: StateContext<any>, { book }: bookFromSearch) {
    ctx.setState(
      patch({
        bookFromSearch: book,
      })
    );
  }

  @Action(bookFromRequest)
  setBookFromRequest(
    ctx: StateContext<any>,
    { book }: bookFromRequest
  ) {
    ctx.setState(
      patch({
        bookFromRequest: book,
      })
    );
  }

  @Action(bookToReturn)
  setBookToReturn(
    ctx: StateContext<any>,
    { book }: bookToReturn
  ) {
    ctx.setState(
      patch({
        bookToReturn: book,
      })
    );
  }

  @Action(ClearlibraryState)
  clearLibraryState(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        schoolLibrary: {},
        books: [],
        forNavigation: [],
      })
    );
  }

  @Action(clearBooksForNavigation)
  clearBooksForNavigation(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        forNavigation: [],
      })
    );
  }
}
