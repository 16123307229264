import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { patch } from '@ngxs/store/operators';

import { loginScreens } from './login.action';

@State<any>({
  name: 'loginScreens',
  defaults: {},
})
@Injectable()
export class loginScreensState {
  constructor() {}

  @Action(loginScreens)
  setUserData(ctx: StateContext<any>, { loginScreens }: loginScreens) {
    ctx.setState(
      patch({
        loginScreens,
      })
    );
  }
}
