<div class="h-full w-full">
  <div class="step-header my-10">
    <div class="logo">
      <img
        class="hurrey_logo"
        src="../../../../assets/images/sz-header-logo.svg"
        alt=""
      />
      <img
        class="hurrey_ico"
        src="../../../../assets/icons/school-onboard/logo-mobile.svg"
        alt="hurrey logo"
      />
    </div>
    <div class="stepper_items">
      <ng-container *ngFor="let step of steps; let i = index">
        <div
          class="step-label"
          (click)="selectStepByIndex(i)"
          [ngClass]="{ active: i == selectedIndex }"
        >
          <ng-container
            *ngIf="step.stepLabel; else showLabelText"
            [ngTemplateOutlet]="step.stepLabel.template"
          >
            <span
              style="width: 25px; height: 25px"
              [ngClass]="{
                'bg-darkblue-default': i == selectedIndex,
                'bg-sky_lightest': i != selectedIndex
              }"
              class="text-white rounded-full flex justify-center items-center"
              >{{ i + 1 }}</span
            >

            <span
              *ngIf="i !== selectedIndex && step.stepControl?.valid"
              style="width: 25px; height: 25px"
              [ngClass]="{
                'bg-green_light': true
              }"
              class="text-white rounded-full flex justify-center items-center"
            >
              <span class="material-symbols-outlined"> check </span>
            </span>
          </ng-container>
          <ng-template #showLabelText>
            {{ step.label }}
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="step-body">
    <!-- <div class="step-body-header"> -->
    <!-- <app-navback [routeUrl]="
      backOptionTitle == 'Back to Timetable'
        ? '/layout/timetablenew'
        : '/layout/dashboard'
    "  [label] ="heading"></app-navback> -->

    <!-- <div
        class="back_to_dashboard-div cursor-pointer"
        [routerLink]="
          backOptionTitle == 'Back to Timetable'
            ? '/layout/timetablenew'
            : '/layout/dashboard'
        "
      >
        <span class="cursor-pointer" (click)="back(backOptionTitle)">
          <img
            src="../../../../assets/icons/school-onboard/chevron-left-white.svg"
            alt=""
          />
        </span>
        <span class="text cursor-pointer" (click)="back(backOptionTitle)">{{
          backOptionTitle ? backOptionTitle : ""
        }}</span>
      </div> -->
    <!-- <div class="step-body-header-head">
        <h1>{{ heading }}</h1>
      </div> -->
    <!-- </div> -->

    <div class="content-container">
      <div class="content" *ngIf="selectedIndex != 2">
        <ng-container
          [ngTemplateOutlet]="selected ? selected.content : null"
        ></ng-container>
      </div>
      <div class="onboarding-content" *ngIf="selectedIndex == 2">
        <ng-container
          [ngTemplateOutlet]="selected ? selected.content : null"
        ></ng-container>
      </div>
    </div>
  </div>
  <!-- <div class="step-footer">
    <footer *ngIf="selectedIndex==0">
      <button class="save_proceed-btn" cdkStepperNext (click)="check_n_save()">Save and Proceed</button>
      <button class="skip-btn">Skip</button>
    </footer>
    <footer *ngIf="selectedIndex==1">
      <button class="save_proceed-btn" cdkStepperNext (click)="checkSub()">Save and Proceed</button>
      <button class="skip-btn">Skip</button>
    </footer>
  </div> -->
</div>
