import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { teacherSubstituteAdd } from 'src/app/shared/store/teacherSubstitute/teacher-substitute.action';
import { TeacherService } from '../../teachers/shared/service/teacher.service';

@Component({
  selector: 'app-substitute-absent-teacher-dialog',
  templateUrl: './substitute-absent-teacher-dialog.component.html',
  styleUrls: ['./substitute-absent-teacher-dialog.component.scss'],
})
export class SubstituteAbsentTeacherDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store,
    private teacherServ_: TeacherService,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  teacherDetails: any[] = [];
  fullTeachersList = [];

  ngOnInit(): void {
    console.log(this.data, this.store.snapshot());

    let teachersDetails$ = this.teacherServ_.fetchTeachersBasicDetailsOnly();

    teachersDetails$.subscribe((res: any) => {
      console.log(res);
      this.fullTeachersList = res;

      for (const iterator of this.data.data.absentTeachers) {
        for (const ite of res) {
          if (iterator.teacher_id == ite.id) {
            this.teacherDetails.push(ite);
          }
        }
      }
    });
  }

  gotoSubstitute(item: any) {
    let obj = {
      teacherslist: this.fullTeachersList,
      absentTeachers: this.teacherDetails,
      selectedTeacher: item,
    };
    this.store.dispatch(new teacherSubstituteAdd(obj));
    this.matDialog.closeAll();
    this.router.navigate(['/layout/teachers/substitute']);
  }
}
