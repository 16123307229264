export class GetSchoolStudents {
  static readonly type = '[schoolStudentsTeachers] GetSchoolStudents';
  constructor(public students: any) {}
}

export class GetSchoolTeachers {
  static readonly type = '[schoolStudentsTeachers] GetSchoolTeachers';
  constructor(public teachers: any) {}
}

export class ClearStudentsTeachers{
  static readonly type = '[schoolStudentsTeachers] ClearStudentsTeachers';
}
