import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { SelectedSchoolState } from '../store/school/selected-school.state';
import { CommonService } from './common.service';
import { SnackbarService } from './snackbar.service';
import {
  council_incharge,
  council_student,
  SchoolCouncil,
} from '../models/school_council.model';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class SchoolCouncilService {
  selectedPhotoFile!: File;
  progress: boolean = false;
  councilId!: any;

  councilPhotoUrl: string = '';
  councilProfileUploadTask!: AngularFireUploadTask;
  councilProfileUploadPercentage!: Observable<number | undefined>;
  councilProfileUploadSnapShot!: Observable<any>;

  currentSchoolDocId: string = '';
  selectedAdmin$ = this.store.select(SelectedSchoolState);

  constructor(
    private store: Store,
    private fireStorage: AngularFireStorage,
    private commonSrvc_: CommonService,
    private fireStore: AngularFirestore,
    private snackBarSrvc_: SnackbarService
  ) {
    let currentSchoolDetails$: Observable<any> = this.selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.commonSrvc_.getCurrentSchoolDetails(
            data.selectedAdmin.school_id
          );
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      this.currentSchoolDocId = el.id;
    });
  }

  fetchCouncils() {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('council')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.created_at = data.created_at.toDate();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  createCouncil(schoolCouncil: SchoolCouncil) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('council')
      .add(schoolCouncil);
  }

  setCouncilDoc(schoolCouncil: SchoolCouncil) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('council')
      .doc(this.councilId)
      .set(schoolCouncil);
  }

  getFirebaseId() {
    let autogenUid = this.fireStore.createId();
    this.councilId = autogenUid;
    // return autogenUid;
  }

  uploadCouncilProfileImage() {
    this.getFirebaseId();
    this.progress = true;
    const filePath = `council/${this.currentSchoolDocId}_${this.councilId}.jpeg`;
    const ref = this.fireStorage.ref(filePath);
    this.councilProfileUploadTask = this.fireStorage.upload(
      filePath,
      this.selectedPhotoFile
    );
    this.councilProfileUploadPercentage =
      this.councilProfileUploadTask.percentageChanges();
    this.councilProfileUploadSnapShot = this.councilProfileUploadTask
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          await ref
            .getDownloadURL()
            .toPromise()
            .then((url: any) => {
              this.councilPhotoUrl = url;
              console.log(this.councilPhotoUrl);
              this.progress = false;
              this.snackBarSrvc_.openSnackBarTop(
                'Profile photo uploaded successfully',
                'Success'
              );
            })
            .catch((error: any) => {
              this.snackBarSrvc_.openSnackBarTop(error, 'Failure');
              this.progress = false;
            });
        })
      );
    // this.profileUploadSnapShot.subscribe();
  }

  updateMultipleStudentDocs(studentIds: any[], council_id: string) {
    let batch = this.fireStore.firestore.batch();
    // let reference = this.fireStore.collection('school_student')
    for (let i = 0; i < studentIds.length; i++) {
      const studentDocId = studentIds[i];
      let batchref = this.fireStore
        .collection('school_student')
        .doc(studentDocId).ref;
      batch.update(batchref, { council_id: council_id });
    }
    return batch.commit();
  }

  updateStudentDoc(studentDocId: any, council_id: string) {
    return this.fireStore
      .collection('school_student')
      .doc(studentDocId)
      .update({
        council_id: council_id,
      });
  }

  updateStudentsArrayInCouncilDoc(
    council_id: string,
    students: council_student[]
  ) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('council')
      .doc(council_id).ref;
    return docRef.update({
      students: firebase.firestore.FieldValue.arrayUnion(...students),
    });
  }

  updateTeacherDoc(teacherDocId: string, councilId: string) {
    return this.fireStore
      .collection('school_teacher')
      .doc(teacherDocId)
      .update({
        council_id: councilId,
      });
  }

  clearCouncilIdFromTeacherDoc(teacherDoc: string) {
    return this.fireStore
      .collection('school_teacher')
      .doc(teacherDoc)
      .update({ council_id: '' });
  }

  clearCouncilIdFromStudentDoc(studentDoc: string) {
    return this.fireStore
      .collection('school_student')
      .doc(studentDoc)
      .update({ council_id: '' });
  }

  removeStudentFromCouncilDoc(council_id: any, student: council_student) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('council')
      .doc(council_id).ref;
    return docRef.update({
      students: firebase.firestore.FieldValue.arrayRemove(student),
    });
  }

  updateInchargesArrayInCouncilDoc(
    council_incharges: council_incharge[],
    council_id: string
  ) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('council')
      .doc(council_id)
      .update({
        incharges: council_incharges,
      });
  }

  addInchargeToCouncilDoc(council_id: any, incharge: council_incharge) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('council')
      .doc(council_id).ref;
    return docRef.update({
      incharges: firebase.firestore.FieldValue.arrayUnion(incharge),
    });
  }

  removeInchargeFromCouncilDoc(council_id: any, incharge: council_incharge) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('council')
      .doc(council_id).ref;
    return docRef.update({
      incharges: firebase.firestore.FieldValue.arrayRemove(incharge),
    });
  }
}
