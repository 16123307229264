import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Disconnect } from '@ngxs-labs/firestore-plugin';
import { Store } from '@ngxs/store';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { schoolForStore } from 'src/app/auth/shared/models/schoolForStore';
import { schoolAdmin } from 'src/app/auth/shared/models/school_admin';
import { SchoolSetupService } from 'src/app/pages/school-onboard/shared/school-setup.service';

import { menuItem } from 'src/app/shared/models/sidebarmenu.model';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { RemoveClasses } from 'src/app/shared/store/class-list/class-list.action';
import { RacesActions } from 'src/app/shared/store/school-admin/school-admin.action';
import { schoolAdminState } from 'src/app/shared/store/school-admin/school-admin.state';
import { schoolListState } from 'src/app/shared/store/school/school-list.state';
import { SelectedSchool } from 'src/app/shared/store/school/selected-school.action';
import { SelectedSchoolState } from 'src/app/shared/store/school/selected-school.state';
import { StudentsActions } from 'src/app/shared/store/students/student.action';
import { TeachersActions } from 'src/app/shared/store/teachers/teachers.action';
import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior,
} from '@angular/cdk/platform';
import { school } from 'src/app/auth/shared/models/school';
import { cloneDeep } from 'lodash';
import { DefaultImage } from 'src/app/shared/directives/default-image.directive';
import { SchoolHouseService } from 'src/app/shared/services/school-house.service';
import {
  ClearStudentsTeachers,
  GetSchoolStudents,
  GetSchoolTeachers,
} from 'src/app/shared/store/students_teachers/students_teachers.action';
import { MediaMatcher } from '@angular/cdk/layout';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  // encapsulation: ViewEncapsulation.None,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: {
    // '(document:click)': 'onClick($event)',
    '(window:resize)': 'onResize($event)',
  },
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() sidenav!: MatSidenav;
  // @HostListener('window:resize', ['$event'])
  mobileQuery: MediaQueryList;

  sidebarState!: string;
  // @HostListener('click', ['$event'])
  selectedMenu: any = 0;
  currentSchoolDetails!: school;
  sidebarWidth: any = 70;
  subScriptions: any = [];
  menuList: any[] = [
    {
      active: '../../../../assets/icons/side-bar/dashboard-active.svg',
      inactive: '../../../../assets/icons/side-bar/dashboard-inactive.svg',
      title: 'Dashboard',
      route: '/layout/dashboard',
    },
    {
      active: '../../../../assets/icons/side-bar/timetable-active.svg',
      inactive: '../../../../assets/icons/side-bar/timetable-inactive.svg',
      title: 'Timetable',
      route: '/layout/timetablenew',
    },
    {
      active: '../../../../assets/icons/side-bar/calendar-active.svg',
      inactive: '../../../../assets/icons/side-bar/calendar-inactive.svg',
      title: 'Calendar',
      route: '/layout/calender',
    },
    {
      active: '../../../../assets/icons/side-bar/staf-active.svg',
      inactive: '../../../../assets/icons/side-bar/staf-inactive.svg',
      title: 'Staff leave',
      route: '/layout/leaves/leaves',
    },
    {
      active: '../../../../assets/icons/side-bar/fee-active.svg',
      inactive: '../../../../assets/icons/side-bar/fee-inactive.svg',
      title: 'Fee Structure',
      route: '/layout/fee-management/feemanagement',
    },

    // {
    //   active: '../../../../assets/icons/side-bar/exam_center_inactive.svg',
    //   inactive: '../../../../assets/icons/side-bar/exam_center_active.svg',
    //   title: 'Exam Center',
    //   route: '/layout/examcenter',
    // },

    // {
    //   icon_white: '../../../../assets/icons/side-bar/house-white.svg',
    //   icon_blue: '../../../../assets/icons/side-bar/house-blue.svg',
    //   title: 'Community',
    //   route: '/layout/community',
    // },

    // {
    //   icon_white: '../../../../assets/icons/side-bar/library-white.svg',
    //   icon_blue: '../../../../assets/icons/side-bar/library-blue.svg',
    //   title: 'Library',
    //   route: '/layout/library',
    // },

    // {
    //   icon_white: '../../../../assets/icons/side-bar/settings-white.svg',
    //   icon_blue: '../../../../assets/icons/side-bar/settings-blue.svg',
    //   title: 'School Setting',
    //   route: '/onboard-school/edit',
    // },

    // { icon: '../../../../assets/more-icon.svg', title: 'More' },
  ];
  panelOpenState = false;
  private _mobileQueryListener: () => void;

  constructor(
    private sidebarService_: SidebarService,
    private _eref: ElementRef,
    private store: Store,
    private router: Router,
    private schoolSetupServ_: SchoolSetupService,
    public platform: Platform,
    private schoolHouseSrvc_: SchoolHouseService,
    private BreakpointObserver: BreakpointObserver,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    router.events.subscribe((val) => {
      let currentUrl: any = this.router.url;
      // console.log(currentUrl, currentUrl.includes('dashboard'));
      if (currentUrl.includes('dashboard')) {
        this.selectedMenu = 0;
      } else if (currentUrl.includes('timetablenew')) {
        this.selectedMenu = 1;
      } else if (currentUrl.includes('calender')) {
        this.selectedMenu = 2;
      } else if (currentUrl.includes('leaves')) {
        this.selectedMenu = 3;
        let data = {
          type: 'edit',
        };
        this.schoolSetupServ_.emitData(data);
        // this.router.navigate(['/onboard-school']);
      } else if (currentUrl.includes('examcenter')) {
        this.selectedMenu = 4;
        // this.router.navigate(['/onboard-school']);
      } else if (currentUrl.includes('house')) {
        this.selectedMenu = 5;
        // this.router.navigate(['/onboard-school']);
      } else if (currentUrl.includes('library')) {
        this.selectedMenu = 6;
        // this.router.navigate(['/onboard-school']);
      } else {
      }
      // switch(currentUrl){
      //   case currentUrl.includes('dashboard') :{ this.selectedMenu = 0; break;}
      //   case  currentUrl.includes('timetablenew'):{ this.selectedMenu = 1; break;}
      //   case currentUrl.includes('calender') :{ this.selectedMenu = 2; break;}
      //   default:{
      //     this.selectedMenu =3;
      //   }

      // }
      // console.log(this.selectedMenu);
    });
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    //
  }

  onResize(event: any) {
    console.log(event.target.innerWidth);
    if (event.target.innerWidth <= 768) {
      this.sidebarWidth = 0;
    } else {
      this.sidebarWidth = 70;
    }
  }

  toggleSideNav() {
    this.sidebarService_.toggle();
  }

  extractionLettersFromSchool() {
    let firstTwoLetters = '';
    this.currentSchoolDetails?.name.forEach((element: any) => {
      firstTwoLetters += element[0];
    });
    return firstTwoLetters.substring(0, 2);
  }

  ngOnInit(): void {
    console.log(this.router.url);

    if (window.innerWidth <= 768) {
      this.sidebarWidth = 0;
    } else {
      this.sidebarWidth = 70;
    }
  }

  // selected item hilight
  selected_index = '0';

  schoollist$ = this.store.select(schoolListState.schools);
  schoolListSub: Subscription;
  adminList$ = this.store.select(schoolAdminState.getadminlist);
  totalAdmin$ = this.adminList$.pipe(map((races) => races.length));
  selectedAdmin$ = this.store.select(SelectedSchoolState);
  selectedSchoolDetails$: Observable<school> = this.selectedAdmin$.pipe(
    map((admin) => admin.selectedAdmin?.school_id),
    tap(console.log),
    switchMap((schoolid) =>
      this.schoollist$.pipe(
        map((school: any) =>
          (school ?? []).find((ell: any) => ell?.id === schoolid)
        )
      )
    ),
    tap(console.log)
  );

  getSchoolDetails(id: string): Observable<schoolForStore> {
    return this.schoollist$.pipe(
      map((school: any) => school.find((ell: any) => ell.id === id))
    );
  }

  getSchoolProfileUrl(id: string) {
    return this.schoollist$.pipe(
      map((school: any) => school.find((ell: any) => ell.id === id))
    );
  }

  updateUrl(event: any) {
    return '../../../../assets/icons/default_school_icon.svg';
  }

  schoolSelected(event: any) {
    let previousId =
      this.store.snapshot().SelectedAdmin?.selectedAdmin?.school_id;

    let uid = this.store.snapshot().UserData.userData.uid;

    this.store.dispatch(
      new Disconnect(new RacesActions.GetAllSchoolAdmin(uid))
    );
    // disconnect teachers store
    this.store.dispatch(
      new Disconnect(new TeachersActions.GetAllTeachers(previousId))
    );
    this.store.dispatch(
      new Disconnect(new StudentsActions.getAllStudents(previousId))
    );
    // this.store.dispatch(new ClearStudentsTeachers());
    // this.schoolListSub = this.selectedAdmin$.subscribe((el) => {
    //   console.log(el);
    // });
    // /event.source.checked
    // event.source.value changes to event
    if (true) {
      console.log(event);
      this.getSchoolDetails(event.school_id).subscribe((el: any) => {
        this.currentSchoolDetails = cloneDeep(el);
        console.log(el);
      });
      this.store.dispatch(new SelectedSchool(event));
      this.store.dispatch(new TeachersActions.GetAllTeachers(event.school_id));
      this.store.dispatch(new StudentsActions.getAllStudents(event.school_id));
      this.schoolHouseSrvc_.fetchSchoolStudents().subscribe((el: any) => {
        this.store.dispatch(new GetSchoolStudents(el));
      });

      this.schoolHouseSrvc_.fetchSchoolTeachers().subscribe((el: any) => {
        this.store.dispatch(new GetSchoolTeachers(el));
      });
      // this.store.dispatch(new RemoveClasses());

      console.log(this.store.snapshot());

      let element: any = document.querySelector(`#item${this.selected_index}`);
      // remove class name
      element.classList.remove('selected');
      element = document.querySelector(`#item${0}`);
      // add class name
      element.classList.add('selected');
      this.selected_index = '0';
      this.router.navigate(['/layout/dashboard']);
    } else {
      console.log('mot');
    }
  }
  @ViewChild('navdrop') navdrop: ElementRef;
  dropClick() {
    const element: any = document.querySelector('#dropDown_list');
    // console.log(element);
    element?.classList.remove('mat-menu-panel');
    console.log('asd');

    // this.navdrop.nativeElement.classList.toggle("visibility");

    // console.log(element.style.display);
    if (element.style.display == 'block') {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }

    element?.classList.add('mat-menu-panel');
  }
  close_dropdown() {
    const element: any = document.querySelector('#dropDown_list');
    element.style.display = 'none';
  }
  // onFocusOutEvent(event: any) {
  //   console.log('asd');
  //   const element: any = document.querySelector('#dropDown_list');
  //   element.style.display = 'none';
  // }

  onMouseLeave() {
    if (window.innerWidth < 768) {
      this.sidebarWidth = 0;
    } else {
      this.sidebarWidth = 70;
    }
  }
  navigateToRoute(route: string) {
    if (window.innerWidth < 768) {
      this.sidebarWidth = 0;
    }

    this.router.navigate([route]);
  }

  onClick(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      const element: any = document.querySelector('#dropDown_list');
      element.style.display = 'none';
    }
  }

  minimize_Sidebar() {
    const element: any = document.querySelector('#sidebarid');
    element.style.width = '60px';
  }
  checkedOrNot(item: any) {
    return this.selectedAdmin$.pipe(
      switchMap((response) => {
        // do something with icons response
        // based on some condition return true or false

        if (response?.selectedAdmin?.id == item?.id) return of(true);
        else return of(false);
      })
    );
  }
  ngOnDestroy(): void {
    if (this.schoolListSub) this.schoolListSub.unsubscribe();
    this.mobileQuery.removeListener(this._mobileQueryListener);

    this.subScriptions.forEach((sub: any) => {
      sub.unsubscribe();
    });
  }
}
