export class GetSchoolTeams {
  static readonly type = '[schoolTeams] getAllSchoolTeams';
  constructor(public schoolTeams: any) {}
}

export class selectedTeam {
  static readonly type = '[schoolTeam] getSelectedTeam';
  constructor(public schoolTeam: any) {}
}

export class removeSelectedTeam{
  static readonly type = '[schoolTeam] removeSelectedTeam';
}
