import { Injectable, OnInit } from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/firestore';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { curriculum } from 'src/app/auth/shared/models/school';
import { schoolForStore } from 'src/app/auth/shared/models/schoolForStore';
import { SchoolEvent } from '../models/school_event.model';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  currentSchoolId: string;
  currentSchoolDetails: schoolForStore;
  constructor(
    private firestore: AngularFirestore,
    private commonSrvc_: CommonService,
    private store:Store
  ) {}

  getCurrentSchoolEvents() {
    this.commonSrvc_.getCurrentSchoolidObservable().subscribe((el) => {
      this.currentSchoolId = el;
      this.commonSrvc_
        .getCurrentSchoolDetails(this.currentSchoolId)
        .subscribe((el) => {
          this.currentSchoolDetails = el;
        });
    });
    return this.firestore
      .collection('school_events', (ref) =>
        ref
          .where('school_id', '==', this.currentSchoolId)
          .where('curriculum', '==', this.currentSchoolDetails.curriculum)
      )
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<any>[]) => {
          return actions.map((action: DocumentChangeAction<any>) => {
            const data: any = action.payload.doc.data();
            const id: string = action.payload.doc.id;
            let fromDate = action.payload.doc.data().from.toDate();
            let toDate = action.payload.doc.data().to.toDate();
            return { ...data, id, from: fromDate, to: toDate };
          });
        })
      );
  }

  addingAnEvent(event: SchoolEvent) {
    event = this.eventToLowerCase(event);
    return this.firestore.collection('school_events').add(event);
  }

  updatingAnEvent(event: SchoolEvent, docId: string) {
    event = this.eventToLowerCase(event);
    return this.firestore.collection('school_events').doc(docId).update(event);
  }

  deleteEvent(docId: string) {
    return this.firestore.collection('school_events').doc(docId).delete();
  }

  eventToLowerCase(event: SchoolEvent) {
    let newObj: SchoolEvent = {
      class_ids: event.class_ids,
      created_at: event.created_at,
      curriculum: event.curriculum,
      day: event.day,
      from: event.from,
      to: event.to,
      school_id: event.school_id,
      title: event.title.toLowerCase(),
      type: event.type,
    };
    return newObj;
  }

  alreadyEventExistsOnSpecificDate(date: Date,events:any[]): any[] {
    let arr: any[] = [];
    for (let i = 0; i < events.length; i++) {
      const el = events[i];
      let from = new Date(el.from);
      from.setHours(0);
      from.setMinutes(0);
      let to = new Date(el.to);
      to.setHours(0);
      to.setMinutes(0);
      if (from <= date && date <= to) {
        arr.push(el);
      }
    }
    return arr;
  }


}
