import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SchoolOnboardService } from 'src/app/shared/services/school-onboard.service';

@Component({
  selector: 'app-add-class-dialog',
  templateUrl: './add-class-dialog.component.html',
  styleUrls: ['./add-class-dialog.component.scss'],
})
export class AddClassDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private onboardSrvc_: SchoolOnboardService,
    private dialogSrvc_: DialogService
  ) {}

  className: string = '';

  ngOnInit(): void {}

  classes: any = [
    'nursery',
    'lkg',
    'ukg',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ];

  submit() {
    if (this.className) {
      this.onboardSrvc_.addClass(this.className);
      this.dialogSrvc_.closeAddClass();
    }
  }
}
