import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchoolSetupService {
  editDetails = new BehaviorSubject<any>({
    type: '',
  });
  editDetails$ = this.editDetails.pipe();

  constructor() {}

  emitData(data: any) {
    this.editDetails.next(data);
  }
}
