<div class="list_contianer flex flex-col gap-4">
  <div class="flex justify-end items-end">
    <button class="flex justify-end items-end mb-4">
      <img src="../../../../assets/icons/close_blue.svg" mat-dialog-close alt="">
    </button>
  </div>

  <ng-container>
    <div class="header flex items-center justify-between">
      <h1 class="text-ink_dark font-bold text_large">Select Teacher</h1>
      <button class="btn_blue" style="padding: 10px 20px 10px 20px ;"
        [ngClass]="{'opacity-50': !selectedTeachersArray.length,'cursor-default': !selectedTeachersArray.length}"
        [disabled]="!selectedTeachersArray.length" (click)="onSavingTeachers()"> Save</button>
    </div>
    <div class="classlist_filter_contianer flex  md:flex-row flex-col-reverse gap-3   md:justify-end justify-end">


      <!-- <div class="sorting_header flex gap-4 ">
            <div class="flex  flex-wrap   " [class]=" selected_sorting_option == i ? 'option_selected' :'option' "
                *ngFor="let item of [1,2,3,4];let i=index">
                <p class="">Grade 1</p>
            </div>
        </div> -->
      <!-- search Options -->

      <div class="search_container md:py-0 py-4">
        <div class="search_box flex gap-2 relative">
          <img src="../../../../assets/icons/search.svg" class="absolute top-3 left-3" alt="search">
          <input type="text" placeholder="Search Teachers" class="search_input " />
        </div>
      </div>
    </div>
    <div class="list_items flex flex-col gap-4">
      <div
        class="list_item flex justify-between gap-4 items-center bg-sky_lightest p-4 rounded-lg hover:bg-purple_lightest"
        *ngFor="let teacher of schoolTeacherList" (click)="popPushTeacher(teacher.id)"
        [ngClass]="{'bg-purple_lightest': !!selectedTeachersArray.includes(teacher.id)}">

        <div class="flex items-center gap-9">
          <div class="flex gap-4 items-center">
            <app-rounded-image borderColor="#FFB600" [imageSrc]="teacher?.userDetails?.photo_url"
              default="../../../../assets/icons/no_profile_pic.svg"></app-rounded-image>
            <div class="flex flex-col gap-1">
              <h1 class="text-ink_dark font-bold text_medium">{{teacher?.userDetails?.name.join('
                ')|titlecase}}</h1>
              <div class="flex gap-2">
                <p class="font-semibold text_small text-ink-darker"
                  *ngFor="let subject of teacher.subjects_taught;let last = last">{{ subject |
                  titlecase
                  }}{{
                  last ? "" : "," }}</p>
              </div>
            </div>
          </div>
          <!-- <div class="hide_in_mobile">
                    <h1 class="font-semibold text-ink_darker text_Small ">1A . 3B . 4A . 6B</h1>
                </div> -->
        </div>

        <div>
          <!-- if selected -->
          <img *ngIf="!selectedTeachersArray.includes(teacher.id)" src="../../../../assets/icons/radio_selection.svg"
            alt="">
          <!-- if not selected -->
          <img *ngIf="!!selectedTeachersArray.includes(teacher.id)" src="../../../../assets/icons/tick_purple.svg"
            alt="">
        </div>

      </div>
      <div>

      </div>



    </div>

  </ng-container>



</div>
