import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-carousel-one',
  templateUrl: './carousel-one.component.html',
  styleUrls: ['./carousel-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
