import { Component, Inject, inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DynamiclinkService } from 'src/app/shared/services/dynamiclink.service';

@Component({
  selector: 'app-floating-onboard-card',
  templateUrl: './floating-onboard-card.component.html',
  styleUrls: ['./floating-onboard-card.component.scss'],
})
export class FloatingOnboardCardComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public dynamicLinkSrvc_: DynamiclinkService
  ) {}

  ngOnInit(): void {}
}
