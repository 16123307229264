import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-rounded-image',
  templateUrl: './no-rounded-image.component.html',
  styleUrls: ['./no-rounded-image.component.scss']
})
export class NoRoundedImageComponent implements OnInit {
  @Input() content: string = 'H';
  @Input() width:string = '50';
  @Input() height:string = '50';
  @Input() radius:string = '50%';
  @Input() borderwidth:string = '2px';
  @Input() borderType:String = 'solid';
  @Input() borderColor:string = 'none';
  @Input() backGroundColor:string = '#6B4EFF';
  @Input() default:string='H'
  @Input() TextColor:string='white'
  @Input() fontSize:string='18px'
  @Input() fontWeight:string='500'

  textStyle = `color:${this.TextColor};font-size:${this.fontSize};font-weight:${this.fontWeight};`
  imageStyle = `width:${this.width}px;height:${this.height}px;border-radius:${this.radius};border:${this.borderwidth} ${this.borderType} ${this.borderColor};`;
  constructor() { }

  ngOnInit(): void {
    this.imageStyle  = `width:${this.width}px;height:${this.height}px;border-radius:${this.radius};border:${this.borderwidth} ${this.borderType} ${this.borderColor};`;
    this.textStyle = `color:${this.TextColor};font-size:${this.fontSize};font-weight:${this.fontWeight};`

  }

}
