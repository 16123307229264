import { docUser } from './../../../auth/shared/models/docUser';
export class UserData {
  static readonly type = '[Userdata] Login';
  constructor(public userData: docUser) {}
}

export class removeUser {
  static readonly type = '[removeUser] removeUser';
  constructor(public clear: string) {}
}

export class logout {
  static readonly type = '[logout] logout';
}
