import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { analytics } from 'firebase';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { SchoolClasses } from '../store/class-list/class-list.action';
import { SelectedSchoolState } from '../store/school/selected-school.state';
import { CommonService } from './common.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ClassAndStudentsService {
  constructor(
    private commonSrvc_: CommonService,
    private store: Store,
    private router: Router
  ) {}
  selectedAdmin$ = this.store.select(SelectedSchoolState);

  private pageName = new BehaviorSubject('Class List');
  pageName$ = this.pageName.asObservable();
  classesSubcription: Subscription;

  changePageName(pageName: string) {
    this.pageName.next(pageName);
  }

  fetchClasses() {
    let currentSchoolDetails$: Observable<any> = this.selectedAdmin$.pipe(
      switchMap((data: any) => {
        console.log('data', data);
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.commonSrvc_.getCurrentSchoolDetails(
            data.selectedAdmin.school_id
          );
        } else return of([] as any[]);
      })
    );

    currentSchoolDetails$.subscribe((el) => {});
    let detailedlist$ = currentSchoolDetails$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('curriculum')) {
          return this.commonSrvc_
            .getSchoolClasses(data.id, data.curriculum)
            .pipe(
              map((schoolClasses: any) => {
                let data = schoolClasses;
                return data;
              })
            );
        } else return of([] as any[]);
      })
    );
    let detailedlist2$ = detailedlist$.pipe(
      switchMap((data: any) => {
        if (data.length) {
          return combineLatest(
            data.map((el: any) => {
              let el1 = JSON.parse(JSON.stringify(el));
              return this.commonSrvc_
                .getStudentsFromClassid(el1.school_id, el1.id)
                .pipe(
                  map((students: any) => {
                    // console.log(students);
                    students = students.map((el: any) => {
                      el.class_ref = el.class_ref.path;
                      return el;
                    });
                    Object.assign(el1, {
                      studentlist: students,
                    });
                    return el1;
                  })
                );
            })
          );
        } else return of([] as any[]);
      })
    );

    let detailedlist3$ = detailedlist2$.pipe(
      switchMap((data: any) => {
        console.log('fetching assessmntssss');
        if (data.length) {
          return combineLatest(
            data.map((el: any) => {
              let el1 = _.cloneDeep(el);
              return this.commonSrvc_
                .getClassAssessment(el1.school_id, el1.id, el1.schoolCurriculum)
                .pipe(
                  map((assesments: any) => {
                    Object.assign(el1, {
                      assessments: assesments,
                      assessmentsTaken: assesments.length,
                    });
                    return el1;
                  })
                );
            })
          );
        } else return of([] as any[]);
      })
    );
    let detailedlist4$ = detailedlist3$.pipe(
      switchMap((data: any) => {
        if (data.length) {
          return combineLatest(
            data.map((el: any) => {
              let el1 = _.cloneDeep(el);
              return this.commonSrvc_
                .getClassAttendance(el1.school_id, el1.id)
                .pipe(
                  map((attendance: any) => {
                    Object.assign(el1, {
                      attendanceArray: attendance,
                    });
                    return el1;
                  })
                );
            })
          );
        } else return of([] as any[]);
      })
    );

    let detailedlist5$ = detailedlist4$.pipe(
      switchMap((data: any) => {
        if (data.length) {
          return combineLatest(
            data.map((el: any) => {
              let el1 = JSON.parse(JSON.stringify(el));
              return this.commonSrvc_
                .getClassSyllabus(el1.school_id, el1.id)
                .pipe(
                  map((syllabus: any) => {
                    if (!!syllabus) {
                      syllabus = syllabus.map((el: any) => {
                        if (el.chapters.length) {
                          el.chapters = el.chapters.map((el2: any) => {
                            if (el2.topics.length) {
                              el2.topics = el2.topics.map((el3: any) => {
                                if (!!el3.contents && !!el3.contents.length) {
                                  el3.contents = el3.contents.map(
                                    (content: any) => {
                                      if (content.content_ref) {
                                        content.content_ref =
                                          content.content_ref.path;
                                      } else {
                                        content.content_ref = '';
                                      }
                                      return content;
                                    }
                                  );
                                }
                                return el3;
                              });
                            }
                            return el2;
                          });
                        }
                        return el;
                      });
                    } else {
                      syllabus = [];
                    }
                    Object.assign(el1, {
                      syllabusArray: syllabus,
                    });
                    return el1;
                  })
                );
            })
          );
        } else return of([] as any[]);
      })
    );
    this.classesSubcription = detailedlist5$.subscribe((el) => {
      el.sort((a: any, b: any) => a.section.localeCompare(b.section));

      el.sort((a: any, b: any) => a.name.localeCompare(b.name));
      el = el.map((clas: any) => {
        if (clas.assessments.length) {
          clas['class_performance'] = this.calculateClassPerformance(
            clas.assessments
          );
        } else {
          clas['class_performance'] = -1;
        }
        return clas;
      });
      el = el.map((clas: any) => {
        clas['class_teacher_details'] = this.knowingClassTeacher(clas);
        return clas;
      });
      el = el.map((clas: any) => {
        clas['attendance'] = this.calculateClassAttendance(
          clas.attendanceArray
        );
        return clas;
      });
      let arr = el.map((clas: any) => {
        let syllArray = JSON.parse(JSON.stringify(clas.syllabusArray));
        let subjects = JSON.parse(JSON.stringify(clas.subjects));
        clas['subjectSyllabusArray'] = subjects.map((sub: string) => {
          let arr = this.calculateClassSyllabusPercentage(syllArray, sub);
          let obj = {
            name: sub,
            syllPerc: arr.percentage,
            details: arr.syllabusDetails,
            ongoing_topic: arr.ongoingTopic,
            isSyllabusMapped: arr.isSyllabusMapped,
          };

          return obj;
        });
        return clas;
      });
      this.store.dispatch(new SchoolClasses(arr));
    });
  }

  knowingClassTeacher(clas: any) {
    let classTeacher: any[];
    if (clas.teachers.length) {
      classTeacher = clas.teachers.filter((el: any) => !!el.class_teacher);
      if (classTeacher.length) {
        return { hasClassTeacher: true, data: classTeacher[0] };
      } else {
        return { hasClassTeacher: false, data: null };
      }
    } else {
      return { hasClassTeacher: false, data: null };
    }
  }

  goToClassDetails(classId: string) {
    console.log(classId);
    classId = window.btoa(classId);
    this.router.navigate([`/layout/dashboard/classes/details/${classId}`]);
  }

  calculateAssessmentsPerformance(assessments: any[]) {
    let total_marks = 0;
    let scored_marks = 0;
    if (assessments.length) {
      for (let i = 0; i < assessments.length; i++) {
        const assessment = assessments[i];
        if (assessment.scored_marks < 0) {
          scored_marks += 0;
        } else {
          scored_marks += assessment.scored_marks;
        }
        total_marks += assessment.total_marks;
      }
      if (total_marks < 0) {
        return 0;
      } else {
        return Math.round((scored_marks * 100) / total_marks);
      }
    } else {
      return 0;
    }
  }

  goToViewClassStudents(classId: string) {
    classId = window.btoa(classId);
    this.router.navigate([`/layout/classes/students_list/${classId}`]);
  }

  goToStudentDetails(studentId: string, classId: string) {
    studentId = window.btoa(studentId);
    classId = window.btoa(classId);
    this.router.navigate([
      `/layout/classes/students_details/${studentId}/${classId}`,
    ]);
  }

  calculateClassPerformance(assessments: any[]) {
    let totalScores: any[] = [];
    let scoredScores: any[] = [];
    console.log(assessments);
    assessments = assessments.map((el) => {
      el['performance'] = (el.scored_marks / el.total_marks) * 100;
      totalScores.push(el.total_marks);
      if (el.scored_marks < 0) {
        scoredScores.push(0);
      } else {
        scoredScores.push(el.scored_marks);
      }
      return el;
    });
    // let totalAssessments= [...new Set(assessments.map(a=>a.question_set))]
    let classPerformance =
      totalScores.reduce((a, b) => a + b, 0) == 0
        ? 0
        : scoredScores.reduce((a, b) => a + b, 0) /
          totalScores.reduce((a, b) => a + b, 0);
    return Math.ceil(classPerformance * 100);
  }

  calculateClassAttendance(attendance: any[]): Number {
    if (attendance.length) {
      let presentAttendanceArray = attendance.filter((a) => a.present == true);
      return Math.floor(
        (presentAttendanceArray.length / attendance.length) * 100
      );
    }
    return -1;
  }

  calculateClassSyllabusPercentage(syllabusArray: any[], subject: string) {
    let ongoingTopic = '';
    let incompletedTopicsofSubject: any[] = [];
    let obj = {
      percentage: 0,
      syllabusDetails: null,
      ongoingTopic: '',
      isSyllabusMapped: false,
    };
    if (syllabusArray.length) {
      let individualSubJectSyllabus = syllabusArray.filter(
        (el) => el.subject == subject
      )[0];
      if (individualSubJectSyllabus) {
        if (individualSubJectSyllabus.chapters.length) {
          individualSubJectSyllabus.chapters =
            individualSubJectSyllabus.chapters.map((chap: any) => {
              let totalTopicsofAChapter = chap.topics.length;
              if (totalTopicsofAChapter) {
                let completedTopicsofAChapter = chap.topics.filter(
                  (topic: any) => topic.completed == true
                ).length;
                let incompleteTopicArray = chap.topics.filter(
                  (topic: any) => topic.completed == false
                );
                if (incompleteTopicArray.length) {
                  incompletedTopicsofSubject.push(...incompleteTopicArray);
                  chap['ongoing_topic'] = incompleteTopicArray[0].topic;
                  ongoingTopic = chap['ongoing_topic'];
                } else {
                  chap['ongoing_topic'] = '';
                  ongoingTopic = '';
                }

                let chapterSyllPercentage =
                  (completedTopicsofAChapter / totalTopicsofAChapter) * 100;

                chap['chapterSyllPercentage'] = chapterSyllPercentage;
              } else {
                chap['ongoing_topic'] = '';
                chap['chapterSyllPercentage'] = 0;
                ongoingTopic = '';
              }
              return chap;
            });

          let chapterPercentageArray = individualSubJectSyllabus.chapters.map(
            (a: any) => a.chapterSyllPercentage
          );

          obj.percentage = Math.round(
            (chapterPercentageArray.filter((a: any) => a == 100).length * 100) /
              chapterPercentageArray.length
          );
          obj.syllabusDetails = individualSubJectSyllabus;
          obj.ongoingTopic = incompletedTopicsofSubject[0]?.topic
            ? incompletedTopicsofSubject[0]?.topic
            : 'N.A';
          obj.isSyllabusMapped = true;
          return obj;
        } else {
          obj.percentage = 0;
          obj.syllabusDetails = individualSubJectSyllabus;
          obj.ongoingTopic = '';
          obj.isSyllabusMapped = false;
          return obj;
        }
      } else {
        obj.percentage = -1;
        obj.syllabusDetails = null;
        obj.ongoingTopic = '';
        obj.isSyllabusMapped = false;
        return obj;
      }
    } else {
      obj.percentage = -1;
      obj.syllabusDetails = null;
      obj.ongoingTopic = '';
      obj.isSyllabusMapped = false;
      return obj;
    }
  }

  /*

  Check if syllabus is mapped or not

db.collection("schools").document(SCHOOLID)
                        .collection("classes").document(CLASSID);
                        .collection("syllabus")
                        .whereEqualTo("subject",SUBJECT.toLowerCase())


if you get any doc that means syllabus is mapped and you can calculate the syllabus percentage
else:
 search for syllabus
	db.collection("syllabus")
                        .whereEqualTo("board",BOARD.toLowerCase())
                        .whereEqualTo("medium",MEDIUM.toLowerCase())
                        .whereEqualTo("subject",SUBJECT.toLowerCase())
                        .whereEqualTo("grade",GRADE.toLowerCase())
                        .limit(1)


	This will give you a HURREY syllabus document from there you can prepare the School Class Syllabus Model
	If no document is returned show that no syllabus found .teacher needs to create their syllabus

	HURREY SYLLABUS Model
	--------------------
	String academic_year="",board="",grade="",medium="",subject="";
    Date created_at;
    chapters:[
		{
			String chapter="";
			int chapter_no,period;
			topics:[
				{
					String topic="";
				int topic_no;
				}
			]
		}
	]

	Prepare School Class Model from above syllabus

	SCHOOL CLASS SYLLABUS MODEL
	-----------------------------
	String board="",grade="",medium="",subject="";
    Date created_at;
    chapters:[
		{
		 String chapter="";
         int chapter_no,period,period_taken;
		 days:[]//keep it empty this is list of timestamp
         topics:[
			{
			  String topic="";
				int topic_no;
				boolean completed=false;
				Date completed_timestamp=null;
				contents:[//keep it empty
					{
						DocumentReference content_ref;
						String type="";
					}
				]
			}
		 ]
		}
	]

	once School Class syllabus doc is prepared add doc to the syllabus subcollection inside classes
	db.collection("schools").document(SCHOOLID)
        .collection("classes").document(CLASSID)
        .collection("syllabus").add(classSyllabusModel);

	once document is added u cn hide the map button and show the syllabus percentage

  */

  fetchstudentDetailfromlist(studentList: any[]) {
    let studentLits$ = of(studentList);
    let detailedlist$ = studentLits$.pipe(
      switchMap((data: any) => {
        if (data.length) {
          return forkJoin(
            data.map((el: any) =>
              this.commonSrvc_.getUserDoc(el.user_id).pipe(
                map((user: any) => {
                  let dataUser = user.data();

                  Object.assign(el, {
                    userdetails: dataUser,
                  });

                  return el;
                })
              )
            )
          );
        } else return of([]);
      })
    );

    return detailedlist$;
  }
}
