import { Injectable } from '@angular/core';
import {
  Action,
  NgxsOnInit,
  NgxsPlugin,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { studentRoot } from '../../models/student_root';
import { StudentsActions } from './student.action';
import { StudentFireStore } from './student.firestore';
import {
  NgxsFirestoreConnect,
  Emitted,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import { patch, updateItem } from '@ngxs/store/operators';
import { DocumentReference } from '@angular/fire/firestore';

export interface studentsStateModel {
  students_list: studentRoot[];
}

@State<studentsStateModel>({
  name: 'students_list',
  defaults: {
    students_list: [],
  },
})
@Injectable()
export class studentState implements NgxsOnInit {
  @Selector() static getStudentList(state: studentsStateModel) {
    return state.students_list;
  }

  constructor(
    private studentFs: StudentFireStore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect
  ) {}

  ngxsOnInit(ctx?: StateContext<studentsStateModel>) {
    this.ngxsFirestoreConnect.connect(StudentsActions.getAllStudents, {
      to: (payload: any) => {
        console.log(payload.payload);
        return this.studentFs.collection$((ref) =>
          ref
            .where('school_id', '==', payload.payload)
            .where('deleted', '==', false)
            .where('active', '==', true)
        );
      },
      trackBy: (payload) => payload.payload,
    });
  }

  @Action(StreamEmitted(StudentsActions.getAllStudents))
  getAllEmitted(
    ctx: StateContext<studentsStateModel>,
    { action, payload }: Emitted<StudentsActions.get, studentRoot[]>
  ) {
    payload = payload.map((element) => {
      if ('class_ref' in element) {
        let docRef: DocumentReference = element.class_ref.id;
        element.class_ref = docRef.path;
      }
      return element;
    });

    console.log(payload);

    ctx.setState(patch({ students_list: payload }));
  }

  @Action(StudentsActions.updateStudent)
  updateStudent(
    ctx: StateContext<any>,
    { payload }: StudentsActions.updateStudent
  ) {
    ctx.setState(
      patch({
        students_list: updateItem<any>(
          (item) => item.user_id === payload.user_id,
          patch({
            performance: payload.performance,
            attendance: payload.attendance,
            attendanceArray: payload?.attendanceArray,
            class_assessments: payload?.class_assessments,
            subjects: payload?.subjects,
          })
        ),
      })
    );
  }

  @Action(StudentsActions.removeStudentlist)
  removeStudnets(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        students_list: [],
      })
    );
  }
}
