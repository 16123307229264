import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-rounded-image',
  templateUrl: './rounded-image.component.html',
  styleUrls: ['./rounded-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundedImageComponent implements OnInit {

  @Input() imageSrc: string = '../../../assets/images/default_prifile.svg';
  @Input() width:string = '50';
  @Input() height:string = '50';
  @Input() radius:string = '50%';
  @Input() borderwidth:string = '2px';
  @Input() borderType:String = 'solid';
  @Input() borderColor:string = '#6B4EFF';
  @Input() default:string=''

  imgeStyles :String = `width:${this.width}px;height:${this.height}px;border-radius:${this.radius};border:${this.borderwidth} ${this.borderType} ${this.borderColor};`;

  constructor() { }

  ngOnInit(): void {
    this.imgeStyles  = `width:${this.width}px;height:${this.height}px;border-radius:${this.radius};border:${this.borderwidth} ${this.borderType} ${this.borderColor};`;
  }

}
