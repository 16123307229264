<div class="component_container flex flex-col gap-4">
  <!-- close button -->
  <div class="flex justify-end">
    <button mat-dialog-close>
      <img src="../../../../assets/images/icons/close.svg" alt="close">
    </button>
  </div>
  <!-- header -->
  <div class="flex items-center justify-between">
    <h2 class="text_medium font-bold text-ink-dark mb-4">Add new Category</h2>
    <button class="btn_blue_small" [ngClass]="{'opacity-50': category.invalid,'cursor-default': category.invalid}"
      [disabled]="category.invalid" (click)="onSaving()">Save</button>
  </div>

  <div>
    <mat-form-field appearance="outline">
      <mat-label>Enter category name</mat-label>
      <input matInput placeholder="Enter category name" [formControl]="category">
    </mat-form-field>
  </div>
</div>
