<div class="flex flex-col gap-4 justify-center items-center">
    <div class="flex flex-col gap-2">
        <ng-lottie [width]="width" [height]="height" [options]="options"></ng-lottie>
        <h2 [style.color]="color" [class]="fontsize" class="text-center">{{message}}</h2>
    </div>
    <div>
        <!-- for button  -->
        <ng-content>

        </ng-content>
    </div>

</div>