import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import {
  GetSchoolHouses,
  RemoveSchoolStudentsSnapShot,
  RemoveSchoolTeachersSnapShot,
  removeselectedHouse,
  SchoolStudentsSnapShot,
  SchoolTeachersSnapShot,
  selectedHouse,
} from './school-house.action';
import { patch } from '@ngxs/store/operators';

@State<any>({
  name: 'SchoolHousesState',
  defaults: {
    schoolStudents: [],
    schoolTeachers: [],
    schoolHouses: [],
    selectedHouse: {},
  },
})
@Injectable()
export class SchoolHousesState {
  @Selector() static getSchoolStudents(state: any) {
    return state.schoolStudents;
  }

  @Selector() static getSchoolTeachers(state: any) {
    return state.schoolTeachers;
  }

  @Selector() static getSchoolHouses(state: any) {
    return state.schoolHouses;
  }

  @Action(GetSchoolHouses)
  setSchoolHouses(ctx: StateContext<any>, { schoolHouses }: GetSchoolHouses) {
    ctx.setState(
      patch({
        schoolHouses: schoolHouses,
      })
    );
  }

  @Action(SchoolStudentsSnapShot)
  setStudents(ctx: StateContext<any>, { students }: SchoolStudentsSnapShot) {
    ctx.setState(
      patch({
        schoolStudents: students,
      })
    );
  }

  @Action(selectedHouse)
  setselectedSchoolHouse(
    ctx: StateContext<any>,
    { schoolHouse }: selectedHouse
  ) {
    ctx.setState(
      patch({
        selectedHouse: schoolHouse,
      })
    );
  }

  @Action(SchoolTeachersSnapShot)
  setTeachers(ctx: StateContext<any>, { teachers }: SchoolTeachersSnapShot) {
    ctx.setState(
      patch({
        schoolTeachers: teachers,
      })
    );
  }

  @Action(RemoveSchoolStudentsSnapShot)
  removeStudents(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        schoolStudents: [],
      })
    );
  }

  @Action(RemoveSchoolTeachersSnapShot)
  removeTeachers(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        schoolTeachers: [],
      })
    );
  }

  @Action(removeselectedHouse)
  removeselectedHouse(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        selectedHouse: {},
      })
    );
  }
}
