import { curriculum } from 'src/app/auth/shared/models/school';

export interface SchoolEvent {
  class_ids: string[];
  created_at: Date;
  curriculum: curriculum;
  day: number;
  from: Date;
  school_id: string;
  title: string;
  to: Date;
  type: Type_of_event;
}

export enum Type_of_event {
  events = 'activities',
  holidays = 'holidays',
  exams = 'exams',
  others = 'others',
}
