<div class="bg-sky_lightest rounded-2xl flex justify-around items-center">
  <span class="font-semibold text-base leading-6 text-purple_base"
    >Onboard {{ data | titlecase }} by Generate Invitation Link Or Add
    Manually</span
  >

  <button
    (click)="dynamicLinkSrvc_.openModal(data)"
    class="btn_blue flex justify-center"
    style="width: 12rem"
    [disabled]="dynamicLinkSrvc_.spinner"
  >
    <span *ngIf="!dynamicLinkSrvc_.spinner"> + Add {{ data | titlecase }}</span>
    <mat-spinner
      *ngIf="dynamicLinkSrvc_.spinner"
      class="p-0 m-0 buttonSpinner"
      diameter="20"
    ></mat-spinner>
  </button>
</div>
