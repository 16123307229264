import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { database } from 'firebase';
import { BoardsPopupComponent } from 'src/app/auth/sign-up/school-form/boards-popup/boards-popup.component';
import { CalenderCreateeventDialogComponent } from 'src/app/pages/calender/calender-createevent-dialog/calender-createevent-dialog.component';
import { CreateEventNewDialogComponent } from 'src/app/pages/calender/create-event-new-dialog/create-event-new-dialog.component';
import { EventViewDialogComponent } from 'src/app/pages/calender/event-view-dialog/event-view-dialog.component';
import { AddEnrollmentCodeModalComponent } from 'src/app/pages/classes/add-enrollment-code-modal/add-enrollment-code-modal.component';
import { AddProfileComponent } from 'src/app/pages/dashboard/add-profile/add-profile.component';
import { SubstituteAbsentTeacherDialogComponent } from 'src/app/pages/dashboard/substitute-absent-teacher-dialog/substitute-absent-teacher-dialog.component';
import { ExamDialogComponent } from 'src/app/pages/exam-center/exam-dialog/exam-dialog.component';
import { SelectSubjectsDialogComponent } from 'src/app/pages/onboard-school/select-subjects-dialog/select-subjects-dialog.component';
import { AddClassDialogComponent } from 'src/app/pages/school-onboard/common-components/add-class-dialog/add-class-dialog.component';
import { AddClassTimetableDialogComponent } from 'src/app/pages/school-onboard/common-components/add-class-timetable-dialog/add-class-timetable-dialog.component';
import { AddSubjectsDialogComponent } from 'src/app/pages/school-onboard/common-components/add-subjects-dialog/add-subjects-dialog.component';
import { OnboardDialogComponent } from 'src/app/pages/school-onboard/common-components/onboard-dialog/onboard-dialog.component';
import { SubstitutionModalComponent } from 'src/app/pages/teachers/substitution-modal/substitution-modal.component';
import { TeacherDialogComponent } from 'src/app/pages/teachers/teacher-dialog/teacher-dialog.component';
import { OnboardAlertComponent } from '../app-alerts/onboard-alert/onboard-alert.component';
import { SchoolExam } from '../models/exam_model';
import { OnboardAlert } from '../models/onboard-alert.model';
import { BehaviorSubject } from 'rxjs';
import { MarksportalStudetsAttandanceComponent } from 'src/app/pages/exam-center/marks-portal/marksportal-studets-attandance/marksportal-studets-attandance.component';
import { HouseStudentsListDialogComponent } from 'src/app/pages/house/house-students-list-dialog/house-students-list-dialog.component';
import { HouseTeachersListDialogComponent } from 'src/app/pages/house/house-teachers-list-dialog/house-teachers-list-dialog.component';
import { LibrarySuccessfullDialogComponent } from 'src/app/pages/library/library-successfull-dialog/library-successfull-dialog.component';
import { AddCategoryComponent } from 'src/app/pages/library/add-category/add-category.component';
import { LibraryAddFineComponent } from 'src/app/pages/library/library-add-fine/library-add-fine.component';
import { TeamsCreateEventDialogComponent } from 'src/app/pages/teams/teams-create-event-dialog/teams-create-event-dialog.component';
import { TeamStudentsListDialogComponent } from 'src/app/pages/teams/team-students-list-dialog/team-students-list-dialog.component';
import { TeamTeachersListDialogComponent } from 'src/app/pages/teams/team-teachers-list-dialog/team-teachers-list-dialog.component';
import { LibraryBookLostDialogComponent } from 'src/app/pages/library/library-book-lost-dialog/library-book-lost-dialog.component';
import { CouncilStudentsListDialogComponent } from '../../pages/council/council-students-list-dialog/council-students-list-dialog.component';
import { CouncilTeachersListDialogComponent } from 'src/app/pages/council/council-teachers-list-dialog/council-teachers-list-dialog.component';
import { LibraryStudentsListDialogComponent } from 'src/app/pages/library/library-students-list-dialog/library-students-list-dialog.component';
import { LibraryTeachersListDialogComponent } from 'src/app/pages/library/library-teachers-list-dialog/library-teachers-list-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogRefAddProfile: MatDialogRef<AddProfileComponent>;
  dialogRefAddSubjects: MatDialogRef<AddSubjectsDialogComponent>;
  dialogRefOnboard: MatDialogRef<OnboardDialogComponent>;
  dialogRefOnboardAlert: MatDialogRef<OnboardAlertComponent>;
  dialogRefAddClassInput: MatDialogRef<AddClassDialogComponent>;
  dialogRefAddBoards: MatDialogRef<BoardsPopupComponent>;
  dialogRefAssignClass: MatDialogRef<TeacherDialogComponent>;
  dialogRefEvent: MatDialogRef<CalenderCreateeventDialogComponent>;
  dialogRefAddSubjects2: MatDialogRef<SelectSubjectsDialogComponent>;
  dialogrefAddClassTotimetable: MatDialogRef<AddClassTimetableDialogComponent>;
  dialogRefEventMini: MatDialogRef<EventViewDialogComponent>;
  dialogRefEventNew: MatDialogRef<CreateEventNewDialogComponent>;
  dialogRefSubstituteAbsentTeachersDialog: MatDialogRef<SubstituteAbsentTeacherDialogComponent>;
  dialogRefSubstitutePresentTeachersDialog: MatDialogRef<SubstitutionModalComponent>;
  dialogRefEnrollcode: MatDialogRef<AddEnrollmentCodeModalComponent>;
  dialogRefExamDialog: MatDialogRef<ExamDialogComponent>;
  createExamSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  examstudensAttandance: MatDialogRef<MarksportalStudetsAttandanceComponent>;
  dialogRefStudentListForHouse: MatDialogRef<HouseStudentsListDialogComponent>;
  dialogRefStudentListForTeam: MatDialogRef<TeamStudentsListDialogComponent>;
  dialogRefTeacherListForHouse: MatDialogRef<HouseTeachersListDialogComponent>;
  dialogRefTeacherListForTeam: MatDialogRef<TeamTeachersListDialogComponent>;
  librarySuccessDialog: MatDialogRef<LibrarySuccessfullDialogComponent>;
  addCategoryDialog: MatDialogRef<AddCategoryComponent>;
  dialogLibraryAddfine: MatDialogRef<LibraryAddFineComponent>;
  dialogTeamsCreteEvent: MatDialogRef<TeamsCreateEventDialogComponent>;
  dialogLibraryBookLost: MatDialogRef<LibraryBookLostDialogComponent>;
  dialogRefStudentListForCouncil: MatDialogRef<CouncilStudentsListDialogComponent>;
  dialogRefTeacherListForCouncil: MatDialogRef<CouncilTeachersListDialogComponent>;
  dialogRefStudentListForLibrary: MatDialogRef<LibraryStudentsListDialogComponent>;
  dialogRefTeacherListForLibrary: MatDialogRef<LibraryTeachersListDialogComponent>;
  constructor(private dialog: MatDialog) {}

  public openAddClassTotimetabledialog(data: any) {
    this.dialogrefAddClassTotimetable = this.dialog.open(
      AddClassTimetableDialogComponent,
      {
        hasBackdrop: true,
        panelClass: 'addClassDialog',
        // disableClose: true,
        data: data,
      }
    );
  }

  public openProfileDialog() {
    this.dialogRefAddProfile = this.dialog.open(AddProfileComponent, {
      // maxWidth: '800px',
      // height: '600px',
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
      panelClass: 'prof-dialog',
    });
  }

  public openSubjectsDialog(data: any, index: number) {
    this.dialogRefAddSubjects = this.dialog.open(AddSubjectsDialogComponent, {
      width: '800px',
      hasBackdrop: true,
      panelClass: 'sub-dailog',
      data: { data: data, index: index },
    });
  }

  // for new ui
  public openSubjectsDialog2(data: any, index: number, section?: any) {
    this.dialogRefAddSubjects2 = this.dialog.open(
      SelectSubjectsDialogComponent,
      {
        width: '800px',
        hasBackdrop: true,
        panelClass: 'sub-dailog',
        data: { data: data, index: index, section: section },
      }
    );
  }

  //for student list in houses module

  public openStudentsList(data: any) {
    this.dialogRefStudentListForHouse = this.dialog.open(
      HouseStudentsListDialogComponent,
      {
        minWidth: '80vw',
        position: { bottom: '0px' },
        height: '80vh',
        panelClass: 'teah_stud_list',
        data: data,
      }
    );
  }

  //for student list in library module

  public openStudentsListLibrary(data: any) {
    this.dialogRefStudentListForLibrary = this.dialog.open(
      LibraryStudentsListDialogComponent,
      {
        minWidth: '80vw',
        position: { bottom: '0px' },
        height: '80vh',
        panelClass: 'teah_stud_list',
        data: data,
      }
    );
  }

  //for student list in teams module

  public openStudentsListTeam(data: any) {
    this.dialogRefStudentListForTeam = this.dialog.open(
      TeamStudentsListDialogComponent,
      {
        minWidth: '80vw',
        position: { bottom: '0px' },
        height: '80vh',
        panelClass: 'teah_stud_list',
        data: data,
      }
    );
  }

  //for student list in councils module

  public openStudentsListCouncil(data: any) {
    this.dialogRefStudentListForCouncil = this.dialog.open(
      CouncilStudentsListDialogComponent,
      {
        minWidth: '80vw',
        position: { bottom: '0px' },
        height: '80vh',
        panelClass: 'teah_stud_list',
        data: data,
      }
    );
  }

  //for teacher list in houses module

  public openTeachersList(data: any) {
    this.dialogRefTeacherListForHouse = this.dialog.open(
      HouseTeachersListDialogComponent,
      {
        minWidth: '80vw',
        position: { bottom: '0px' },
        height: '80vh',
        panelClass: 'teah_stud_list',
        data: data,
      }
    );
  }

  //for teacher list in houses module

  public openTeachersListForLibrary(data: any) {
    this.dialogRefTeacherListForLibrary = this.dialog.open(
      LibraryTeachersListDialogComponent,
      {
        minWidth: '80vw',
        position: { bottom: '0px' },
        height: '80vh',
        panelClass: 'teah_stud_list',
        data: data,
      }
    );
  }

  //for teacher list in teams module

  public openTeachersListTeam(data: any) {
    this.dialogRefTeacherListForTeam = this.dialog.open(
      TeamTeachersListDialogComponent,
      {
        minWidth: '80vw',
        position: { bottom: '0px' },
        height: '80vh',
        panelClass: 'teah_stud_list',
        data: data,
      }
    );
  }

  //for teacher list in councils module

  public openTeachersListCouncil(data: any) {
    this.dialogRefTeacherListForCouncil = this.dialog.open(
      CouncilTeachersListDialogComponent,
      {
        minWidth: '80vw',
        position: { bottom: '0px' },
        height: '80vh',
        panelClass: 'teah_stud_list',
        data: data,
      }
    );
  }

  // for new ui
  public mapAllSubjectsDialog(data: any) {
    this.dialogRefAddSubjects2 = this.dialog.open(
      SelectSubjectsDialogComponent,
      {
        width: '800px',
        hasBackdrop: true,
        panelClass: 'sub-dailog',
        data: { data: data, index: null, mapAll: true },
      }
    );
  }

  public openOnboardDialog(data: any) {
    this.dialogRefOnboard = this.dialog.open(OnboardDialogComponent, {
      width: '800px',
      panelClass: 'onboard-dialog',
      hasBackdrop: true,
      data: data,
    });
  }

  public openOnboardAlertDialog(data: OnboardAlert) {
    this.dialogRefOnboardAlert = this.dialog.open(OnboardAlertComponent, {
      width: '684px',
      panelClass: 'onboard-alert',
      hasBackdrop: true,
      data: data,
    });
  }

  public openAssignClassDialog(data: any) {
    this.dialogRefAssignClass = this.dialog.open(TeacherDialogComponent, {
      hasBackdrop: true,
      panelClass: 'addClassDialog',
      // disableClose: true,
      data: data,
    });
  }

  public timetableAddClassDialog(data: any) {
    this.dialogRefAddClassInput = this.dialog.open(AddClassDialogComponent, {
      panelClass: 'addClassDialog',
      hasBackdrop: true,
      data: data,
      // disableClose: true,
    });
  }

  public openAddClassDialog() {
    this.dialogRefAddClassInput = this.dialog.open(AddClassDialogComponent, {
      panelClass: 'addClassDialog',
      hasBackdrop: true,
      // disableClose: true,
    });
  }

  public openExamDialog(data: boolean) {
    this.dialogRefExamDialog = this.dialog.open(ExamDialogComponent, {
      data: data,
      width: '500px',
    });
  }

  openAddBoards(data: any) {
    this.dialogRefAddBoards = this.dialog.open(BoardsPopupComponent, {
      panelClass: 'addClassDialog',
      hasBackdrop: true,
      data: data,
      // disableClose: true,
    });
  }

  openEventDialog(data: any) {
    this.dialogRefEvent = this.dialog.open(CalenderCreateeventDialogComponent, {
      data: data,
    });
  }
  openEventNewDialog(data: any) {
    this.dialogRefEventNew = this.dialog.open(CreateEventNewDialogComponent, {
      data: data,
      width: '650px',
    });
  }

  public openEventDialogMini(data: any) {
    this.dialogRefEventMini = this.dialog.open(EventViewDialogComponent, {
      data: data,
      width: '350px',
      panelClass: 'events_view_dialog_style',
    });
  }

  public closeEventDialogMini() {
    this.dialogRefEventMini.close();
  }

  // public confirmed() {
  //   return this.dialogRefAddProfile.afterClosed();
  // }

  public profileClose() {
    this.dialogRefAddProfile.close();
  }

  public subjectDialogClose() {
    this.dialogRefAddSubjects.close();
  }

  public dismissAll() {
    this.dialog.closeAll();
  }
  public closeAddClass() {
    this.dialogRefAddClassInput.close();
  }

  public openSubstituteAbsentTeachersDialog(data: any) {
    this.dialogRefSubstituteAbsentTeachersDialog = this.dialog.open(
      SubstituteAbsentTeacherDialogComponent,
      {
        width: '800px',
        hasBackdrop: true,
        panelClass: 'sub-dailog',
        data: { data: data },
      }
    );
  }

  public openSubstitutePresentTeachersDialog(data: any) {
    this.dialogRefSubstitutePresentTeachersDialog = this.dialog.open(
      SubstitutionModalComponent,
      {
        width: '800px',
        hasBackdrop: true,
        panelClass: 'sub-dailog',
        data: { data: data },
      }
    );
  }

  addEnrollmentNumberModal(data: any) {
    this.dialogRefEnrollcode = this.dialog.open(
      AddEnrollmentCodeModalComponent,
      {
        data: data,
      }
    );
  }
  // open exam studetn attendance dialog
  openExamStudentAttendanceDialog(data: any) {
    this.examstudensAttandance = this.dialog.open(
      MarksportalStudetsAttandanceComponent,
      {
        data: data,
        width: '350px',
        panelClass: 'rounded_radius',
      }
    );
  }

  // library succes dialog
  OpenLibrarySuccessDialog(data: any) {
    this.librarySuccessDialog = this.dialog.open(
      LibrarySuccessfullDialogComponent,
      {
        data: data,
        width: '350px',
        panelClass: 'rounded_radius',
      }
    );
  }
  // add category dialog
  openAddCategoryDialog(data?: any) {
    this.addCategoryDialog = this.dialog.open(AddCategoryComponent, {
      data: !!data ? data : null,
      width: '600px',
      panelClass: 'rounded_radius',
    });
  }
  openAddfineLibraryDialog(data: any) {
    this.dialogLibraryAddfine = this.dialog.open(LibraryAddFineComponent, {
      data: data,
      width: '600px',
      panelClass: 'rounded_radius',
    });
  }

  // teams create event dialog
  openTeamsCreateEventDialog(data: any) {
    this.dialogTeamsCreteEvent = this.dialog.open(
      TeamsCreateEventDialogComponent,
      {
        data: data,
        width: '600px',
        panelClass: 'rounded_radius',
      }
    );
  }

  // dialogLibraryBookLost
  openLibraryBookLostDialog(data: any) {
    this.dialogLibraryBookLost = this.dialog.open(
      LibraryBookLostDialogComponent,
      {
        data: data,
        width: '600px',
        panelClass: 'rounded-t-lg',
        position: {
          bottom: '0',
        },
      }
    );
  }

  close() {
    this.dialog.closeAll();
  }
}
