import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { cloneDeep, union } from 'lodash';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LibraryService } from 'src/app/shared/services/library.service';
import { SchoolHousesState } from 'src/app/shared/store/schoolhouses/school-house.state';

@Component({
  selector: 'app-library-students-list-dialog',
  templateUrl: './library-students-list-dialog.component.html',
  styleUrls: ['./library-students-list-dialog.component.scss'],
})
export class LibraryStudentsListDialogComponent implements OnInit {
  selected_sorting_option = 0;
  schoolStudentList: any[] = [];
  gradesArray: any[] = [];
  filteredStudents: any[] = [];
  selectedStudentsArray: any[] = [];
  searchInput: string = '';
  students2: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private commonSrvc_: CommonService,
    private librarySrvc_: LibraryService,
    private dialogSrvc_: DialogService
  ) {}

  ngOnInit(): void {
    this.store
      .select(SchoolHousesState.getSchoolStudents)
      .subscribe((el: any) => {
        let stdList = cloneDeep(el);
        this.gradesArray = union(stdList.map((a: any) => a.grade));
        this.gradesArray = this.commonSrvc_.sortClassForExams(this.gradesArray);
        console.log(this.gradesArray);
        this.schoolStudentList = cloneDeep(el);
        this.filterStudentWRTGrade(this.gradesArray[0], 0);
      });
  }

  filterStudentWRTGrade(grade: string, index: number) {
    this.selected_sorting_option = index;
    this.filteredStudents = this.schoolStudentList.filter(
      (student: any) => student.grade.toLowerCase() == grade.toLowerCase()
    );
    this.searchInput = '';
    this.students2 = cloneDeep(this.filteredStudents);
    console.log(this.filteredStudents);
  }

  popPushStudent(id: string) {
    if (this.selectedStudentsArray.length == 1) {
      this.selectedStudentsArray = [];
    } else {
    }
    this.selectedStudentsArray.push(id);
  }

  searchFilter(event: any) {
    if (!event) {
      this.filteredStudents = this.students2;
    }
    let searchInput = event.toLowerCase().trim().replace(/ /g, '');
    this.filteredStudents = this.students2.filter((student: any) => {
      let searchString: any = '';
      searchString = student.userDetails.name.join('').trim().replace(/ /g, '');
      return searchString.includes(searchInput);
    });
  }

  onSave() {
    this.librarySrvc_.selectedStudent = this.schoolStudentList.find(
      (student: any) => student.id == this.selectedStudentsArray[0]
    );
    this.librarySrvc_.selectedBorrowerSubject.next(
      this.librarySrvc_.selectedStudent
    );
    this.dialogSrvc_.close();
  }
}
