import { Component, OnInit, Inject } from '@angular/core';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-add-class-timetable-dialog',
  templateUrl: './add-class-timetable-dialog.component.html',
  styleUrls: ['./add-class-timetable-dialog.component.scss'],
})
export class AddClassTimetableDialogComponent implements OnInit {
  constructor(
    public dialogServ_: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private common_: CommonService
  ) {}

  selectedClasses: any[] = [];

  ngOnInit(): void {
    console.log(this.data);

    this.Classes = this.data?.classesToAdd.map((a: any) => {
      return {
        id: a.id,
        name: a.name,
        section: a.section,
      };
    });
  }

  Classes: any[] = [];

  // find classes seletcion
  findSelection(classobj: any) {
    let find = this.selectedClasses.filter(
      (a: any) => a.name === classobj.name && a.section === classobj.section
    );

    if (find.length) return true;
    else return false;
  }

  Selection(event: any, cls: any) {
    let seelcted_class_details = {
      class_id: cls.id,
      name: cls.name,
      section: cls.section,
    };

    console.log(seelcted_class_details, this.Classes);

    if (event.target.checked) {
      // classobj['selectedSubjects'].push(subject);

      this.selectedClasses.push(seelcted_class_details);
    } else {
      this.selectedClasses = this.selectedClasses.filter(
        (a: any) => a.class_id !== seelcted_class_details.class_id
      );
    }
    console.log('here', this.selectedClasses);
  }

  addToTT() {
    let sid = this.data?.sid;

    let seelctedids = this.selectedClasses.map((el) => el.class_id);

    this.common_.addClassesToTT(sid, this.data.ttid, seelctedids).then((el) => {
      this.dialogServ_.dismissAll();

      console.log('added');
    });
  }
}
