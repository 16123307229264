import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { fetchAdmins, removeAdmins } from './currentSchoolAdmin.action';

@State<any>({
  name: 'CurrentSchoolAdmins',
  defaults: {
    currentSchoolAdmins: [],
  },
})
@Injectable()
export class CurrentSchoolAdminsState {
  @Select() static getCurrentSchoolAdmins(state: any) {
    return state.currentSchoolAdmins;
  }
  constructor() {}

  @Action(fetchAdmins)
  selectedSchoolAdmins(ctx: StateContext<any>, { admins }: fetchAdmins) {
    console.log(admins);
    if (admins.length) {
      ctx.setState(
        patch({
          currentSchoolAdmins: [...admins],
        })
      );
    }
  }

  @Action(removeAdmins)
  removeSchoolAdmins(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        currentSchoolAdmins: [],
      })
    );
  }
}
