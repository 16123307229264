export class SelectedSchoolLibrary {
  static readonly type = '[library] SelectedSchoolLibrary';
  constructor(public library: any) {}
}

export class GetLibraryBooks {
  static readonly type = '[library] GetLibraryBooks';
  constructor(public books: any[]) {}
}

export class GetLibraryBookRequests {
  static readonly type = '[library] GetLibraryBookRequests';
  constructor(public bookRequests: any[]) {}
}

export class ClearlibraryState {
  static readonly type = '[library] ClearlibraryState';
}

export class BooksListForNavigation {
  static readonly type = '[library] BooksListForNavigation';
  constructor(public books: any[]) {}
}

export class bookFromSearch {
  static readonly type = '[library] BookFromSearch';
  constructor(public book: any) {}
}

export class bookFromRequest {
  static readonly type = '[library] BookFromRequest';
  constructor(public book: any) {}
}

export class bookToReturn {
  static readonly type = '[library] BookToReturn';
  constructor(public book: any) {}
}

export class clearBooksForNavigation {
  static readonly type = '[library] clearBooksForNavigation';
}
