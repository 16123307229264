import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { cloneDeep, union } from 'lodash';
import { council_incharge } from 'src/app/shared/models/school_council.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { StudentsAndTeachersState } from 'src/app/shared/store/students_teachers/students_teachers.state';
import { SchoolCouncilService } from '../../../shared/services/school-council.service';

@Component({
  selector: 'app-council-teachers-list-dialog',
  templateUrl: './council-teachers-list-dialog.component.html',
  styleUrls: ['./council-teachers-list-dialog.component.scss'],
})
export class CouncilTeachersListDialogComponent implements OnInit {
  schoolTeacherList: any[] = [];
  displayTeachersList: any[] = [];
  selectedTeachersArray: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private commonSrvc_: CommonService,
    private schoolCouncilSrvc_: SchoolCouncilService,
    private snackBarSrvc_: SnackbarService,
    private dialogSrvc_: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.store
      .select(StudentsAndTeachersState.getSchoolTeachers)
      .subscribe((el: any) => {
        console.log(el);
        this.schoolTeacherList = cloneDeep(el);
        this.displayTeachersList = this.schoolTeacherList.filter(
          (teacher: any) => {
            if (
              !teacher.hasOwnProperty('council_id') ||
              teacher.council_id == ''
            ) {
              return teacher;
            }
          }
        );
        console.log(this.displayTeachersList);
      });
  }

  popPushIncharge(id: string) {
    if (this.selectedTeachersArray.length >= 1) {
      this.selectedTeachersArray = [];
    }
    this.selectedTeachersArray.push(id);
    console.log(this.selectedTeachersArray);
  }

  onSavingIncharge() {
    let teacher = this.schoolTeacherList.find(
      (teacher: any) => teacher.id == this.selectedTeachersArray[0]
    );
    let councilIncharge: council_incharge = {
      doc_id: this.selectedTeachersArray[0],
      role: this.data.role.toLowerCase(),
      uid: teacher.user_id,
    };
    console.log(councilIncharge);
    if (this.data.action == 'add') {
      this.commonSrvc_.showSpinner = true;
      this.schoolCouncilSrvc_
        .addInchargeToCouncilDoc(this.data.id, councilIncharge)
        .then(() => {
          this.snackBarSrvc_.openSnackBarTop(
            'Incharge added successfully to council doc',
            'Success'
          );
          this.schoolCouncilSrvc_
            .updateTeacherDoc(councilIncharge.doc_id, this.data.id)
            .then(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Teacher Document updated successfully',
                'Success'
              );
              this.commonSrvc_.showSpinner = false;
              this.dialogSrvc_.close();
              this.router.navigate([`layout/community/council/list`]);
            })
            .catch(() => {
              this.snackBarSrvc_.openSnackBar(
                'Teacher document updation failed',
                'Failure'
              );
              this.commonSrvc_.showSpinner = false;
            });
        })
        .catch(() => {
          this.snackBarSrvc_.openSnackBar(
            'Incharge addition failed',
            'Failure'
          );
          this.commonSrvc_.showSpinner = false;
        });
    } else if (this.data.action == 'edit') {
      this.commonSrvc_.showSpinner = true;
      console.log(this.data.incharges);
      let council_incharges = this.data.incharges;
      council_incharges = council_incharges.map((hIncharge: any) => {
        let obj: council_incharge = {
          doc_id: hIncharge.doc_id,
          role: hIncharge.role.toLowerCase(),
          uid: hIncharge.uid,
        };
        return obj;
      });
      let index = council_incharges.findIndex(
        (hIn: any) => hIn.doc_id == this.data.incharge_doc_id
      );
      council_incharges.splice(index, 1, councilIncharge);
      console.log(council_incharges);
      // return
      this.schoolCouncilSrvc_
        .updateTeacherDoc(this.data.incharge_doc_id, '')
        .then(() => {
          this.snackBarSrvc_.openSnackBarTop(
            'Teacher Document has been updated successfully',
            'Success'
          );
          this.schoolCouncilSrvc_
            .updateInchargesArrayInCouncilDoc(council_incharges, this.data.id)
            .then(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Incharges array has been updated successfully',
                'Success'
              );
              this.schoolCouncilSrvc_
                .updateTeacherDoc(this.selectedTeachersArray[0], this.data.id)
                .then(() => {
                  this.snackBarSrvc_.openSnackBar(
                    'Teacher Document has been updated successfully',
                    'Success'
                  );
                  this.commonSrvc_.showSpinner = false;
                  this.dialogSrvc_.close();
                  this.router.navigate([`layout/community/council/list`]);
                })
                .catch((err) => {
                  console.log(err);
                  this.snackBarSrvc_.openSnackBarTop(
                    'Teacher Document updation failed',
                    'Failure'
                  );
                  this.commonSrvc_.showSpinner = false;
                });
            })
            .catch(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Incharges array updation failed',
                'Failure'
              );
              this.commonSrvc_.showSpinner = false;
            });
        })
        .catch((err) => {
          console.log(err);
          this.snackBarSrvc_.openSnackBarTop(
            'Teacher Document updation failed',
            'Failure'
          );
          this.commonSrvc_.showSpinner = false;
        });
    }
  }
}
