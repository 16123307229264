import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  scroll(direction: string) {
    console.log(direction);
    let el: any = document.getElementById('scrollable');
    if (direction == 'left') {
      el.scroll({
        left: el.scrollLeft - 600,
        behavior: 'smooth',
      });
    }
    if (direction == 'right') {
      el.scroll({
        left: el.scrollLeft + 600,
        behavior: 'smooth',
      });
    }
  }

}
