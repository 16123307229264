import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-hover-dropdown',
  templateUrl: './hover-dropdown.component.html',
  styleUrls: ['./hover-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoverDropdownComponent implements OnInit {
  @Input() id:any;
  // 196px
  @Input() width:string = '196px'
  @Input() background:string = 'white'
  @Input() border:string = ''//'1px solid #F7F9FA'
  @Input() borderRadius:string = '4px'
  @Input() zindex:any = '999'
  // @Input() height:string = 'auto'

  @Input() styles:any ;

  constructor() { }

  ngOnInit(): void {
    console.log(this.styles)
    this.styles = 'width: ' + this.width + '; background: ' + this.background + '; border: ' + this.border + '; border-radius: ' + this.borderRadius +';z-index:' + this.zindex;
  }

}
