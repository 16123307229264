import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';
import { docUser } from 'src/app/auth/shared/models/docUser';
import { username } from 'src/app/auth/shared/models/username';
import { UserCheckService } from 'src/app/auth/shared/services/user-check.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UserData } from 'src/app/shared/store/user/user.action';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss'],
})
export class AddProfileComponent implements OnInit {
  submitted!: boolean;
  hintColor = '#23C16B';
  submittedSpinner!: boolean;

  profileForm: FormGroup = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    dateOfBirth: new FormControl('', Validators.required),
    gender: new FormControl('', [Validators.required]),
    userName: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(30),
      this.commonSrvc_.userNameValidatorFn(),
    ]),
  });
  constructor(
    private store: Store,
    private userCheck: UserCheckService,
    private dialogService: DialogService,
    private _snackBar: MatSnackBar,
    private commonSrvc_:CommonService
  ) {}

  user: docUser;
  uid: string;

  ngOnInit(): void {
    let store = this.store.snapshot();
    this.user = store.UserData.userData;
    this.uid = store.UserData.userData.uid;

    // this.profileForm.patchValue({
    //   // schoolCode: el.school_id,

    //   dateOfBirth: new FormControl('', Validators.required),
    //   gender: new FormControl('', [Validators.required]),
    //   userName: new FormControl('', [
    //     Validators.required,
    //     Validators.pattern(/^[a-z0-9_.-]{4,30}$/),
    //   ]),

    // )};
  }

  get profileFormControls() {
    return this.profileForm.controls;
  }

  get username() {
    return this.profileForm.get('userName') as FormControl;
  }

  getUserNameError() {
    if (this.username.hasError('required')) {
      return 'Username is required';
    }
    if (this.username.hasError('minlength')) {
      return 'Username must be at least 4 characters';
    }
    if (this.username.hasError('maxlength')) {
      return 'Username must be less than 30 characters';
    }
    if (this.username.hasError('startingWithDigit')) {
      return 'Username must start with a letters only';
    }
    if (this.username.hasError('lastValueValid')) {
      return 'Username must not end with special characters';
    }
    if (this.username.hasError('specialCharInRowValid')) {
      return 'Special charcters must not be in a row';
    }
    if (this.username.hasError('specialCharValid')) {
      return 'Special characters allowed are - . _';
    }
    return '';
  }

  spinner = false;

  submitProfileForm() {
    if (this.profileForm.valid) {
      this.spinner = true;
      console.log(this.profileForm.value);
      let formValue = this.profileForm.value;
      let userDoc: any = {
        ...this.user,
        name: formValue.fullName.split(' ').map((v: string) => v.toLowerCase()),
        gender: formValue.gender.toLowerCase(),
        username: formValue.userName,
        dob: formValue.dateOfBirth,
        created_at: new Date(this.user.created_at?.seconds * 1000),
      };
      delete userDoc.uid;
      console.log(userDoc);
      this.userCheck
        .createUserdoc(userDoc, this.uid)
        .then((res) => {
          userDoc['uid'] = this.uid;

          this.store.dispatch(new UserData(userDoc));

          const usernameDoc: username = {
            created_at: new Date(),
            reference: '',
            username: formValue.userName,
          };
          this.userCheck
            .createUsername(usernameDoc, this.uid)
            .then(() => {
              this.spinner = false;
              this.dialogService.profileClose();
              // open event or school popup
            })
            .catch((err) => {
              console.log(err);
              this.openSnackBar('Something went wrong; try again', '');
            });
        })
        .catch((err) => {
          this.dialogService.profileClose();
          this.openSnackBar('Something went wrong ;try again', '');
        });
    } else {
    }
  }

  openSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'top';
    config.panelClass = ['blue-snackbar'];
    this._snackBar.open(message, action, config);
  }
}
