<div class="component_container">
  <!-- if library not created -->
  <div class="flex flex-col gap-6 items-center justify-center ">
    <h1 class="md:text_large text_medium text-ink_darker font-bold text-center">{{data}}</h1>
    <img src="../../../../assets/images/library/library.svg" alt="Hurrey_library">

    <button class="md:btn_blue_large btn_blue_medium" (click)="navigateToLibraryDashboard()">Library Dashboard</button>

  </div>
</div>
