<div class="notification-sidebar">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="notification-sidebar-header">
                    <img src="../../../../assets/icons/notifications/notification-bell.svg" alt="" />
                    <h4 class="notification-sidebar-title">Notifications</h4>
                </div>
            </div>
        </div>

        <!-- notifications content -->
        <div class="row">   
            <div class="col-12">
                <div class="notification-sidebar-content-item">
                    
                        <div class="notification-image">
                            <img src="../../../../assets/icons/notifications/default.svg" alt="" />
                        </div>
                   
                            <h5 class="notification-sidebar-content-item-header-title"><span>Hindi Week Celebrations</span> start today</h5>
                       
                        <div class="notification-image-active">
                            <img src="../../../../assets/icons/notifications/active.svg" alt="" />
                        </div>
                  
                </div>
            </div>
        </div>
    </div>
</div>