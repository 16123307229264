import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  GetSchoolCouncils,
  removeSelectedCouncil,
  selectedCouncil,
} from './schoolCouncil.action';

@State<any>({
  name: 'SchoolCouncilState',
  defaults: {
    schoolCouncils: [],
    selectedCouncil: {},
  },
})
@Injectable()
export class SchoolCouncilState {
  @Selector() static getSchoolCouncils(state: any) {
    console.log(state);
    return state.schoolCouncils;
  }
  @Action(GetSchoolCouncils)
  setSchoolCouncils(
    ctx: StateContext<any>,
    { schoolCouncils }: GetSchoolCouncils
  ) {
    ctx.setState(
      patch({
        schoolCouncils: schoolCouncils,
      })
    );
  }

  @Action(selectedCouncil)
  setSelectedCouncil(
    ctx: StateContext<any>,
    { schoolCouncil }: selectedCouncil
  ) {
    ctx.setState(
      patch({
        selectedCouncil: schoolCouncil,
      })
    );
  }

  @Action(removeSelectedCouncil)
  removeSelectedCouncil(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        selectedCouncil: {},
      })
    );
  }
}
