<div class="container p-1" *ngIf="toggle == 0">
  <div class="card_head mb-3">
    <h1 class="title text-center">Assign Class</h1>
    <!-- remove image -->
    <img
      src="../../../../assets/icons/close.svg"
      alt="teacher"
      class="remove cursor-pointer"
      (click)="dialogServ_.dismissAll()"
    />
  </div>
  <div
    class="card_body flex flex-col gap-3 items-center justify-center flex-wrap"
  >
    <div class="side_head">
      <h1 class="leading-4">
        Assign Classes to {{ data?.name?.join(" ") | titlecase }}
      </h1>
      <!-- <p>0 Classes</p> -->
    </div>

    <div class="flex flex-wrap gap-2">
      <div
        id="ck-button"
        [ngClass]="{ borderLess: findSelection(sec) }"
        *ngFor="let sec of schoolClasses; let i = index"
      >
        <label>
          <input
            type="checkbox"
            value="{{ sec }}"
            [checked]="findSelection(sec)"
            (click)="Selection($event, sec)"
          /><span style="padding: 12px">
            Class &nbsp;{{ sec?.name | uppercase }}

            {{ sec.section | uppercase }}
          </span>
        </label>
      </div>
    </div>

    <!-- class list -->
    <!-- <div class="class_list grid gap-4 grid-cols-6">


      <button
        class="section-btn"
        *ngFor="let sec of schoolClasses; let i = index"
        (click)="Selection(sec)"
        [ngClass]="{ selected: findSelection(sec) }"
      >
        <span>Class &nbsp; </span>{{ sec?.name | uppercase }}

        {{ sec.section | uppercase }}
      </button>
   >
    </div> -->
  </div>
  <div class="card_footer">
    <button
      mat-stroked-button
      class="cancel_btn"
      (click)="dialogServ_.dismissAll()"
    >
      Cancel
    </button>
    <button mat-flat-button class="save_btn" class="full" (click)="save1()">
      Save and Proceed
    </button>
  </div>
</div>

<!-- assign nsubjects -->
<div class="container" *ngIf="toggle == 1">
  <div class="card_head">
    <h1 class="title text-center">Assign Subjects</h1>
    <!-- remove image -->
    <img
      src="../../../../assets/icons/close.svg"
      alt="teacher"
      class="remove cursor-pointer"
      (click)="dialogServ_.dismissAll()"
    />
  </div>
  <div class="card_body">
    <div class="side_head mb-3">
      <!-- <h1>Assign Subjects</h1> -->
      <!-- <p>4 Classes</p> -->
    </div>

    <!-- class list -->
    <div class="subject_cards mt-3" id="subject_cards">
      <div class="subject_card mb-3 mt-3" *ngFor="let item of selectedClasses">
        <div class="card-header">
          <h4>
            <span>Class &nbsp; </span>{{ item?.name | uppercase }}

            {{ item?.section | uppercase }}
          </h4>
          <div class="head-right">
            <div class="dialog-body" mat-dialog-content>
              <div class="body-header">
                <div class="same_for_all-check">
                  <label class="container"
                    ><span class="text">Make Class Teacher</span>
                    <input
                      type="checkbox"
                      value="{{ item.id }}"
                      (change)="classTeacher($event)"
                      [checked]="checktick(item)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <!-- remove image -->
            <!-- <img src="../../../../assets/icons/close.svg" alt="teacher" class="remove cursor-pointer"
                            (click)="dialogServ_.dismissAll()" /> -->
          </div>
        </div>

        <div class="subjects-div gap-3">
          <ng-container *ngIf="item?.subjects.length">
            <div
              id="ck-button"
              [ngClass]="{ borderLess: findChecked(item, item2) }"
              *ngFor="let item2 of item.subjects; let j = index"
            >
              <label>
                <input
                  type="checkbox"
                  value="{{ item2 }}"
                  [checked]="findChecked(item, item2)"
                  (change)="onClickingSub($event, item, item2)"
                /><span style="padding: 12px">{{ item2 | titlecase }}</span>
              </label>
            </div>
          </ng-container>
          <ng-container *ngIf="!item?.subjects.length">
            <p>Ask your admin to add subjects</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="card_footer">
    <button mat-stroked-button class="cancel_btn" mat-dialog-close>
      Cancel
    </button>
    <button mat-flat-button class="save_btn" class="full" (click)="save2()">
      Save and Proceed
    </button>
  </div>
</div>
