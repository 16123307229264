import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchoolOnboardService } from 'src/app/shared/services/school-onboard.service';

@Component({
  selector: 'app-add-enrollment-code-modal',
  templateUrl: './add-enrollment-code-modal.component.html',
  styleUrls: ['./add-enrollment-code-modal.component.scss'],
})
export class AddEnrollmentCodeModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,

    public schoolOnboardServ_: SchoolOnboardService,
    private modal: MatDialog
  ) {}

  myGroup = new FormGroup({
    enrollmentnumber: new FormControl(),
  });

  ngOnInit(): void {}

  submit() {
    console.log(this.myGroup.value.enrollmentnumber);

    if (this.myGroup.valid) {
      console.log(this.data);
      this.schoolOnboardServ_
        .addEnrollmentNumber(this.data.id, this.myGroup.value.enrollmentnumber)
        .then(() => {
          this.modal.closeAll();
        });
    }
  }

  getErrorMessage() {
    if (this.myGroup.controls.enrollmentnumber.hasError('required')) {
      return 'Please Enter A Valid Code';
    }

    return 'Not A Valid Code';
  }

  getenrollmentcontrol() {
    return this.myGroup.controls.enrollmentnumber;
  }
}
