import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SchoolOnboardService } from 'src/app/shared/services/school-onboard.service';

@Component({
  selector: 'app-add-subjects-dialog',
  templateUrl: './add-subjects-dialog.component.html',
  styleUrls: ['./add-subjects-dialog.component.scss'],
})
export class AddSubjectsDialogComponent implements OnInit {
  selectedSubjects: any[] = [];
  subName: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private onboardSrvc_: SchoolOnboardService,
    private dialogSrvc_: DialogService
  ) {
    console.log(data);
  }

  ngOnInit(): void {
    this.selectedSubjects =
      this.data.data.subjectAllocation[this.data.index].selectedSubjects;
  }

  onClickingSub(event: any) {
    this.selectedSubjects =
      this.data.data.subjectAllocation[this.data.index].selectedSubjects;
    if (event.target.checked) {
      this.selectedSubjects.push(event.target.value);
    } else {
      if (this.selectedSubjects.includes(event.target.value)) {
        let index = this.selectedSubjects.indexOf(event.target.value);
        if (index != -1) {
          this.selectedSubjects.splice(index, 1);
        } else {
        }
      }
      // this.saveProceed();
    }
    this.saveProceed();
    console.log(this.data.data.subjectAllocation);
    console.log(this.selectedSubjects);
    console.log(this.data.data);
  }

  addSubject() {
    if (this.subName) {
      this.data.data.subjects.push(this.subName.toLowerCase());
    }
    this.subName = '';
  }

  saveProceed() {
    let clone = this.selectedSubjects.slice();
    this.data.data.subjectAllocation[this.data.index].selectedSubjects = clone;
    console.log(this.onboardSrvc_.subjectArray);
    let count = 0;
    for (const iterator of this.data.data.subjectAllocation) {
      if (iterator.selectedSubjects.length) {
        count++;
      }
    }
    console.log(count, this.data.data);
    if (this.data.data.subjectAllocation.length == count) {
      this.data.data.subChecked = true;
    } else {
      this.data.data.subChecked = false;
    }
  }

  checktick() {
    return !!this.data.data.subjectAllocation[this.data.index].sametoall;
    // if (this.data.data.subjectAllocation[this.data.index].sametoall) {
    //   return true;
    // } else {
    //   return false;
    // }
  }

  sameToAll(event: any) {
    /*
    if checked copy all the selected subjects of current section to all sections.
    sameToAll flag to true for current section anf false for the remaining sections.


*/
    let clone = this.selectedSubjects.slice();
    let flag = this.data.data.subjectAllocation[this.data.index]?.sametoall;
    if (event.target.checked) {
      if (!flag) {
        this.data.data.subjectAllocation = this.data.data.subjectAllocation.map(
          (element: any) => {
            element.selectedSubjects = [...clone];
            element.sametoall = false;
            return element;
          }
        );
        // for (const element of this.data.data.subjectAllocation) {
        //   element.selectedSubjects = [...clone];
        //   element.sametoall = false;
        // }
        this.data.data.subjectAllocation[this.data.index].sametoall = true;
        this.data.data.subChecked = true;
      }
    } else {
      this.data.data.subjectAllocation[this.data.index].sametoall = false;
      return;
    }
    console.log(this.onboardSrvc_.subjectArray);
  }

  isChecked(item: any) {
    return this.data.data.subjectAllocation[
      this.data.index
    ].selectedSubjects.includes(item);
  }
}
