export namespace StudentsActions {
  export class getAllStudents {
    public static readonly type = '[SchoolStudents] GetAll';
    constructor(public payload: string) {}
  }

  export class updateStudent{
    public static readonly type = '[SchoolStudents] updateStudent';
    constructor(public payload: any) {}
  }
  export class get {
    public static readonly type = '[students] Get';
    constructor(public payload: string) {}
  }

  export class removeStudentlist {
    public static readonly type = '[RemoveStudents] remove';
  }
}
