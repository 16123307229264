import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { patch } from '@ngxs/store/operators';
import { invitationAdd, invitationRemove } from './invitation.action';

@State<any>({
  name: 'invitationData',
  defaults: {
    inviteData: {},
  },
})
@Injectable()
export class invitationDataState {
  constructor() {}

  @Selector() static getInvitationData(state: any) {
    return state.inviteData;
  }

  @Action(invitationAdd)
  setUserData(ctx: StateContext<any>, { inviteData }: invitationAdd) {
    ctx.setState(
      patch({
        inviteData: inviteData,
      })
    );
  }

  @Action(invitationRemove)
  removeSchools(ctx: StateContext<any>) {
    ctx.setState(patch({ inviteData: {} }));
  }
}
