export namespace SchoolActions {
  export class GetAllOnce {
    public static readonly type = '[Races] GetAllOnce';
  }
  export class GetOnce {
    public static readonly type = '[Races] GetOnce';
    constructor(public payload: string) {}
  }
  export class GetSchool {
    public static readonly type = '[school] Get';
    constructor(public payload: string) {}
  }

  export class AppendSchool {
    public static readonly type = '[school] append';
    constructor(public payload: any) {}
  }

  export class removeSchools {
    static readonly type = '[SelectedSchool] removSchool';
    constructor(public schools: any[]) {}
  }
  // export class Get {
  //   public static readonly type = '[Races] Get';
  //   constructor(public payload: string) {}
  // }
}
