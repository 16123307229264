<div class="dialog-header" mat-dialog-title [align]="'center'">
  Select {{ data.name | titlecase }}
</div>
<div class="dialog-body" mat-dialog-content>
  <!-- <div class="body-header">
    <div class="individual-class">
      <h1>Select {{ data.name | titlecase }}</h1>
    </div>
  </div> -->
  <div class="subjects-div">
    <div
      id="ck-button"
      [ngClass]="{ borderLess: isChecked(item) }"
      *ngFor="let item of data.list; let j = index"
    >
      <label>
        <input
          type="checkbox"
          value="{{ item }}"
          [checked]="isChecked(item)"
          (change)="onClicking($event)"
        /><span style="padding: 12px">{{ item | uppercase }}</span>
      </label>
    </div>
  </div>
</div>
<!-- <div class="dialog-actions" mat-dialog-actions [align]="'end'">
  <button class="save_n_proceed" (click)="saveProceed()">
    Save and Proceed
  </button>
</div> -->
<span class="close" mat-dialog-close>
  <img
    src="../../../../../assets/icons/school-onboard/close-icon-dialog.svg"
    alt=""
  />
</span>
