import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsernameDirective } from './username.directive';
import { SchoolCodeDirective } from './school-code.directive';
import { EndDateValidDirective } from './end-date-valid.directive';
import { NumberOnlyDirective } from './number-only.directive';
import { DefaultImage } from './default-image.directive';
import { MyDurationPickerDirective } from './duration-input.directive';
import { DigitOnlyDirective } from './digit-only.directive';

@NgModule({
  declarations: [
    UsernameDirective,
    SchoolCodeDirective,
    EndDateValidDirective,
    NumberOnlyDirective,
    DefaultImage,
    MyDurationPickerDirective,
    DigitOnlyDirective,
  ],
  imports: [CommonModule],
  exports: [
    UsernameDirective,
    SchoolCodeDirective,
    EndDateValidDirective,
    NumberOnlyDirective,
    DefaultImage,
    MyDurationPickerDirective,
    DigitOnlyDirective,
  ],
})
export class DirectivesModule {}
