import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerInput,
} from '@angular/material/datepicker';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import { Subscription } from 'rxjs';
import {
  SchoolEvent,
  Type_of_event,
} from 'src/app/shared/models/school_event.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { EventService } from 'src/app/shared/services/event.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ClassListState } from 'src/app/shared/store/class-list/class-list.state';

@Component({
  selector: 'app-calender-createevent-dialog',
  templateUrl: './calender-createevent-dialog.component.html',
  styleUrls: ['./calender-createevent-dialog.component.scss'],
})
export class CalenderCreateeventDialogComponent implements OnInit, OnDestroy {
  typeOfEvent: string = '';
  schoolClasses: any[];
  selectedClasses: any[] = [];
  classesBeforeSelect: any[] = [];
  selectedDateEvents: any[] = [];
  examEvents: any[] = [];
  activities: any[] = [];
  holidays: any[] = [];
  patch: boolean = false;
  docId: string = '';
  sub: Subscription;
  sub1!: Subscription;
  submitted: boolean = false;
 temp= [1,2,3,]
  createEventForm = new FormGroup({
    title: new FormControl('', Validators.required),
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required),
    fromTime: new FormControl({ value: '', disabled: true }),
    toTime: new FormControl({ value: '', disabled: true }),
  });

  @ViewChild('picker1') picker1: MatDatepickerInput<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private eventSrvc_: EventService,
    private dialogSrvc_: DialogService,
    private snackBarSrvc_: SnackbarService
  ) {}

  ngOnInit(): void {
    this.sub1 = this.store.select(ClassListState).subscribe((el) => {
      this.schoolClasses = el.classList;
      console.log(this.schoolClasses);
    });
    if (!!this.data.classes && this.data?.classes.length) {
      for (let i = 0; i < this.data.classes.length; i++) {
        const element = this.data.classes[i];
        this.selectedClasses.push(element);
      }
      console.log(this.selectedClasses);
    }
    if (this.data.arr.length) {
      this.selectedDateEvents = [...this.data.arr];
      this.examEvents = [
        ...this.selectedDateEvents.filter((el) => el.type == 'exams'),
      ];
      this.activities = [
        ...this.selectedDateEvents.filter((el) => el.type == 'activities'),
      ];
      this.holidays = [
        ...this.selectedDateEvents.filter((el) => el.type == 'holidays'),
      ];
    }
    this.formValueChanges();
  }

  //observing valuechanges of formcontrols in form
  formValueChanges() {
    this.fromValue.valueChanges.subscribe((el) => {
      if (el) {
        this.startTime.enable();
      }
      this.startTime.setValue('');
      this.toValue.reset();
    });
    this.sub = this.toValue.valueChanges.subscribe((el) => {
      console.log(el);
      if (el) {
        this.endTime.enable();
      }
      this.endTime.setValue('');
    });
    this.endTime.valueChanges.subscribe((el) => {
      if (el) {
        this.endTimeSet(el);
      }
    });
    this.startTime.valueChanges.subscribe((el) => {
      this.startTimeSet(el);
    });
    this.toValue.setErrors(null);
    this.fromValue.setErrors(null);
    this.startTime.setErrors(null);
    this.endTime.setErrors(null);
  }

  gettingClassesofEvents(classIds: any[]) {
    let classes = this.schoolClasses.filter((clas) =>
      classIds.includes(clas.id)
    );
    return classes;
  }

  //output function emiited when time is set
  timechangedEvent(event: any) {
    console.log(event);
  }

  isChecked(item: any) {
    let arr = this.selectedClasses.filter((clas: any) => clas.id == item.id);
    return !!arr.length;
  }

  onEditClick(event: SchoolEvent) {
    this.patch = true;
    this.patchingValues(event);
  }

  convertTime12to24(time12h: string) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    return `${hours}:${minutes}`;
  }

  endTimeSet(event: any) {
    console.log(event);
    if (this.startTime.value) {
      if (this.toValue.value.getTime() == this.fromValue.value.getTime()) {
        // timeFrom=  this.startTime.value.setHours()
        let start24 = this.convertTime12to24(this.startTime.value);
        let end24 = this.convertTime12to24(event);
        if (!this.timeComparision(start24, end24)) {
          this.snackBarSrvc_.openSnackBarTop(
            'End time should be greater than start time',
            'Failed'
          );
          this.endTime.reset();
        }
      }
    }
  }

  timeComparision(start24: string, end24: string) {
    let startTimeObject = new Date();
    let endTimeObject = new Date();
    let startMin = start24.split(':')[1];
    let endMin = end24.split(':')[1];
    let startHour = start24.split(':')[0];
    let endHour = end24.split(':')[0];
    startTimeObject.setHours(+startHour, +startMin, 0);
    endTimeObject.setHours(+endHour, +endMin, 0);
    if (startTimeObject.getTime() >= endTimeObject.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  startTimeSet(event: any) {
    console.log(event);
    if (this.endTime.value) {
      if (this.toValue.value.getTime() == this.fromValue.value.getTime()) {
        let start24 = this.convertTime12to24(event);
        let end24 = this.convertTime12to24(this.endTime.value);
        if (!this.timeComparision(start24, end24)) {
          this.snackBarSrvc_.openSnackBarTop(
            'Start time should be less than end time',
            'Failed'
          );
          this.startTime.setValue('');
        }
      }
    }
  }

  patchingValues(event: any) {
    console.log(event);
    this.typeOfEvent = event.type;
    this.docId = event.id;
    if (!!event) {
      if (event.type == 'holidays') {
        this.createEventForm.patchValue({
          title: event.title,
          from: event.from,
          to: event.to,
        });
      } else {
        this.createEventForm.patchValue({
          title: event.title,
          from: event.from,
          to: event.to,
          fromTime: `${event.from.getHours()}:${event.from.getMinutes()}`,
          toTime: `${event.to.getHours()}:${event.to.getMinutes()}`,
        });
      }
      this.selectedClasses = this.schoolClasses.filter((clas) =>
        event.class_ids.includes(clas.id)
      );
    }
  }

  onClickingClass(event: any, clas: any) {
    console.log(clas);
    if (event.target.checked) {
      this.selectedClasses.push(clas);
      console.log(this.selectedClasses);
    } else {
      if (this.selectedClasses.includes(clas)) {
        let index = this.selectedClasses.indexOf(clas);
        if (index != -1) {
          this.selectedClasses.splice(index, 1);
          console.log(this.selectedClasses);
        } else {
        }
      }
    }
  }

  createEventtype(type: string) {
    if (type == 'activities') {
      this.typeOfEvent = Type_of_event.events;
    } else if (type == 'exams') {
      this.typeOfEvent = Type_of_event.exams;
    } else {
      this.typeOfEvent = Type_of_event.holidays;
    }
  }

  sameForAllClasses(event: any) {
    console.log(event);
    if (event.target.checked) {
      this.classesBeforeSelect = [...this.selectedClasses];
      this.selectedClasses = [...this.schoolClasses];
    } else {
      this.selectedClasses = [...this.classesBeforeSelect];
    }
  }

  // form getters
  get toValue() {
    return this.createEventForm.get('to') as FormControl;
  }
  get fromValue() {
    return this.createEventForm.get('from') as FormControl;
  }

  get title() {
    return this.createEventForm.get('title') as FormControl;
  }

  get startTime() {
    return this.createEventForm.get('fromTime') as FormControl;
  }

  get endTime() {
    return this.createEventForm.get('toTime') as FormControl;
  }

  calculateLeave(startDate: any, endDate: any) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days + 1;
  }

  deleteEvent(event: any) {
    this.eventSrvc_
      .deleteEvent(event.id)
      .then(() => {
        this.snackBarSrvc_.openSnackBarTop(
          'Event deleted successfully!!',
          'Success'
        );
        this.dialogSrvc_.dismissAll();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  saveAndProceed() {
    this.submitted = true;
    console.log(this.createEventForm.value);
    if (!this.createEventForm.valid) {
      this.snackBarSrvc_.openSnackBarTop(
        'Please check for errors in form',
        'Error'
      );
      return;
    }
    if (this.selectedClasses.length == 0) {
      this.snackBarSrvc_.openSnackBarTop(
        'Please select atleast one class',
        'Error'
      );
      return;
    }
    if (this.createEventForm.valid && this.selectedClasses.length != 0) {
      let event: SchoolEvent = {
        class_ids: this.selectedClasses.map((a) => a.id),
        day: this.calculateLeave(
          this.fromValue.value.toISOString().slice(0, 10),
          this.toValue.value.toISOString().slice(0, 10)
        ),
        from:
          this.typeOfEvent == 'holidays'
            ? new Date(
                this.fromValue.value.getFullYear(),
                this.fromValue.value.getMonth(),
                this.fromValue.value.getDate(),
                0,
                0,
                0
              )
            : new Date(
                this.fromValue.value.getFullYear(),
                this.fromValue.value.getMonth(),
                this.fromValue.value.getDate(),
                +this.convertTime12to24(this.startTime.value).split(':')[0],
                +this.convertTime12to24(this.startTime.value).split(':')[1],
                0
              ),
        to:
          this.typeOfEvent == 'holidays'
            ? new Date(
                this.toValue.value.getFullYear(),
                this.toValue.value.getMonth(),
                this.toValue.value.getDate(),
                23,
                59,
                59
              )
            : new Date(
                this.toValue.value.getFullYear(),
                this.toValue.value.getMonth(),
                this.toValue.value.getDate(),
                +this.convertTime12to24(this.endTime.value).split(':')[0],
                +this.convertTime12to24(this.endTime.value).split(':')[0],
                0
              ),
        title: this.title.value,
        created_at: new Date(),
        type:
          this.typeOfEvent == 'activities'
            ? Type_of_event.events
            : this.typeOfEvent == 'exams'
            ? Type_of_event.exams
            : Type_of_event.holidays,
        curriculum: this.eventSrvc_.currentSchoolDetails.curriculum,
        school_id: this.eventSrvc_.currentSchoolId,
      };
      console.log(event);
      if (!this.patch) {
        this.eventSrvc_
          .addingAnEvent(event)
          .then(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Event added successfully!!',
              'Success'
            );
            this.dialogSrvc_.dismissAll();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.eventSrvc_
          .updatingAnEvent(event, this.docId)
          .then(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Event updated successfully!!',
              'Success'
            );
            this.dialogSrvc_.dismissAll();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
  ngOnDestroy(): void {
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
  }
}
