<div class="flex items-center gap-4 justify-between flex-wrap">
  <div class="title items-center flex gap-2">
    <picture class="hide_in_mobile">
      <source srcset="../../../../assets/icons/home.svg" type="image/webp" />
      <source srcset="../../../../assets/icons/home.svg" type="image/png" />
      <img src="../../../../assets/icons/home.svg" alt="Hero Image" />
    </picture>
    <h1 class="text-ink_dark font-semibold md:text-extra_large text-medium">
      Hi,{{ userDetails?.name?.join(" ") | titlecase }}
    </h1>
  </div>

  <div class="overflow-x-auto flex flex-wrap items-center gap-4 justify-end">
    <div class="nav_items flex items-center gap-8">
      <div class="items flex-none" *ngFor="let item of NavItems; let i = index">
        <a
          (click)="onClick(item)"
          class="nav_item cursor-pointer regular"
          [class]="item.active == 'true' ? 'active' : 'inactive'"
          >{{ item.title }}</a
        >
      </div>
      <!-- acedemic tools -->

      <div class="">
        <app-hover-dropdown
          width="250px"
          background="#F7F9FA"
          borderRadius="10px"
          zindex="999"
        >
          <div content-title>
            <a
              routerLink="AcademicTools "
              class="nav_item text-small cursor-pointer inactive"
              >Academic Tools
            </a>
          </div>
          <div content-items>
            <div class="drop_items flex flex-col gap-4">
              <div
                class="items flex items-center gap-6 cursor-pointer"
                (click)="onClickAcedemicTools(item)"
                *ngFor="let item of AcedemicTools"
              >
                <img class="tools_image" [src]="item.image" alt="" />
                <h4 class="large_normal2 text-ink_darker">
                  {{ item.title }}
                </h4>
              </div>
            </div>
          </div>
        </app-hover-dropdown>
      </div>
    </div>
  </div>
</div>
