export class SelectedSchool {
  static readonly type = '[SelectedSchool] Selected School';
  constructor(public school: any) {}
}

export class removeSelectedAdmin {
  static readonly type = '[SelectedSchool] removSchool';
  constructor(public schools: string) {}
}

export class Schools {
  static readonly type = '[Schools] All Schools';
  constructor(public schools: any[]) {}
}
