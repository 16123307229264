import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-layout-navigation',
  templateUrl: './layout-navigation.component.html',
  styleUrls: ['./layout-navigation.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutNavigationComponent implements OnInit {
  userDetails: any = {};
  NavItems = [
    { title: 'My School', route: 'myschool', active: 'false' },
    { title: 'Teachers', route: 'teachers', active: 'false' },
    { title: 'Students', route: 'students-overview', active: 'false' },
    { title: 'Admins', route: 'admins', active: 'false' },
    { title: 'Task To Do', route: 'todo', active: 'false' },
    // { title: 'Fee Structure', route: '/layout/fee-management/feemanagement' },
  ];
  AcedemicTools = [
    {
      title: 'Exam center',
      route: 'examcenter',
      active: 'false',
      image: '../../../../assets/icons/examcenter-icon.svg',
    },
    {
      title: 'Community',
      route: 'community',
      active: 'false',
      image: '../../../../assets/icons/community-icon.svg',
    },
    {
      title: 'Digital Library',
      route: 'library',
      active: 'false',
      image: '../../../../assets/icons/library-icon.svg',
    },

    // { title: 'Fee Structure', route: '/layout/fee-management/feemanagement' },
  ];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.router.events.subscribe((el: any) => {
      console.log(el.url);

      if (el instanceof NavigationStart) {
        this.makeRouteActive(el);
        console.log(this.NavItems);
      }
    });
  }

  makeRouteActive(el: any) {
    for (const item of this.NavItems) {
      if (el.url.includes(item.route)) {
        item.active = 'true';
        console.log('here', this.NavItems);
      } else {
        item.active = 'false';
      }
    }

    // this.NavItems = { ...this.NavItems };
  }

  ngOnInit(): void {
    // console.log();
    this.userDetails = this.store.snapshot().UserData.userData;
    let currentRoute = this.route.snapshot;

    console.log(this.router.url);
    this.NavItems.forEach((item) => {
      console.log(this.router.url.includes(item.route));
      if (this.router.url.includes(item.route)) {
        item.active = 'true';
      }
    });
  }

  onClick(item: any) {
    // this.NavItems.forEach((item) => {
    //   item.active = 'false';
    // });
    // item.active = 'true';
    this.router.navigate(['/layout/dashboard/' + item.route]);
  }

  onClickAcedemicTools(item: any) {
    // this.NavItems.forEach((item) => {
    //   item.active = 'false';
    // });
    // item.active = 'true';
    this.router.navigate(['/layout/' + item.route]);
  }
}
