import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-library-book-lost-dialog',
  templateUrl: './library-book-lost-dialog.component.html',
  styleUrls: ['./library-book-lost-dialog.component.scss']
})
export class LibraryBookLostDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
