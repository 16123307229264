<div class="dialog-header" mat-dialog-title [align]="'center'">
  Select Subjects
</div>
<div class="dialog-body" mat-dialog-content>
  <div class="body-header">
    <div class="individual-class">
      <h1>
        Select Subjects for Class {{ data.data.name | uppercase }}
        <span *ngIf="!!mapAll">
          <span *ngFor="let sec of sections; let last = last"
            >{{ sec | uppercase }} {{ last ? "." : "," }}</span
          >
        </span>
        <span *ngIf="!mapAll">
          <span>{{ section | uppercase }}</span>
        </span>
      </h1>
      <div class="sub-head">
        {{ selectedSubjects.length }} Subjects Selected
      </div>
    </div>
    <div class="same_for_all-check" *ngIf="!this.mapAll">
      <label class="container"
        ><span class="text">Same For All Sections</span>
        <input
          type="checkbox"
          (change)="sameToAll($event)"
          [checked]="checktick()"
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
  <div class="subjects-div">
    <div
      id="ck-button"
      [ngClass]="{ borderLess: isChecked(item) }"
      *ngFor="let item of data.data.subjects; let j = index"
    >
      <label>
        <input
          type="checkbox"
          value="{{ item }}"
          [checked]="isChecked(item)"
          (change)="onClickingSub($event)"
        /><span style="padding: 12px">{{ item | titlecase }}</span>
      </label>
    </div>
    <div class="add-subject flex items-center">
      <input
        type="text"
        placeholder="Subject Name"
        class="add-class-input mr-7"
        [(ngModel)]="subName"
      />
      <div class="add-button cursor-pointer" (click)="addSubject()">+ Add</div>
      <div class="add-image" (click)="addSubject()">
        <span>
          <img
            src="../../../../../assets/icons/school-onboard/Add.svg"
            alt=""
          />
        </span>
      </div>
    </div>
  </div>
</div>
<!-- <div class="dialog-actions" mat-dialog-actions [align]="'end'">
  <button class="save_n_proceed" (click)="saveProceed()">
    Save and Proceed
  </button>
</div> -->
<span class="close" mat-dialog-close>
  <img
    src="../../../../../assets/icons/school-onboard/close-icon-dialog.svg"
    alt=""
  />
</span>
