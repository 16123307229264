<mat-accordion class="accordion">
  <mat-expansion-panel hideToggle class="active" [@.disabled]="true">
    <mat-expansion-panel-header expandedHeight="44px">
      <div class="head">
        <div class="icon">
          <span>
            <img src="../../../../assets/icons/side-bar/calendar-icon.svg" alt="">
          </span>
        </div>
        <div class="title">Dashboard</div>
      </div>
    </mat-expansion-panel-header>
    <mat-list role="list">
      <mat-list-item role="listitem">Teacher</mat-list-item>
      <mat-list-item role="listitem">Classes</mat-list-item>
      <mat-list-item role="listitem">Admin</mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>
