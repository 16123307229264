export class SchoolClasses {
  static readonly type = '[Class List] SchoolClasses';
  constructor(public classList: any) {}
}

export class updatingClassStudentsList {
  static readonly type = '[Class List] update studentlist';
}

export class RemoveClasses {
  static readonly type = '[removeClasses] removeClasses';
}
