import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngxs/store';
import firebase, { firestore } from 'firebase';
import { forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { teacherRoot } from 'src/app/shared/models/teacher_root';
import { CommonService } from 'src/app/shared/services/common.service';
import { TeacherDetailedList } from 'src/app/shared/store/teacherDetailedlist/teacherlist.action';

import { teacherState } from 'src/app/shared/store/teachers/teachers.state';

@Injectable({
  providedIn: 'root',
})
export class TeacherService {
  constructor(
    private firestore: AngularFirestore,
    private commonserv_: CommonService,
    private store: Store
  ) {}

  fetchTeachers() {
    let TeacherRootlist$ = this.store.select(teacherState.getteacherlist);

    TeacherRootlist$.subscribe((el) => {
      console.log('list', el);
    });

    // get teacher full details

    let detailedlist$ = TeacherRootlist$.pipe(
      switchMap((data: any) => {
        if (data.length) {
          return forkJoin(
            data.map((el: teacherRoot) =>
              this.commonserv_.getUserDoc(el.user_id).pipe(
                map((user: any) => {
                  let data = user.data();
                  console.log('data', data);

                  if (data)
                    Object.assign(data, {
                      allocations: el,
                      state: 0,
                    });

                  return data;
                })
              )
            )
          );
        } else return of([]);
      })
    );

    // detailedlist$.subscribe((el: any) => {
    //   console.log(el);

    //   // this.checkAnalytics()
    // });

    // fetch assessment

    let detailedlist2$ = detailedlist$.pipe(
      switchMap((data: any) => {
        if (data.length) {
          return forkJoin(
            data.map((el: any) => {
              // console.log(el);
              return this.commonserv_
                .getTeacherAssessment(
                  el.allocations.user_id,
                  el.allocations.school_id
                )
                .pipe(
                  map((user: any) => {
                    console.log(user);
                    Object.assign(el, {
                      assessments: user,
                    });

                    return el;
                  })
                );
            })
          );
        } else return of([]);
      })
    );

    detailedlist2$.subscribe((el: any) => {
      console.log(el);
      this.store.dispatch(new TeacherDetailedList(el));
    });
    return detailedlist2$;
  }

  fetchTeachersBasicDetailsOnly() {
    let TeacherRootlist$ = this.store.select(teacherState.getteacherlist);

    // TeacherRootlist$.subscribe((el) => {
    //   console.log('list', el);
    // });

    let detailedlist$ = TeacherRootlist$.pipe(
      switchMap((data: any) => {
        if (data.length) {
          return forkJoin(
            data.map((el: teacherRoot) =>
              this.commonserv_.getUserDoc(el.user_id).pipe(
                map((user: any) => {
                  let data = user.data();

                  Object.assign(data, {
                    id: el.user_id,
                    allocations: el,
                    state: 0,
                  });

                  return data;
                })
              )
            )
          );
        } else return of([]);
      })
    );

    return detailedlist$;
  }

  getSyllabusDetails(list: any) {
    for (const iterator of list) {
      iterator['sylperc'] = 0;
      if (iterator.allocations.classes.length) {
        let finaltotapcompleted = 0;
        let finaltotaltopics = 0;
        for (const itera of iterator.allocations.classes) {
          if (itera.subject) {
            this.commonserv_
              .getSyllabusSubjectWise(
                iterator.allocations.school_id,
                itera.class_doc_id,
                itera.subject
              )
              .subscribe((syl) => {
                if ('syll' in iterator) {
                  iterator.syll.push(syl[0]);
                } else {
                  iterator['syll'] = syl;
                }

                if (syl.length) {
                  // looping syl
                  let wholesylltopiccompleted = 0;
                  let wholesyllnooftopic = 0;
                  for (const sy of syl) {
                    if (sy.chapters.length) {
                      //chapter
                      let wholechaptertopics = 0;
                      let wholechapterTopicsCopleted = 0;
                      for (const i of sy.chapters) {
                        let topicCompleted = 0;
                        let nooftopics = i.topics.length;
                        if (i.topics.length) {
                          //topic

                          for (const topic of i.topics) {
                            if (topic.completed) {
                              topicCompleted++;
                            }
                          }

                          wholechapterTopicsCopleted =
                            wholechapterTopicsCopleted + topicCompleted;
                          wholechaptertopics = wholechaptertopics + nooftopics;
                        } else {
                          let topicCompleted = 0;
                          let nooftopics = 0;
                          wholechapterTopicsCopleted =
                            wholechapterTopicsCopleted + topicCompleted;
                          wholechaptertopics = wholechaptertopics + nooftopics;
                        }
                      }
                      wholesylltopiccompleted =
                        wholesylltopiccompleted + wholechapterTopicsCopleted;
                      wholesyllnooftopic =
                        wholesyllnooftopic + wholechaptertopics;

                      // console.log(wholesylltopiccompleted, wholesyllnooftopic);
                    }
                  }

                  finaltotaltopics = finaltotaltopics + wholesyllnooftopic;
                  finaltotapcompleted =
                    finaltotapcompleted + wholesylltopiccompleted;
                  // console.log(wholesylltopiccompleted, wholesyllnooftopic);

                  // console.log(finaltotapcompleted, finaltotaltopics);

                  iterator['sylperc'] = Math.floor(
                    (finaltotapcompleted * 100) / finaltotaltopics
                  );
                }
              });
          } else {
            iterator['syll'] = [];
          }
        }
      } else {
        iterator['syll'] = [];
      }
    }

    console.log(list);

    let toStore = list.map((el: any) => el);
    this.store.dispatch(new TeacherDetailedList(toStore));
    return toStore;
    // this.store.dispatch(new teacherList(list));
  }

  calculatePercentage(assessmentlist: any[]) {
    // console.log(list);
    let total = 0;
    let score = 0;
    for (const iterator of assessmentlist) {
      try {
        total += iterator.total_marks;
        score += iterator.scored_marks;
      } catch {
        console.log('err');
      }
    }
    if (total > 0) {
      return Math.floor((score * 100) / total);
    } else {
      return 0;
    }
  }

  removeFromClassesDoc(list: any[], schoolid: string) {
    var batch = this.firestore.firestore.batch();
    for (const iterator of list) {
      let batchref = this.firestore
        .collection('schools')
        .doc(schoolid)
        .collection('classes')
        .doc(iterator.id).ref;
      batch.update(batchref, {
        teachers: firebase.firestore.FieldValue.arrayRemove(iterator.data),
      });
    }

    return batch.commit();
  }

  updateClassesDoc(list: any[], schoolid: string) {
    var batch = this.firestore.firestore.batch();
    for (const iterator of list) {
      let batchref = this.firestore
        .collection('schools')
        .doc(schoolid)
        .collection('classes')
        .doc(iterator.id).ref;
      batch.update(batchref, {
        teachers: firebase.firestore.FieldValue.arrayUnion(iterator.data),
      });
    }

    return batch.commit();
  }

  updateClassesArrayinTeacherDoc(tid: string, classesArray: any[]) {
    return this.firestore.collection('school_teacher').doc(tid).update({
      classes: classesArray,
    });
  }

  removeTeacher(id: string) {
    return this.firestore.collection('school_teacher').doc(id).update({
      deleted: true,
      deleted_at: new Date(),
      classes: [],
    });
  }

  decrementTeacherCount(sid: string) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .update({
        teachers: firebase.firestore.FieldValue.increment(-1),
      });
  }

  removeTeacherFromTT(doc: any, sid: string) {
    let ttid = doc.id;
    var batch = this.firestore.firestore.batch();
    for (const iterator of doc.days) {
      let batchref = this.firestore
        .collection('schools')
        .doc(sid)
        .collection('timetable')
        .doc(ttid)
        .collection('days')
        .doc(iterator.day).ref;
      batch.update(batchref, {
        periods: iterator.periods,
      });
    }

    return batch.commit();
  }
}
