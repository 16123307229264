import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { patch } from '@ngxs/store/operators';
import { SelectedSchoolTimetable } from './schoolTimetable.state';

@State<any>({
  name: 'SchoolTimetable',
  defaults: {
    schoolTT: [],
  },
})
@Injectable()
export class selectedSchoolTimetablestore {
  @Selector() static getSchoolTT(state: any) {
    return state.schoolTT;
  }
  constructor() {}

  @Action(SelectedSchoolTimetable)
  setUserData(ctx: StateContext<any>, { schoolTT }: SelectedSchoolTimetable) {
    ctx.setState(
      patch({
        schoolTT,
      })
    );
  }

  // @Action(removeUser)
  // removeSchools(ctx: StateContext<any>, { clear }: removeUser) {
  //   ctx.setState(patch({ userData: {} }));
  // }
}
