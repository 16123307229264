import { Directive } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';

@Directive({
  selector: '[usernameValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: UsernameDirective,
      multi: true,
    },
  ],
})
export class UsernameDirective implements AsyncValidator {
  constructor(private afs: AngularFirestore) {}
  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.afs
      .collection('usernames', (ref) =>
        ref.where('username', '==', control.value)
      )
      .valueChanges()
      .pipe(
        debounceTime(500),
        take(1),
        map((array: any[]) => {
          return array.length ? { userNameAvailable: false } : null;
        })
      );
  }
}
