import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-stepper',
  templateUrl: './vertical-stepper.component.html',
  styleUrls: ['./vertical-stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: VerticalStepperComponent}],

})
export class VerticalStepperComponent extends CdkStepper  {

  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
    console.log(this.selectedIndex);
  }

}
