import {
  NgxsFirestoreConnect,
  Emitted,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  State,
  Action,
  StateContext,
  NgxsOnInit,
  Selector,
  Store,
} from '@ngxs/store';
import { schoolAdmin } from 'src/app/auth/shared/models/school_admin';
import { Injectable } from '@angular/core';

import { RacesActions } from './school-admin.action';
import { append, patch } from '@ngxs/store/operators';
import { RacesFirestore } from '../../services/races.firestore';

export interface RacesStateModel {
  school_admin: schoolAdmin[];
}

@State<RacesStateModel>({
  name: 'school_admin_list',
  defaults: {
    school_admin: [],
  },
})
@Injectable()
export class schoolAdminState implements NgxsOnInit {
  @Selector() static getadminlist(state: RacesStateModel) {
    return state.school_admin;
  }

  constructor(
    private racesFS: RacesFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private store: Store
  ) {}

  ngxsOnInit(ctx: StateContext<RacesStateModel>) {
    // get school admins
    this.ngxsFirestoreConnect.connect(RacesActions.GetAllSchoolAdmin, {
      to: (payload) => {
        console.log(payload.payload, 'im in fetching admins');
        return this.racesFS.collection$((ref) =>
          ref
            .where('user_id', '==', payload.payload)
            .where('deleted', '==', false)
        );
      },
    });

  }

  @Action(StreamEmitted(RacesActions.GetAllSchoolAdmin))
  getAllEmitted(
    ctx: StateContext<RacesStateModel>,
    { action, payload }: Emitted<RacesActions.Get, schoolAdmin[]>
  ) {
    console.log(payload);
    ctx.setState(patch({ school_admin: payload }));
  }

  @Action(RacesActions.removeAdminlist)
  removeSchools(
    ctx: StateContext<any>,
    { schools }: RacesActions.removeAdminlist
  ) {
    ctx.setState(
      patch({
        school_admin: [],
      })
    );
  }

  @Action(RacesActions.schoolAdminsListNormal)
  addAdminlistNormal(
    ctx: StateContext<any>,
    { list }: RacesActions.schoolAdminsListNormal
  ) {
    ctx.setState(
      patch({
        school_admin: list,
      })
    );
  }

  @Action(RacesActions.AppendAdmin)
  appendSchoolAdmin(ctx: StateContext<any>, { payload }: RacesActions.AppendAdmin) {
    ctx.setState(
      patch({
        school_admin: append([payload])
      })
    );
  }
}
