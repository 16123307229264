import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { SelectedSchoolState } from '../store/school/selected-school.state';
import { finalize, map, switchMap } from 'rxjs/operators';
import { CommonService } from './common.service';
import { SchoolTeam, team_captain, team_incharge, team_student } from '../models/school_team.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { SnackbarService } from './snackbar.service';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  selectedPhotoFile!: File;
  progress: boolean = false;
  teamId!: any;

  teamPhotoUrl: string = '';
  teamProfileUploadTask!: AngularFireUploadTask;
  teamProfileUploadPercentage!: Observable<number | undefined>;
  teamProfileUploadSnapShot!: Observable<any>;

  currentSchoolDocId: string = '';
  selectedAdmin$ = this.store.select(SelectedSchoolState);

  constructor(
    private store: Store,
    private fireStorage: AngularFireStorage,
    private commonSrvc_: CommonService,
    private fireStore: AngularFirestore,
    private snackBarSrvc_:SnackbarService
  ) {
    let currentSchoolDetails$: Observable<any> = this.selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.commonSrvc_.getCurrentSchoolDetails(
            data.selectedAdmin.school_id
          );
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      this.currentSchoolDocId = el.id;
    });
  }

  fetchTeams() {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.created_at = data.created_at.toDate();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  createTeam(schoolTeam: SchoolTeam) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .add(schoolTeam);
  }

  setTeamDoc(schoolTeam: SchoolTeam) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .doc(this.teamId)
      .set(schoolTeam);
  }

  getFirebaseId() {
    let autogenUid = this.fireStore.createId();
    this.teamId = autogenUid;
    // return autogenUid;
  }

  uploadTeamProfileImage() {
    this.getFirebaseId();
    this.progress = true;
    const filePath = `teams/${this.currentSchoolDocId}_${this.teamId}.jpeg`;
    const ref = this.fireStorage.ref(filePath);
    this.teamProfileUploadTask = this.fireStorage.upload(
      filePath,
      this.selectedPhotoFile
    );
    this.teamProfileUploadPercentage =
      this.teamProfileUploadTask.percentageChanges();
    this.teamProfileUploadSnapShot = this.teamProfileUploadTask
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          await ref
            .getDownloadURL()
            .toPromise()
            .then((url: any) => {
              this.teamPhotoUrl = url;
              console.log(this.teamPhotoUrl);
              this.progress = false;
              this.snackBarSrvc_.openSnackBarTop(
                'Profile photo uploaded successfully',
                'Success'
              );
            })
            .catch((error: any) => {
              this.snackBarSrvc_.openSnackBarTop(error, 'Failure');
              this.progress = false;
            });
        })
      );
    // this.profileUploadSnapShot.subscribe();
  }

  updateMultipleStudentDocs(studentIds: any[], team_id: string) {
    let batch = this.fireStore.firestore.batch();
    // let reference = this.fireStore.collection('school_student')
    for (let i = 0; i < studentIds.length; i++) {
      const studentDocId = studentIds[i];
      let batchref = this.fireStore
        .collection('school_student')
        .doc(studentDocId).ref;
      batch.update(batchref, { team_id: team_id });
    }
    return batch.commit();
  }

  updateStudentDoc(studentDocId: any, team_id: string) {
    return this.fireStore
      .collection('school_student')
      .doc(studentDocId)
      .update({
        team_id: team_id,
      });
  }

  // updateMultipleTeacherDocs(teacherDocIds: any[], house_id: string) {
  //   let batch = this.fireStore.firestore.batch();
  //   // let reference = this.fireStore.collection('school_student')
  //   for (let i = 0; i < teacherDocIds.length; i++) {
  //     const teacherDocId = teacherDocIds[i];
  //     let batchref = this.fireStore
  //       .collection('school_teacher')
  //       .doc(teacherDocId).ref;
  //     batch.update(batchref, { house_id: house_id });
  //   }
  //   return batch.commit();
  // }

  updateStudentsArrayInTeamDoc(team_id: string, students: team_student[]) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .doc(team_id).ref;
    return docRef.update({
      students: firebase.firestore.FieldValue.arrayUnion(...students),
    });
  }

  // updateTeachersArrayInTeamDoc(team_id: string, teachers: house_teacher[]) {
  //   let docRef = this.fireStore
  //     .collection('schools')
  //     .doc(this.currentSchoolDocId)
  //     .collection('houses')
  //     .doc(team_id).ref;
  //   return docRef.update({
  //     teachers: firebase.firestore.FieldValue.arrayUnion(...teachers),
  //   });
  // }

  updateTeacherDoc(teacherDocId: string, teamId: string) {
    return this.fireStore
      .collection('school_teacher')
      .doc(teacherDocId)
      .update({
        team_id: teamId,
      });
  }

  // removeTeacherFromHouseDoc(house_id: any, teacher: house_teacher) {
  //   let docRef = this.fireStore
  //     .collection('schools')
  //     .doc(this.currentSchoolDocId)
  //     .collection('houses')
  //     .doc(house_id).ref;
  //   return docRef.update({
  //     teachers: firebase.firestore.FieldValue.arrayRemove(teacher),
  //   });
  // }

  clearTeamIdFromTeacherDoc(teacherDoc: string) {
    return this.fireStore
      .collection('school_teacher')
      .doc(teacherDoc)
      .update({ team_id: '' });
  }

  clearTeamIdFromStudentDoc(studentDoc: string) {
    return this.fireStore
      .collection('school_student')
      .doc(studentDoc)
      .update({ team_id: '' });
  }

  removeStudentFromTeamDoc(team_id: any, student: team_student) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .doc(team_id).ref;
    return docRef.update({
      students: firebase.firestore.FieldValue.arrayRemove(student),
    });
  }

  addCaptainToTeamDoc(team_id: any, captian: team_captain) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .doc(team_id).ref;
    return docRef.update({
      captains: firebase.firestore.FieldValue.arrayUnion(captian),
    });
  }

  updateCaptainsArrayInTeamDoc(
    team_captains: team_captain[],
    team_id: string
  ) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .doc(team_id)
      .update({
        captains: team_captains,
      });
  }

  updateInchargesArrayInTeamDoc(
    team_incharges: team_incharge[],
    team_id: string
  ) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .doc(team_id)
      .update({
        incharges: team_incharges,
      });
  }

  addInchargeToTeamDoc(team_id: any, incharge: team_incharge) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .doc(team_id).ref;
    return docRef.update({
      incharges: firebase.firestore.FieldValue.arrayUnion(incharge),
    });
  }

  removeCaptianFromTeamDoc(team_id: any, captian: team_captain) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .doc(team_id).ref;
    return docRef.update({
      captains: firebase.firestore.FieldValue.arrayRemove(captian),
    });
  }

  removeInchargeFromTeamDoc(team_id: any, incharge: team_incharge) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('teams')
      .doc(team_id).ref;
    return docRef.update({
      incharges: firebase.firestore.FieldValue.arrayRemove(incharge),
    });
  }
}
