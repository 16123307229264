<div class="container">
  <ng-container *ngIf="typeOfEvent=='' && data.id=='' && data.edit==false">
    <h2 class="title">Create Events</h2>
    <div class="event_cards grid xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-3">
      <div class="card1 card_exam">
        <div class="card-body">
          <h5 class="card-title">Exams</h5>
          <button class="btn card-button" (click)="createEventtype('exams')">Schedule Exam</button>
        </div>
      </div>
      <div class="card1 card_event">
        <div class="card-body">
          <h5 class="card-title">Events</h5>
          <button class="btn card-button" (click)="createEventtype('activities')">Create Event</button>
        </div>
      </div>
      <div class="card1 card_holiday">
        <div class="card-body">
          <h5 class="card-title">Holiday</h5>
          <button class="btn card-button" (click)="createEventtype('holidays')">Add Holiday</button>
        </div>
      </div>
    </div>

  </ng-container>

  <!-- shedule exam -->
  <ng-container *ngIf="typeOfEvent!=''|| (patch==true && data.edit==true)">
    <div class="shedule_exam_div">
      <h2 class="title">{{typeOfEvent=='holidays'?'Add':'Schedule'}}
        {{typeOfEvent=='activities'?'Event':typeOfEvent=='exams'?'Exam':'Holiday'}}</h2>
      <form [formGroup]="createEventForm">
        <div class="add_title">
          <h6>Add Title*</h6>
          <mat-form-field appearance="legacy" class="input_field">
            <input matInput required formControlName="title">
            <mat-error *ngIf="title?.errors?.required || title?.dirty||title.touched || submitted"
              style="font-family: Nunito !important;">Please enter a valid
              title</mat-error>
          </mat-form-field>
        </div>
        <div class="shedule_div">
          <h4>Schedule</h4>
          <div class="select_shedule">
            <div class="from">
              <p>From</p>
              <div class="selectors">
                <input mat-stroked-button (click)="picker.open()" [matDatepicker]=" picker" id="asd"
                  formControlName="from" [ngClass]="{'borderRed': fromValue?.errors?.required && fromValue?.dirty}">
                <mat-error *ngIf="(fromValue?.errors?.required && fromValue?.dirty)"
                  style="font-family: Nunito !important;">Please enter a
                  valid Start date</mat-error>
                <input [ngxTimepicker]="fromTimePick" formControlName="fromTime" *ngIf="typeOfEvent!='holidays'"
                  [required]="typeOfEvent!='holidays'"
                  [ngClass]="{'borderRed': startTime?.errors?.required && startTime?.dirty}">
                <mat-error *ngIf="startTime?.errors?.required && startTime?.dirty"
                  style="font-family: Nunito !important;">Please enter a
                  valid start time</mat-error>
                <ngx-material-timepicker #fromTimePick (timeSet)="startTimeSet($event)"></ngx-material-timepicker>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>
            <div class="to">
              <p>To</p>
              <div class="selectors">
                <input mat-stroked-button (click)="picker1.open()" [matDatepicker]="picker1" id="asd"
                  formControlName="to" [min]="fromValue.value"
                  [ngClass]="{'borderRed': toValue?.errors?.required && toValue?.dirty}" required>
                <mat-error *ngIf="toValue?.errors?.required && toValue?.dirty " style="font-family: Nunito !important;">
                  Please enter a valid end date</mat-error>
                <input [ngxTimepicker]="toTimePick" formControlName="toTime" *ngIf="typeOfEvent!='holidays'"
                  [ngClass]="{'borderRed': endTime?.errors?.required && endTime?.dirty }"
                  [required]="typeOfEvent!='holidays'">

                <mat-datepicker #picker1></mat-datepicker>
                <ngx-material-timepicker #toTimePick (timeSet)="endTimeSet($event)"></ngx-material-timepicker>
                <mat-error *ngIf="endTime?.errors?.required&&endTime?.dirty" style="font-family: Nunito !important;">
                  Please enter a valid end time</mat-error>
              </div>
            </div>
          </div>

        </div>
      </form>
      <div class="select_classes">
        <div class="head">
          <label class="container"><span class="text">Same For All Classes</span>
            <input type="checkbox" (change)="sameForAllClasses($event)"
              [checked]="selectedClasses.length==schoolClasses.length" />
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="class_list">
          <div id="ck-button" *ngFor="let class of schoolClasses; let j = index"
            >
            <label>
              <input type="checkbox" [value]="class?.id" [checked]="isChecked(class)"
                (change)="onClickingClass($event,class)" /><span style="padding: 12px"> Class {{
                class?.name | titlecase
                }}&nbsp;{{class.section|titlecase}}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="footer">
        <button mat-stroked-button class="btn_cancel" mat-dialog-close>Cancel</button>
        <button mat-stroked-button class="btn_save" (click)="saveAndProceed()">Save and Proceed</button>

      </div>

    </div>
  </ng-container>

  <!-- after creating event  -->
  <ng-container *ngIf="data.edit==true && data.id=='' && patch==false">
    <div class="shedule_review_div">
      <h2 class="title">{{data?.date|date:'longDate'}}</h2>
      <div class="exams">
        <div class="title_div">
          <h4>Exams</h4>
          <p>{{examEvents?.length==0?'0':examEvents?.length}} exams</p>
        </div>
        <div class="sheduled_exams" *ngFor="let exam of examEvents">
          <div class="sheduled_data">
            <div class="time">
              <p class="active">{{exam.from|date:'shortTime'}}</p>
              <p>{{exam.to|date:'shortTime'}}</p>
            </div>
            <!-- <div class="progress">
              <div class="circle"></div>
              <div class="progress_line">
                <div class="progress_line_inner"></div>
              </div>
            </div> -->
            <div class="shedule_details">
              <h3>{{exam.title|titlecase}}</h3>
              <h2>{{exam.from|date:'longDate'}} to {{exam.from|date:'longDate'}}</h2>
              <p class="class_names">
                <span *ngFor="let class of gettingClassesofEvents(exam.class_ids); let isLast = last">{{ class.name |
                  titlecase }} {{class.section | titlecase}} {{ isLast ? "" : ", " }} </span>
              </p>
            </div>

          </div>
          <div class="edit">
            <img src="../../../../assets/icons/edit-icon.svg  " alt="" (click)="onEditClick(exam)">
            <img src="../../../../assets/icons/delete-icon.svg" alt="" (click)="deleteEvent(exam)">
          </div>
        </div>
        <!-- <div class="action_btn_exam">
          <button mat-stroked-button>Schedule Exam</button>
        </div> -->
      </div>
      <div class="hr_line"></div>
      <!-- shedule events -->
      <div class="exams">
        <div class="title_div">
          <h4>Events</h4>
          <p>{{activities?.length==0?'0':activities?.length}} events</p>
        </div>
        <div class="sheduled_exams" *ngFor="let event of activities">
          <div class="sheduled_data">
            <div class="time">
              <p class="active">{{event.from|date:'shortTime'}}</p>
              <p>{{event.to|date:'shortTime'}}</p>
            </div>
            <!-- <div class="progress">
              <div class="circle"></div>
              <div class="progress_line">
                <div class="progress_line_inner"></div>
              </div>
            </div> -->
            <div class="shedule_details">
              <h3>{{event.title|titlecase}}</h3>
              <h2>{{event.from|date:'longDate'}} to {{event.to|date:'longDate'}}</h2>
              <p class="class_names">
                <span *ngFor="let class of gettingClassesofEvents(event.class_ids); let isLast = last">{{ class.name |
                  titlecase }} {{class.section | titlecase}} {{ isLast ? "" : ", " }} </span>
              </p>
            </div>

          </div>
          <div class="edit">
            <img src="../../../../assets/icons/edit-icon.svg  " alt="" (click)="onEditClick(event)">
            <img src="../../../../assets/icons/delete-icon.svg" alt="" (click)="deleteEvent(event)">
          </div>
        </div>
        <!-- <div class="action_btn_exam">
          <button mat-stroked-button>Schedule Exam</button>
        </div> -->
      </div>
      <div class="hr_line"></div>
      <div class="exams">
        <div class="title_div">
          <h4>Holidays</h4>
          <p>{{holidays?.length==0?'0':holidays?.length}} holidays</p>
        </div>
        <div class="sheduled_exams" *ngFor="let holiday of holidays">
          <div class="sheduled_data">
            <div class="time">
              <p class="active">{{holiday.from|date:'shortTime'}}</p>
              <p>{{holiday.to|date:'shortTime'}}</p>
            </div>
            <!-- <div class="progress">
              <div class="circle"></div>
              <div class="progress_line">
                <div class="progress_line_inner"></div>
              </div>
            </div> -->
            <div class="shedule_details">
              <h3>{{holiday.title|titlecase}}</h3>
              <h2>{{holiday.from|date:'longDate'}} to {{holiday.to|date:'longDate'}}</h2>
              <p class="class_names">
                <span *ngFor="let class of gettingClassesofEvents(holiday.class_ids); let isLast = last">{{ class.name |
                  titlecase }} {{class.section | titlecase}} {{ isLast ? "" : ", " }} </span>
              </p>
            </div>

          </div>
          <div class="edit">
            <img src="../../../../assets/icons/edit-icon.svg  " alt="" (click)="onEditClick(holiday)">
            <img src="../../../../assets/icons/delete-icon.svg" alt="" (click)="deleteEvent(holiday)">
          </div>
        </div>
        <!-- <div class="action_btn_exam">
          <button mat-stroked-button>Schedule Exam</button>
        </div> -->
      </div>

    </div>
  </ng-container>

</div>

