import {
  NgxsFirestoreConnect,
  Emitted,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  State,
  Action,
  StateContext,
  NgxsOnInit,
  Selector,
  Store,
} from '@ngxs/store';
import { schoolAdmin } from 'src/app/auth/shared/models/school_admin';
import { Injectable } from '@angular/core';

import { patch } from '@ngxs/store/operators';
import { RacesFirestore } from '../../services/races.firestore';
import { teacherRoot } from '../../models/teacher_root';
import { TeachersActions } from './teachers.action';
import { TeacherFirestore } from './teacher.firestore';

export interface teacherStateModel {
  teachers_list: teacherRoot[];
}

@State<teacherStateModel>({
  name: 'teachers_list',
  defaults: {
    teachers_list: [],
  },
})
@Injectable()
export class teacherState implements NgxsOnInit {
  @Selector() static getteacherlist(state: teacherStateModel) {
    return state.teachers_list;
  }

  constructor(
    private teacherFs: TeacherFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect
  ) {}

  ngxsOnInit(ctx: StateContext<teacherStateModel>) {
    console.log('new connction');
    //conenct to school teacher collection
    this.ngxsFirestoreConnect.connect(TeachersActions.GetAllTeachers, {
      to: (payload) => {
        console.log('teacher connection established', payload.payload);
        return this.teacherFs.collection$((ref) =>
          ref
            .where('school_id', '==', payload.payload)
            .where('deleted', '==', false)
            .where('active', '==', true)
        );
      },
    });
  }

  @Action(StreamEmitted(TeachersActions.GetAllTeachers))
  getAllEmitted(
    ctx: StateContext<teacherStateModel>,
    { action, payload }: Emitted<TeachersActions.Get, teacherRoot[]>
  ) {
    ctx.setState(patch({ teachers_list: payload }));
  }

  @Action(TeachersActions.removeTeacherlist)
  removeTeachers(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        teachers_list: [],
      })
    );
  }
}
