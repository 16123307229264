import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { cloneDeep, union } from 'lodash';
import { team_captain, team_student } from 'src/app/shared/models/school_team.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SchoolHouseService } from 'src/app/shared/services/school-house.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { TeamService } from 'src/app/shared/services/team.service';
import { StudentsAndTeachersState } from 'src/app/shared/store/students_teachers/students_teachers.state';

@Component({
  selector: 'app-team-students-list-dialog',
  templateUrl: './team-students-list-dialog.component.html',
  styleUrls: ['./team-students-list-dialog.component.scss']
})
export class TeamStudentsListDialogComponent implements OnInit {
  selected_sorting_option = 0;
  schoolStudentList: any[] = [];
  gradesArray: any[] = [];
  filteredStudents: any[] = [];
  selectedStudentsArray: any[] = [];
  searchInput: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private commonSrvc_: CommonService,
    // private schoolHouseSrvc_: SchoolHouseService,
    private schoolTeamSrvc_:TeamService,
    private snackBarSrvc_: SnackbarService,
    private dialogSrvc_: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // console.log(this.data);
    this.store
      .select(StudentsAndTeachersState.getSchoolStudents)
      .subscribe((el: any) => {
        console.log(el);
        let stdList = cloneDeep(el);
        this.gradesArray = union(stdList.map((a: any) => a.grade));
        this.gradesArray = this.commonSrvc_.sortClassForExams(this.gradesArray);
        console.log(this.gradesArray);
        this.schoolStudentList = cloneDeep(el);
        this.schoolStudentList = this.schoolStudentList.filter(
          (student: any) =>
            !student.hasOwnProperty('team_id') || student.team_id == ''
        );
        this.filterStudentWRTGrade(this.gradesArray[0], 0);
      });
  }

  filterStudentWRTGrade(grade: string, index: number) {
    this.selected_sorting_option = index;
    this.filteredStudents = this.schoolStudentList.filter(
      (student: any) => student.grade.toLowerCase() == grade.toLowerCase()
    );
    console.log(this.filteredStudents);
  }

  popPushStudent(id: string) {
    if (this.selectedStudentsArray.includes(id)) {
      let index = this.selectedStudentsArray.findIndex(
        (sid: string) => sid == id
      );
      this.selectedStudentsArray.splice(index, 1);
    } else {
      this.selectedStudentsArray.push(id);
      this.selectedStudentsArray = union(this.selectedStudentsArray);
    }
    console.log(this.selectedStudentsArray);
  }

  popPushCaptain(id: string) {
    if (this.selectedStudentsArray.length == 1) {
      this.selectedStudentsArray = [];
    } else {
    }
    this.selectedStudentsArray.push(id);
  }

  searchFilter(event: any) {
    let students = cloneDeep(this.filteredStudents);
    if (!event) {
      this.filteredStudents = students;
    }
    let searchInput = event.toLowerCase().trim().replace(/ /g, '');
    this.filteredStudents = students.filter((student: any) => {
      let searchString: any = '';
      searchString = student.userDetails.name.join('').trim().replace(/ /g, '');
      return searchString.includes(searchInput);
    });
  }

  onSaving() {
    //add selectedHousedoc id to student doc
    let students: team_student[] = [];
    for (let i = 0; i < this.selectedStudentsArray.length; i++) {
      const studentDocId = this.selectedStudentsArray[i];
      let student = this.schoolStudentList.find(
        (student: any) => student.id == studentDocId
      );
      if (!!student) {
        let teamStudent: team_student = {
          class_id:
            student.class_ref.split('/')[
              student.class_ref.split('/').length - 1
            ],
          grade: student.grade.toLowerCase(),
          roll: student.roll,
          section: student.section.toLowerCase(),
          uid: student.user_id,
          doc_id: studentDocId,
        };
        students.push(teamStudent);
      }
    }
    console.log(students);
    this.commonSrvc_.showSpinner = true;

    this.schoolTeamSrvc_
      .updateStudentsArrayInTeamDoc(this.data.id, students)
      .then(() => {
        this.snackBarSrvc_.openSnackBarTop(
          'Teams Doc Updated Successfully',
          'Success!!'
        );
        this.schoolTeamSrvc_
          .updateMultipleStudentDocs(this.selectedStudentsArray, this.data.id)
          .then(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Student Documents updated successfully',
              'Success!!'
            );
            this.commonSrvc_.showSpinner = false;
            this.dialogSrvc_.close();
            this.router.navigate(['layout/community/teams/list']);
          })
          .catch(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Student Documents updation failed',
              'Failure'
            );
          });
      })
      .catch((err) => {
        this.snackBarSrvc_.openSnackBarTop(
          `Teams Doc Updation failed due to ${err}`,
          'Failure'
        );
      });
    return;
  }

  onSavingCaptainViceCaptain() {
    let selectedCaptainDocId = this.selectedStudentsArray[0];
    let captain: any = this.schoolStudentList.find(
      (student: any) => student.id == selectedCaptainDocId
    );
    let teamCaptian: team_captain = {
      class_id:
        captain.class_ref.split('/')[captain.class_ref.split('/').length - 1],
      doc_id: selectedCaptainDocId,
      grade: captain.grade.toLowerCase(),
      role: this.data.role.toLowerCase(),
      roll: captain.roll,
      section: captain.section.toLowerCase(),
      uid: captain.user_id,
    };
    console.log(teamCaptian);
    // return
    if (this.data.action == 'add') {
      this.commonSrvc_.showSpinner = true;
      this.schoolTeamSrvc_
        .addCaptainToTeamDoc(this.data.id, teamCaptian)
        .then(() => {
          this.snackBarSrvc_.openSnackBarTop(
            'Captain added to Team Doc',
            'Success'
          );
          this.schoolTeamSrvc_
            .updateStudentDoc(selectedCaptainDocId, this.data.id)
            .then(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Updated Student Doc',
                'Success'
              );
              this.commonSrvc_.showSpinner = false;
              this.dialogSrvc_.close();
              this.router.navigate(['layout/community/teams/list']);
            })
            .catch(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Student Document updation failed',
                'Failure'
              );
              this.commonSrvc_.showSpinner = false;
              this.dialogSrvc_.close();
            });
        })
        .catch(() => {
          this.snackBarSrvc_.openSnackBarTop(
            'Captain addition has been failed',
            'Failure'
          );
          this.commonSrvc_.showSpinner = false;
          this.dialogSrvc_.close();
        });
    } else if (this.data.action == 'edit') {
      console.log(this.data.captains);
      this.commonSrvc_.showSpinner = true;
      let team_captains: any[] = this.data.captains;
      team_captains = team_captains.map((hCap: any) => {
        let obj: team_captain = {
          class_id: hCap.class_id,
          doc_id: hCap.doc_id,
          grade: hCap.grade,
          role: hCap.role,
          roll: hCap.roll,
          section: hCap.section,
          uid: hCap.uid,
        };
        return obj;
      });
      let index = team_captains.findIndex(
        (cap: any) => cap.role == this.data.role
      );
      team_captains.splice(index, 1, teamCaptian);
      this.schoolTeamSrvc_
        .updateStudentDoc(this.data.captian_doc_id, '')
        .then(() => {
          this.snackBarSrvc_.openSnackBarTop(
            'Student Document updated successfully',
            'Success'
          );
          this.schoolTeamSrvc_
            .updateCaptainsArrayInTeamDoc(team_captains, this.data.id)
            .then(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Captains Array updated successfully',
                'Success'
              );
              this.schoolTeamSrvc_
                .updateStudentDoc(selectedCaptainDocId, this.data.id)
                .then(() => {
                  this.snackBarSrvc_.openSnackBarTop(
                    'Student Doc updated successfully',
                    'Success'
                  );
                  this.commonSrvc_.showSpinner = false;
                  this.dialogSrvc_.close();
                  this.router.navigate(['layout/community/teams/list']);
                })
                .catch(() => {
                  this.snackBarSrvc_.openSnackBarTop(
                    'Student Doc updation failed',
                    'Failure'
                  );
                  this.commonSrvc_.showSpinner = false;
                  this.dialogSrvc_.close();
                });
            })
            .catch(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Captains Array updation failed',
                'Failure'
              );
              this.commonSrvc_.showSpinner = false;
              this.dialogSrvc_.close();
            });
        })
        .catch(() => {
          this.snackBarSrvc_.openSnackBarTop(
            'Student Document updation failed',
            'Failure'
          );
          this.commonSrvc_.showSpinner = false;
          this.dialogSrvc_.close();
        });
      console.log(team_captains);
    }
  }
}
