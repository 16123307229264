import { State, Action, StateContext, NgxsOnInit, Selector } from '@ngxs/store';

import { tap } from 'rxjs/operators';
import {
  NgxsFirestoreConnect,
  Connected,
  Emitted,
  Disconnected,
  StreamConnected,
  StreamEmitted,
  StreamDisconnected,
  Page,
} from '@ngxs-labs/firestore-plugin';

import { patch, insertItem, iif, updateItem, append } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';

import { SchoolActions } from './school-list.action';
import { schoolForStore } from 'src/app/auth/shared/models/schoolForStore';
import { SchoolFirestore } from '../../services/school.firestore';
import { AngularFirestore } from '@angular/fire/firestore';
import { school } from 'src/app/auth/shared/models/school';

export interface SchoolStateModel {
  schools: schoolForStore[];
  activeSchools: schoolForStore[];
}

@State<SchoolStateModel>({
  name: 'schoolList',
  defaults: {
    schools: [],
    activeSchools: [],
  },
})
@Injectable()
export class schoolListState implements NgxsOnInit {
  @Selector() static schools(state: SchoolStateModel) {
    return state.schools;
  }
  @Selector() static activeRaces(state: SchoolStateModel) {
    return state.activeSchools;
  }

  constructor(
    private schoolFS: SchoolFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private firestore: AngularFirestore
  ) {}

  ngxsOnInit(ctx: StateContext<SchoolStateModel>) {
    // this.ngxsFirestoreConnect.connect(Page(SchoolActions.GetSchool), {
    //   to: (action) => this.schoolFS.doc$(action.payload),
    //   trackBy: (action) => action.payload,
    // });

    this.ngxsFirestoreConnect.connect(SchoolActions.GetSchool, {
      to: (action) => this.schoolFS.doc$(action.payload),
      trackBy: (action) => action.payload,
    });
  }

  @Action(StreamEmitted(SchoolActions.GetSchool))
  getEmitted(
    ctx: StateContext<SchoolStateModel>,
    { action, payload }: Emitted<SchoolActions.GetSchool, schoolForStore>
  ) {
    console.log(payload);
    if (payload) {
      ctx.setState(
        patch<SchoolStateModel>({
          schools: iif(
            (races) => !!races!.find((race) => race!.id === payload.id),
            updateItem((race) => race!.id === payload.id, patch(payload)),
            insertItem(payload)
          ),
        })
      );
    }
  }

  @Action(SchoolActions.removeSchools)
  removeSchools(
    ctx: StateContext<any>,
    { schools }: SchoolActions.removeSchools
  ) {
    ctx.setState(
      patch({
        schools: [],
      })
    );
  }

  @Action(SchoolActions.AppendSchool)
  appendSchool(ctx: StateContext<any>, { payload }: SchoolActions.AppendSchool) {
    ctx.setState(
      patch({
        schools: append([payload])
      })
    );
  }
}
