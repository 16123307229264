export class selectedGradeForFeeManagmentNaviagtion {
  static readonly type = '[selectedGradeForFeeManagmentNaviagtion] fms';
  constructor(public data: any) {}
}

export class selectedGradeSectionForFeeManagmentNaviagtion {
  static readonly type = '[selectedGradeSectionForFeeManagmentNaviagtion] fms';
  constructor(public data: any) {}
}

export class selectedStudentForFeeManagmentNaviagtion {
  static readonly type = '[selectedStudentForFeeManagmentNaviagtion] fms';
  constructor(public data: any) {}
}
