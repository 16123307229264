import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { patch } from '@ngxs/store/operators';
import {
  selectedTeacherForTimetableNaviagtion,
  selectedTimetableForNaviagtion,
} from './temperoryTimetableNavigation.state';

@State<any>({
  name: 'SelectTimtableForNavigation',
  defaults: {
    ttData: {},
    teacher: {},
  },
})
@Injectable()
export class SelectTimtableForNavigation {
  @Selector() static getSelectedClassAndTimetable(state: any) {
    return state.ttData;
  }

  @Selector() static getSelectedTeacherforTimetable(state: any) {
    return state.teacher;
  }
  constructor() {}

  @Action(selectedTimetableForNaviagtion)
  setUserData(
    ctx: StateContext<any>,
    { ttData }: selectedTimetableForNaviagtion
  ) {
    ctx.setState(
      patch({
        ttData,
      })
    );
  }

  @Action(selectedTeacherForTimetableNaviagtion)
  setSelectedTeacherData(
    ctx: StateContext<any>,
    { teacher }: selectedTeacherForTimetableNaviagtion
  ) {
    ctx.setState(
      patch({
        teacher,
      })
    );
  }

  // @Action(removeUser)
  // removeSchools(ctx: StateContext<any>, { clear }: removeUser) {
  //   ctx.setState(patch({ userData: {} }));
  // }
}
