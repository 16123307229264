import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LibraryService } from 'src/app/shared/services/library.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent implements OnInit {
  category: FormControl = new FormControl('', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public librarySrvc_: LibraryService,
    private snackBarSrvc: SnackbarService,
    private dialogSrvc_: DialogService
  ) {}

  ngOnInit(): void {}

  onSaving() {
    // this.category.invalid
    if (this.data == null) {
      let categories = this.librarySrvc_.categories.map((a: string) =>
        a.toLowerCase()
      );
      if (categories.includes(this.category.value.toLowerCase())) {
        this.snackBarSrvc.openSnackBarTop(
          'Category is already present',
          'Add another'
        );
      } else {
        this.librarySrvc_.categories.push(this.category.value);
        this.dialogSrvc_.close();
      }
    } else {
      let categories = this.librarySrvc_.selectedSchoolLibraryCategories.map(
        (a: string) => a.toLowerCase()
      );
      if (categories.includes(this.category.value.toLowerCase())) {
        this.snackBarSrvc.openSnackBarTop(
          'Category is already present',
          'Add another'
        );
      } else {
        this.librarySrvc_.selectedSchoolLibraryCategories.push(
          this.category.value
        );
        this.dialogSrvc_.close();
      }
    }
  }
}
