import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Router } from '@angular/router';
import { LibraryFine } from 'src/app/shared/models/library.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LibraryService } from 'src/app/shared/services/library.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-library-add-fine',
  templateUrl: './library-add-fine.component.html',
  styleUrls: ['./library-add-fine.component.scss'],
})
export class LibraryAddFineComponent implements OnInit {
  amount: FormControl = new FormControl('', [Validators.required]);
  radioFormControl: FormControl = new FormControl(false, [Validators.required]);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarSrvc_: SnackbarService,
    private dialogSrvc_: DialogService,
    private librarySrvc_: LibraryService,
    private commonSrvc_: CommonService
  ) {}

  ngOnInit(): void {
    if (this.data.title == 'Update Fine') {
      this.radioFormControl.patchValue(true);
      this.amount.patchValue(this.data.fine.amount);
    }
  }

  onSave() {
    if (this.data == 'Add Fine') {
      if (this.radioFormControl.value == false) {
        this.dialogSrvc_.close();
      } else {
        let fine: LibraryFine = {
          amount: +this.amount.value,
          currency: 'inr',
          type: 'day',
        };
        this.commonSrvc_.showSpinner = true;
        this.librarySrvc_
          .updateFineSystem(this.data.libraryId, fine)
          .then(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Fine System updated successfully',
              'Success'
            );
            this.commonSrvc_.showSpinner = false;
            this.dialogSrvc_.close();
          })
          .catch(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Fine System updated failed',
              'Failure'
            );
            this.commonSrvc_.showSpinner = false;
            this.dialogSrvc_.close();
          });
      }
    } else {
      if (this.radioFormControl.value == false) {
        this.commonSrvc_.showSpinner = true;
        this.librarySrvc_
          .updateFineSystem(this.data.libraryId, null)
          .then(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Fine System updated successfully',
              'Success'
            );
            this.commonSrvc_.showSpinner = false;
            this.dialogSrvc_.close();
          })
          .catch(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Fine System updated failed',
              'Failure'
            );
            this.commonSrvc_.showSpinner = false;
            this.dialogSrvc_.close();
          });
      } else {
        let fine: LibraryFine = {
          amount: +this.amount.value,
          currency: 'inr',
          type: 'day',
        };
        this.commonSrvc_.showSpinner = true;
        this.librarySrvc_
          .updateFineSystem(this.data.libraryId, fine)
          .then(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Fine System updated successfully',
              'Success'
            );
            this.commonSrvc_.showSpinner = false;
            this.dialogSrvc_.close();
          })
          .catch(() => {
            this.snackBarSrvc_.openSnackBarTop(
              'Fine System updated failed',
              'Failure'
            );
            this.commonSrvc_.showSpinner = false;
            this.dialogSrvc_.close();
          });
      }
    }
  }
}
