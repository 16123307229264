import { splitAtColon } from '@angular/compiler/src/util';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { getCountries } from 'libphonenumber-js';
import { Subscription } from 'rxjs';
import { docUser } from 'src/app/auth/shared/models/docUser';
import { schoolAdmin } from 'src/app/auth/shared/models/school_admin';
import { UserCheckService } from 'src/app/auth/shared/services/user-check.service';
import { studentRoot } from 'src/app/shared/models/student_root';
import { teacherRoot } from 'src/app/shared/models/teacher_root';
import { ClassAndStudentsService } from 'src/app/shared/services/classandstudents.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DynamiclinkService } from 'src/app/shared/services/dynamiclink.service';
import { SchoolOnboardService } from 'src/app/shared/services/school-onboard.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ClassListState } from 'src/app/shared/store/class-list/class-list.state';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-onboard-dialog',
  templateUrl: './onboard-dialog.component.html',
  styleUrls: ['./onboard-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardDialogComponent implements OnInit, OnDestroy {
  addTeacherForm: FormGroup = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    email: new FormControl('', [
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    ]),
    gender: new FormControl('', [Validators.required]),
  });

  studentClass!: any;

  addStudentForm: FormGroup = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    class: new FormControl('', [Validators.required]),
    rollNumber: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    ]),
    gender: new FormControl('', [Validators.required]),
  });

  addAdminForm: FormGroup = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    role: new FormControl('', [Validators.required]),
    roleName: new FormControl({ disabled: true, value: '' }, [
      Validators.required,
    ]),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    ]),
    gender: new FormControl('', [Validators.required]),
  });
  matcher = new MyErrorStateMatcher();

  flags = {
    teacher: false,
    admin: false,
    student: false,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private onBoardServ_: SchoolOnboardService,
    private common: CommonService,
    private snackbar: SnackbarService,
    private usercheckService_: UserCheckService,
    private firestore: AngularFirestore,
    private dialogserv_: DialogService,
    private store: Store,
    private classandStudentSrvc_: ClassAndStudentsService,
    private dynamicSrvc_: DynamiclinkService
  ) {}

  loading = false;
  selectedClassStudents: any[];
  selectedClass: any;
  copied = false;
  schoolid: string = '';
  schoolClasses: any[];
  submitted = false;
  rollnoExistes = false;
  patchClasses: any[] = [];
  classListSub: Subscription;
  currentSchoolDetailsSub: Subscription;

  ngOnInit(): void {
    console.log(this.data);
    this.classListSub = this.store
      .select(ClassListState)
      .subscribe((el: any) => {
        this.schoolClasses = JSON.parse(JSON.stringify(el.classList));
        console.log(this.schoolClasses);

        // sorting
        const collator = new Intl.Collator('en', {
          numeric: true,
          sensitivity: 'base',
        });
        this.schoolClasses = this.schoolClasses.sort((a, b) =>
          collator.compare(`${a.name}${a.section}`, `${b.name}${b.section}`)
        );
      });
    if (this.data.class) {
      this.patchClassForStudent(this.data.class);
    }
  }

  getSchoolid() {
    let store = this.store.snapshot();
    let schoolid = store.SelectedAdmin?.selectedAdmin?.school_id;
    return schoolid;
  }

  get teacherForm() {
    return this.addTeacherForm.controls;
  }

  get TeacherMailVaild() {
    return this.teacherForm.email.valid;
  }

  get teacherMobileValid() {
    return this.teacherForm.phone.valid;
  }

  get studentForm() {
    return this.addStudentForm.controls;
  }

  print() {
    this.copied = false;
    console.log(this.studentClass);
  }

  get adminForm() {
    return this.addAdminForm.controls;
  }

  patchClassForStudent(clas1: any) {
    // this.schoolClasses = [];

    // this.schoolClasses.push(obj);
    this.selectedClass = this.schoolClasses.filter(
      (clas) => clas.id == clas1
    )[0];
    this.addStudentForm.get('class')?.setValue(this.selectedClass);
    this.studentClass = this.selectedClass;
    this.selectedClassStudents = this.selectedClass.studentlist;
    let maxRoll = 0;
    if (this.selectedClassStudents.length) {
      let maxRoll = Math.max.apply(
        Math,
        this.selectedClassStudents.map(function (o) {
          return o.roll;
        })
      );
      console.log(maxRoll);
      this.addStudentForm.get('rollNumber')?.setValue(maxRoll + 1);
    } else {
      this.addStudentForm.get('rollNumber')?.setValue(maxRoll + 1);
    }
  }

  async openManual(type: any) {
    if (type.name == 'admin') {
      this.flags = {
        teacher: false,
        admin: true,
        student: false,
      };
    } else if (type.name == 'teacher') {
      this.flags = {
        teacher: true,
        admin: false,
        student: false,
      };
    } else {
      this.flags = {
        teacher: false,
        admin: false,
        student: true,
      };

      // get classes list here
      let schoolid = this.getSchoolid();
      this.currentSchoolDetailsSub = this.common
        .getCurrentSchoolDetails(schoolid)
        .subscribe(async (el) => {
          console.log(this.schoolClasses);

          // this.schoolClasses = this.schoolClasses.sort((a, b) =>
          //   a.section.localeCompare(b.section)
          // );

          // this.schoolClasses = this.schoolClasses.sort((a, b) =>
          //   a.name.localeCompare(b.name)
          // );

          // sorting
          const collator = new Intl.Collator('en', {
            numeric: true,
            sensitivity: 'base',
          });
          this.schoolClasses = this.schoolClasses.sort((a, b) =>
            collator.compare(`${a.name}${a.section}`, `${b.name}${b.section}`)
          );
        });
    }
  }

  createDynamicLinkForStudent() {
    let store = this.store.snapshot();
    let schoolid = store.SelectedAdmin?.selectedAdmin?.school_id;
    let schoolList = store.schoolList.schools;
    let selectedSchool = schoolList.find((el: any) => el.id == schoolid);
    let role = 'student';
    let schoolName = selectedSchool.name.join(' ');
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 10);
    console.log(tomorrow.getDate());
    let day =
      tomorrow.getDate() > 9
        ? `${tomorrow.getDate()}`
        : `0${tomorrow.getDate()}`;
    let month =
      tomorrow.getMonth() + 1 > 9
        ? `${tomorrow.getMonth() + 1}`
        : `0${tomorrow.getMonth() + 1}`;
    let year = `${tomorrow.getFullYear()}`;
    let ddmmyy = `${day}${month}${year}`;
    console.log(ddmmyy);
    let url: string = this.dynamicSrvc_.getUrl(role);
    console.log(url);
    let requestUrl = '';
    requestUrl = `${url}invitation?validity=${ddmmyy}&type=school&id=${schoolid}_${this.studentClass.id}&role=${role}&name=${schoolName}`;
    this.dynamicSrvc_.getDynamicUrl(requestUrl).subscribe((data: any) => {
      let shareLink = data.shortLink;
      let whatsalink = `https://web.whatsapp.com/send?text=Use ${shareLink} link to join ${schoolName.toUpperCase()} -Link is valid for 10 days`;

      this.copied = true;
      navigator.clipboard.writeText(shareLink);
    });
  }

  copylink() {
    this.copied = true;

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(this.data.shareLink);
  }
  async formAction(data: any) {
    this.submitted = true;

    if (data.name == 'teacher') {
      this.universalOnboardFunction('teacher');
    } else if (data.name == 'student') {
      this.universalOnboardFunction('student');
    } else if (data.name == 'admin') {
      this.universalOnboardFunction('admin');
    } else {
      return;
    }
  }

  valueChange(event: any) {
    if (!this.data.class) {
      this.selectedClass = this.schoolClasses.filter(
        (clas) => clas.id == event.value.id
      )[0];
      this.selectedClassStudents = this.selectedClass.studentlist;
      let maxRoll = 0;
      if (this.selectedClassStudents.length) {
        let maxRoll = Math.max.apply(
          Math,
          this.selectedClassStudents.map(function (o) {
            return o.roll;
          })
        );
        console.log(maxRoll);
        this.addStudentForm.get('rollNumber')?.setValue(maxRoll + 1);
      } else {
        this.addStudentForm.get('rollNumber')?.setValue(maxRoll + 1);
      }
    }
  }

  async universalOnboardFunction(type: string) {
    //set form based on type
    let form: FormGroup;
    let roolCollectionName = '';
    if (type == 'admin') {
      form = this.addAdminForm;
      roolCollectionName = 'school_admin';
    } else if (type == 'student') {
      form = this.addStudentForm;
      roolCollectionName = 'school_student';

      // make roll no validation here

      let RegisterdRollNumber = this.selectedClassStudents.map((el) => el.roll);

      console.log(RegisterdRollNumber);

      if (RegisterdRollNumber.includes(parseInt(form.value.rollNumber))) {
        this.rollnoExistes = true;
        this.addStudentForm.get('rollNumber')?.setErrors({ rollPresent: true });
      } else {
        this.addStudentForm.get('rollNumber')?.setErrors(null);
      }
    } else if (type == 'teacher') {
      form = this.addTeacherForm;
      roolCollectionName = 'school_teacher';
    } else return;

    console.log(form.value);
    if (form.valid) {
      this.loading = true;
      let data = form.value;
      console.log(data);
      let obj: any = {};
      if (data.email == '' && data.phone == '') {
        this.snackbar.openSnackBarTop(
          'Please enter email or phone number',
          'error'
        );
        this.loading = false;
        return;
      } else {
        obj['name'] = data.fullName.toLowerCase();
        obj['email'] = data.email;
        obj['mobile'] = data.phone;
      }
      // let obj = {
      //   name: data.fullName.toLowerCase(),
      //   email: data.email,
      //   mobile: data.phone,
      // };

      // return;
      // get uid
      let responseUserID: any = await this.onBoardServ_.getUserId(obj);

      let parseduid = JSON.parse(responseUserID);
      console.log(parseduid.uid);

      // validation of response
      if (!parseduid.uid) {
        this.snackbar.openSnackBarTop(
          'Something went wrong please recheck the form and try again!',
          'Error'
        );
        this.loading = false;
        console.log('It failed!', responseUserID);
        return;
      }

      let DocID: string = parseduid.uid;

      // Checking if a user exists with an assoicated user id
      let responseUserData: any = await this.common
        .getUserDoc(DocID)
        .toPromise();

      let schoolid = this.getSchoolid();

      // check if user root doc exists
      let userExistsInRoot: any = await this.onBoardServ_
        .checkUserExistsinRoot(DocID, schoolid, roolCollectionName)
        .toPromise();

      console.log(responseUserData, userExistsInRoot);

      // user already exists in same school
      if (userExistsInRoot.size !== 0) {
        this.snackbar.openSnackBarTop(
          ` ${data.fullName.toUpperCase()} is already associated with this school as a ${type}`,
          ''
        );
        this.loading = false;

        return;
      } else {
        // user ready to be onboarded

        let dataOperationsPromises: any[] = [];

        // create user doc if it doesnt exist
        if (!responseUserData.exists) {
          let userDoc: docUser = {
            name: data.fullName.split(' ').map((v: string) => v.toLowerCase()),
            gender: data.gender,
            email: data.email,
            mobile: data.phone,
            photo_url: '',
            username: '',
            dob: null,
            created_at: new Date(),
          };

          console.log(userDoc);
          dataOperationsPromises.push(
            this.usercheckService_.createUserdoc(userDoc, DocID)
          );
        }

        // if teacher

        if (type == 'teacher') {
          let teacherDoc: teacherRoot = {
            active: true,
            created_at: new Date(),
            deleted: false,
            school_id: schoolid,
            classes: [],
            user_id: DocID,
          };
          console.log(teacherDoc);
          dataOperationsPromises.push(
            this.onBoardServ_.makeRootDoc(DocID, teacherDoc, roolCollectionName)
          );
        } else if (type == 'student') {
          let classref = this.firestore
            .collection('schools')
            .doc(schoolid)
            .collection('classes')
            .doc(data.class.id).ref;

          let studentDoc: studentRoot = {
            active: true,
            created_at: new Date(),
            deleted: false,
            school_id: schoolid,
            roll: parseInt(data.rollNumber),
            grade: data.class.name,
            section: data.class.section,
            user_id: DocID,
            class_ref: classref,
          };
          console.log(studentDoc);
          dataOperationsPromises.push(
            this.onBoardServ_.makeRootDoc(DocID, studentDoc, roolCollectionName)
          );
        } else if (type == 'admin') {
          let studentDoc: schoolAdmin = {
            active: true,
            created_at: new Date(),
            deleted: false,
            school_id: schoolid,
            role: this.adminForm.roleName.enabled
              ? data.roleName.toLowerCase()
              : data.role,
            user_id: DocID,
          };
          console.log(studentDoc);
          dataOperationsPromises.push(
            this.onBoardServ_.makeRootDoc(DocID, studentDoc, roolCollectionName)
          );
        }

        await Promise.all(dataOperationsPromises)
          .then((el) => {
            this.onBoardServ_
              .incrementCountOfKeyInSchoolDoc(schoolid, type)
              .then((el) => {
                this.loading = false;

                form.reset();
              })
              .catch((er) => console.log(er));
            if (type == 'student') {
              // this.classandStudentSrvc_.fetchClasses();
            }
            this.snackbar.openSnackBarTop(
              ` ${data.fullName.toUpperCase()} has been added successfully as ${type} !`,
              ''
            );

            this.dialogserv_.dismissAll();
          })
          .catch((er) => {
            this.snackbar.openSnackBarTop(
              'Something went wrong please try again!',
              'Error'
            );
            this.loading = false;

            console.log(er);
          });
      }
    } else {
      this.snackbar.openSnackBarTop('Please fill the form correctly', 'Error');
      return;
    }
  }

  valueChangeRole(event: any) {
    if (event.value == 'other') {
      this.addAdminForm.get('roleName')?.enable();
    } else {
      this.addAdminForm.get('roleName')?.disable();
    }
  }

  ngOnDestroy(): void {
    if (this.classListSub) this.classListSub.unsubscribe();
    if (this.currentSchoolDetailsSub)
      this.currentSchoolDetailsSub.unsubscribe();
  }

  // logic

  //create admin
  // 1 - call create user api get uid from response
  //2 - check if admin is already mapped to current school
  // db. .collection("school_admin")
  //               .whereEqualTo("user_id",uid)
  //               .whereEqualTo("school_id",schoolModel.getDocument_id())
  //               .whereEqualTo("deleted",false)
  //3 if geting documents - throw error
  //4 else check for users doc if doesnt exist - create users doc
  // 5 create school admin doc in school_admin collection
  //6 incremnt admins count by 1 in school doc

  //create student

  // 1 - load classes subcollection of the school  with

  // db.collection("schools")
  //                     .document(schoolModel.getDocument_id())
  //                     .collection("classes")
  //                     .whereEqualTo("active",true)
  //                     .whereEqualTo("deleted",false)
  //                     .whereEqualTo("curriculum",schoolModel.getCurriculum())

  //2 - fetch the student count when grade is selected

  // db.collection("school_student")
  //                     .whereEqualTo("class_ref",class_ref)
  //                     .whereEqualTo("active",true)
  //                     .whereEqualTo("deleted",false)
  //                     .get()
  //                     .addOnCompleteListener(task -> {
  //                         try {

  //                             rollEditText.setText(String.valueOf(snapshot.size()+1));
  //                         }catch (Exception e){ }
  //                     });

  // 3- incremnt student doc no +1 and fill roll no field

  // validate roll no on submission

  // 4- on click of register button  - call create user api get uid from response

  //5 - check if student is already mapped to current school
  // db..collection("school_student")
  // .whereEqualTo("user_id",uid)
  // .whereEqualTo("school_id",schoolModel.getDocument_id())
  // .whereEqualTo("deleted",false)

  //6 if geting documents - throw error
  //7 else check for users doc if doesnt exist - create users doc
  //8 create  student doc in school_student collection
  //9 incremnt students count by 1 in school doc
}
