import { UserDataState } from './shared/store/user/user.state';
import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MaterialModule } from './material/material.module';

// ngxs
import {
  getActionTypeFromInstance,
  NgxsModule,
  NGXS_PLUGINS,
} from '@ngxs/store';
import {
  NgxsStoragePluginModule,
  StorageEngine,
  STORAGE_ENGINE,
} from '@ngxs/storage-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsFirestoreModule } from '@ngxs-labs/firestore-plugin';

import { HttpClientModule } from '@angular/common/http';
import { loginScreensState } from './shared/store/auth/login/login.state';
import { AgmCoreModule } from '@agm/core';
import { SwiperModule } from 'swiper/angular';

import { schoolListState } from './shared/store/school/school-list.state';
import { schoolAdminState } from './shared/store/school-admin/school-admin.state';
import { SelectedSchoolState } from './shared/store/school/selected-school.state';
import { OnboardAlertComponent } from './shared/app-alerts/onboard-alert/onboard-alert.component';
import { teacherState } from './shared/store/teachers/teachers.state';
import { studentState } from './shared/store/students/students.state';
import { UserdetailsPipe } from './shared/pipes/userdetails.pipe';
import { ClassListState } from './shared/store/class-list/class-list.state';
import { teacherListState } from './shared/store/teacherDetailedlist/teacherlist.state';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SelectTimtableForNavigation } from './shared/store/timetable/temporartTimetableNavigation.action';
import { CurrentSchoolAdminsState } from './shared/store/currentSchoolAdmins/currentSchoolAdmin.state';
// angular calender
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FormsModule } from '@angular/forms';

import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { selectedSchoolTimetablestore } from './shared/store/timetable/schoolTimetable.action';
// import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { removeUser } from './shared/store/user/user.action';
import { NgChartsModule } from 'ng2-charts';
import { invitationDataState } from './shared/store/invitation/invitation.state';
import { ComponentsModule } from './core/components/components.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FlipModule } from 'ngx-flip';
import { teacherSubstituteState } from './shared/store/teacherSubstitute/teacher-substitute.state';
import { SelectedFeeManagmentForNavigationState } from './shared/store/feeManagement/feeManagmentNavigation.state';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { ReusableComponentsModule } from './reusable-components/reusable-components.module';

import { HammerModule } from '../../node_modules/@angular/platform-browser';
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { Ng2LoadingSpinnerModule } from 'ng2-loading-spinner';

import * as Hammer from 'hammerjs';
import { SchoolExamState } from './shared/store/exam-center/exam-center.state';
import { SelectedQuestionPaperForNavigationState } from './shared/store/questionpapper/questionPaperNavigation.state';
import { SchoolHousesState } from './shared/store/schoolhouses/school-house.state';
// import { LottieModule } f/rom 'ngx-lottie';
// import player from 'lottie-web';
import { SchoolLibraryState } from './shared/store/library/library.state';
import { StudentsAndTeachersState } from './shared/store/students_teachers/students_teachers.state';
import { SchoolTeamState } from './shared/store/schoolTeams/schoolTeam.state';
import { SchoolCouncilState } from './shared/store/schoolCouncil/schoolCouncil.state';
import { CommonModule } from '@angular/common';

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);
// Add these two
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

// Export this function
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent, OnboardAlertComponent],
  imports: [
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      lazy: false,
    }),
    FlipModule,
    AngularFireModule.initializeApp(environment.config),
    NgxMaterialTimepickerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    MaterialModule, // material
    //ngxs,

    NgxsModule.forRoot(
      [
        UserDataState,
        loginScreensState,
        schoolAdminState,
        schoolListState,
        SelectedSchoolState,
        teacherState,
        studentState,
        SelectedFeeManagmentForNavigationState,

        ClassListState,
        teacherListState,
        SelectTimtableForNavigation,
        CurrentSchoolAdminsState,
        selectedSchoolTimetablestore,
        invitationDataState,
        teacherSubstituteState,
        SchoolExamState,
        SelectedQuestionPaperForNavigationState,
        SchoolHousesState,
        SchoolLibraryState,
        StudentsAndTeachersState,
        SchoolTeamState,
        SchoolCouncilState
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsStoragePluginModule.forRoot({
      key: [
        UserDataState,
        loginScreensState,
        schoolListState,
        schoolAdminState,
        SelectedSchoolState,
        teacherState,
        ClassListState,
        studentState,
        teacherListState,
        SelectTimtableForNavigation,
        CurrentSchoolAdminsState,
        selectedSchoolTimetablestore,
        invitationDataState,
        teacherSubstituteState,
        SelectedFeeManagmentForNavigationState,
        SchoolExamState,
        SelectedQuestionPaperForNavigationState,
        SchoolHousesState,
        SchoolLibraryState,
        StudentsAndTeachersState,
        SchoolTeamState,
        SchoolCouncilState
      ],
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsFirestoreModule.forRoot(),
    // NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxMaterialTimepickerModule,
    HttpClientModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FormsModule,
    FullCalendarModule,
    NgChartsModule,
    ComponentsModule,
    AngularFireAnalyticsModule,
    SwiperModule,
    ReusableComponentsModule,
    HammerModule,
    Ng2LoadingSpinnerModule.forRoot({}),
    // LottieModule.forRoot({ player: playerFactory })
    // LottieAnimationViewModule.forRoot(),
    CommonModule,
    // Add the module like so:
    LottieModule.forRoot({ player: playerFactory }),


  ],
  providers: [
    {
      provide: NGXS_PLUGINS,
      useValue: logoutPlugin,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }

export function logoutPlugin(state: any, action: any, next: any) {
  if (getActionTypeFromInstance(action) == removeUser.type) {
    console.log('clearing state {}');
    // console.log(state);

    state = {
      ClassList: { classList: [] },
      CurrentSchoolAdmins: { currentSchoolAdmins: [] },
      SchoolTimetable: { schoolTT: {} },
      SelectTimtableForNavigation: { ttData: {}, teacher: {} },
      SelectedAdmin: { selectedAdmin: {} },
      UserData: { userData: {} },
      loginScreens: { loginScreens: {} },
      ngxs_firestore: { connections: [] },
      schoolList: { schools: [], activeSchools: [] },
      school_admin_list: { school_admin: [] },
      students_list: { students_list: [] },
      teacherList: { teacherData: [] },
      teachers_list: { teachers_list: [] },
    };
  }
  return next(state, action);

  // return the next function with the empty state
}
