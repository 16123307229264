import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { schoolAdmin } from 'src/app/auth/shared/models/school_admin';
import { teacherRoot } from '../../models/teacher_root';

@Injectable({
  providedIn: 'root',
})
export class TeacherFirestore extends NgxsFirestore<teacherRoot> {
  protected path = 'school_teacher';
}
