import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import {
  house_captain,
  house_captain_incharge,
  house_student,
  house_teacher,
  SchoolHouse,
} from '../models/house.model';
import { SelectedSchoolState } from '../store/school/selected-school.state';
import { CommonService } from './common.service';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { SnackbarService } from './snackbar.service';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class SchoolHouseService {
  selectedHouseDetails: SchoolHouse;
  selectedPhotoFile!: File;
  allSchoolHouses: SchoolHouse[] = [];
  progress: boolean = false;
  houseId!: any;

  housePhotoUrl: string = '';
  houseProfileUploadTask!: AngularFireUploadTask;
  houseProfileUploadPercentage!: Observable<number | undefined>;
  houseProfileUploadSnapShot!: Observable<any>;

  currentSchoolDocId: string = '';
  selectedAdmin$ = this.store.select(SelectedSchoolState);
  constructor(
    private fireStore: AngularFirestore,
    private store: Store,
    private commonSrvc_: CommonService,
    private fireStorage: AngularFireStorage,
    private snackBarSrvc_: SnackbarService
  ) {
    let currentSchoolDetails$: Observable<any> = this.selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.commonSrvc_.getCurrentSchoolDetails(
            data.selectedAdmin.school_id
          );
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      this.currentSchoolDocId = el?.id;
    });
  }

  fetchHouses() {
    let currentschoolDocId = '';
    let selectedAdmin$ = this.store.select(SelectedSchoolState);
    let currentSchoolDetails$: Observable<any> = selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.commonSrvc_.getCurrentSchoolDetails(
            data.selectedAdmin.school_id
          );
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      currentschoolDocId = el.id;
    });
    return this.fireStore
      .collection('schools')
      .doc(currentschoolDocId)
      .collection('houses')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.created_at = data.created_at.toDate();
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  createHouse(schoolHouse: SchoolHouse) {
    let currentschoolDocId = '';
    let selectedAdmin$ = this.store.select(SelectedSchoolState);
    let currentSchoolDetails$: Observable<any> = selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.commonSrvc_.getCurrentSchoolDetails(
            data.selectedAdmin.school_id
          );
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      currentschoolDocId = el.id;
    });
    return this.fireStore
      .collection('schools')
      .doc(currentschoolDocId)
      .collection('houses')
      .add(schoolHouse);
  }

  setHouseDoc(schoolHouse: SchoolHouse) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(this.houseId)
      .set(schoolHouse);
  }

  getFirebaseId() {
    let autogenUid = this.fireStore.createId();
    this.houseId = autogenUid;
    // return autogenUid;
  }

  uploadHouseProfileImage() {
    this.getFirebaseId();
    this.progress = true;
    const filePath = `houses/${this.currentSchoolDocId}_${this.houseId}.jpeg`;
    const ref = this.fireStorage.ref(filePath);
    this.houseProfileUploadTask = this.fireStorage.upload(
      filePath,
      this.selectedPhotoFile
    );
    this.houseProfileUploadPercentage =
      this.houseProfileUploadTask.percentageChanges();
    this.houseProfileUploadSnapShot = this.houseProfileUploadTask
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          await ref
            .getDownloadURL()
            .toPromise()
            .then((url: any) => {
              this.housePhotoUrl = url;
              console.log(this.housePhotoUrl);
              this.progress = false;
              this.snackBarSrvc_.openSnackBarTop(
                'Profile photo uploaded successfully',
                'Success'
              );
            })
            .catch((error: any) => {
              this.snackBarSrvc_.openSnackBarTop(error, 'Failure');
              this.progress = false;
            });
        })
      );
    // this.profileUploadSnapShot.subscribe();
  }

  fetchSchoolStudents() {
    return this.fireStore
      .collection('school_student', (ref) =>
        ref
          .where('school_id', '==', this.currentSchoolDocId)
          .where('active', '==', true)
          .where('deleted', '==', false)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.class_ref = data.class_ref?.path;
            data.created_at = data.created_at.toDate();
            return { id, ...data };
          });
        }),
        switchMap((data: any) => {
          if (data.length) {
            return forkJoin(
              data.map((el: any) =>
                this.commonSrvc_.getUserDoc(el.user_id).pipe(
                  map((user: any) => {
                    let dataUser = user.data();
                    dataUser.created_at = dataUser.created_at.toDate();
                    dataUser.dob = dataUser.dob?.toDate();
                    Object.assign(el, {
                      userDetails: dataUser,
                    });

                    return el;
                  })
                )
              )
            );
          } else return of([]);
        })
      );
  }

  fetchSchoolTeachers() {
    return this.fireStore
      .collection('school_teacher', (ref) =>
        ref
          .where('school_id', '==', this.currentSchoolDocId)
          .where('active', '==', true)
          .where('deleted', '==', false)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.created_at = data.created_at.toDate();
            return { id, ...data };
          });
        }),
        switchMap((data: any) => {
          if (data.length) {
            return forkJoin(
              data.map((el: any) =>
                this.commonSrvc_.getUserDoc(el.user_id).pipe(
                  map((user: any) => {
                    let dataUser = user.data();
                    dataUser.created_at = dataUser.created_at.toDate();
                    dataUser.dob = dataUser.dob?.toDate();
                    Object.assign(el, {
                      userDetails: dataUser,
                    });

                    return el;
                  })
                )
              )
            );
          } else return of([]);
        })
      );
  }

  updateMultipleStudentDocs(studentIds: any[], house_id: string) {
    let batch = this.fireStore.firestore.batch();
    // let reference = this.fireStore.collection('school_student')
    for (let i = 0; i < studentIds.length; i++) {
      const studentDocId = studentIds[i];
      let batchref = this.fireStore
        .collection('school_student')
        .doc(studentDocId).ref;
      batch.update(batchref, { house_id: house_id });
    }
    return batch.commit();
  }

  updateStudentDoc(studentDocId: any, house_id: string) {
    return this.fireStore
      .collection('school_student')
      .doc(studentDocId)
      .update({
        house_id: house_id,
      });
  }

  updateMultipleTeacherDocs(teacherDocIds: any[], house_id: string) {
    let batch = this.fireStore.firestore.batch();
    // let reference = this.fireStore.collection('school_student')
    for (let i = 0; i < teacherDocIds.length; i++) {
      const teacherDocId = teacherDocIds[i];
      let batchref = this.fireStore
        .collection('school_teacher')
        .doc(teacherDocId).ref;
      batch.update(batchref, { house_id: house_id });
    }
    return batch.commit();
  }

  updateStudentsArrayInHouseDoc(house_id: string, students: house_student[]) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id).ref;
    return docRef.update({
      students: firebase.firestore.FieldValue.arrayUnion(...students),
    });
  }

  updateTeachersArrayInHouseDoc(house_id: string, teachers: house_teacher[]) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id).ref;
    return docRef.update({
      teachers: firebase.firestore.FieldValue.arrayUnion(...teachers),
    });
  }

  updateTeacherDoc(teacherDocId: string, houseId: string) {
    return this.fireStore
      .collection('school_teacher')
      .doc(teacherDocId)
      .update({
        house_id: houseId,
      });
  }

  removeTeacherFromHouseDoc(house_id: any, teacher: house_teacher) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id).ref;
    return docRef.update({
      teachers: firebase.firestore.FieldValue.arrayRemove(teacher),
    });
  }

  clearHouseIdFromTeacherDoc(teacherDoc: string) {
    return this.fireStore
      .collection('school_teacher')
      .doc(teacherDoc)
      .update({ house_id: '' });
  }

  clearHouseIdFromStudentDoc(studentDoc: string) {
    return this.fireStore
      .collection('school_student')
      .doc(studentDoc)
      .update({ house_id: '' });
  }

  removeStudentFromHouseDoc(house_id: any, student: house_student) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id).ref;
    return docRef.update({
      students: firebase.firestore.FieldValue.arrayRemove(student),
    });
  }

  addCaptainToHouseDoc(house_id: any, captian: house_captain) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id).ref;
    return docRef.update({
      captains: firebase.firestore.FieldValue.arrayUnion(captian),
    });
  }

  updateCaptainsArrayInHouseDoc(
    house_captains: house_captain[],
    house_id: string
  ) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id)
      .update({
        captains: house_captains,
      });
  }

  updateInchargesArrayInHouseDoc(
    house_incharges: house_captain_incharge[],
    house_id: string
  ) {
    return this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id)
      .update({
        incharges: house_incharges,
      });
  }

  addInchargeToHouseDoc(house_id: any, incharge: house_captain_incharge) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id).ref;
    return docRef.update({
      incharges: firebase.firestore.FieldValue.arrayUnion(incharge),
    });
  }

  removeCaptianFromHouseDoc(house_id: any, captian: house_captain) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id).ref;
    return docRef.update({
      captains: firebase.firestore.FieldValue.arrayRemove(captian),
    });
  }

  removeInchargeFromHouseDoc(house_id: any, incharge: house_captain_incharge) {
    let docRef = this.fireStore
      .collection('schools')
      .doc(this.currentSchoolDocId)
      .collection('houses')
      .doc(house_id).ref;
    return docRef.update({
      incharges: firebase.firestore.FieldValue.arrayRemove(incharge),
    });
  }

  // getStuff(schoolId: string, curriculum: string) {
  //   let ob1$ = this.firestore
  //     .collection('school_exams', (ref) =>
  //       ref
  //         .where('school_id', '==', schoolId)
  //         .where('curriculum', '==', curriculum)
  //     )

  //       switchMap((data: SchoolExam[]) => {
  //         //switchmap = switching obsevables and subscribes internally
  //         const obs = data.map((item) => {
  //           if (!isEmpty(item.paper_ids)) {
  //             const ids = item.paper_ids;
  //             return forkJoin(
  //               // takes multiple observables and the calls in parellell
  //               (ids ?? []).map((id) =>
  //                 this.firestore
  //                   .doc(`school_question_papers/${id}`)
  //                   .get()
  //                   .pipe(
  //                     map((documentSnapshot) => {
  //                       var doc_data: any = documentSnapshot.data();
  //                       var id = documentSnapshot.id;
  //                       return { id, ...doc_data };
  //                     })
  //                   )
  //               )
  //             ).pipe(
  //               map((paperIdData) => {
  //                 return { ...item, paperIdData: paperIdData };
  //               })
  //             );
  //           }
  //           return of(item);
  //         });

  //         return forkJoin(obs);
  //       })
  //     );

  //   return ob1$;
  // }
}
