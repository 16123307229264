import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { MaterialModule } from 'src/app/material/material.module';
import { AccordionComponent } from './accordion/accordion.component';
import { NotificationsSidebarComponent } from './notifications-sidebar/notifications-sidebar.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StepperComponent } from './stepper/stepper.component';
import { RouterModule } from '@angular/router';
import { VerticalStepperComponent } from './vertical-stepper/vertical-stepper.component';
import {
  TippyModule,
  tooltipVariation,
  popperVariation,
} from '@ngneat/helipopper';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { ReusableComponentsModule } from 'src/app/reusable-components/reusable-components.module';
import { LayoutNavigationComponent } from './layout-navigation/layout-navigation.component';
@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    AccordionComponent,
    NotificationsSidebarComponent,
    IconButtonComponent,
    StepperComponent,
    VerticalStepperComponent,
    LayoutNavigationComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DirectivesModule,
    ReusableComponentsModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    AccordionComponent,
    NotificationsSidebarComponent,
    IconButtonComponent,
    StepperComponent,
    VerticalStepperComponent,
    LayoutNavigationComponent,
  ],
})
export class ComponentsModule {}
