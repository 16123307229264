import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { schoolAdmin } from 'src/app/auth/shared/models/school_admin';

@Injectable({
  providedIn: 'root',
})
export class RacesFirestore extends NgxsFirestore<schoolAdmin> {
  protected path = 'school_admin';
}
