import { Directive } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AbstractControl,
  AsyncValidator,
  FormArray,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';
import { schoolDbData } from 'src/app/auth/shared/models/school_db_data';
import { FormService } from 'src/app/auth/shared/services/form-service.service';
@Directive({
  selector: '[SchoolCodeValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: SchoolCodeDirective,
      multi: true,
    },
  ],
})
export class SchoolCodeDirective implements AsyncValidator {
  constructor(
    private afs: AngularFirestore,
    private formservice: FormService
  ) {}
  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.afs
      .collection('schools_db')
      .doc(control.value)
      .valueChanges()
      .pipe(
        debounceTime(500),
        take(1),
        tap((el: any) => {
          if (el !== undefined) {
            if (Object.keys(el).length !== 0 && el.constructor === Object) {
              console.log('grgrg');
              this.formservice.school_code_details.next(el);
              this.formservice.school_UDISE_details.next(el);
            }
          }
          else {
            let obj: schoolDbData = {
              category: '',
              created_at: '',
              school_id: '',
              status: '',
              email: [],
              name: [],
              mobile: [],
              board: [],
              website: [],
              address: {
                address_line: '',
                city: '',
                country: '',
                district: '',
                latitude: 0,
                locality: '',
                longitude: 0,
                pincode: '',
                state: '',
              },
            };
            this.formservice.school_UDISE_details.next(obj);
          }
          console.log(el);
          // if (el.length != 0) {

          // }
        }),
        map((array: any) => {
          if (array) {
            if (
              Object.keys(array).length === 0 &&
              array.constructor === Object
            ) {
              return { userNameAvailable: false };
            } else {
              return null;
            }
          } else {
            return { userNameAvailable: false };
          }

          // return array.length == 0 ? { userNameAvailable: false } : null;
        })
      );
  }
}

// .collection('schools_db', (ref) =>
// ref.where('school_id', '==', control.value)
// )
