import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teams-create-event-dialog',
  templateUrl: './teams-create-event-dialog.component.html',
  styleUrls: ['./teams-create-event-dialog.component.scss']
})
export class TeamsCreateEventDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  createEvent() {
    alert('create event');
  }
}
