<div class="cursor-pointer ">
    <div class="list flex items-center justify-between  rounded-lg p-6 relative"
        [class]="id == expanded ? 'bg-sky_lightest' : 'bg-sky_lightest'" (click)="toggle(id)">
        <ng-content select="[content-header]"></ng-content>
        <h6 class="md:col-span-3 col-span-4 flex justify-end absolute right-3">
            <mat-icon *ngIf="id != expanded"> expand_more</mat-icon>
            <mat-icon *ngIf="id == expanded"> expand_less</mat-icon>
        </h6>

    </div>

    <div class="subject list  bg-sky_lightest rounded-b-lg p-4" *ngIf="id == expanded">
        <ng-content select="[content-body]"></ng-content>

    </div>
 
</div>