import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { isEmpty } from 'lodash';
import { SchoolExamMarks } from 'src/app/shared/models/schoolExamMarks.modal';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import {
  removeStudentMarksEntryDetails,
  StudentMarksEntryDetails,
} from 'src/app/shared/store/exam-center/exam-center.action';

@Component({
  selector: 'app-marksportal-studets-attandance',
  templateUrl: './marksportal-studets-attandance.component.html',
  styleUrls: ['./marksportal-studets-attandance.component.scss'],
})
export class MarksportalStudetsAttandanceComponent implements OnInit {
  studentAttendance: any;
  studentInfo: any = {};
  selectedQuestionPaper: any = {};
  subjectExamPapersDetails: any = {};
  selectedExamDetails: any = {};
  selectedSet: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private firestore: AngularFirestore,
    private store: Store,
    private commonSrvc_: CommonService,
    private snackBarSrvc_: SnackbarService,
    private dialogSrvc_: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.studentInfo = this.data.studentInfo;
    this.subjectExamPapersDetails = this.data.subjectExamPapersDetails;
    console.log(this.subjectExamPapersDetails);
    this.selectedExamDetails = this.data.selectedExamDetails;
    if (this.subjectExamPapersDetails.question_papers.length == 1) {
      this.selectedQuestionPaper =
        this.subjectExamPapersDetails.question_papers[0];
    }
  }

  markAttendance(attendanceState: string) {
    if (attendanceState == 'present') {
      this.studentInfo.present = true;
    } else {
      this.studentInfo.present = false;
      this.sendDocumentToFBIfAbsent();
    }
  }

  choosePaper(paper: any) {
    this.selectedQuestionPaper = paper;
    console.log(paper);
  }

  isObjEmpty() {
    return isEmpty(this.selectedQuestionPaper);
  }

  sendDocumentToFBIfAbsent() {
    let schoolExamMarksDoc: SchoolExamMarks = {
      class_ref: this.firestore
        .collection('schools')
        .doc(this.selectedExamDetails.school_id)
        .collection('classes')
        .doc(this.subjectExamPapersDetails.examDetails.class_id).ref,
      created_at: new Date(),
      curriculum: this.selectedExamDetails.curriculum,
      exam_from: new Date(this.subjectExamPapersDetails.examDetails.start_time),
      exam_to: new Date(this.subjectExamPapersDetails.examDetails.end_time),
      exam_ref: this.firestore
        .collection('school_exams')
        .doc(this.selectedExamDetails.id).ref,
      grade: this.studentInfo.grade.toLowerCase(),
      student_id: this.studentInfo.user_id,
      internal_scored: 0,
      internal_total: 0,
      ml_processed: true,
      paper_ref: null,
      present: false,
      duration: 0,
      paper_set: '',
      questions: [],
      recommendations: [],
      roll: this.studentInfo.roll,
      school_id: this.selectedExamDetails.school_id,
      section: this.studentInfo.section.toLowerCase(),
      student_name: this.studentInfo.userDetails.name.join(' ').toLowerCase(),
      subject: this.data.subject,
      total_marks: 0,
      total_question: 0,
      uploader_id: this.store.snapshot().UserData.userData.uid,
      uploader_name: this.store
        .snapshot()
        .UserData.userData.name.join(' ')
        .toLowerCase(),
      exam_name: this.selectedExamDetails.title.toLowerCase(),
      scored_marks: 0,
    };
    console.log(schoolExamMarksDoc);
    // return;
    this.commonSrvc_.showSpinner = true;
    this.commonSrvc_
      .addSchooExamMarksDoc(schoolExamMarksDoc)
      .then(() => {
        this.snackBarSrvc_.openSnackBarTop(
          'The document has been added successfully',
          'Success!!'
        );
        this.commonSrvc_.showSpinner = false;
        this.dialogSrvc_.close();
      })
      .catch((err: any) => {
        this.snackBarSrvc_.openSnackBarTop(`${err}`, 'Failure');
        this.commonSrvc_.showSpinner = false;
        this.dialogSrvc_.close();
      });
    // this.console.log(schoolExamMarksDoc);
  }

  goToEnterMarksPage() {
    let obj: any = {};
    obj['studentInfo'] = this.studentInfo;
    obj['selectedExamDetails'] = this.selectedExamDetails;
    obj['subjectExamPapersDetails'] = this.subjectExamPapersDetails;
    this.store.dispatch(new removeStudentMarksEntryDetails());
    this.store.dispatch(new StudentMarksEntryDetails(obj));
    this.router.navigate([
      `layout/examcenter/marksportal/${this.selectedExamDetails.id}/studetmarks/${this.selectedQuestionPaper.id}`,
    ]);
    this.dialogSrvc_.close();
  }
}
