<div class="title" mat-dialog-title [align]="'center'">
  Enter The Class Name
</div>
<div class="body" mat-dialog-content [align]="'center'">
  <!-- Class
  <input
    type="text"
    placeholder=""
    class="add-class-input"
    [(ngModel)]="className"
  /> -->
  <mat-form-field appearance="outline">
    <mat-label>Select Class</mat-label>
    <mat-select [(ngModel)]="className" name="food">
      <mat-option *ngFor="let class of classes" [value]="class">
        Class {{class|uppercase}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<small
  >Enter Only The Class Name, Sections Can Be Added In The Next Step!</small
>
<div class="actions" mat-dialog-actions [align]="'center'">
  <button class="submit" (click)="submit()">Submit</button>
</div>
