<div class="onboard_container">
    <div class="sidebar">
        <div class="sidebar-header">
            <img src="../../../../assets/icons/side-bar/hurrey_for_school.svg" class="hurrey_school_img cursor-pointer" alt="logo" routerLink="/layout/dashboard" />
        </div>
        <!-- start of selected school -->
        <!-- <div class="selected_school">
            <img class="school_img"
            src="https://source.unsplash.com/randomg" alt="loading.." />
            <h1 class="getting_started heading3">
                Create School
            </h1>
        </div> -->
        <!-- end of selected school -->

        <h1 class="getting_started heading3">
            Create School
        </h1>

        <div class="StepProgress">
          <!-- is-done -->
            <div class="StepProgress-item "
              [class.example-active]="selectedIndex === i" [class.is-done]="selectedIndex > i" *ngFor="let step of steps; let i = index"
              (click)="selectStepByIndex(i)">
              <h3 class="heading3">
                <ng-container *ngIf="step.stepLabel" [ngTemplateOutlet]="step.stepLabel.template">
                </ng-container>
              </h3>

            </div>
          </div>


    </div>

    <div class="main_contwnt" >
        <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
      </div>

</div>
