<div class="create_container pb-5">
  <div *ngIf="!viewClasses">
    <div class="head flex items-center justify-between mb-3">
      <h2 class="heading2 mb-1">Create Event</h2>
      <button
        class="bg_sky_light color_sky_light rounded-full"
        mat-dialog-close
      >
        <img
          src="../../../../assets/icons/event-close.svg"
          alt="back"
          class="back-button"
        />
      </button>
    </div>

    <form
      class="flex flex-col gap-3"
      autocomplete="off"
      [formGroup]="createEventForm"
    >
      <div class="event_type_selection_contianer flex gap-3">
        <div class="event_types flex items-center flex-wrap gap-3">
          <button
            [class.selected]="typeOfEvent == 'exams'"
            (click)="toggleEvent('exams')"
            type="button"
          >
            Exam
          </button>
          <button
            [class.selected]="typeOfEvent == 'activities'"
            (click)="toggleEvent('activities')"
            type="button"
          >
            Events
          </button>
          <button
            [class.selected]="typeOfEvent == 'holidays'"
            (click)="toggleEvent('holidays')"
            type="button"
          >
            Holidays
          </button>
          <button
            [class.selected]="typeOfEvent == 'others'"
            (click)="toggleEvent('others')"
            type="button"
          >
            Others
          </button>
        </div>
      </div>

      <div class="create">
        <input
          type="text"
          placeholder="Add Title"
          formControlName="title"
          [class.errorBorder]="
            (title?.dirty || title?.touched) && title?.invalid
          "
        />
        <mat-error *ngIf="(title?.dirty || title?.touched) && title?.invalid">{{
          getTitleErrorMessage()
        }}</mat-error>
        <p style="color: tomato" *ngIf="title?.invalid && submitted">
          {{ getTitleErrorMessage() }}
        </p>
      </div>

      <div class="time_selection flex gap-3">
        <div class="flex flex-col items-center justify-center relative -top-3">
          <div class="start_clock">
            <img src="../../../../assets/images/icons/clock_blue.svg" alt="" />
          </div>
          <div class="vertical_line" style="height: 40%"></div>
          <div class="end_clock">
            <!-- <img src="../../../../assets/images/icons/clock_blue.svg" alt=""> -->
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="start flex items-center gap-2">
            <div class="flex flex-col">
              <div class="flex gap-5 items-center">
                <mat-form-field fill="outline">
                  <input
                    matInput
                    [ngxMatDatetimePicker]="picker"
                    placeholder="Choose Start Date"
                    formControlName="from"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="$any(picker)"
                  ></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker
                    [enableMeridian]="true"
                  ></ngx-mat-datetime-picker>
                  <mat-error *ngIf="startDate?.invalid">{{
                    getStartErrorMessage()
                  }}</mat-error>
                </mat-form-field>
                <!-- <div class="flex gap-5 items-center" *ngIf="!showDatapicker1">
                  <h3 class="heading3 font-semibold">Thursday. December 5</h3>
                  <p>|</p>
                  <h3 class="heading2 font-semibold">12:00pm</h3>
                </div> -->
                <!-- <p>|</p> -->
                <!-- <mat-checkbox class="example-margin">All Day</mat-checkbox> -->
              </div>
              <!-- <p class="text">Time zone - Indian Standard Time</p> -->

              <!-- <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose Start Date" formControlName="from">
                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [enableMeridian]="true"></ngx-mat-datetime-picker>
              </mat-form-field> -->
            </div>
          </div>
          <div class="end flex items-center gap-2">
            <div class="flex flex-col">
              <div class="flex gap-5 items-center">
                <!-- <div class="flex gap-5 items-center" *ngIf="!showDatapicker2">
                  <h3 class="heading3 font-semibold">Thursday. December 5</h3>
                  <p>|</p>
                  <h3 class="heading2 font-semibold">12:00pm</h3>
                </div> -->
                <!-- <p>|</p> -->
                <!-- <mat-checkbox class="example-margin">All Day</mat-checkbox> -->
                <mat-form-field>
                  <input
                    matInput
                    [ngxMatDatetimePicker]="picker1"
                    placeholder="Choose End Date"
                    formControlName="to"
                    [min]="minEndDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="$any(picker1)"
                  ></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker1
                    [enableMeridian]="true"
                  ></ngx-mat-datetime-picker>
                  <mat-error *ngIf="endDate?.invalid">{{
                    getEndErrorMessage()
                  }}</mat-error>
                </mat-form-field>
              </div>

              <!-- <mat-form-field >
                <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Choose End Date" formControlName="to"
                  [min]="minEndDate">
                <mat-datepicker-toggle matSuffix [for]="$any(picker1)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker1 [enableMeridian]="true"></ngx-mat-datetime-picker>
              </mat-form-field> -->
            </div>
          </div>
        </div>
      </div>

      <div class="horizontal_line"></div>

      <div class="classess flex gap-2 flex-wrap">
        <button>
          <!-- <mat-checkbox class="example-margin">All Classes</mat-checkbox> -->
          <div class="flex gap-2 items-center">
            <input
              type="checkbox"
              id="scales"
              name="scales"
              (change)="onSelectingAllClasses($event)"
              [checked]="schoolClasses.length == selectedClasses.length"
            />
            <p class="text">All Classes</p>
          </div>
        </button>
        <button class="selected" (click)="classViewButton()">
          <div class="flex gap-2 items-center">
            <div class="back">
              <img src="../../../../assets/images/icons/classess.svg" alt="" />
            </div>
            <p class="text">For Class</p>
          </div>
        </button>
      </div>

      <div class="flex items-center gap-3">
        <div class="back">
          <img src="../../../../assets/images/icons/classess.svg" alt="" />
        </div>
        <div class="flex flex-col gap-1">
          <p class="font-semibold">For classes</p>
          <p *ngIf="selectedClasses.length">
            <span *ngFor="let class of selectedClasses; let isLast = last"
              >{{ class?.name | titlecase }} {{ class?.section | titlecase }}
              {{ isLast ? "" : ", " }}</span
            >
          </p>
          <p *ngIf="!selectedClasses.length">No Classes Selected</p>
        </div>
      </div>
      <div class="horizontal_line"></div>
      <div class="actions flex gap-3 justify-end items-center mb-6">
        <button class="btn_white" mat-dialog-close>Close</button>
        <button class="btn_blue flex gap-2 items-center text-center" type="submit" (click)="onSave()">
          <span class="m-auto">{{ save | async }}</span>
          <span>
            <mat-spinner
              *ngIf="(save | async) == 'Saving'"
              class="p-0 m-0 buttonSpinner mat-spinner-color-white"
              diameter="20"
            ></mat-spinner>
          </span>
        </button>
      </div>
    </form>
  </div>
  <div class="events_for flex flex-col gap-4" *ngIf="viewClasses">
    <h2 class="heading2">This Event is for</h2>
    <div class="head flex items-center justify-between">
      <p class="text">{{ selectedClasses.length }} Classes Selected</p>
      <div class="flex gap-3">
        <!-- <button class="btn_white_squre" [class.comboClass]="schoolClasses.length==selectedClasses.length"
          (click)="selectAllButton()">{{schoolClasses.length==selectedClasses.length?'Unselect All':'Select
          All'}}</button> -->
        <div
          id="ck-button"
          [ngClass]="{
            borderLess: schoolClasses.length == selectedClasses.length
          }"
        >
          <label>
            <input
              type="checkbox"
              [checked]="schoolClasses.length == selectedClasses.length"
              (change)="onSelectingAllClasses($event)"
            /><span style="padding: 12px">
              {{
                schoolClasses.length == selectedClasses.length
                  ? "Unselect All"
                  : "Select
              All"
              }}</span
            >
          </label>
        </div>
        <!-- <button class="btn_white_squre ">1 - 5</button>
        <button class="btn_white_squre "> 6 -12</button>
        <button class="btn_white_squre ">Sections</button> -->
      </div>
    </div>

    <div class="horizontal_line"></div>
    <div class="flex gap-1 flex-wrap">
      <div
        id="ck-button"
        *ngFor="let class of schoolClasses; let j = index"
        [ngClass]="{ borderLess: isChecked(class) }"
      >
        <label>
          <input
            type="checkbox"
            [value]="class?.id"
            [checked]="isChecked(class)"
            (change)="onClickingClass($event, class)"
          /><span style="padding: 12px">
            Class {{ class?.name | titlecase }}&nbsp;{{
              class.section | titlecase
            }}</span
          >
        </label>
      </div>
    </div>

    <div class="horizontal_line"></div>

    <div class="flex justify-end">
      <button class="btn_white_squre" mat-dialog-close>Cancel</button>
      <button class="btn_blue_squre text-center px-5" (click)="viewClasses = false">Save</button>
    </div>
  </div>
</div>
