<div class="backContainer flex  items-center gap-3 cursor-pointer w-max" (click)="OnClick()">
    <div class="backButton" >
        <img src="../../../../assets/images/back.svg" alt="" />
    </div>
    <div class="backText text-ink-darker font-bold md:text-large text-mall">
        <h2>{{label}}</h2>
    </div>
</div>

<div class="teachers_list_container">
    
</div>