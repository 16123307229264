<div class="title" mat-dialog-title [align]="'center'">Add Profile Details</div>
<div class="body" mat-dialog-content>
  <div class="profile-sub-div">Enter your details and create a password</div>
  <form [formGroup]="profileForm" autocomplete="off">
    <div class="profileForm">
      <mat-form-field appearance="outline">
        <mat-label>Full Name</mat-label>
        <input formControlName="fullName" matInput />
        <!-- <mat-error *ngIf="profileFormControls.fullName.invalid && submitted">Please enter a valid Name</mat-error> -->
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Date of Birth</mat-label>
        <input
          formControlName="dateOfBirth"
          matInput
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <!-- <mat-error *ngIf="profileFormControls.dateOfBirth.invalid && submitted">Please enter a date</mat-error> -->
      </mat-form-field>
      <div class="gender-label">Select Gender</div>
      <div class="radio-button-div">
        <label for="male" class="l-radio">
          <input type="radio" id="male" value="male" formControlName="gender" />
          <span>Male</span>
        </label>
        <label for="female" class="l-radio">
          <input
            type="radio"
            id="female"
            value="female"
            formControlName="gender"
          />
          <span>Female</span>
        </label>
        <label for="other" class="l-radio">
          <input
            type="radio"
            id="other"
            value="other"
            formControlName="gender"
          />
          <span>Other</span>
        </label>

        <!-- <mat-error *ngIf="profileFormControls.gender.invalid && submitted">Please select your gender</mat-error> -->
      </div>
      <div class="create-user-name-txt">Create your Username</div>
      <div class="username-hint">
        Username can vary 4 - 30 characters. Special characters allowed are dot,
        underscore & hyphen
      </div>
      <mat-form-field appearance="outline" class="username-input">
        <mat-label>Username</mat-label>
        <input
          maxlength="30"
          formControlName="userName"
          matInput
          placeholder="Enter the Username"
          usernameValidator
        />
        <mat-hint
          [ngStyle]="{ color: 'red' }"
          *ngIf="username.invalid && username.dirty"
          >{{ username.value }} is not available</mat-hint
        >
        <mat-hint
          [ngStyle]="{ color: 'rgb(59 130 246 / 100%)' }"
          *ngIf="username.pending"
          >Checking availablity of {{ username.value }} ...</mat-hint
        >
        <mat-hint [ngStyle]="{ color: hintColor }" *ngIf="username.valid"
          >Username is valid and available</mat-hint
        >
        <mat-error *ngIf="username?.invalid">{{getUserNameError()}}</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div class="actions" mat-dialog-actions [align]="'end'">
  <button class="save-btn flex justify-center" (click)="submitProfileForm()">
    <span> Save and Proceed </span>
    <mat-spinner
      *ngIf="spinner"
      class="p-0 m-0 buttonSpinner"
      diameter="20"
    ></mat-spinner>
    <!-- <mat-spinner *ngIf="submittedSpinner" class="p-0 m-0 buttonSpinner" diameter="20"></mat-spinner> -->
  </button>
</div>
<span class="close" mat-dialog-close>
  <img
    src="../../../../assets/icons/school-onboard/close-icon-dialog.svg"
    alt=""
  />
</span>
<!-- <div class="home" style="display: none;">
  <div class="profile-container">
    <div class="profile-div">
      <div class="add-profile-txt">Add profile details</div>
    </div>
    <div class="profile-sub-div">Enter your details and create a password</div>
    <form [formGroup]="profileForm" autocomplete="off" (ngSubmit)="submitProfileForm()">
      <div class="profileForm">
        <mat-form-field appearance="outline">
          <mat-label>Full Name</mat-label>
          <input formControlName="fullName" matInput />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Date of Birth</mat-label>
          <input formControlName="dateOfBirth" matInput [matDatepicker]="picker" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div class="gender-label">Select Gender</div>
        <div class="radio-button-div">
          <label for="male" class="l-radio">
            <input type="radio" id="male" value="male" formControlName="gender" />
            <span>Male</span>
          </label>
          <label for="female" class="l-radio">
            <input type="radio" id="female" value="female" formControlName="gender" />
            <span>Female</span>
          </label>
          <label for="other" class="l-radio">
            <input type="radio" id="other" value="other" formControlName="gender" />
            <span>Other</span>
          </label>

        </div>
        <div class="create-user-name-txt">Create your Username</div>
        <div class="username-hint">
          Username can vary 4 - 30 characters. Special characters allowed are
          dot, underscore & hyphen
        </div>
        <mat-form-field appearance="outline" class="username-input">
          <mat-label>Username</mat-label>
          <input maxlength="30" formControlName="userName" matInput placeholder="Enter the Username"
            usernameValidator />
          <mat-hint [ngStyle]="{ color: 'red' }" *ngIf="username.invalid && username.dirty">{{ username.value }} is not
            available</mat-hint>
          <mat-hint [ngStyle]="{ color: 'rgb(59 130 246 / 100%)' }" *ngIf="username.pending">Checking availablity of {{
            username.value }} ...</mat-hint>
          <mat-hint [ngStyle]="{ color: hintColor }" *ngIf="username.valid">Username is valid and available</mat-hint>
        </mat-form-field>
        <div class="btn-div">
          <button class="save-btn flex justify-center">
            <span> Save and Proceed </span>
            <mat-spinner *ngIf="spinner" class="p-0 m-0 buttonSpinner" diameter="20"></mat-spinner>
          </button>
        </div>
      </div>
    </form>
  </div>
</div> -->
