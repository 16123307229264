<div class="title" mat-dialog-title [align]="'center'">
  Enter The Enrollment Code
</div>
<form class="body" [formGroup]="myGroup">
  <mat-form-field appearance="outline">
    <input
      matInput
      type="text"
      placeholder="Enrollment Code"
      class="mt-3"
      formControlName="enrollmentnumber"
      minlength="6"
      maxlength="40"
      onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
    />

    <mat-hint>
      Enrollment code can be alphanumeric , length can vary from 6- 40
      charecters
    </mat-hint>

    <mat-error *ngIf="getenrollmentcontrol().invalid">{{
      getErrorMessage()
    }}</mat-error>
  </mat-form-field>
</form>
<div class="actions" mat-dialog-actions [align]="'center'">
  <button class="submit" (click)="submit()">Submit</button>
</div>
