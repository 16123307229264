<div class="dialog-header" mat-dialog-title [align]="'center'">
  Onboard {{ data.name | titlecase }}
</div>
<div class="dialog-body1" mat-dialog-content>
  <div class="body-header">
    <div *ngIf="!flags.teacher && !flags.admin && !flags.student" class="head">
      Generate Invitation Link Or Add Manually
    </div>
    <div class="head" *ngIf="flags.teacher || flags.admin || flags.student">
      Add {{ data.name | titlecase }}
    </div>
  </div>
  <div class="body-content" *ngIf="!flags.teacher && !flags.admin && !flags.student">
    <div class="card" *ngIf="!!data.shareLink">
      <div class="card-left flex-wrap">
        <div class="icon">
          <img src="../../../../../assets/icons/school-onboard/Attach.svg" alt="" />
        </div>
        <div class="content">
          <div class="title">Get Link</div>
          <div class="sub-title">
            Share this link with {{ data.name | titlecase }} to onboard them to
            your school
          </div>
        </div>
      </div>
      <div class="card-right flex-wrap" *ngIf="data.name != 'student'">
        <button class="action-btn" [ngClass]="{ greenbutton: copied }" (click)="copylink()">
          {{ copied ? "Copied!" : "Copy Link" }}
        </button>
      </div>
    </div>
    <div class="card mt-2" *ngIf="data.name == 'student'">
      <div class="card-left">
        <mat-form-field appearance="outline" *ngIf="data.name == 'student'">
          <mat-label>Select Class</mat-label>
          <mat-select [(ngModel)]="studentClass" (selectionChange)="print()">
            <mat-option *ngFor="let item of schoolClasses" [value]="item">
              {{ item.name | uppercase }}
              {{ item.section | uppercase }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="card-right">
        <button class="action-btn" [ngClass]="{ greenbutton: copied }" (click)="createDynamicLinkForStudent()"
          [disabled]="!studentClass">
          {{ copied ? "Copied!" : "Copy Link" }}
        </button>
      </div>


    </div>
    <mat-divider *ngIf="!!data.shareLink" style="margin-bottom: 28px; margin-top: 28px"></mat-divider>
    <div class="card" *ngIf="false">
      <div class="card-left " >
        <div class="icon">
          <img src="../../../../../assets/icons/school-onboard/Attach.svg" alt="" />
        </div>
        <div class="content">
          <div class="title">Attach CSV</div>
          <div class="sub-title">
            Attach a CSV file according to the sample format to onboard
            {{ data.name | lowercase }} to your school.
          </div>
        </div>
      </div>
      <div class="card-right">
        <button class="action-btn">Attach</button>
      </div>
    </div>
    <!-- <mat-divider style="margin-bottom: 28px; margin-top: 28px"></mat-divider> -->
    <div class="card">
      <div class="card-left flex-wrap">
        <div class="icon">
          <img src="../../../../../assets/icons/school-onboard/Add.svg" alt="" />
        </div>
        <div class="content">
          <div class="title">Attach Manually</div>
          <div class="sub-title">
            Add {{ data.name | lowercase }} details and onboard them to your
            school
          </div>
        </div>
      </div>
      <div class="card-right">
        <button (click)="openManual(data)" class="action-btn">Add</button>
      </div>
    </div>
  </div>
  <div class="roleForm-outer" *ngIf="flags.teacher || flags.admin || flags.student">
    <form *ngIf="flags.teacher" action="" class="roleForm-inner" [formGroup]="addTeacherForm">
      <mat-form-field appearance="outline">
        <mat-label>Full Name</mat-label>
        <input matInput formControlName="fullName" required placeholder="Enter your full name" />
      </mat-form-field>
      <mat-error *ngIf="teacherForm.fullName.invalid && submitted">Please enter the Name</mat-error>
      <mat-form-field appearance="outline" class="phone">
        <ngx-mat-intl-tel-input format="default" formControlName="phone" [preferredCountries]="['in']"
          [enablePlaceholder]="true" [enableSearch]="true" name="phone" style="font-family: Nunito"
          inputPlaceholder="Enter Mobile number">
        </ngx-mat-intl-tel-input>
      </mat-form-field>

      <p style="text-align: center;">or</p>
      <!-- <mat-error *ngIf="teacherForm.fullName.invalid && submitted">Please enter the Name</mat-error> -->
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Enter the email" formControlName="email" />
        <mat-icon matSuffix>email</mat-icon>
      </mat-form-field>
      <!-- <mat-error *ngIf="teacherForm.email">Please enter the Name</mat-error> -->
      <div class="gender-label">Select Gender</div>
      <div class="radio-button-div">
        <label for="male" class="l-radio">
          <input type="radio" id="male" value="male" formControlName="gender" />
          <span>Male</span>
        </label>
        <label for="female" class="l-radio">
          <input type="radio" id="female" value="female" formControlName="gender" />
          <span>Female</span>
        </label>
        <label for="other" class="l-radio">
          <input type="radio" id="other" value="other" formControlName="gender" />
          <span>Other</span>
        </label>

        <mat-error *ngIf="teacherForm.gender.invalid && submitted">Please select your gender</mat-error>
      </div>
      <div class="btn-div">
        <button class="next-btn flex justify-center" [disabled]="loading" (click)="formAction(data)">
          <span *ngIf="!loading"> Submit </span>

          <mat-spinner *ngIf="loading" class="p-0 m-0 buttonSpinner" diameter="20"></mat-spinner>
        </button>
      </div>
    </form>
    <form *ngIf="flags.student" action="" class="roleForm-inner" [formGroup]="addStudentForm">
      <mat-form-field appearance="outline">
        <mat-label>Full Name</mat-label>
        <input matInput required formControlName="fullName" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Class</mat-label>
        <mat-select (selectionChange)="valueChange($event)" required formControlName="class"
          [errorStateMatcher]="matcher">
          <mat-option *ngFor="let item of schoolClasses" [value]="item">
            {{ item.name | uppercase }}
            {{ item.section | uppercase }}</mat-option>
        </mat-select>
        <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
        <!-- <mat-error *ngIf="selected.hasError('required')">You must make a selection</mat-error>
        <mat-error *ngIf="selected.hasError('pattern') && !selected.hasError('required')">
          Your selection is invalid
        </mat-error> -->
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Roll number</mat-label>
        <input matInput formControlName="rollNumber" required
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />

        <mat-error *ngIf="studentForm.rollNumber?.errors?.rollPresent && submitted">Roll Number is already taken
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="phone">
        <ngx-mat-intl-tel-input format="default" formControlName="phone" [preferredCountries]="['in']"
          [enablePlaceholder]="true" [enableSearch]="true" name="phone" style="font-family: Nunito"
          inputPlaceholder="Enter Mobile number">
        </ngx-mat-intl-tel-input>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Enter the email" formControlName="email" required />
        <mat-icon matSuffix>email</mat-icon>
      </mat-form-field>
      <div class="gender-label">Select Gender</div>
      <div class="radio-button-div">
        <label for="male" class="l-radio">
          <input formControlName="gender" type="radio" id="male" value="male" />
          <span>Male</span>
        </label>
        <label for="female" class="l-radio">
          <input formControlName="gender" type="radio" id="female" value="female" />
          <span>Female</span>
        </label>
        <label for="other" class="l-radio">
          <input formControlName="gender" type="radio" id="other" value="other" />
          <span>Other</span>
        </label>

        <mat-error *ngIf="studentForm.gender.invalid && submitted">Please select your gender</mat-error>
      </div>
      <div class="btn-div">
        <button [disabled]="loading" type="submit" class="next-btn justify-center" (click)="formAction(data)">
          <span *ngIf="!loading"> Submit </span>

          <mat-spinner *ngIf="loading" class="p-0 m-0 buttonSpinner" diameter="20"></mat-spinner>
        </button>
      </div>
    </form>
    <form *ngIf="flags.admin" action="" class="roleForm-inner" [formGroup]="addAdminForm">
      <mat-form-field appearance="outline">
        <mat-label>Full Name</mat-label>
        <input matInput formControlName="fullName" required placeholder="Enter your full name" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Role</mat-label>
        <mat-select (selectionChange)="valueChangeRole($event)" required formControlName="role"
          [errorStateMatcher]="matcher">
          <mat-option value="principal">Principal</mat-option>
          <mat-option value="vice-principal">Vice-Principal</mat-option>
          <mat-option value="other">Other</mat-option>
          <!-- <mat-option >
            <input
              type="text"
              placeholder="Class Name"
              class="add-class-input"
              formControlName="role"
            />
          </mat-option> -->
        </mat-select>
        <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
        <!-- <mat-error *ngIf="selected.hasError('required')">You must make a selection</mat-error>
        <mat-error *ngIf="selected.hasError('pattern') && !selected.hasError('required')">
          Your selection is invalid
        </mat-error> -->
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="adminForm.roleName.enabled">
        <mat-label>Role name</mat-label>
        <input matInput formControlName="roleName" required placeholder="Enter the role" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="phone">
        <ngx-mat-intl-tel-input formControlName="phone" format="default" [preferredCountries]="['in']"
          [enablePlaceholder]="true" [enableSearch]="true" name="phone" style="font-family: Nunito"
          inputPlaceholder="Enter Mobile number">
        </ngx-mat-intl-tel-input>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Enter the email" formControlName="email" required />
        <mat-icon matSuffix>email</mat-icon>
      </mat-form-field>
      <div class="gender-label">Select Gender</div>
      <div class="radio-button-div">
        <label for="male" class="l-radio">
          <input type="radio" id="male" value="male" formControlName="gender" />
          <span>Male</span>
        </label>
        <label for="female" class="l-radio">
          <input type="radio" id="female" value="female" formControlName="gender" />
          <span>Female</span>
        </label>
        <label for="other" class="l-radio">
          <input type="radio" id="other" value="other" formControlName="gender" />
          <span>Other</span>
        </label>

        <!-- <mat-error *ngIf="profileFormControls.gender.invalid && submitted">Please select your gender</mat-error> -->
      </div>
      <div class="btn-div" mat-dialog-actions>
        <button class="next-btn" (click)="formAction(data)" [disabled]="loading">
          <span *ngIf="!loading"> Submit </span>

          <mat-spinner *ngIf="loading" class="p-0 m-0 buttonSpinner" diameter="20"></mat-spinner>
        </button>
      </div>
    </form>
  </div>
</div>

<span class="close" mat-dialog-close>
  <img src="../../../../../assets/icons/school-onboard/close-icon-dialog.svg" alt="" />
</span>
