<div class="container">
  <h1 class="title">Select Teacher to Substitute</h1>

  <div class="horizontal_line mt-3"></div>

  <!-- list of teachers -->
  <div class="profiles mt-5" *ngIf="teacherDetails?.length">
    <div
      (click)="gotoSubstitute(item)"
      class="profile"
      *ngFor="let item of teacherDetails"
    >
      <div class="info">
        <img
          [src]="
            !!item?.photo_url
              ? item?.photo_url
              : item?.gender == 'male'
              ? '../../../../assets/images/teachers/teacher-default-male.svg'
              : '../../../../assets/images/teachers/teacher-default-female.svg'
          "
          alt="teacher"
          class="profile_icon"
        />
        <div class="details">
          <h4>{{ item?.name.join(" ") | titlecase }}</h4>
          <!-- <p>subject</p> -->
        </div>
      </div>
    </div>

    <div class="horizontal_line mb-3 mt-3"></div>
  </div>
</div>
