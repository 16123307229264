import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { cloneDeep } from 'lodash';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LibraryService } from 'src/app/shared/services/library.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { StudentsAndTeachersState } from 'src/app/shared/store/students_teachers/students_teachers.state';

@Component({
  selector: 'app-library-teachers-list-dialog',
  templateUrl: './library-teachers-list-dialog.component.html',
  styleUrls: ['./library-teachers-list-dialog.component.scss'],
})
export class LibraryTeachersListDialogComponent implements OnInit {
  schoolTeacherList: any[] = [];
  displayTeachersList: any[] = [];
  selectedTeachersArray: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private dialogSrvc_: DialogService,
    private librarySrvc_: LibraryService
  ) {}

  ngOnInit(): void {
    this.store
      .select(StudentsAndTeachersState.getSchoolTeachers)
      .subscribe((el: any) => {
        console.log(el);
        this.schoolTeacherList = cloneDeep(el);
        this.displayTeachersList = this.schoolTeacherList;
        console.log(this.displayTeachersList);
      });
  }

  popPushTeacher(id: string) {
    if (this.selectedTeachersArray.length >= 1) {
      this.selectedTeachersArray = [];
    }
    this.selectedTeachersArray.push(id);
  }

  onSavingTeachers() {
    this.librarySrvc_.selectedTeacher = this.schoolTeacherList.find(
      (teacher: any) => teacher.id == this.selectedTeachersArray[0]
    );
    this.librarySrvc_.selectedBorrowerSubject.next(
      this.librarySrvc_.selectedTeacher
    );
    this.dialogSrvc_.close();
  }
}
