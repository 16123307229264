import { username } from './../models/username';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { map, tap } from 'rxjs/operators';
import { docUser } from '../models/docUser';
import { schoolDbData } from '../models/school_db_data';
import { school } from '../models/school';
import { schoolAdmin } from '../models/school_admin';
@Injectable({
  providedIn: 'root',
})
export class UserCheckService {
  constructor(private firestore: AngularFirestore) {}
  checkUserPresent(docid: string) {
    return this.firestore
      .collection('users')
      .doc(docid)
      .get()
      .pipe(
        map((documentSnapshot) => {
          var doc_data: any = documentSnapshot.data();
          return { ...doc_data };
        })
      );
  }

  get windowRef() {
    return window;
  }

  checkUsername(username: string) {
    return this.firestore
      .collection('usernames', (ref) => ref.where('username', '==', username))
      .valueChanges()
      .pipe(tap((el) => console.log(el)));
  }

  createUserdoc(data: docUser, uid: string) {
    return this.firestore.collection('users').doc(uid).set(data);
  }

  createUsername(data: username, uid: string) {
    let ref = this.firestore.collection('users').doc(uid).ref;
    data.reference = ref;
    return this.firestore.collection('usernames').add(data);
  }

  CreateSchoolDoc(data: school) {
    return this.firestore.collection('schools').add(data);
  }

  CreateSchoolAdminDoc(data: schoolAdmin) {
    return this.firestore.collection('school_admin').add(data);
  }

  getSchoolAdmin(uid: string) {
    return this.firestore
      .collection('school_admin', (ref) =>
        ref.where('user_id', '==', uid).where('deleted', '==', false)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            return { ...data };
          })
        )
      );
  }

  checkIfUserIsAdminInSchool(uid: string, sid: string) {
    return this.firestore
      .collection('school_admin', (ref) =>
        ref
          .where('user_id', '==', uid)
          .where('school_id', '==', sid)
          .where('deleted', '==', false)
          .where('active', '==', true)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            return { ...data };
          })
        )
      );
  }
}
