<div class="dropdown_container">
  <div class="">
    <ng-content select="[content-title]"></ng-content>
  </div>

  <div class="drop_downContent" [style]="styles">
    <ng-content select="[content-items]"></ng-content>
  </div>
</div>
<!--
<div class="relative dropdown_container" >
    <button >
        <img DefaultImage [src]="
            !!(userData$ | async).userData.photo_url
              ? (userData$ | async).userData.photo_url
              : (userData$ | async).userData.gender == 'male'
              ? '../../../../assets/images/admin-default-male.svg'
              : '../../../../assets/images/admin-default-female.svg'
          " class="profile_img" alt="" default="../../../../assets/icons/no_profile_pic.svg" />
    </button>
    <div class="drop_downContent ">
        <div class="drop_downContent drop_downContent">

            <div class="drop_item text-ink_base  regular_normal1 p-3"  *ngFor="let item of profile_dropdown_items">
                <img src="../../../../assets/icons/side-bar/profile.svg" alt="">
                <a (click)="OndropDownClick(item.name)">{{ item.name }}</a>
            </div>
        </div>


    </div>
</div> -->
