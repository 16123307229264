import { Action, Select, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  addSchoolExam,
  deleteSchoolExam,
  removeExamsList,
  removeSelectedSubjectExamDetails,
  removeStudentMarksEntryDetails,
  SchoolExamsList,
  SelectedSubjectExamDetails,
  StudentMarksEntryDetails,
} from './exam-center.action';
import { append, patch, removeItem } from '@ngxs/store/operators';

@State<any>({
  name: 'SchoolExamsState',
  defaults: {
    schoolExams: [],
    selectedSubjectExamDetails: {},
    studentMarksEntryDetails: {},
  },
})
@Injectable()
export class SchoolExamState {
  @Select() static getExamList(state: any) {
    return state.SchoolExamsState.schoolExams;
  }
  constructor() {}

  @Action(SchoolExamsList)
  setExams(ctx: StateContext<any>, { exams }: SchoolExamsList) {
    console.log('new dispatch', exams);
    ctx.setState(
      patch({
        schoolExams: exams,
      })
    );
  }

  @Action(SelectedSubjectExamDetails)
  setSelectedSubjectExamDetails(
    ctx: StateContext<any>,
    { selectedSubjectExamDetails }: SelectedSubjectExamDetails
  ) {
    ctx.setState(
      patch({
        selectedSubjectExamDetails: selectedSubjectExamDetails,
      })
    );
  }

  @Action(StudentMarksEntryDetails)
  setStudentMarksEntryDetails(
    ctx: StateContext<any>,
    { studentMarksEntryDetails }: StudentMarksEntryDetails
  ) {
    ctx.setState(
      patch({
        studentMarksEntryDetails: studentMarksEntryDetails,
      })
    );
  }

  @Action(addSchoolExam)
  addExam(ctx: StateContext<any>, { exam }: addSchoolExam) {
    ctx.setState(
      patch({
        schoolExams: append([exam]),
      })
    );
  }

  @Action(deleteSchoolExam)
  deleteExam(ctx: StateContext<any>, { exam }: addSchoolExam) {
    ctx.setState(
      patch({
        schoolExams: removeItem<any>((id) => id === exam.id),
      })
    );
  }

  @Action(removeSelectedSubjectExamDetails)
  removeSelectedSubjectExamDetails(ctx: StateContext<any>) {
    ctx.setState(patch({ selectedSubjectExamDetails: {} }));
  }

  @Action(removeExamsList)
  removeExams(ctx: StateContext<any>) {
    ctx.setState(
      patch({
        schoolExams: [],
      })
    );
  }

  @Action(removeStudentMarksEntryDetails)
  removeStudentMarksEntryDetails(ctx: StateContext<any>) {
    ctx.setState(patch({ studentMarksEntryDetails: {} }));
  }
}
