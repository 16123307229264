<div class="container p-1">
  <div class="card_head mb-3">
    <h1 class="title text-center">Select Class</h1>
    <!-- remove image -->
    <img
      src="../../../../assets/icons/close.svg"
      alt="teacher"
      class="remove cursor-pointer"
      (click)="dialogServ_.dismissAll()"
    />
  </div>
  <div
    class="card_body flex flex-col gap-3 items-center justify-center flex-wrap"
  >
    <div class="side_head">
      <!-- <h1 class="leading-4">
        Assign Classes to {{ data?.name?.join(" ") | titlecase }}
      </h1> -->
      <!-- <p>0 Classes</p> -->
    </div>

    <div class="flex justify-center flex-wrap">
      <div
        id="ck-button"
        [ngClass]="{ borderLess: findSelection(sec) }"
        *ngFor="let sec of Classes; let i = index"
      >
        <label>
          <input
            type="checkbox"
            value="{{ sec }}"
            [checked]="findSelection(sec)"
            (click)="Selection($event, sec)"
          /><span style="padding: 12px">
            Class &nbsp;{{ sec?.name | uppercase }}

            {{ sec.section | uppercase }}
          </span>
        </label>
      </div>
    </div>

    <!-- class list -->
    <!-- <div class="class_list grid gap-4 grid-cols-6">


      <button
        class="section-btn"
        *ngFor="let sec of schoolClasses; let i = index"
        (click)="Selection(sec)"
        [ngClass]="{ selected: findSelection(sec) }"
      >
        <span>Class &nbsp; </span>{{ sec?.name | uppercase }}

        {{ sec.section | uppercase }}
      </button>
   >
    </div> -->
  </div>
  <div class="card_footer">
    <button
      mat-stroked-button
      class="cancel_btn"
      (click)="dialogServ_.dismissAll()"
    >
      Cancel
    </button>
    <button
      *ngIf="selectedClasses.length"
      (click)="addToTT()"
      mat-flat-button
      class="save_btn"
      class="full"
    >
      Save and Proceed
    </button>
  </div>
</div>
