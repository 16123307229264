import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  expanded :number;
  @Input() id:any;

  constructor() { }

  ngOnInit(): void {
  }

  toggle(index:number){
    if(this.expanded == index){
      this.expanded = -1;
    }else{
      this.expanded = index;
    }
  }

}
