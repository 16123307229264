import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { cloneDeep, union } from 'lodash';
import { team_incharge } from 'src/app/shared/models/school_team.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { TeamService } from 'src/app/shared/services/team.service';
import { StudentsAndTeachersState } from 'src/app/shared/store/students_teachers/students_teachers.state';

@Component({
  selector: 'app-team-teachers-list-dialog',
  templateUrl: './team-teachers-list-dialog.component.html',
  styleUrls: ['./team-teachers-list-dialog.component.scss'],
})
export class TeamTeachersListDialogComponent implements OnInit {
  schoolTeacherList: any[] = [];
  displayTeachersList: any[] = [];
  selectedTeachersArray: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private commonSrvc_: CommonService,
    private schoolTeamSrvc_: TeamService,
    private snackBarSrvc_: SnackbarService,
    private dialogSrvc_: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.store
      .select(StudentsAndTeachersState.getSchoolTeachers)
      .subscribe((el: any) => {
        console.log(el);
        this.schoolTeacherList = cloneDeep(el);
        this.displayTeachersList = this.schoolTeacherList.filter(
          (teacher: any) => {
            if (!teacher.hasOwnProperty('team_id') || teacher.team_id == '') {
              return teacher;
            }
          }
        );
        console.log(this.displayTeachersList);
      });
  }

  popPushIncharge(id: string) {
    if (this.selectedTeachersArray.length >= 1) {
      this.selectedTeachersArray = [];
    }
    this.selectedTeachersArray.push(id);
  }

  popPushTeacher(id: string) {
    if (this.selectedTeachersArray.includes(id)) {
      let index = this.selectedTeachersArray.findIndex(
        (tid: string) => tid == id
      );
      this.selectedTeachersArray.splice(index, 1);
    } else {
      this.selectedTeachersArray.push(id);
      this.selectedTeachersArray = union(this.selectedTeachersArray);
    }
    console.log(this.selectedTeachersArray);
  }

  onSavingTeachers() {
    // let teachers: house_teacher[] = [];
    // for (let i = 0; i < this.selectedTeachersArray.length; i++) {
    //   const teacherDocId = this.selectedTeachersArray[i];
    //   let teacher = this.schoolTeacherList.find(
    //     (teacher: any) => teacher.id == teacherDocId
    //   );
    //   if (!!teacher) {
    //     let houseTeacher: house_teacher = {
    //       doc_id: teacherDocId,
    //       uid: teacher.user_id,
    //     };
    //     teachers.push(houseTeacher);
    //   }
    // }

    // console.log(teachers);

    // this.commonSrvc_.showSpinner = true;
    // this.schoolHouseSrvc_
    //   .updateTeachersArrayInHouseDoc(this.data.id, teachers)
    //   .then(() => {
    //     this.snackBarSrvc_.openSnackBarTop(
    //       'Teachers updated in house document',
    //       'Success'
    //     );
    //     this.schoolHouseSrvc_
    //       .updateMultipleTeacherDocs(this.selectedTeachersArray, this.data.id)
    //       .then(() => {
    //         this.commonSrvc_.showSpinner = false;
    //         this.snackBarSrvc_.openSnackBarTop(
    //           `Teacher docs updated successfully`,
    //           'Success'
    //         );
    //         this.dialogSrvc_.close();
    //       })
    //       .catch((err) => {
    //         this.commonSrvc_.showSpinner = false;
    //         this.snackBarSrvc_.openSnackBarTop(
    //           `Error in updation of teacher docs due to ${err}`,
    //           'Failure'
    //         );
    //       });
    //   })
    //   .catch((err: any) => {
    //     this.commonSrvc_.showSpinner = false;
    //     this.snackBarSrvc_.openSnackBarTop(
    //       `Document updation failed due to ${err}`,
    //       'Failure'
    //     );
    //   });
  }

  onSavingIncharge() {
    let teacher = this.schoolTeacherList.find(
      (teacher: any) => teacher.id == this.selectedTeachersArray[0]
    );
    let teamIncharge: team_incharge = {
      doc_id: this.selectedTeachersArray[0],
      role: this.data.role.toLowerCase(),
      uid: teacher.user_id,
    };
    console.log(teamIncharge);
    if (this.data.action == 'add') {
      this.commonSrvc_.showSpinner = true;
      this.schoolTeamSrvc_
        .addInchargeToTeamDoc(this.data.id, teamIncharge)
        .then(() => {
          this.snackBarSrvc_.openSnackBarTop(
            'Incharge added successfully to team doc',
            'Success'
          );
          this.schoolTeamSrvc_
            .updateTeacherDoc(teamIncharge.doc_id, this.data.id)
            .then(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Teacher Document updated successfully',
                'Success'
              );
              this.commonSrvc_.showSpinner = false;
              this.dialogSrvc_.close();
              this.router.navigate([`layout/community/teams/list`]);
            })
            .catch(() => {
              this.snackBarSrvc_.openSnackBar(
                'Teacher document updation failed',
                'Failure'
              );
              this.commonSrvc_.showSpinner = false;
            });
        })
        .catch(() => {
          this.snackBarSrvc_.openSnackBar(
            'Incharge addition failed',
            'Failure'
          );
          this.commonSrvc_.showSpinner = false;
        });
    } else if (this.data.action == 'edit') {
      this.commonSrvc_.showSpinner = true;
      console.log(this.data.incharges);
      let team_incharges = this.data.incharges;
      team_incharges = team_incharges.map((hIncharge: any) => {
        let obj: team_incharge = {
          doc_id: hIncharge.doc_id,
          role: hIncharge.role.toLowerCase(),
          uid: hIncharge.uid,
        };
        return obj;
      });
      let index = team_incharges.findIndex(
        (hIn: any) => hIn.doc_id == this.data.incharge_doc_id
      );
      team_incharges.splice(index, 1, teamIncharge);
      this.schoolTeamSrvc_
        .updateTeacherDoc(this.data.incharge_doc_id, '')
        .then(() => {
          this.snackBarSrvc_.openSnackBarTop(
            'Teacher Document has been updated successfully',
            'Success'
          );
          this.schoolTeamSrvc_
            .updateInchargesArrayInTeamDoc(team_incharges, this.data.id)
            .then(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Incharges array has been updated successfully',
                'Success'
              );
              this.schoolTeamSrvc_
                .updateTeacherDoc(this.selectedTeachersArray[0], this.data.id)
                .then(() => {
                  this.snackBarSrvc_.openSnackBar(
                    'Teacher Document has been updated successfully',
                    'Success'
                  );
                  this.commonSrvc_.showSpinner = false;
                  this.dialogSrvc_.close();
                  this.router.navigate([`layout/community/teams/list`]);
                })
                .catch(() => {
                  this.snackBarSrvc_.openSnackBarTop(
                    'Teacher Document updation failed',
                    'Failure'
                  );
                  this.commonSrvc_.showSpinner = false;
                });
            })
            .catch(() => {
              this.snackBarSrvc_.openSnackBarTop(
                'Incharges array updation failed',
                'Failure'
              );
              this.commonSrvc_.showSpinner = false;
            });
        })
        .catch(() => {
          this.snackBarSrvc_.openSnackBarTop(
            'Teacher Document updation failed',
            'Failure'
          );
          this.commonSrvc_.showSpinner = false;
        });
    }
  }
}
