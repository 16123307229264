<div class="scroolsd scroll-smooth  ">
    <div class="overflow-auto w-full flex gap-3 " id="scrollable">
        <ng-content>
            
        </ng-content>

    </div>
    <div class="navibuttons">
      <button (click)="scroll('left')">
        <img src="../../../../assets/images/icons/arrow-left.svg" alt="">
      </button>

      <button (click)="scroll('right')">
        <img src="../../../../assets/images/icons/arrow-right.svg" alt="">

      </button>
    </div>
  </div>