<div class="grid grid-cols-12 gap-4 items-center">
    <div class="md:col-span-4 col-span-12">
        <mat-form-field appearance="outline">
            <mat-label>Enter fine amount</mat-label>
            <input matInput placeholder="Enter fine amount">
        </mat-form-field>
    </div>
    <div class="md:col-span-4 col-span-12">
        <mat-form-field appearance="outline">
            <mat-label>Enter Dicount amount</mat-label>
            <input matInput placeholder="Enter Dicount amount">
        </mat-form-field>
    </div>
    <div class="md:col-span-4 col-span-12 relative -top-4">
        <h1 class="text-purple_base small_tight3">Total payable - ₹1000</h1>
    </div>
</div>
<div class="flex justify-center ">

    <app-buttons label="Mark as lost" color="blue"></app-buttons>
</div>


