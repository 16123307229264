import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/firestore';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Store } from '@ngxs/store';
import firebase from 'firebase';
import { cloneDeep, isEmpty } from 'lodash';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { curriculum } from 'src/app/auth/shared/models/school';
import { schoolForStore } from 'src/app/auth/shared/models/schoolForStore';
import { SchoolExam } from '../models/exam_model';
import { SchoolExamMarks } from '../models/schoolExamMarks.modal';
import { SyllabusModel } from '../models/syllabus.model';
import { timeTableRoot } from '../models/timeTableRoot';
import { fetchAdmins } from '../store/currentSchoolAdmins/currentSchoolAdmin.action';
import { schoolListState } from '../store/school/school-list.state';
import { SelectedSchoolState } from '../store/school/selected-school.state';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  selectedSchool$ = this.store.select(SelectedSchoolState);
  sub1: Subscription;

  constructor(
    private firestore: AngularFirestore,
    private store: Store,
    private httpClientSrvc_: HttpClient
  ) {
    // this.searchForSyllabus('geography', '1').subscribe((el) => {
    //   console.log(el);
    // });
  }

  showSpinner: boolean = false;

  classesArray: any = [
    { class: 'nursary', score: 0 },
    { class: 'nursery', score: 0 },
    { class: 'lkg', score: 1 },
    { class: 'ukg', score: 2 },
    { class: '1', score: 3 },
    { class: '2', score: 4 },
    { class: '3', score: 5 },
    { class: '4', score: 6 },
    { class: '5', score: 7 },
    { class: '6', score: 8 },
    { class: '7', score: 9 },
    { class: '8', score: 10 },
    { class: '9', score: 11 },
    { class: '10', score: 12 },
    { class: '11', score: 13 },
    { class: '12', score: 14 },
  ];

  sortClassForExams(classes: any) {
    let classes1 = cloneDeep(classes);
    classes1.sort((a: any, b: any) => {
      return (
        this.classesArray.find((el: any) => el.class == a).score -
        this.classesArray.find((el: any) => el.class == b).score
      );
    });
    return classes1;
  }

  sortClasses1(classes: any) {
    let classes1 = cloneDeep(classes);

    classes1 = classes1.map((clas: any) => {
      clas['score'] = this.classesArray.find(
        (clas1: any) => clas1.class.toLowerCase() == clas.name.toLowerCase()
      ).score;
      return clas;
    });

    // console.log(classes1);

    classes1.sort((a: any, b: any) => {
      return (
        this.classesArray.find((el: any) => el.class == a.name).score -
        this.classesArray.find((el: any) => el.class == b.name).score
      );
    });
    // sort classes based  on class and section
    classes1.sort((a: any, b: any) => {
      return (
        this.classesArray.find((el: any) => el.class == a.name).score -
          this.classesArray.find((el: any) => el.class == b.name).score ||
        a.section.localeCompare(b.section)
      );
    });
    classes1.forEach((clas: any) => {
      delete clas.score;
    });

    console.log(classes1);

    return classes1;
  }

  sortClassesForMarksPortal(classes: any) {
    let classes1 = cloneDeep(classes);

    classes1 = classes1.map((clas: any) => {
      clas['score'] = this.classesArray.find(
        (clas1: any) =>
          clas1.class.toLowerCase() == clas?.classDetails?.name?.toLowerCase()
      ).score;
      return clas;
    });

    // console.log(classes1);

    classes1.sort((a: any, b: any) => {
      return (
        this.classesArray.find((el: any) => el.class == a?.classDetails?.name)
          .score -
        this.classesArray.find((el: any) => el.class == b?.classDetails?.name)
          .score
      );
    });
    // sort classes based  on class and section
    classes1.sort((a: any, b: any) => {
      return (
        this.classesArray.find((el: any) => el.class == a?.classDetails?.name)
          .score -
          this.classesArray.find((el: any) => el.class == b?.classDetails?.name)
            .score ||
        a?.classDetails?.section.localeCompare(b?.classDetails?.section)
      );
    });
    classes1.forEach((clas: any) => {
      delete clas.score;
    });

    console.log(classes1);

    return classes1;
  }

  sortClasses(classes: any) {
    let classes1 = cloneDeep(classes);
    console.log(classes1);

    classes1 = classes1.map((clas: any) => {
      clas['score'] = this.classesArray.find(
        (clas1: any) => clas1.class == clas.grade
      ).score;
      return clas;
    });

    // console.log(classes1);

    classes1.sort((a: any, b: any) => {
      return (
        this.classesArray.find((el: any) => el.class == a.grade).score -
        this.classesArray.find((el: any) => el.class == b.grade).score
      );
    });
    // sort classes based  on class and section
    classes1.sort((a: any, b: any) => {
      return (
        this.classesArray.find((el: any) => el.class == a.grade).score -
          this.classesArray.find((el: any) => el.class == b.grade).score ||
        a.section.localeCompare(b.section)
      );
    });
    classes1.forEach((clas: any) => {
      delete clas.score;
    });

    return classes1;
  }

  getSchoolFromFb(sid: string) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .snapshotChanges()
      .pipe(
        map((changes) => {
          const data: any = changes.payload.data();
          const id = changes.payload.id;
          return { id, ...data };
        })
      );
  }

  getUserDoc(id: string) {
    return this.firestore.collection('users').doc(id).get();
  }
  getUserDocDetails(id: string) {
    return this.firestore
      .collection('users')
      .doc(id)
      .get()
      .pipe(
        map((documentSnapshot) => {
          var doc_data: any = documentSnapshot.data();
          return { ...doc_data };
        })
      );
  }

  addingSyllabusToClass(
    schoolId: string,
    classId: string,
    syllabus: SyllabusModel
  ) {
    return this.firestore
      .collection('schools')
      .doc(schoolId)
      .collection('classes')
      .doc(classId)
      .collection('syllabus')
      .add(syllabus);
  }

  getUserDocSnapshot(id: string) {
    return this.firestore
      .collection('users')
      .doc(id)
      .snapshotChanges()
      .pipe(
        // tap((el) => console.log(el)),
        map((el) => {
          return el.payload.data();
        })
      );
  }

  getSchoolAdmins(schoolId: string) {
    return this.firestore
      .collection('school_admin', (ref) =>
        ref
          .where('school_id', '==', schoolId)
          .where('active', '==', true)
          .where('deleted', '==', false)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getAdmins() {
    this.selectedSchool$.subscribe((el) => {
      let currentSchoolId = el.selectedAdmin.school_id;
      this.sub1 = this.getSchoolAdmins(currentSchoolId).subscribe((el) => {
        console.log(el);
        this.store.dispatch(new fetchAdmins(el));
      });
    });
  }

  getCurrentSchoolidObservable() {
    return this.store.select(SelectedSchoolState.getselectedAdminSchoolId);
    // return this.store.select(SelectedSchoolState.getselectedAdmin);
  }

  getCurrentSchoolDetails(id: string): Observable<schoolForStore> {
    let schoollist$ = this.store.select(schoolListState.schools);
    return schoollist$.pipe(
      map((school: any) => school.find((ell: any) => ell.id === id))
    );
  }

  getSchoolClasses(schoolId: string, schoolCuriculum: curriculum) {
    return this.firestore
      .collection('schools')
      .doc(schoolId)
      .collection('classes', (ref) =>
        ref
          .where('deleted', '==', false)
          .where('active', '==', true)
          .where('curriculum', '==', schoolCuriculum)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {
              id,
              ...data,
              school_id: schoolId,
              schoolCurriculum: schoolCuriculum,
            };
          });
        })
      );
  }

  getSchoolTeacherAttendance(schoolId: string) {
    return this.firestore
      .collection('school_teacher_attendance', (ref) =>
        ref.where('school_id', '==', schoolId)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            // data.class_ref = data.class_ref.path;
            return { ...data, id };
          })
        )
      );
  }

  getSchoolClassesGet(schoolId: string, schoolCuriculum: curriculum) {
    return this.firestore
      .collection('schools')
      .doc(schoolId)
      .collection('classes', (ref) =>
        ref
          .where('deleted', '==', false)
          .where('active', '==', true)
          .where('curriculum', '==', schoolCuriculum)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            return {
              ...data,
              id,
              school_id: schoolId,
              schoolCurriculum: schoolCuriculum,
            };
          })
        )
      );
  }

  getClassDoc(schoolid: string, classId: string) {
    return this.firestore
      .collection('schools')
      .doc(schoolid)
      .collection('classes')
      .doc(classId)
      .get()
      .pipe(
        // tap((el) => console.log(el)),
        map((el) => {
          return { ...el.data(), id: classId };
        })
      );
  }
  getStudentsFromClassid(schoolid: string, classId: string) {
    let classref = this.firestore
      .collection('schools')
      .doc(schoolid)
      .collection('classes')
      .doc(classId).ref;

    let studlist$ = this.firestore
      .collection('school_student', (ref) =>
        ref
          .where('deleted', '==', false)
          .where('active', '==', true)
          .where('class_ref', '==', classref)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {
              id,
              ...data,
            };
          });
        })
      );

    let detailedSTudList$ = studlist$.pipe(
      switchMap((data: any) => {
        if (data.length) {
          return combineLatest(
            data.map((el1: any) => {
              return this.getUserDoc(el1.user_id)
                .pipe(
                  map((documentSnapshot) => {
                    var doc_data: any = documentSnapshot.data();
                    return { ...doc_data };
                  })
                )
                .pipe(
                  map((user: any) => {
                    Object.assign(el1, {
                      userDetails: user,
                    });
                    return el1;
                  })
                );
            })
          );
        } else return of([] as any[]);
      })
    );

    return detailedSTudList$;
  }

  getCount(id: string, collectionName: string) {
    return this.firestore
      .collection(collectionName, (ref) =>
        ref
          .where('active', '==', true)
          .where('deleted', '==', false)
          .where('school_id', '==', id)
      )
      .valueChanges();
  }

  getClassAssessment(school_id: string, class_id: string, curriculum: any) {
    // console.log('fetching assessmntssss', school_id, class_id, curriculum);

    let class_ref = this.firestore
      .collection('schools')
      .doc(school_id)
      .collection('classes')
      .doc(class_id).ref;
    return this.firestore
      .collection('school_student_assessment', (ref) =>
        ref
          .where('class_ref', '==', class_ref)
          .where('curriculum', '==', curriculum)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            data.class_ref = data.class_ref.path;
            return { ...data, id };
          })
        )
      );
  }

  getClassAttendance(sid: string, cid: string) {
    let classref = this.firestore
      .collection('schools')
      .doc(sid)
      .collection('classes')
      .doc(cid).ref;

    return this.firestore
      .collection('school_student_attendance', (ref) =>
        ref.where('class_ref', '==', classref)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            data.class_ref = data.class_ref.path;
            return { ...data, id };
          })
        )
      );
  }

  getTeacherAttendance(sid: string) {
    return this.firestore
      .collection('school_teacher_attendance', (ref) =>
        ref.where('school_id', '==', sid)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();

            return { ...data, id };
          })
        )
      );
  }

  getStudentAssessments(schoolId: string, curriculum: any) {
    return this.firestore
      .collection('school_student_assessment', (ref) =>
        ref
          .where('curriculum', '==', curriculum)
          .where('school_id', '==', schoolId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            console.log(data);
            const id = a.payload.doc.id;
            data.class_ref = data.class_ref.path;
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  getClassSyllabus(schoolId: string, classId: string) {
    return this.firestore
      .collection('schools')
      .doc(schoolId)
      .collection('classes')
      .doc(classId)
      .collection('syllabus')
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            // data.class_ref = data.class_ref.path;
            return { id, ...data };
          })
        )
        // map((actions) => {
        //   return actions.map((a) => {
        //     console.log(a.payload.doc.data());
        //     const data: any = a.payload.doc.data();
        //     const id = a.payload.doc.id;
        //     return {
        //       id,
        //       ...data,
        //     };
        //   });
        // })
      );
  }
  getTeacherAssessment(tid: string, schoolid: string) {
    return this.firestore
      .collection('school_student_assessment', (ref) =>
        ref.where('teacher_id', '==', tid).where('school_id', '==', schoolid)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            data.class_ref = data.class_ref.path;
            return { ...data };
          })
        )
      );
  }

  searchForSyllabus(
    subject: string,
    grade: string,
    schoolid: string,
    schooldetails: any
  ) {
    let currentSchoolId: string;
    let currentSchoolDetails: schoolForStore;
    // this.getCurrentSchoolidObservable().subscribe((el) => {
    currentSchoolId = schoolid;
    // this.getCurrentSchoolDetails(currentSchoolId).subscribe((el) => {
    currentSchoolDetails = schooldetails;
    // });
    // });

    return this.firestore
      .collection('syllabus', (ref) =>
        ref
          .where('subject', '==', subject)
          .where('board', '==', currentSchoolDetails.board[0])
          .where(
            'medium',
            '==',
            currentSchoolDetails.medium[0]
              ? currentSchoolDetails.medium[0]
              : 'english'
          )
          .where('grade', '==', grade)
          .limit(1)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            return { ...data };
          })
        )
      );
  }

  getSyllabusSubjectWise(schoolid: string, classid: string, subject: string) {
    return this.firestore
      .collection('schools')
      .doc(schoolid)
      .collection('classes')
      .doc(classid)
      .collection('syllabus', (ref) => ref.where('subject', '==', subject))
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            return { ...data };
          })
        )
      );
  }

  removeClass(classId: string) {
    let currentschoolDocId = '';
    let selectedAdmin$ = this.store.select(SelectedSchoolState);
    let currentSchoolDetails$: Observable<any> = selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.getCurrentSchoolDetails(data.selectedAdmin.school_id);
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      currentschoolDocId = el.id;
    });
    return this.firestore
      .collection('schools')
      .doc(currentschoolDocId)
      .collection('classes')
      .doc(classId)
      .update({
        deleted: true,
        deleted_at: new Date(),
        active: false,
      });
  }

  decrementClassCount(classId: string) {
    let currentschoolDocId = '';
    let selectedAdmin$ = this.store.select(SelectedSchoolState);
    let currentSchoolDetails$: Observable<any> = selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.getCurrentSchoolDetails(data.selectedAdmin.school_id);
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      currentschoolDocId = el.id;
    });
    return this.firestore
      .collection('schools')
      .doc(currentschoolDocId)
      .update({
        classes: firebase.firestore.FieldValue.increment(-1),
      });
  }

  removeAdmin(id: string) {
    return this.firestore.collection('school_admin').doc(id).update({
      deleted: true,
      deleted_at: new Date(),
    });
  }

  removeStudent(rootId: string) {
    this.firestore.collection('school_student').doc(rootId).update({
      deleted: true,
      deleted_at: new Date(),
      active: false,
    });
  }

  decrementAdminCount(sid: string) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .update({
        admins: firebase.firestore.FieldValue.increment(-1),
      });
  }

  decrementStudentCount() {
    let currentschoolDocId = '';
    let selectedAdmin$ = this.store.select(SelectedSchoolState);
    let currentSchoolDetails$: Observable<any> = selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.getCurrentSchoolDetails(data.selectedAdmin.school_id);
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      currentschoolDocId = el.id;
    });
    return this.firestore
      .collection('schools')
      .doc(currentschoolDocId)
      .update({
        students: firebase.firestore.FieldValue.increment(-1),
      });
  }

  getClassesCountFromFB(schoolId: string) {
    let currentSchoolId: string;
    let currentSchoolDetails: schoolForStore;
    this.getCurrentSchoolidObservable().subscribe((el) => {
      currentSchoolId = el;
      this.getCurrentSchoolDetails(currentSchoolId).subscribe((el) => {
        currentSchoolDetails = el;
      });
    });
    return this.firestore
      .collection('schools')
      .doc(schoolId)
      .collection('classes', (ref) =>
        ref.where('deleted', '==', false).where('active', '==', true)
      )
      .valueChanges();
  }

  createTimetableRoot(sid: string, data: timeTableRoot) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .collection('timetable')
      .add(data);
  }

  CreateTimeTablePeriods(sid: string, tid: string, list: any[]) {
    var batch = this.firestore.firestore.batch();
    for (const iterator of list) {
      let batchref = this.firestore
        .collection('schools')
        .doc(sid)
        .collection('timetable')
        .doc(tid)
        .collection('days')
        .doc(iterator.day.toLowerCase()).ref;
      batch.set(batchref, iterator.data);
    }

    return batch.commit();
  }

  getTimetable(sid: string) {
    let TimetableRoot$ = this.firestore
      .collection('schools')
      .doc(sid)
      .collection('timetable')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {
              id,
              assignedSchool: sid,
              ...data,
            };
          });
        })
      );

    return TimetableRoot$.pipe(
      switchMap((data: any) => {
        if (data.length) {
          return forkJoin(
            data.map((el: any) =>
              this.firestore
                .collection('schools')
                .doc(sid)
                .collection('timetable')
                .doc(el.id)
                .collection('days')
                .get()
                .pipe(
                  map((docs) =>
                    docs.docs.map((doc) => {
                      let id: string = doc.id;
                      let data: any = doc.data();
                      return { ...data, day: id };
                    })
                  )
                )
                .pipe(
                  map((days: any) => {
                    // let data = days.data();

                    Object.assign(el, {
                      days: days,
                    });

                    return el;
                  })
                )
            )
          );
        } else return of([]);
      })
    );
  }

  getTimeTableDays(tid: string, sid: string) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .collection('timetable')
      .doc(tid)
      .collection('days')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {
              day: id,
              ...data,
            };
          });
        })
      );
  }

  getTimetabaleFormId(sid: string, tid: string) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .collection('timetable')
      .doc(tid)
      .get()
      .pipe(
        // tap((el) => console.log(el)),
        map((el) => {
          return { ...el.data() };
        })
      );
  }

  UpdateTimetableDays(sid: string, tid: string, obj: any) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .collection('timetable')
      .doc(tid)
      .collection('days')
      .doc(obj.day)
      .update({
        periods: obj.periods,
      });
  }

  removeTimetable(sid: string, tid: string) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .collection('timetable')
      .doc(tid)
      .delete();
  }

  EditTimetableRoot(sid: string, tid: string, data: any) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .collection('timetable')
      .doc(tid)
      .set(data);
  }

  getUsersAdminList(id: string) {
    return this.firestore
      .collection('school_admin', (ref) =>
        ref.where('user_id', '==', id).where('deleted', '==', false)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            return {
              ...data,
              id,
            };
          })
        )
      );
  }

  addClassesToTT(sid: string, ttid: string, classids: string[]) {
    var batch = this.firestore.firestore.batch();
    for (const iterator of classids) {
      let batchref = this.firestore
        .collection('schools')
        .doc(sid)
        .collection('timetable')
        .doc(ttid).ref;
      batch.update(batchref, {
        class_doc_ids: firebase.firestore.FieldValue.arrayUnion(iterator),
      });
    }

    return batch.commit();
  }

  removeClassFromtt(sid: string, ttid: string, list: string[]) {
    return this.firestore
      .collection('schools')
      .doc(sid)
      .collection('timetable')
      .doc(ttid)
      .update({
        class_doc_ids: list,
      });
  }

  fetchAbcentTeachers(schoolid: string) {
    let date = {
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };
    return this.firestore
      .collection('school_teacher_attendance', (ref) =>
        ref
          .where('attendance_date', '==', date)
          .where('school_id', '==', schoolid)

          .where('present', '==', false)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  fetchPresentTeachers(schoolid: string) {
    let date = {
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };
    return this.firestore
      .collection('school_teacher_attendance', (ref) =>
        ref
          .where('attendance_date', '==', date)
          .where('school_id', '==', schoolid)

          .where('present', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  fetchtodaysSubstitutedPeriods(schoolid: string) {
    let date = {
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };
    return this.firestore
      .collection('schools')
      .doc(schoolid)
      .collection('teacher_substitute', (ref) => ref.where('date', '==', date))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {
              id,
              ...data,
            };
          });
        })
      );
  }

  checkidSubstitutealreadyassigned(
    schoolid: string,
    start: string,
    end: string,
    classid: string,
    curriculum: any,
    teacherid: string
  ) {
    let date = {
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };

    console.log(schoolid, start, end, classid, curriculum, teacherid);
    return this.firestore
      .collection('schools')
      .doc(schoolid)

      .collection('teacher_substitute', (ref) =>
        ref
          .where('period_end', '==', end)
          .where('period_start', '==', start)
          .where('class_id', '==', classid)
          .where('date', '==', date)
          .where('absent_teacher_id', '==', teacherid)
          .where('curriculum', '==', curriculum)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {
              id,
              ...data,
            };
          });
        })
      );

    // db.collection.school.schhol.d .where(period_start = iterator.period_start).where(period_end = iterator.period_end ).where(classid==classid)
    // .where(date=date).where(curriculum =curriculum).where(absent_ teacher id === iterator.teacher id)
  }

  createTeacherSubstitute(schoolid: string, obj: any) {
    return this.firestore
      .collection('schools')
      .doc(schoolid)

      .collection('teacher_substitute')
      .add(obj);
  }

  updateTeacherSubstitute(schoolid: string, docid: string, subid: string) {
    return this.firestore
      .collection('schools')
      .doc(schoolid)
      .collection('teacher_substitute')
      .doc(docid)
      .update({
        substitute_teacher_id: subid,
      });
  }

  userNameValidatorFn(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      let firstLetter = value.charAt(0);

      let lastLetter = value.charAt(value.length - 1);

      const allowedSpecialCharacters = ['-', '.', '_'];

      //special character array
      const specialChars = [
        '!',
        '*',
        '#',
        '$',
        '%',
        '^',
        '&',
        '(',
        ')',
        '+',
        '=',
        '~',
        '`',
        '|',
        '@',
        '"',
        '{',
        '}',
        '[',
        ']',
        '/',
        '<',
        '>',
        '?',
        ':',
        ';',
        "'",
        '\\',
        '|',
      ];

      //can not start with digit
      //min 4 character
      //max 30 character
      //special symbol allowed(dot underscore hyphen)
      //can not end with any of special character
      //No 2 special character can be in a row

      const numberArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

      const startingWithDigit: boolean = !numberArray.includes(firstLetter);

      const lastValueValid: boolean =
        allowedSpecialCharacters.includes(lastLetter) ||
        specialChars.includes(lastLetter);

      const specialCharInRowValid: boolean = !value.match(/[-._]{2}/i);

      const specialCharValid: boolean = this.checkingSpecialCharacters(value);

      const userNameValid =
        startingWithDigit &&
        !lastValueValid &&
        specialCharInRowValid &&
        specialCharValid;

      if (!!userNameValid) {
        return null;
      } else {
        if (!startingWithDigit) {
          return { startingWithDigit: true };
        }
        if (!specialCharValid) {
          return { specialCharValid: true };
        }
        if (!specialCharInRowValid) {
          return { specialCharInRowValid: true };
        }
        if (!!lastValueValid) {
          return { lastValueValid: true };
        }
        return null;
      }

      // return !!userNameValid ? null : { userNameValid: true };
    };
  }

  checkingSpecialCharacters(value: string): boolean {
    let count = 0;
    const specialChars = [
      '!',
      '*',
      '#',
      '$',
      '%',
      '^',
      '&',
      '(',
      ')',
      '+',
      '=',
      '~',
      '`',
      '|',
      '@',
      '"',
      '{',
      '}',
      '[',
      ']',
      '/',
      '<',
      '>',
      '?',
      ':',
      ';',
      "'",
      '\\',
      '|',
    ];

    for (let i = 0; i < value.length; i++) {
      const element = value[i];
      if (specialChars.includes(element)) {
        count++;
      } else {
      }
    }
    return count == 0;
  }

  getnewapp() {
    let config = {
      apiKey: 'AIzaSyDjgsIvnqGvDo9B1kcH55s5v0HXvLpnDbo',
      authDomain: 'dev-hurrey.firebaseapp.com',
      projectId: 'dev-hurrey',
      storageBucket: 'dev-hurrey.appspot.com',
      messagingSenderId: '968893484382',
      appId: '1:968893484382:web:da0f5e92a28be8454ad3cf',
      measurementId: 'G-RJ61ZHK7JZ',
    };
    let app2 = firebase.initializeApp(config, 'secondary');
    let token = 'abcd';
    app2.auth().signInWithCustomToken(token);
  }

  addExamDoc(exam: SchoolExam) {
    return this.firestore.collection('school_exams').add(exam);
  }

  // get exam list
  getExamList(schoolId: string, curriculum: string) {
    //based on school id and curriculum from school_exams
    return this.firestore
      .collection('school_exams', (ref) =>
        ref
          .where('school_id', '==', schoolId)
          .where('curriculum', '==', curriculum)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a: DocumentChangeAction<any>) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            // exam_from and exam_to to date
            data.created_at = data.created_at.toDate();
            data.exam_from = data.exam_from.toDate();
            data.exam_to = data.exam_to.toDate();

            return {
              id,

              ...data,
            };
          });
        })
      );
  }

  getStuff(schoolId: string, curriculum: string) {
    let ob1$ = this.firestore
      .collection('school_exams', (ref) =>
        ref
          .where('school_id', '==', schoolId)
          .where('curriculum', '==', curriculum)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.created_at = data.created_at.toDate();
            data.exam_from = data.exam_from.toDate();
            data.exam_to = data.exam_to.toDate();
            return { id, ...data };
          });
        }),
        switchMap((data: SchoolExam[]) => {
          //switchmap = switching obsevables and subscribes internally
          const obs = data.map((item) => {
            if (!isEmpty(item.paper_ids)) {
              const ids = item.paper_ids;
              return forkJoin(
                // takes multiple observables and the calls in parellell
                (ids ?? []).map((id) =>
                  this.firestore
                    .doc(`school_question_papers/${id}`)
                    .get()
                    .pipe(
                      map((documentSnapshot) => {
                        var doc_data: any = documentSnapshot.data();
                        var id = documentSnapshot.id;
                        return { id, ...doc_data };
                      })
                    )
                )
              ).pipe(
                map((paperIdData) => {
                  return { ...item, paperIdData: paperIdData };
                })
              );
            }
            return of(item);
          });

          return forkJoin(obs);
        })
      );

    return ob1$;
  }

  // delete exam
  deleteExam(examId: string) {
    return this.firestore.collection('school_exams').doc(examId).delete();
  }

  updateExam(examId: string, exam: SchoolExam) {
    return this.firestore
      .collection('school_exams')
      .doc(examId)
      .set(exam, { merge: true });
  }

  getWeather(city: string) {
    //api.weatherapi.com/v1/forecast.json?key=cbe4098387ec48fb92f92805221606&q=madanapalle&days=8
    return this.httpClientSrvc_.get(
      `https://api.weatherapi.com/v1/forecast.json?key=cbe4098387ec48fb92f92805221606&q=${city}&days=5`
    );
  }

  getSchoolExamMarks(classId: string, exam_id: string, subject: string) {
    let currentschoolDocId = '';
    let selectedAdmin$ = this.store.select(SelectedSchoolState);
    let currentSchoolDetails$: Observable<any> = selectedAdmin$.pipe(
      switchMap((data: any) => {
        if (data && data.hasOwnProperty('selectedAdmin')) {
          return this.getCurrentSchoolDetails(data.selectedAdmin.school_id);
        } else return of([]);
      })
    );
    currentSchoolDetails$.subscribe((el) => {
      currentschoolDocId = el.id;
    });
    let classref = this.firestore
      .collection('schools')
      .doc(currentschoolDocId)
      .collection('classes')
      .doc(classId).ref;
    let exam_ref = this.firestore.collection('school_exams').doc(exam_id).ref;
    return this.firestore
      .collection('school_exam_marks', (ref) =>
        ref
          .where('class_ref', '==', classref)
          .where('exam_ref', '==', exam_ref)
          .where('subject', '==', subject.toLowerCase())
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a: DocumentChangeAction<any>) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.exam_from = data.exam_from.toDate();
            data.exam_to = data.exam_to.toDate();
            data.class_ref = data?.class_ref?.path;
            data.exam_ref = data?.exam_ref?.path;
            return { id, ...data };
          });
        })
      );
  }

  removeExtra(str: any) {
    // find and replace in string
    return str?.replace('//cdn.weatherapi.com', '');
  }
  // get all student exmas marks by exam ref
  getAllStudentExamMarks(examId: string) {
    console.log(examId);
    let exam_ref = this.firestore.doc(`school_exams/${examId}`).ref;
    return this.firestore
      .collection('school_exam_marks', (ref) =>
        ref.where('exam_ref', '==', exam_ref)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            return { ...data, id };
          })
        )
      );
  }
  // get student exam by student id and exam id
  getStudentExamScore(examId: string, studentId: string) {
    console.log(studentId, examId);
    // mathc exam id and student id
    let exam_ref = this.firestore.doc(`school_exams/${examId}`).ref;
    return this.firestore
      .collection('school_exam_marks', (ref) =>
        ref
          .where('exam_ref', '==', exam_ref)
          .where('student_id', '==', studentId)
      )
      .get()
      .pipe(
        map((docs) =>
          docs.docs.map((doc) => {
            let id: string = doc.id;
            let data: any = doc.data();
            return { ...data, id };
          })
        )
      );
  }

  addSchooExamMarksDoc(schooExamMarksDoc: SchoolExamMarks) {
    return this.firestore
      .collection('school_exam_marks')
      .add(schooExamMarksDoc);
  }

  // GET BOOKS BY ISBN
  getBooksByIsbn(isbns: any) {
    console.log(isbns);
    //  converto form data
    let formData: any = new FormData();
    formData.append('isbns', isbns);
    for (const value of formData.values()) {
      console.log(value);
    }
    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization', '48242_41a621d33a9a5bf3c4bdb1c907872eb7');
    // headers.set('Content-Type', 'application/json');

    // return this.httpClientSrvc_.post(
    //   `https://api2.isbndb.com/books`, formData, { headers });

    // use curl
    let headers = {
      'Content-Type': 'application/json',
      Authorization: '48242_41a621d33a9a5bf3c4bdb1c907872eb7',
    };
    return fetch('https://api2.isbndb.com/books', {
      method: 'POST',
      headers: headers,
      body: formData,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
