import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-nav-side-heading',
  templateUrl: './nav-side-heading.component.html',
  styleUrls: ['./nav-side-heading.component.scss']
})
export class NavSideHeadingComponent implements OnInit {
@Input() label: string = 'Hurrey'
  constructor() { }

  ngOnInit(): void {
  }

}
