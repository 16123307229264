import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { studentRoot } from '../models/student_root';
import { teacherRoot } from '../models/teacher_root';
import { DynamiclinkService } from './dynamiclink.service';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class SchoolOnboardService {
  constructor(
    private firestore: AngularFirestore,
    private dialogSrvc_: DialogService,
    private http: HttpClient
  ) {}

  classArray: any[] = [];

  classArrayBase: any[] = [
    {
      name: '1',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 2,
      subChecked: false,
      subState: 2,
      selectedSections: [],
      subjects: [
        'english language',
        'english literature',
        'evs',
        'gk',
        'hindi',
        'mathematics',
        'physical education',
        'value education',
      ],
      subjectAllocation: [],
    },
    {
      name: '2',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'english language',
        'english literature',
        'evs',
        'gk',
        'hindi language',
        'hindi literature',
        'mathematics',
        'physical education',
        'computer science',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
    {
      name: '3',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'english language',
        'english literature',
        'evs',
        'gk',
        'hindi language',
        'hindi literature',
        'mathematics',
        'physical education',
        'value education',
        'sanskrit',
        'computer science',
        'social studies',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
    {
      name: '4',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'evs',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskrit',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
    {
      name: '5',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'evs',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskrit',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },

    {
      name: '6',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskit',
        'biology',
        'physics',
        'chemistry',
        'civics',
        'geography',
        'history',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
    {
      name: '7',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskit',
        'biology',
        'physics',
        'chemistry',
        'civics',
        'geography',
        'history',
        'science',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
    {
      name: '8',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskit',
        'biology',
        'physics',
        'chemistry',
        'civics',
        'geography',
        'history',
        'science',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
    {
      name: '9',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskit',
        'biology',
        'physics',
        'chemistry',
        'civics',
        'geography',
        'history',
        'science',
        'commercial-studies',
        'computer applications',
        'economic applications',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
    {
      name: '10',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskit',
        'biology',
        'physics',
        'chemistry',
        'civics',
        'geography',
        'history',
        'science',
        'commercial-studies',
        'computer applications',
        'economic applications',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
    {
      name: '11',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskit',
        'biology',
        'physics',
        'chemistry',
        'civics',
        'geography',
        'history',
        'science',
        'commercial-studies',
        'computer applications',
        'economic applications',
        'accounts',
        'accountancy',
        'business studies',
        'political science',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
    {
      name: '12',
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskit',
        'biology',
        'physics',
        'chemistry',
        'civics',
        'geography',
        'history',
        'science',
        'commercial-studies',
        'computer applications',
        'economic applications',
        'accounts',
        'accountancy',
        'business studies',
        'political science',
      ],
      subjectAllocation: [
        {
          secName: 'a',
          selectedSubjects: [],
        },
        {
          secName: 'b',
          selectedSubjects: [],
        },
        {
          secName: 'c',
          selectedSubjects: [],
        },
      ],
    },
  ];

  subjectArray: any[] = [];

  addClass(name: string) {
    let newClass = {
      name: name.toLowerCase(),
      sections: ['a', 'b', 'c'],
      checked: false,
      state: 1,
      subChecked: false,
      subState: 1,
      selectedSections: [],
      subjects: [
        'computer science',
        'english language',
        'english literature',
        'hindi language',
        'hindi literature',
        'gk',
        'hindi',
        'mathematics',
        'value education',
        'social studies',
        'sanskit',
        'biology',
        'physics',
        'chemistry',
        'civics',
        'geography',
        'history',
        'science',
        'commercial-studies',
        'computer applications',
        'economic applications',
        'accounts',
        'accountancy',
        'business studies',
        'political science',
      ],
      subjectAllocation: [],
    };
    this.classArray.push(newClass);
  }

  saveClasses(Array: any[], schoolid: string) {
    let batch = this.firestore.firestore.batch();

    Array.forEach((ele) => {
      let autogenUid = this.firestore.createId();
      let ref = this.firestore
        .collection('schools')
        .doc(schoolid)
        .collection('classes')
        .doc(autogenUid).ref;

      // delete ele['id'];

      batch.set(ref, ele);
    });

    return batch.commit();
  }

  updateClasses(Array: any[], schoolid: string) {
    let batch = this.firestore.firestore.batch();

    Array.forEach((ele) => {
      let autogenUid = this.firestore.createId();
      if (ele.idToEdit) {
        autogenUid = ele.idToEdit;
      }
      let ref = this.firestore
        .collection('schools')
        .doc(schoolid)
        .collection('classes')
        .doc(autogenUid).ref;

      delete ele['idToEdit'];

      batch.set(ref, ele);
    });

    return batch.commit();
  }

  checkIfClasesPresent(schoolid: string) {
    return this.firestore
      .collection('schools')
      .doc(schoolid)
      .collection('classes')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a: any) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  updateDoc(schoolid: string, grades: string[], classCount: number) {
    return this.firestore.collection('schools').doc(schoolid).update({
      grades: grades,
      classes: classCount,
      modified_at: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  getUserId(body: { name: string; email: string; mobile: string }) {
    let url: string;

    if (environment.production === false) {
      url = 'https://us-central1-dev-hurrey.cloudfunctions.net/createUser';
    } else {
      url =
        'https://us-central1-hurreytech-b72f1.cloudfunctions.net/createUser';
    }

    console.log(url);

    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post(url, body, {
        headers,
        responseType: 'text',
      })
      .toPromise();
  }

  checkTeacherAlreadyExists(uid: string, sid: string) {
    return this.firestore
      .collection('school_teacher', (ref) =>
        ref
          .where('user_id', '==', uid)
          .where('school_id', '==', sid)
          .where('deleted', '==', false)
      )
      .get();
  }

  checkUserExistsinRoot(uid: string, sid: string, collectionName: string) {
    return this.firestore
      .collection(collectionName, (ref) =>
        ref
          .where('user_id', '==', uid)
          .where('school_id', '==', sid)
          .where('deleted', '==', false)
      )
      .get();
  }

  makeTeacherDoc(id: string, teacherDoc: teacherRoot) {
    let autogenUid = this.firestore.createId();
    return this.firestore.collection('school_teacher').add(teacherDoc);
  }

  makeRootDoc(id: string, Doc: any, collectionName: string) {
    return this.firestore.collection(collectionName).add(Doc);
  }

  makeStudentDoc(id: string, studentDoc: studentRoot) {
    return this.firestore.collection('school_student').add(studentDoc);
  }

  incrementTeacherCount(id: string) {
    return this.firestore
      .collection('schools')
      .doc(id)
      .update({
        teachers: firebase.firestore.FieldValue.increment(1),
      });
  }

  incrementCountOfKeyInSchoolDoc(id: string, keyname: string) {
    if (keyname == 'admin') {
      return this.firestore
        .collection('schools')
        .doc(id)
        .update({
          admins: firebase.firestore.FieldValue.increment(1),
        });
    } else if (keyname == 'student') {
      return this.firestore
        .collection('schools')
        .doc(id)
        .update({
          students: firebase.firestore.FieldValue.increment(1),
        });
    } else if (keyname == 'teacher') {
      return this.firestore
        .collection('schools')
        .doc(id)
        .update({
          teachers: firebase.firestore.FieldValue.increment(1),
        });
    } else {
      return Promise.reject(new Error('invalid key'));
    }
  }

  addEnrollmentNumber(id: string, code: string) {
    return this.firestore.collection('school_student').doc(id).update({
      enrollment_number: code,
    });
  }
}
