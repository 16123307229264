<div class="view_card">
  <!-- bg_green_base bg_red_base -->
  <div class="head flex flex-col gap-3 p-3 bg_blue_base color_white"
    [class.bg_blue_base]="eventDetails.type=='activities'" [class.bg_green_base]="eventDetails.type=='exams'"
    [class.bg_red_base]="eventDetails.type=='holidays'" [class.bg_blue_base]="eventDetails.type=='others'">
    <div class=" close bg_sky_light color_sky_light rounded-full flex items-end justify-end">
      <button class="bg_sky_light color_sky_light rounded-full " mat-dialog-close>
        <img src="../../../../assets/icons/event-close.svg" alt="back" class="back-button">
      </button>
    </div>
    <div class="flex flex-col gap-3 ">
      <h3 class="heading3">{{eventDetails.title|titlecase}}</h3>
      <h3 class="heading3 text-white">From- {{eventDetails.from|date:'d,MMM y hh:mm
        aaa'}}</h3>
        <h3 class="heading3 text-white">To- {{eventDetails.to|date:'d,MMM y hh:mm
        aaa'}}</h3>
    </div>
  </div>
  <div class="content p-5 flex flex-col gap-3">
    <p class=" small_text color_ink_base">For Classes</p>
    <h3 class="heading3">{{eventDetails?.classes}}</h3>
  </div>
  <div class="horizontal_line"></div>
  <div class="foot flex justify-center items-center gap-3 p-5 cursor-pointer" (click)="editevent()">
    <img src=".././../../../assets/images/icons/edit.svg" alt="">
    <p class="heading3">Edit Event</p>
  </div>
  <div class="foot flex justify-center items-center gap-3 p-5 cursor-pointer" (click)="deleteAlert()">
    <img src=".././../../../assets/images/icons/delete.svg" alt="">
    <p class="heading3">Delete Event</p>
  </div>
</div>
