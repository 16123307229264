import { environment } from './../../../../environments/environment.prod';
import { firebaseUser } from './../models/firebaseUser';
import { Injectable, NgZone } from '@angular/core';
// import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';

// const auth2 = auth.Auth();
import firebase from 'firebase/app';
import 'firebase/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngxs/store';

import { UserCheckService } from './user-check.service';

import { schoolForStore } from '../models/schoolForStore';
import { RacesActions } from 'src/app/shared/store/school-admin/school-admin.action';
import { schoolAdminState } from 'src/app/shared/store/school-admin/school-admin.state';
import { SchoolActions } from 'src/app/shared/store/school/school-list.action';
import { StateClear } from 'ngxs-reset-plugin';
import { schoolListState } from 'src/app/shared/store/school/school-list.state';
import {
  removeSelectedAdmin,
  SelectedSchool,
} from 'src/app/shared/store/school/selected-school.action';
import {
  Disconnect,
  ngxsFirectoreConnections,
} from '@ngxs-labs/firestore-plugin';
import { removeUser, UserData } from 'src/app/shared/store/user/user.action';
import { TeachersActions } from 'src/app/shared/store/teachers/teachers.action';
import { StudentsActions } from 'src/app/shared/store/students/student.action';
import { RemoveClasses } from 'src/app/shared/store/class-list/class-list.action';
import { CommonService } from 'src/app/shared/services/common.service';
import { removeAdmins } from 'src/app/shared/store/currentSchoolAdmins/currentSchoolAdmin.action';
import { SelectedSchoolState } from 'src/app/shared/store/school/selected-school.state';
import { id } from 'date-fns/locale';
import { invitationRemove } from 'src/app/shared/store/invitation/invitation.action';
import { removeExamsList } from 'src/app/shared/store/exam-center/exam-center.action';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private http: HttpClient,
    private store: Store,
    private commonSrvc_: CommonService
  ) {
    /* Saving user data   when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        //console.log('logged in');
        // this.store.dispatch(new RacesActions.GetAllSchoolAdmin(user.uid));

        // localStorage.setItem('user', JSON.stringify(this.userData));
        // JSON.parse(localStorage.getItem('user'));

        // this.checkSchoolAdmin(user.uid);
      } else {
        //console.log('not logged in');
        localStorage.setItem('user', '');
        //console.log(this.store.snapshot());

        // JSON.parse(localStorage.getItem('user'));
      }
    });
    let allconnections = this.store
      .select(ngxsFirectoreConnections)
      .subscribe((el) => {
        //console.log(el);
      });
  }

  setAllStatesAndRedirect(user: any, sid?: string) {
    //console.log('setallstates');
    this.store.dispatch(new UserData(user)).subscribe(() => {
      //console.log(this.store.snapshot());
      this.checkSchoolAdmin(user.uid, sid);
    });
  }

  checkSchoolAdmin(id: string, sid?: string) {
    //console.log(id);

    // this.store.dispatch(new RacesActions.GetAllSchoolAdmin(id));
    // this.getSchools(id);

    this.commonSrvc_.getUsersAdminList(id).subscribe((el: any[]) => {
      //console.log(el);
      this.store.dispatch(new RacesActions.schoolAdminsListNormal(el));

      if (sid) {
        this.loopNFetchSchools(el, sid);
      } else {
        this.loopNFetchSchools(el);
      }
    });

    //console.log(this.store.snapshot());
  }

  loopNFetchSchools(el: any[], sid?: string) {
    if (el.length) {
      let ids: any[] = el.map((el, i) => {
        if (sid) {
          if (sid === el.school_id) {
            this.store.dispatch(new SelectedSchool(el));
            this.store.dispatch(
              new TeachersActions.GetAllTeachers(el.school_id as string)
            );
            this.store.dispatch(
              new StudentsActions.getAllStudents(el.school_id as string)
            );
          }
        } else {
          if (i == 0) {
            // this.commonSrvc_.getCurrentSchoolDetails(el.school_id as string);
            this.store.dispatch(new SelectedSchool(el));
            this.store.dispatch(
              new TeachersActions.GetAllTeachers(el.school_id as string)
            );
            this.store.dispatch(
              new StudentsActions.getAllStudents(el.school_id as string)
            );
          }
        }
        return el.school_id;
      });
      // //console.log(ids);
      ids.forEach((id) => {
        this.store.dispatch(new SchoolActions.GetSchool(id));
      });

      this.store.dispatch(new invitationRemove());

      this.router.navigate(['layout/dashboard']);

      let allconnections = this.store
        .select(ngxsFirectoreConnections)
        .subscribe((el) => {
          //console.log(el);
        });
    } else {
      alert('no you are not an admin in any schools');
      // this.checkSchoolAdmin(id);
      // this.router.navigate(['layout/dashboard']);
    }
  }

  getSchools(id: string) {
    this.store.select(schoolAdminState.getadminlist).subscribe((el) => {
      //console.log(el);
      // let currentSchoolDetails=this.commonSrvc_.getCurrentSchoolDetails;

      if (el.length) {
        let ids: any[] = el.map((el, i) => {
          if (i == 0) {
            // this.commonSrvc_.getCurrentSchoolDetails(el.school_id as string);
            this.store.dispatch(new SelectedSchool(el));
            this.store.dispatch(
              new TeachersActions.GetAllTeachers(el.school_id as string)
            );
            this.store.dispatch(
              new StudentsActions.getAllStudents(el.school_id as string)
            );
          }
          return el.school_id;
        });
        // //console.log(ids);
        ids.forEach((id) => {
          this.store.dispatch(new SchoolActions.GetSchool(id));
        });

        this.router.navigate(['layout/dashboard']);

        let allconnections = this.store
          .select(ngxsFirectoreConnections)
          .subscribe((el) => {
            //console.log(el);
          });
      } else {
        // alert('no data fetched');
        // this.checkSchoolAdmin(id);
      }
    });

    //get schools

    // this.store.select(schoolListState.schools).subscribe((el) => {
    //   //console.log(el);
    // });

    // fetteachers
    this.store
      .select(SelectedSchoolState.getselectedAdminSchoolId)
      .subscribe((schoolid) => {
        //console.log('123');
        this.store.dispatch(
          new TeachersActions.GetAllTeachers(schoolid as string)
        );
      });
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  // Send email verfificaiton when new user sign up
  // SendVerificationMail() {
  //   return this.afAuth.currentUser.sendEmailVerification().then(() => {
  //     this.router.navigate(['verify-email-address']);
  //   });
  // }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user') as string);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Sign in with Facebook
  FacebookAuth() {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth.signInWithPopup(provider);
  }

  checkUserAndRedirect(user: firebaseUser) {
    let uid = user.uid;
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: firebaseUser) {
    //console.log(user);
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(
    //   `users/${user.uid}`
    // );
    // const userData: firebaseUser = {
    //   uid: user.uid,
    //   email: user.email,
    //   displayName: user.displayName,
    //   photoURL: user.photoURL,
    //   emailVerified: user.emailVerified,
    // };
    // return userRef.set(userData, {
    //   merge: true,
    // });
  }

  clearAllstate() {
    let schoolid =
      this.store.snapshot().SelectedAdmin?.selectedAdmin?.school_id;

    this.store.dispatch(new Disconnect(new SchoolActions.GetSchool(schoolid)));
    this.store.dispatch(
      new Disconnect(new TeachersActions.GetAllTeachers(schoolid))
    );
    this.store.dispatch(
      new Disconnect(new StudentsActions.getAllStudents(schoolid))
    );

    this.store.dispatch(new removeUser(''));
    //console.log(this.store.snapshot());
    this.store.dispatch(
      new Disconnect(new RacesActions.GetAllSchoolAdmin(this.userData.uid))
    );
    this.store.dispatch(new StudentsActions.removeStudentlist());
    this.store.dispatch(new StateClear());
    localStorage.clear();
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      let schoolid =
        this.store.snapshot().SelectedAdmin?.selectedAdmin?.school_id;
      let uid = this.store.snapshot().UserData?.userData.uid;

      // this.store.dispatch(new RacesActions.GetAllSchoolAdmin(uid));
      this.store.dispatch(
        new Disconnect(new SchoolActions.GetSchool(schoolid))
      );
      this.store.dispatch(
        new Disconnect(new TeachersActions.GetAllTeachers(schoolid))
      );
      this.store.dispatch(
        new Disconnect(new StudentsActions.getAllStudents(schoolid))
      );
      // this.store.dispatch(new SchoolActions.removeSchools([]));
      // this.store.dispatch(new removeSelectedAdmin(''));
      // this.store.dispatch(new RemoveClasses());
      // this.store.dispatch(new removeAdmins());
      // this.store.dispatch(new RacesActions.removeAdminlist(''));
      // this.store.dispatch(new TeachersActions.removeTeacherlist());

      this.store.dispatch(new removeUser(''));
      //console.log(this.store.snapshot());
      this.store.dispatch(
        new Disconnect(new RacesActions.GetAllSchoolAdmin(this.userData.uid))
      );
      this.store.dispatch(new StudentsActions.removeStudentlist());
      this.store.dispatch(new StateClear());
      this.store.dispatch(new removeExamsList());
      localStorage.clear();
      this.router.navigate(['']);
    });
  }

  fetchSigniInMethodsForEmail(email: string) {
    return this.afAuth.fetchSignInMethodsForEmail(email);
  }

  linkWithFacebook(cred: any) {
    const auth = firebase.auth();
    // var facebookProvider = new firebase.auth.FacebookAuthProvider();
    return auth.currentUser?.linkWithCredential(cred);
  }

  linkWithGoogle(cred: any) {
    const auth = firebase.auth();

    // var googleProvider = new firebase.auth.GoogleAuthProvider();
    return auth.currentUser?.linkWithCredential(cred);
  }

  sentOtpEmail(emailId: string, temp: string, otp: string) {
    var res = temp.replace('$$CODE$$', otp);
    //console.log(emailId);
    let obj = {
      subject: 'Hurrey Account Verification',
      body: res,
      email: [emailId],
    };

    let body = JSON.stringify(obj);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    );
    httpOptions.headers.append(
      'Access-Control-Allow-Headers',
      'Origin, Content-Type, X-Auth-Token'
    );
    httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

    let url = '';
    if (environment.production === false)
      url = 'https://us-central1-dev-hurrey.cloudfunctions.net/sendEmail';
    else
      url = 'https://us-central1-hurreytech-b72f1.cloudfunctions.net/sendEmail';

    return this.http.post(url, body, httpOptions).toPromise();
  }

  signinWithMobile(num: string, appVerifier: any) {
    return firebase.auth().signInWithPhoneNumber(num, appVerifier);
  }

  resetpass(email: string) {
    let url = '';

    if (environment.production === false) {
      url = 'https://dev-hurrey.web.app/';
    } else {
      url = 'https://dev-hurrey.web.app/';
    }

    //console.log(email);

    return this.afAuth.sendPasswordResetEmail(email, {
      url: url,
    });
  }

  createToken() {
    let url =
      'https://us-central1-dev-hurrey.cloudfunctions.net/customAuthToken-1';

    let uid = 'D3fiq80lFcOTkI5Dp4oBHtOvVyu2';

    let body = {
      uid: uid,
    };

    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post(url, body, {
        headers,
        responseType: 'text',
      })
      .toPromise();
  }

  convertToken(token: string) {
    return firebase.auth().signInWithCustomToken(token);
  }

  getidtoken() {
    firebase
      .auth()
      .currentUser?.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // Send token to your backend via HTTPS
        // ...
        //console.log('id token is', idToken);
      })
      .catch(function (error) {
        // Handle error
      });
  }
}
