export class SchoolExamsList {
  static readonly type = '[schoolExams] get';
  constructor(public exams: any) {}
}

export class addSchoolExam {
  static readonly type = '[schoolExam] add';
  constructor(public exam: any) {}
}

export class deleteSchoolExam {
  static readonly type = '[schoolExam] deleteExam';
  constructor(public exam: any) {}
}

export class updateSchoolExam {
  static readonly type = '[schoolExam] updateExam';
  constructor(public exam: any) {}
}

export class removeExamsList {
  static readonly type = '[removeExamsList] remove';
}

export class SelectedSubjectExamDetails {
  static readonly type = '[examCenter] selectedSubjectExamDetails';
  constructor(public selectedSubjectExamDetails: any) {}
}

export class removeSelectedSubjectExamDetails {
  static readonly type = '[examCenter] removeSelectedSubjectExamDetails';
}

export class StudentMarksEntryDetails {
  static readonly type = '[examCenter] studentMarksEntryDetails';
  constructor(public studentMarksEntryDetails: any) {}
}

export class removeStudentMarksEntryDetails {
  static readonly type = '[examCenter] removeStudentMarksEntryDetails';
}
