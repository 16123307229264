import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { TimetableSchoolCreateComponent } from './pages/timetable/timetable-school/timetable-school-create/timetable-school-create.component';

const redirectUnauthorizedToLogging = () => redirectUnauthorizedTo(['/login']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'layout',
    loadChildren: () =>
      import('./pages/layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogging },
  },
  {
    path: 'school-onboard-deleted ',
    loadChildren: () =>
      import('./pages/school-onboard/school-onboard.module').then(
        (m) => m.SchoolOnboardModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogging },
  },
  // {
  //   path: '',

  //   component: TimetableSchoolCreateComponent,
  //   canActivate: [AngularFireAuthGuard],
  //   data: { authGuardPipe: redirectUnauthorizedToLogging },
  // },
  // {
  //   path: 'onboard-school/:type',
  //   loadChildren: () =>
  //     import('./pages/onboard-school/onboard-school.module').then(
  //       (m) => m.OnboardSchoolModule
  //     ),
  //   data: { authGuardPipe: redirectUnauthorizedToLogging },
  // },
  {
    path: 'invitation',
    loadChildren: () =>
      import('./auth/invitation/invitation.module').then(
        (m) => m.InvitationModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
