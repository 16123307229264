// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config: {
    apiKey: 'AIzaSyDjgsIvnqGvDo9B1kcH55s5v0HXvLpnDbo',
    authDomain: 'dev-hurrey.firebaseapp.com',
    projectId: 'dev-hurrey',
    storageBucket: 'dev-hurrey.appspot.com',
    messagingSenderId: '968893484382',
    appId: '1:968893484382:web:da0f5e92a28be8454ad3cf',
    measurementId: 'G-RJ61ZHK7JZ',
  },
};

// export const environment = {
//   config: {
//     apiKey: 'AIzaSyANCDEETPYGzgu4iwmenGLZxddjglBoVnU',
//     authDomain: 'hurreytech-b72f1.firebaseapp.com',
//     projectId: 'hurreytech-b72f1',
//     storageBucket: 'hurreytech-b72f1.appspot.com',
//     messagingSenderId: '866357892195',
//     appId: '1:866357892195:web:6deccd15ad7b2bac88d456',
//     measurementId: 'G-5VCBRPEGM5',
//   },
//   production: false,
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
