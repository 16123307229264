import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[lessThanStart]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: EndDateValidDirective, multi: true },
  ],
})
export class EndDateValidDirective {
  @Input('lessThanStart') shouldBeLess: any;
  constructor() {}
  validate(control: AbstractControl): { [key: string]: boolean } | null {
    console.log(this.shouldBeLess);
    console.log(control.value);
    const sDate = new Date(this.shouldBeLess);
    console.log(sDate);
    const eDate = new Date(control.value);
    // console.log(sDate > eDate ? { 'End Date is less than start': true } : null);

    return sDate > eDate ? { End_Date_is_less_than_start: true } : null;
  }
}
