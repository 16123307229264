import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-onboard-alert',
  templateUrl: './onboard-alert.component.html',
  styleUrls: ['./onboard-alert.component.scss'],
})
export class OnboardAlertComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public modal: MatDialog
  ) {}

  ngOnInit(): void {}
}
