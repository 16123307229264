import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { patch } from '@ngxs/store/operators';
import {
  teacherSubstituteAdd,
  teacherSubstituteRemove,
} from './teacher-substitute.action';

@State<any>({
  name: 'teacherSubstitute',
  defaults: {
    teacherSubstituteData: {},
  },
})
@Injectable()
export class teacherSubstituteState {
  constructor() {}

  @Selector() static getTeacherSubstituteData(state: any) {
    return state.teacherSubstituteData;
  }

  @Action(teacherSubstituteAdd)
  setUserData(
    ctx: StateContext<any>,
    { teacherSubstituteData }: teacherSubstituteAdd
  ) {
    ctx.setState(
      patch({
        teacherSubstituteData: teacherSubstituteData,
      })
    );
  }

  @Action(teacherSubstituteRemove)
  removeSchools(ctx: StateContext<any>) {
    ctx.setState(patch({ teacherSubstituteData: {} }));
  }
}
