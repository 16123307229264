import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import { school } from 'src/app/auth/shared/models/school';

@Injectable({
  providedIn: 'root',
})
export class SchoolFirestore extends NgxsFirestore<school> {
  protected path = 'schools';
}
