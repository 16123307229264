import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { OnboardAlert } from 'src/app/shared/models/onboard-alert.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { EventService } from 'src/app/shared/services/event.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ClassListState } from 'src/app/shared/store/class-list/class-list.state';

@Component({
  selector: 'app-event-view-dialog',
  templateUrl: './event-view-dialog.component.html',
  styleUrls: ['./event-view-dialog.component.scss'],
  providers: [TitleCasePipe],
})
export class EventViewDialogComponent implements OnInit {
  eventDetails: any;
  schoolClasses: any[] = [];
  sub1: Subscription;
  eventToUpdate: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private titleCase: TitleCasePipe,
    private dialogSrvc_: DialogService,
    private eventSrvc_: EventService,
    private snackbarSrvc_: SnackbarService
  ) {}

  ngOnInit(): void {
    this.sub1 = this.store.select(ClassListState).subscribe((el) => {
      this.schoolClasses = el.classList;
    });
    if (!!this.data) {
      this.eventDetails = this.data;
      this.eventToUpdate = this.data;
      console.log(this.eventToUpdate);
      this.eventDetails['classes'] = this.mapClassesToEvents(
        this.eventDetails.class_ids
      );
      console.log(this.eventDetails);
    }
  }

  mapClassesToEvents(class_ids: any[]) {
    let classArray = [];
    classArray = class_ids.map((class_id: any) => {
      let classFound = this.schoolClasses.find(
        (clas: any) => clas.id == class_id
      );
      if (!!classFound) {
        return `${classFound.name} ${this.titleCase.transform(
          classFound.section
        )}`;
      } else {
        return;
      }
    });
    return classArray;
  }

  editevent() {
    this.dialogSrvc_.dismissAll();
    this.dialogSrvc_.openEventNewDialog({
      edit: true,
      event: this.eventToUpdate,
      type: '',
    });
  }

  // deleteEvent() {
  //   this.eventSrvc_.deleteEvent(this.eventToUpdate.id).then(() => {
  //     this.snackbarSrvc_.openSnackBarTop('Event Deleted Successfully', 'Alert');
  //     this.dialogSrvc_.dismissAll();
  //   });
  // }

  deleteAlert() {
    this.dialogSrvc_.dismissAll();
    let obj: OnboardAlert = {
      title: 'Alert',
      message: 'Do you really want to delete this event?',
      primaryButton: 'Yes',
      secondaryButton: 'No',
      primaryCallBackFn: this.deleteEvent,
    };
    this.dialogSrvc_.openOnboardAlertDialog(obj);
  }

  get deleteEvent() {
    const delFun = () => {
      this.eventSrvc_
        .deleteEvent(this.eventToUpdate.id)
        .then(() => {
          this.snackbarSrvc_.openSnackBarTop(
            'Event deleted successfully!!',
            'Success'
          );
          this.dialogSrvc_.dismissAll();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return delFun.bind(this);
  }
}
