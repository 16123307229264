import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { patch } from '@ngxs/store/operators';
import {
  selectedGradeForFeeManagmentNaviagtion,
  selectedGradeSectionForFeeManagmentNaviagtion,
  selectedStudentForFeeManagmentNaviagtion,
} from './feeManagmentNavigation.action';

@State<any>({
  name: 'FeeManagmentNavigation',
  defaults: {
    gradeSelected: {},
    gradeSectionSelected: {},
    studentSelected: {},
  },
})
@Injectable()
export class SelectedFeeManagmentForNavigationState {
  @Selector() static getSelectedGradeFMS(state: any) {
    return state.gradeSelected;
  }

  @Selector() static getSelectedGradeSectionFMS(state: any) {
    return state.gradeSectionSelected;
  }

  @Selector() static getSelectedStudentFMS(state: any) {
    return state.studentSelected;
  }
  constructor() {}

  @Action(selectedGradeForFeeManagmentNaviagtion)
  setGradeFMS(
    ctx: StateContext<any>,
    { data }: selectedGradeForFeeManagmentNaviagtion
  ) {
    ctx.setState(
      patch({
        gradeSelected: data,
      })
    );
  }

  @Action(selectedGradeSectionForFeeManagmentNaviagtion)
  setGradeSectionFMS(
    ctx: StateContext<any>,
    { data }: selectedGradeSectionForFeeManagmentNaviagtion
  ) {
    ctx.setState(
      patch({
        gradeSectionSelected: data,
      })
    );
  }

  @Action(selectedStudentForFeeManagmentNaviagtion)
  setStudentFMS(
    ctx: StateContext<any>,
    { data }: selectedStudentForFeeManagmentNaviagtion
  ) {
    ctx.setState(
      patch({
        studentSelected: data,
      })
    );
  }
}
