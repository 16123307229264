// namespace RacesActionsPayloads {
//   export type Update = Partial<Race>;
//   export type Upsert = Partial<Race>;
//   export type Create = Partial<Race>;
// }

export namespace RacesActions {
  export class GetAllOnce {
    public static readonly type = '[Races] GetAllOnce';
  }
  export class GetOnce {
    public static readonly type = '[Races] GetOnce';
    constructor(public payload: string) {}
  }
  export class Get {
    public static readonly type = '[Races] Get';
    constructor(public payload: string) {}
  }
  export class GetAllSchoolAdmin {
    public static readonly type = '[SchoolAdmin] GetAll';
    constructor(public payload: string) {}
  }

  export class Delete {
    public static readonly type = '[Races] Delete';
    constructor(public payload: string) {}
  }
  export class AppendAdmin {
    public static readonly type = '[Races] append admin';
    constructor(public payload: any) {}
  }

  export class removeAdminlist {
    static readonly type = '[removeAdminlist] removeAdminlist';
    constructor(public schools: string) {}
  }

  export class schoolAdminsListNormal {
    static readonly type = '[schoolAdminsListNormal] schoolAdminsListNormal';
    constructor(public list: any[]) {}
  }
}
