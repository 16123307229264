import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class DynamiclinkService {
  shareLink: string;
  whatsalink: string;
  postUrl = '';
  private devPostUrl: string =
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDjgsIvnqGvDo9B1kcH55s5v0HXvLpnDbo';
  private prodPostUrl: string =
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyD2TZXni6yJcbPuMy78b_LjqXrI1yaBWgU';

  // private url: string = 'http://localhost:4200/';
  // private prodUrl: string = 'http://localhost:4200/';

  // private url: string = 'https://dev-hurrey.web.app/';
  // private prodUrl: string = 'https://dev-hurrey.web.app/';
  domainPrefix: string = '';
  private devDomainPrefix: string = 'https://hurreytech.page.link';
  private prodDomainPrefix: string = 'share.hurreytech.com';
  spinner = false;

  constructor(
    private http: HttpClient,
    private dialogSrvc_: DialogService,
    private store: Store
  ) {
    this.checkAndSetProd();
  }

  getDynamicUrl = (url: string) => {
    console.log(url, 'link');
    this.checkAndSetProd();
    return this.http.post(this.postUrl, {
      dynamicLinkInfo: {
        domainUriPrefix: this.domainPrefix,
        link: url,
        androidInfo: {
          androidPackageName: 'com.hurrey.superapp',
        },
      },
    });
  };

  checkAndSetProd() {
    if (environment.production) {
      console.log('im on prod');
      this.postUrl = this.prodPostUrl;
      this.domainPrefix = this.prodDomainPrefix;
      // this.url = this.prodUrl;
    } else {
      console.log('im on dev');
      this.postUrl = this.devPostUrl;
      this.domainPrefix = this.devDomainPrefix;
      // this.url = this.devPostUrl;
    }
  }

  getUrl(role: string) {
    let url = '';
    let prodUrl = '';
    if (role == 'admin') {
      url = 'https://hurrey-master.web.app/';
      prodUrl = 'https://home.hurreytech.com/';
    }

    if (role == 'teacher') {
      url = 'https://hurrey-master.web.app/';
      prodUrl = 'https://home.hurreytech.com/';
      // this.url = 'http://localhost:4200/';
      // this.prodUrl = 'http://localhost:4200/';
    }

    if (role == 'student') {
      url = 'https://hurrey-master.web.app/';
      prodUrl = 'https://home.hurreytech.com/';
      // this.url = 'http://localhost:4200/';
      // this.prodUrl = 'http://localhost:4200/';
    }

    if (environment.production) {
      url = prodUrl;
    } else {
      url = url;
    }
    return url;
  }

  createDynamicLink(
    schoolid: string,
    schoolName: string,
    role: string,
    classId?: string
  ) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 10);
    console.log(tomorrow.getDate());
    let day =
      tomorrow.getDate() > 9
        ? `${tomorrow.getDate()}`
        : `0${tomorrow.getDate()}`;
    let month =
      tomorrow.getMonth() + 1 > 9
        ? `${tomorrow.getMonth() + 1}`
        : `0${tomorrow.getMonth() + 1}`;
    let year = `${tomorrow.getFullYear()}`;
    let ddmmyy = `${day}${month}${year}`;
    console.log(ddmmyy);
    let url: string = this.getUrl(role);
    console.log(url);
    let requestUrl = '';
    if (role == 'student') {
      requestUrl = `${url}invitation?validity=${ddmmyy}&type=school&id=${schoolid}_${classId}&role=${role}&name=${schoolName}`;
    } else {
      requestUrl = `${url}invitation?validity=${ddmmyy}&type=school&id=${schoolid}&role=${role}&name=${schoolName}`;
    }
    this.getDynamicUrl(requestUrl).subscribe((data: any) => {
      this.shareLink = data.shortLink;
      this.whatsalink = `https://web.whatsapp.com/send?text=Use ${
        this.shareLink
      } link to join ${schoolName.toUpperCase()} -Link is valid for 10 days`;

      let obj = {
        name: role,
        shareLink: this.shareLink,
        whatsapplink: this.whatsalink,
        class: classId != '' ? classId : '',
      };
      console.log(obj);
      this.spinner = false;

      // return obj;
      this.dialogSrvc_.dismissAll();
      this.dialogSrvc_.openOnboardDialog(obj);
    });
  }

  openModal(type: string, classID?: string) {
    // this.dialogSrvc_.dismissAll();
    this.spinner = true;
    let store = this.store.snapshot();
    let schoolid = store.SelectedAdmin?.selectedAdmin?.school_id;
    let schoolList = store.schoolList.schools;
    let selectedSchool = schoolList.find((el: any) => el.id == schoolid);

    if (type == 'teacher') {
      this.createDynamicLink(
        schoolid,
        selectedSchool.name.join(' '),
        'teacher'
      );
    } else if (type == 'admin') {
      this.createDynamicLink(schoolid, selectedSchool.name.join(' '), 'admin');
    } else {
      // this.createDynamicLink(
      //   schoolid,
      //   selectedSchool.name.join(' '),
      //   'student'
      // );
      let obj = {
        name: type,
        shareLink: 'no-link',
        whatsapplink: '',
        classId: classID != '' ? classID : '',
      };
      this.createDynamicLink(
        schoolid,
        selectedSchool.name.join(' '),
        'student',
        classID
      );
    }
  }
}

// https://school.hurreytech.com/invitation/validity=03022022/type=school/id=eCae7s51VNaOJvqXxE18/role=admin/name=Delhi%20Public%20School
// http://localhost:4200/invitation/validity-03022022/type-school/id-HCzl1zrkGr8qhGL0rzGb/role-admin/name=kendriya vidyalaya
