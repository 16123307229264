import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { logout, removeUser, UserData } from './user.action';
import { patch } from '@ngxs/store/operators';

@State<any>({
  name: 'UserData',
  defaults: {},
})
@Injectable()
export class UserDataState {
  constructor() {}

  @Action(UserData)
  setUserData(ctx: StateContext<any>, { userData }: UserData) {
    ctx.setState(
      patch({
        userData,
      })
    );
  }

  @Action(removeUser)
  removeSchools(ctx: StateContext<any>, { clear }: removeUser) {
    ctx.setState(patch({ userData: {} }));
  }
}
