import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navback',
  templateUrl: './navback.component.html',
  styleUrls: ['./navback.component.scss']
})
export class NavbackComponent implements OnInit {

  @Input() label: string;
  @Input() isBackButton: boolean = true;
  @Input() routeUrl: String
  @Input()  color: String = 'primary';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  OnClick() {
    if (this.routeUrl) {
      this.router.navigate([this.routeUrl]);

    } else {
      window.history.back();
    }

  }
}
