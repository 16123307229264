import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbackComponent } from './navback/navback.component';
import { RoundedImageComponent } from './rounded-image/rounded-image.component';
import { CarouselOneComponent } from './carousel-one/carousel-one.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { DirectivesModule } from '../shared/directives/directives.module';
import { HButtonComponent } from './h-button/h-button.component';
import { BarchartComponent } from './barchart/barchart.component';
import { NoRoundedImageComponent } from './no-rounded-image/no-rounded-image.component';
import {
  TippyModule,
  tooltipVariation,
  popperVariation,
} from '@ngneat/helipopper';
import { NoDateFoundDirective } from './no-date-found.directive';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { NoDataComponent } from './no-data/no-data.component';
import { NavSideHeadingComponent } from './nav-side-heading/nav-side-heading.component';
import { ButtonsComponent } from './buttons/buttons/buttons.component';
import { AnimationComponent } from './animations/animation/animation.component';
import { AccordionComponent } from './accordion/accordion.component';
import { MaterialModule } from '../material/material.module';
import { CarouselComponent } from './carousel/carousel.component';
import { HoverDropdownComponent } from './hover-dropdown/hover-dropdown.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { EmptyComponent } from './empty/empty.component';
import { FloatingOnboardCardComponent } from './floating-onboard-card/floating-onboard-card.component';
import { MatDialogRef } from '@angular/material/dialog';
export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    NavbackComponent,
    RoundedImageComponent,
    CarouselOneComponent,
    ProgressbarComponent,
    HButtonComponent,
    BarchartComponent,
    NoRoundedImageComponent,
    NoDateFoundDirective,
    NoDataFoundComponent,
    NoDataComponent,
    NavSideHeadingComponent,
    ButtonsComponent,
    AnimationComponent,
    AccordionComponent,
    CarouselComponent,
    HoverDropdownComponent,
    EmptyComponent,
    FloatingOnboardCardComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    MaterialModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [
    NavbackComponent,
    RoundedImageComponent,
    CarouselOneComponent,
    ProgressbarComponent,
    HButtonComponent,
    BarchartComponent,
    NoRoundedImageComponent,
    NoDataFoundComponent,
    NoDataComponent,
    NavSideHeadingComponent,
    ButtonsComponent,
    AnimationComponent,
    AccordionComponent,
    CarouselComponent,
    HoverDropdownComponent,
    EmptyComponent,
    FloatingOnboardCardComponent,
  ],
  providers: [{ provide: MatDialogRef, useValue: {} }],
})
export class ReusableComponentsModule {}
