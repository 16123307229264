export class GetSchoolCouncils {
  static readonly type = '[schoolCouncils] getAllSchoolCouncils';
  constructor(public schoolCouncils: any) {}
}

export class selectedCouncil {
  static readonly type = '[schoolCouncil] getSelectedCouncil';
  constructor(public schoolCouncil: any) {}
}

export class removeSelectedCouncil{
  static readonly type = '[schoolCouncil] removeSelectedCouncil';
}
